import React from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import Button from "@material-ui/core/Button";

const StyledButton = withStyles({
    root: {
        background: colors.darkError,
        borderRadius: 3,
        border: 0,
        color: "        white",
        height: 36,
        padding: "0 30px",
        boxShadow: colors.black,
        "&:hover": {
            filter: "brightness(0.8)",
            backgroundColor: colors.darkError,
        },
    },
    label: {
        textTransform: "none",
        fontWeight: "bold",
    },
})(Button);

export default function DeleteButton(props) {
    return <StyledButton {...props}>{props.text}</StyledButton>;
}
