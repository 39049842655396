import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./theme";
import Header from "../../Common/Header";
import Grid from "@material-ui/core/Grid";
import { fetch } from "../../../store/pages/actions";
import { connect } from "react-redux";
import SkeletonListPage from "../../Common/SkeletonListPage";
import MainPagesTable from "./Partials/MainPagesTable";
import i18next from "i18next";

class Index extends React.Component {
    componentDidMount() {
        this.props.fetchPages();
    }
    render() {
        const { loading } = this.props;
        return (
            <div>
                {loading ? (
                    <SkeletonListPage />
                ) : (
                    <Grid item container spacing={2}>
                        <Header
                            title={i18next.t("sidebar.pages")}
                            breadcrumbs={{
                                contents: i18next.t("sidebar.contents"),
                                pages: i18next.t("sidebar.pages"),
                            }}
                        />

                        <Grid item xs={12}>
                            <MainPagesTable data={this.props.data} userLang={this.props.userLang} />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.pages.data,
        loading: state.pages.loading,
        userLang: state.authentication.user.language,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchPages: (form) => dispatch(fetch(form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Index));
