import React from "react";
import TextMenuItem from "../Common/TextMenuItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import colors from "../../styles/colors.scss";

/*
 * Használat:
 * <Topic
 *   key={kulcs}
 *   text="Téma neve"
 *   children={<React.Fragment>...menüpontok...</React.Fragment>} (vagy <> és </>, ahogy jobban tetszik)
 * />
 */

export default function Topic(props) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const hasChildren = !props.children.props.children.every((item) => item === false);

    return (
        hasChildren && (
            <React.Fragment>
                <TextMenuItem
                    style={{
                        backgroundColor: colors.lightPrimary,
                        color: colors.primary,
                    }}
                    onClick={handleClick}
                    {...props}
                >
                    <Typography variant="inherit">{props.text}</Typography>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </TextMenuItem>
                <Collapse in={open} timeout="auto" unmountOnExit children={props.children} />
                <Divider />
            </React.Fragment>
        )
    );
}
