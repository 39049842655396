import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import colors from "../../styles/colors.scss";
import MenuList from "@material-ui/core/MenuList";
import Topic from "../Common/Topic";
import MenuItem from "@material-ui/core/MenuItem";
import CourseCalendar from "../Common/CourseCalendar";
import MainNews from "./MainNews";
import Paper from "@material-ui/core/Paper";
import TabPanel from "../Common/TabPanel";
import { TablePagination, useMediaQuery, useTheme } from "@material-ui/core";
import mainAction from "../../store/main/actions";
import loginAction from "../../store/login/actions";
import { getUserPrivacyStatementCourseAction, updateUserPrivacyStatementCourseAction } from "../../store/pages/actions";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import moment from "moment";
import * as AppConst from "../../utils/AppConst";
import AntTabs from "../Common/AntTabs";
import i18next from "i18next";
import PrivacyStatementCourse from "../PrivacyStatementCourse";
import { getImpersonationToken, getJWTToken } from "../../utils/AuthHelper";
import axios from "axios";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    primaryColor: {
        backgroundColor: colors.primary,
    },
    tabsLayout: {
        justifyContent: "space-evenly",
    },
    indicator: {
        backgroundColor: "white",
    },
    textColor: {
        color: "white",
    },
    link: {
        width: "100%",
        height: "100%",
        color: "#000",
        textDecoration: "initial",
        padding: "10px 10px",
        "&:hover": {
            color: colors.darkPrimary,
        },
    },
    menuItem: {
        padding: "0",
        "&:hover": {
            backgroundColor: colors.lightPrimary,
        },
    },
    menuItemEmpty: {
        cursor: "default",
    },
    paginator: {
        maxWidth: "100%",
    },
});

//useMediaQuery-t csak függvénykomponensekben lehet használni, ezért külön vettem ezt a részt
const ToUseHook = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <AntTabs
            value={props.value}
            orientation={matches ? "horizontal" : "vertical"}
            onChange={props.handleChange}
            aria-label="simple tabs example"
            className={props.classes.tabsBg}
            variant="fullWidth"
        >
            <Tab label={i18next.t("mainpage.tab.courses")} {...a11yProps(0)} />
            <Tab label={i18next.t("mainpage.tab.coursecalendar")} {...a11yProps(1)} />

            <Tab
                label={
                    <Badge color="secondary" className={"btn-red"} badgeContent={props.newNews}>
                        <div style={{ padding: "2px" }}>{i18next.t("mainpage.tab.news")}</div>
                    </Badge>
                }
                {...a11yProps(2)}
            />

            <Tab
                label={
                    <Badge color="secondary" className={"btn-red"} badgeContent={props.newSysMsg}>
                        <div style={{ padding: "2px" }}>{i18next.t("mainpage.tab.systeminfos")}</div>
                    </Badge>
                }
                {...a11yProps(3)}
            />
        </AntTabs>
    );
};

class UserTabs extends Component {
    componentDidMount() {
        //a then ág akkor fog lefutni, ha minden kérés sikeresen lefutott
        const openCourseId = new URLSearchParams(this.props.search).get("opencourse");

        axios.all([
            this.props.fetchNews(),
            this.props.fetchSystemMessages(),
            this.props.fetchMyCourses(),
            this.props.fetchOpenCourse(openCourseId)
        ]).then(
            axios.spread(() => {
                const course = this.props.openCourseObject;
                if(!course){
                    return;
                }
                const url = `${process.env.REACT_APP_API_URL}/moodle/open?token=${getJWTToken()}&course=${
                    course.id
                }${getImpersonationToken() ? `&impersonation=${getImpersonationToken()}` : ``}`;
                this.openCoursePrivacyStatementDialog(course.id, url, course.fullName);
            })
        );
    }

    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            newsTable: {
                rowsPerPage: 5,
                page: 0,
            },
            systemInfosTable: {
                rowsPerPage: 5,
                page: 0,
            },

            privacyStatementCourseDialog: {
                open: false,
                title: "",
                text: "",
                url: null,
                courseId: null,
            },

            openCourse: new URLSearchParams(props.search).get("opencourse"),
        };
    }

    checkPrivacyStatementCourseDialog = (e, courseId, courseTitle, url) => {
        e.preventDefault();

        if (getImpersonationToken()) {
            window.location.href = url;
            return;
        }

        this.openCoursePrivacyStatementDialog(courseId, url, courseTitle);
    };

    openCoursePrivacyStatementDialog(courseId, url, courseTitle) {
        this.props.getUserPrivacyStatementCourse(courseId).then((response) => {
            const data = response.data;

            if (data.accepted) {
                window.location.href = url;
                return;
            }

            let text = "";
            if (data.statement && data.statement.content && data.statement.content[this.props.userLang]) {
                text = data.statement.content[this.props.userLang];
            }

            this.setState({
                ...this.state,
                privacyStatementCourseDialog: {
                    open: !data.accepted,
                    title: `<strong>${courseTitle}</strong> kurzus adatvédelmi nyilatkozata`,
                    courseId: courseId,
                    text: text,
                    url: url,
                    //courseId: courseId,
                },
            });
        });
    }

    checkPrivacyStatementCourseDialogOpenValue = (openValue) => {
        this.setState({
            ...this.state,
            privacyStatementCourseDialog: {
                ...this.state.privacyStatementCourseDialog,
                open: openValue,
            },
        });
    };

    labelDisplayedRows = ({ from, to, count, page }) => {
        return `${from}-${to === -1 ? count : to} (Összesen: ${count})`;
    };

    handleChange = (event, newValue) => {
        switch (newValue) {
            case 2: // Hírfolyam
                this.props.refreshUserLastSeen(
                    AppConst.LAST_SEEN_TYPE_NEWS,
                    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                );
                this.props.setLastSeen(AppConst.LAST_SEEN_TYPE_NEWS);
                break;
            case 3: // Rendszerüzenetek
                this.props.refreshUserLastSeen(
                    AppConst.LAST_SEEN_TYPE_MESSAGES,
                    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                );
                this.props.setLastSeen(AppConst.LAST_SEEN_TYPE_MESSAGES);
                break;
            default:
                break;
        }
        this.setState({ value: newValue });
    };

    handleNewsChangePage = (event, newValue) => {
        this.setState({
            newsTable: {
                ...this.state.newsTable,
                page: parseInt(newValue),
            },
        });
    };

    handleNewsChangeRowsPerPage = (event) => {
        this.setState({
            newsTable: {
                ...this.state.newsTable,
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            },
        });
    };

    handleSystemInfosChangePage = (event, newValue) => {
        this.setState({
            systemInfosTable: {
                ...this.state.systemInfosTable,
                page: parseInt(newValue),
            },
        });
    };

    handleSystemInfosChangeRowsPerPage = (event) => {
        this.setState({
            systemInfosTable: {
                ...this.state.systemInfosTable,
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0,
            },
        });
    };

    getValueByLang = (data, key) => {
        const lang = this.props.userLang;
        if (data[key] && data[key][lang]) return data[key][lang];
        return data[key][Object.keys(data[key])[0]];
    };

    render() {
        const { classes, userLastSeen } = this.props;
        const checkSeenDate = new Date();
        checkSeenDate.setMonth(checkSeenDate.getMonth() - 1); // Csak az 1 hónapon belüli új rendszerüzenetek/hírek számítanak újaknak
        const newNews =
            userLastSeen && userLastSeen.news
                ? this.props.news.data.data.filter(
                      (obj) =>
                          new Date(obj.published_at) >= new Date(userLastSeen.news) &&
                          new Date(obj.published_at) > checkSeenDate
                  ).length
                : this.props.news.data.data.filter((obj) => new Date(obj.published_at) >= checkSeenDate).length;
        const newSysMsg =
            userLastSeen && userLastSeen.messages
                ? this.props.systemMessages.data.data.filter(
                      (obj) =>
                          new Date(obj.published_at) >= new Date(userLastSeen.messages) &&
                          new Date(obj.published_at) > checkSeenDate
                  ).length
                : this.props.systemMessages.data.data.filter((obj) => new Date(obj.published_at) >= checkSeenDate)
                      .length;

        const myCourses = {
            progress: [],
            future: [],
            past: [],
        };

        let openCourse = null;

        for (const k of Object.keys(myCourses)) {
            if (this.props.myCourses[k] && this.props.myCourses[k].length > 0) {
                for (let i = 0; i < this.props.myCourses[k].length; i++) {
                    const course = this.props.myCourses[k][i];

                    const url = `${process.env.REACT_APP_API_URL}/moodle/open?token=${getJWTToken()}&course=${
                        course.id
                    }${getImpersonationToken() ? `&impersonation=${getImpersonationToken()}` : ``}`;

                    //ez a rész felesleges lesz ezután
                    if (this.state.openCourse == course.id) {
                        openCourse = course;
                        openCourse.url = url;
                    }

                    myCourses[k].push(
                        <MenuItem className={classes.menuItem} key={`${k}-${course.id}`}>
                            <a
                                className={classes.link}
                                onClick={(e) =>
                                    this.checkPrivacyStatementCourseDialog(e, course.id, course.fullName, url)
                                }
                                href={url}
                            >
                                {course.fullName} ({course.startDateDisplay} - {course.endDateDisplay})
                            </a>
                        </MenuItem>
                    );
                }
            } else {
                myCourses[k].push(
                    <MenuItem className={classes.menuItemEmpty} key={k}>
                        <i>{i18next.t("mainpage.courses.nocourse")}</i>
                    </MenuItem>
                );
            }
        }

        return (
            <div className={classes.root}>
                {this.state.privacyStatementCourseDialog.open && (
                    <PrivacyStatementCourse
                        url={this.state.privacyStatementCourseDialog.url}
                        courseId={this.state.privacyStatementCourseDialog.courseId}
                        title={this.state.privacyStatementCourseDialog.title}
                        text={this.state.privacyStatementCourseDialog.text}
                        openValue={this.state.privacyStatementCourseDialog.open}
                        openChange={this.checkPrivacyStatementCourseDialogOpenValue}
                    />
                )}
                <AppBar position="static" className={classes.primaryColor}>
                    <ToUseHook
                        value={this.state.value}
                        handleChange={this.handleChange}
                        classes={classes}
                        newNews={newNews}
                        newSysMsg={newSysMsg}
                    />
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <MenuList>
                        <Topic
                            key={0}
                            text={i18next.t("mainpage.courses.progresscourses")}
                            children={<React.Fragment>{myCourses.progress}</React.Fragment>}
                        />
                        <Topic
                            key={1}
                            text={i18next.t("mainpage.courses.futurecourses")}
                            children={<React.Fragment>{myCourses.future}</React.Fragment>}
                        />
                        <Topic
                            key={2}
                            text={i18next.t("mainpage.courses.pastcourses")}
                            children={<React.Fragment>{myCourses.past}</React.Fragment>}
                        />
                    </MenuList>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <Paper style={{ overflow: "auto" }}>
                        <Grid container justify="center">
                            <CourseCalendar courses={this.props.myCourses} />
                        </Grid>
                    </Paper>
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    {this.props.news.data.data.length ? (
                        <Grid container spacing={2}>
                            <TablePagination
                                className={classes.paginator}
                                rowsPerPageOptions={[1, 3, 5]}
                                component="div"
                                labelRowsPerPage={i18next.t("mainpage.pagination.news")}
                                labelDisplayedRows={this.labelDisplayedRows}
                                count={this.props.news.data.data.length}
                                rowsPerPage={this.state.newsTable.rowsPerPage}
                                page={this.state.newsTable.page}
                                onChangePage={this.handleNewsChangePage}
                                onChangeRowsPerPage={this.handleNewsChangeRowsPerPage}
                                backIconButtonText={i18next.t("mainpage.previous")}
                                nextIconButtonText={i18next.t("mainpage.next")}
                            />
                            {this.props.news.data.data
                                .slice(
                                    this.state.newsTable.page * this.state.newsTable.rowsPerPage,
                                    this.state.newsTable.page * this.state.newsTable.rowsPerPage +
                                        this.state.newsTable.rowsPerPage
                                )
                                .map((nd) => (
                                    <MainNews
                                        key={nd.id}
                                        title={this.getValueByLang(nd, "title")}
                                        date={new Date(nd.published_at).toLocaleDateString()}
                                        new={this.getValueByLang(nd, "content")}
                                    />
                                ))}
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                labelRowsPerPage={i18next.t("mainpage.pagination.news")}
                                labelDisplayedRows={this.labelDisplayedRows}
                                count={this.props.news.data.data.length}
                                rowsPerPage={this.state.newsTable.rowsPerPage}
                                page={this.state.newsTable.page}
                                onChangePage={this.handleNewsChangePage}
                                onChangeRowsPerPage={this.handleNewsChangeRowsPerPage}
                                backIconButtonText={i18next.t("mainpage.previous")}
                                nextIconButtonText={i18next.t("mainpage.next")}
                            />
                        </Grid>
                    ) : (
                        `${i18next.t("mainpage.nonew")}`
                    )}
                </TabPanel>
                <TabPanel value={this.state.value} index={3}>
                    {this.props.systemMessages.data.data.length ? (
                        <Grid container spacing={2}>
                            <TablePagination
                                rowsPerPageOptions={[1, 3, 5]}
                                component="div"
                                labelRowsPerPage={i18next.t("mainpage.pagination.system_messages")}
                                labelDisplayedRows={this.labelDisplayedRows}
                                count={this.props.systemMessages.data.data.length}
                                rowsPerPage={this.state.systemInfosTable.rowsPerPage}
                                page={this.state.systemInfosTable.page}
                                onChangePage={this.handleSystemInfosChangePage}
                                onChangeRowsPerPage={this.handleSystemInfosChangeRowsPerPage}
                                backIconButtonText={i18next.t("mainpage.previous")}
                                nextIconButtonText={i18next.t("mainpage.next")}
                            />
                            {this.props.systemMessages.data.data
                                .slice(
                                    this.state.systemInfosTable.page * this.state.systemInfosTable.rowsPerPage,
                                    this.state.systemInfosTable.page * this.state.systemInfosTable.rowsPerPage +
                                        this.state.systemInfosTable.rowsPerPage
                                )
                                .map((si) => (
                                    <MainNews
                                        key={si.id}
                                        title={this.getValueByLang(si, "title")}
                                        date={new Date(si.published_at).toLocaleDateString()}
                                        new={this.getValueByLang(si, "content")}
                                    />
                                ))}
                            <Grid container justify="flex-start">
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    labelDisplayedRows={this.labelDisplayedRows}
                                    count={this.props.systemMessages.data.data.length}
                                    rowsPerPage={this.state.systemInfosTable.rowsPerPage}
                                    page={this.state.systemInfosTable.page}
                                    onChangePage={this.handleSystemInfosChangePage}
                                    onChangeRowsPerPage={this.handleSystemInfosChangeRowsPerPage}
                                    backIconButtonText={i18next.t("mainpage.previous")}
                                    nextIconButtonText={i18next.t("mainpage.next")}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        `${i18next.t("mainpage.nosystem_messages")}`
                    )}
                </TabPanel>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        news: {
            loading: state.main.loading,
            data: state.main.news,
        },
        systemMessages: {
            loading: state.main.loading,
            data: state.main.sysMessages,
        },
        myCourses: state.main.myCourses,
        userLang: state.authentication.user.language,
        userLastSeen: state.authentication.user.last_seen,
        openCourseObject: state.main.openCourseObject,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(mainAction.getNews()),
        fetchSystemMessages: () => dispatch(mainAction.getSysMessages()),
        fetchMyCourses: () => dispatch(mainAction.getMyCourses()),
        fetchOpenCourse: (courseId) => dispatch(mainAction.getOpenCourse(courseId)),
        setLastSeen: (type) => dispatch(mainAction.setLastSeen(type)),
        refreshUserLastSeen: (name, value) => dispatch(loginAction.refreshLastSeen(name, value)),
        getUserPrivacyStatementCourse: (courseId) => dispatch(getUserPrivacyStatementCourseAction(courseId)),
        updateUserPrivacyStatementCourse: (courseId) => dispatch(updateUserPrivacyStatementCourseAction(courseId)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(UserTabs));
