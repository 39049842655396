export const types = {
    FETCH_REQUEST: "@@users/FETCH_REQUEST",
    FETCH_SUCCESS: "@@users/FETCH_SUCCESS",
    FETCH_FAILURE: "@@users/FETCH_FAILURE",

    SEARCH_REQUEST: "@@users/SEARCH_REQUEST",
    SEARCH_SUCCESS: "@@users/SEARCH_SUCCESS",
    SEARCH_FAILURE: "@@users/SEARCH_FAILURE",

    SEARCH_IMPERSONATION_REQUEST: "@@users/SEARCH_IMPERSONATION_REQUEST",
    SEARCH_IMPERSONATION_SUCCESS: "@@users/SEARCH_IMPERSONATION_SUCCESS",
    SEARCH_IMPERSONATION_FAILURE: "@@users/SEARCH_IMPERSONATION_FAILURE",

    POST_REQUEST: "@@users/POST_REQUEST",
    POST_SUCCESS: "@@users/POST_SUCCESS",
    POST_FAILURE: "@@users/POST_FAILURE",

    GET_REQUEST: "@@users/GET_REQUEST",
    GET_SUCCESS: "@@users/GET_SUCCESS",
    GET_FAILURE: "@@users/GET_FAILURE",

    DEFAULT_FORM: "@@users/DEFAULT_FORM",
    CHANGE_FORM: "@@users/CHANGE_FORM",
    DEFAULT_ROW: "@@users/DEFAULT_ROW",

    FETCH_CHAMBERS_GET: "@@users/FETCH_CHAMBERS_GET",
    FETCH_CHAMBERS_SUCCESS: "@@users/FETCH_CHAMBERS_SUCCESS",
    FETCH_CHAMBERS_FAILURE: "@@users/FETCH_CHAMBERS_FAILURE",

    CREATE_FORM: "@@users/CREATE_FORM",
    CREATE_REQUEST: "@@users/CREATE_REQUEST",
    CREATE_SUCCESS: "@@users/CREATE_SUCCESS",
    CREATE_FAILURE: "@@users/CREATE_FAILURE",

    UPDATE_REQUEST: "@@users/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@users/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@users/UPDATE_FAILURE",

    SEND_PSW_RESET_REQUEST: "@@users/SEND_PSW_RESET_REQUEST",
    SEND_PSW_RESET_SUCCESS: "@@users/SEND_PSW_RESET_SUCCESS",
    SEND_PSW_RESET_FAILURE: "@@users/SEND_PSW_RESET_FAILURE",

    SEND_ACTIVATION_MAIL_REQUEST: "@@users/SEND_ACTIVATION_MAIL_REQUEST",
    SEND_ACTIVATION_MAIL_SUCCESS: "@@users/SEND_ACTIVATION_MAIL_SUCCESS",
    SEND_ACTIVATION_MAIL_FAILURE: "@@users/SEND_ACTIVATION_MAIL_FAILURE",

    DELETE_REQUEST: "@@users/DELETE_REQUEST",
    DELETE_SUCCESS: "@@users/DELETE_SUCESS",
    DELETE_FAILURE: "@@users/DELETE_FAILURE",

    DELETE_REPEAL_REQUEST: "@@users/DELETE_REPEAL_REQUEST",
    DELETE_REPEAL_SUCCESS: "@@users/DELETE_REPEAL_SUCCESS",
    DELETE_REPEAL_FAILURE: "@@users/DELETE_REPEAL_FAILURE",

    POST_IMPERSONATION_REQUEST: "@@users/POST_IMPERSONATION_REQUEST",
    POST_IMPERSONATION_SUCCESS: "@@users/POST_IMPERSONATION_SUCCESS",
    POST_IMPERSONATION_FAILURE: "@@users/POST_IMPERSONATION_FAILURE",

    FETCH_SET_INFO: "@@users/FETCH_SET_INFO",
};
