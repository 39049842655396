import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../../styles/colors.scss";
import TabPanel from "../../../Common/TabPanel";
import Validator from "../../../../utils/Validator";
import Loading from "../../../Common/Loading";
import AntTabs from "../../../Common/AntTabs";
import Copy from "../../../Common/Copy";
import TxtField from "../../../Common/TxtField";
import SubmitButton from "../../../Common/SubmitButton";
import LinkToButton from "../../../Common/LinkToButton";
import CancelButton from "../../../Common/CancelButton";
import validator from "../../../../store/validator/actions";
import notificationsActions from "../../../../store/notifications/actions";
import systemMessagesActions from "../../../../store/systemMessages/actions";
import CkeditorCustom from "../../../Common/CkeditorCustom";
import DatePicker from "../../../Common/DatePicker";
import i18next from "i18next";
import { convertDateToHyphenFormat } from "../../../../utils/AppConst";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    tabs: {
        width: "100%",
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    fields: {
        margin: theme.spacing(0, 0, 3),
    },
    root: {
        backgroundColor: colors.white,
    },
    icon: {
        color: colors.primary,
    },
    dBlock: {
        display: "block",
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function Form(props) {
    const classes = useStyles();
    const { form, loading } = props;
    const systemMessagesId = props.systemMessagesId ? props.systemMessagesId : null;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const [needCData, setNeedCData] = useState(false);

    useEffect(() => {
        props.setValidatorErrors({});
        props.getValidationRules("messages", systemMessagesId);
        if (systemMessagesId) {
            props.get(systemMessagesId);
            setNeedCData(true);
        } else {
            setNeedCData(false);
            props.createForm();
        }
    }, []);

    //tabváltáshoz kell
    const [actualTab, setActualTab] = useState(0); //tabváltás

    const mainLang = "hu";
    const langCode = {
        0: "hu",
        1: "en",
    };
    const headerTexts = [i18next.t("hu"), i18next.t("en")];
    const changeTab = (event, newValue) => {
        setActualTab(newValue);
    };
    const lang = langCode[actualTab];
    //eddig

    const setTabByErrors = (validator) => {
        const firstLang = validator.getErrorFirstLang();
        if (firstLang) {
            const firstLangIndex = validator.getKeyByValue(langCode, firstLang);
            if (firstLangIndex != -1 && firstLangIndex != actualTab) {
                setActualTab(parseInt(firstLangIndex));
            }
        }
    };

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false);
        props.changeForm("form", {
            ...form,
            [name]: newDate,
        });
    };

    const handleChange = (event) => {
        setNeedCData(true);
        const name = event.target.name;
        let val = null;
        if (event.target.type === "checkbox") {
            // checkboxnál máshogy kezeljük
            val = event.target.checked ? 1 : 0;
            props.changeForm("form", {
                ...form,
                [name]: val,
            });
        } else {
            const value = event.target.value;

            props.changeForm("form", {
                ...form,
                [name]: {
                    ...form[name],
                    [lang]: value,
                },
            });
        }
    };
    const ckeditorChange = (event, editor) => {
        setNeedCData(true);
        const value = editor.getData();
        const name = "content";

        props.changeForm("form", {
            ...form,
            [name]: {
                ...form[name],
                [lang]: value,
            },
        });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name, lang);
        props.setValidatorErrors(validator.getErrors());
    };

    const copyAction = () => {
        setNeedCData(true);
        let newForm = {};
        Object.assign(newForm, {
            ...form,
            title: { ...form.title, [lang]: form.title[mainLang] },
            content: { ...form.content, [lang]: form.content[mainLang] },
        });
        Object.assign(form, newForm);
        props.changeForm("form", newForm);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        if (systemMessagesId) {
            props
                .update(form, systemMessagesId)
                .then(() => {
                    props.addNotification("success", i18next.t("system_messages.success"), "/system-messages");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("system_messages.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        } else {
            props
                .create(form)
                .then(() => {
                    props.addNotification("success", i18next.t("system_messages.success"), "/system-messages");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("system_messages.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        }
    };
    if (loading) return <Loading />;
    return (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Grid item xs={12} md={6}>
                <div className={classes.tabs}>
                    <AppBar position="static">
                        <AntTabs
                            value={actualTab}
                            onChange={changeTab}
                            aria-label="simple tabs example"
                            className={classes.tabsBg}
                            variant="fullWidth"
                        >
                            {headerTexts.map((value, index) => (
                                <Tab label={value} key={index} />
                            ))}
                        </AntTabs>
                    </AppBar>
                    {headerTexts.map((v, i) => (
                        <TabPanel value={actualTab} index={i} key={i}>
                            <Grid container spacing={1} className={classes.dBlock}>
                                <Grid item container justify="flex-end">
                                    {mainLang === langCode[i] ? "" : <Copy onClick={copyAction} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <TxtField
                                        required={validator.isRequired("title")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={form.title[langCode[i]] || ""}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => handleChange(event)}
                                        onBlur={(event) => handleBlur(event)}
                                        label={i18next.t("system_messages.title")}
                                        id="title"
                                        name="title"
                                        error={validator.hasError("title", langCode[i])}
                                        helperText={validator.getErrorText("title", langCode[i])}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CkeditorCustom
                                        label={i18next.t("system_messages.content")}
                                        required={validator.isRequired("content")}
                                        getValue={() => {
                                            if (!needCData) return "";
                                            return form.content[langCode[i]] || "";
                                        }}
                                        lang={langCode[i]}
                                        ckeditorChange={ckeditorChange}
                                        error={validator.hasError("content", langCode[i])}
                                        helperText={validator.getErrorText("content", langCode[i])}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.titleText}>
                                        {i18next.t("news.addresses")}
                                        <span
                                            aria-hidden="true"
                                            className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                                        >
                                             *
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" />}
                                        label={i18next.t("news.isziir")}
                                        checked={form.addresses.includes("isziir")}
                                        onChange={(event) => {
                                            props.changeForm("form", {
                                                ...form,
                                                addresses: event.target.checked
                                                    ? [...form.addresses, "isziir"]
                                                    : form.addresses.filter((item) => item !== "isziir"),
                                            });
                                        }}
                                    ></FormControlLabel>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" />}
                                        label={i18next.t("news.normal")}
                                        checked={form.addresses.includes("normal")}
                                        onChange={(event) => {
                                            props.changeForm("form", {
                                                ...form,
                                                addresses: event.target.checked
                                                    ? [...form.addresses, "normal"]
                                                    : form.addresses.filter((item) => item !== "normal"),
                                            });
                                        }}
                                    ></FormControlLabel>
                                    {validator.hasError("addresses") && (
                                        <Typography
                                            variant="caption"
                                            style={{ color: "#f44336", width: "100%", margin: "0 14px 4px 14px" }}
                                        >
                                            {validator.getErrorText("addresses")}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        onChange={(date) => handleDateChange(date, "published_at")}
                                        onBlur={(event) => handleBlur(event)}
                                        className={classes.date}
                                        error={validator.hasError("published_at")}
                                        helperText={validator.getErrorText("published_at")}
                                        required={validator.isRequired("published_at")}
                                        value={form.published_at}
                                        id="published_at"
                                        label={i18next.t("system_messages.date")}
                                        name="published_at"
                                        style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                    />
                                </Grid>
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    className={classes.submit}
                                    text={i18next.t("save")}
                                />
                                <LinkToButton to="/system-messages">
                                    <CancelButton
                                        variant="outlined"
                                        className={classes.submit}
                                        text={i18next.t("cancel")}
                                    />
                                </LinkToButton>
                            </Grid>
                        </TabPanel>
                    ))}
                </div>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { form, loading } = state.systemMessages;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, loading, user, validator };
}

const actionCreators = {
    changeForm: systemMessagesActions.changeForm,
    create: systemMessagesActions.create,
    update: systemMessagesActions.update,
    get: systemMessagesActions.get,
    createForm: systemMessagesActions.createForm,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(Form);
