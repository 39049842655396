import React, { Component } from "react";
import Header from "../../Common/Header";
import { AppBar, Grid, Tab, useMediaQuery, useTheme } from "@material-ui/core";
import TabPanel from "../../Common/TabPanel";
import Loading from "../../Common/Loading";
import AntTabs from "../../Common/AntTabs";
import CourseManagementEditForm from "./CourseManagementEditForm";
import colors from "../../../styles/colors.scss";
import PropTypes from "prop-types";
import CourseManagementEditList from "./CourseManagementEditList";
import CourseManagementEditStatement from "./CourseManagementEditStatement";
import { history } from "../../../store";
import coursesActions from "../../../store/courses/actions";
import coursesAction, { get, patch } from "../../../store/courses/actions";
import { connect } from "react-redux";
import notificationsActions from "../../../store/notifications/actions";
import elearningCourseCategories from "../../../store/elearningCourseCategories/actions";
import Validator from "../../../utils/Validator";
import validator from "../../../store/validator/actions";
import moment from "moment";
import i18next from "i18next";
import { convertDateToHyphenFormat } from '../../../utils/AppConst';

TabPanel.propTypes = {
    children: PropTypes.node,
    //index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ToUseHook(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <AntTabs
            value={props.value}
            onChange={props.handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            orientation={matches ? "horizontal" : "vertical"}
        >
            <Tab label={i18next.t("course_management.basic")} {...a11yProps(0)} />
            <Tab label={i18next.t("course_management.course_permissions")} {...a11yProps(1)} />
            <Tab label={i18next.t("course_management.statement")} {...a11yProps(2)} />
        </AntTabs>
    );
}

class CourseManagementEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            show: "",
            user: "",
            elearning: "",
            form: {},
            statementListShow: false,
            validator: new Validator({}, props.validator.validatorErorrs, props.validator.rules),
        };
    }

    onChange(key, value) {
        this.props.changeForm('row', { ...this.props.row, [key]: value });
    }

    onChangeCategory(newValue) {
        this.props.changeForm({ ...this.props.row, ...{ categoryId: newValue } });
    }

    onChangeType(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ courseType: newValue },
        });
    }

    onChangeShow(newValue) {
        this.setState({ show: newValue });
    }

    onChangeCourseName(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ fullName: newValue },
        });
    }

    onChangeSampleExamName(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ sampleExamName: newValue },
        });
    }

    onChangeSampleExam(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ sampleExam: newValue },
        });
    }

    onChangeNormalExam(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ normalExam: newValue },
        });
    }

    onChangeGrantContractNumber(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ grantContractNumber: newValue },
        });
    }

    onChangeCourseShortName(newValue) {
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ shortName: newValue },
        });
    }

    onChangeStartDate(date) {
        let newDate = convertDateToHyphenFormat(date, true)
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ startDate: newDate },
        });
    }
    onChangeEndDate(date) {
        let newDate = convertDateToHyphenFormat(date, true)
        console.log(newDate);
        this.props.changeForm("row", {
            ...this.props.row,
            ...{ endDate: newDate },
        });
    }

    onChangeUser(newValue) {
        this.setState({ user: newValue });
    }

    onChangeElearning(newValue) {
        this.setState({ elearning: newValue });
    }
    onChangeCheckbox(event) {
        const ch = event.target.checked;
        const courseId = this.props.match.params.id;
        //this.setState({ checked: event.target.checked })

        this.props
            .updateSettings(courseId, {
                unique_privacy_statement: ch,
                course_id: parseInt(this.props.courseId),
            })
            .then(() => {
                if (ch) {
                    this.props.statementGetsAction(courseId).then(() => {
                        this.setState({ statementListShow: ch });
                    });
                } else {
                    this.setState({ statementListShow: ch });
                    this.setState({ filteredItems: {} });
                }
                this.props.getSettings(this.props.match.params.id);
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props
            .submitAction(this.props.row.id, { ...this.props.row, courseSubjectManagers: this.props.row.courseSubjectManagers?.map(item => item.value || item) || [] })
            .then(() => {
                this.props.addNotification(
                    "success",
                    i18next.t("course_management.success"),
                    "/e-learning/course-management"
                );
            })
            .catch((error) => {
                this.props.addNotification("error", i18next.t("course_management.error"));
                this.state.validator.setReponseError(error);
                this.props.setValidatorErrors(this.state.validator.getErrors());
            });
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getCourse(this.props.match.params.id).then((response) => {
                this.props.getExams(this.props.match.params.id);
            });
            this.props.getSettings(this.props.match.params.id).then((response) => {
                const ch = response.data.unique_privacy_statement;

                if (ch) {
                    this.props.statementGetsAction(this.props.match.params.id).then(() => {
                        this.setState({ statementListShow: ch });
                    });
                } else {
                    this.setState({ statementListShow: ch });
                    this.setState({ filteredItems: {} });
                }
            });
        } else {
            history.push("/404");
        }
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };

        if (this.props.loading) return <Loading />;

        return (
            <Grid container spacing={2}>
                <Header
                    title={i18next.t("course_management.edit", {
                        chamber: this.props.row.fullName,
                    })}
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        "/e-learning/course-management": i18next.t("sidebar.course_management"),
                        "edit-course_management": i18next.t("course_management.edit", {
                            chamber: this.props.row.fullName,
                        }),
                    }}
                />
                <Grid item xs={12}>
                    <AppBar position="static">
                        <ToUseHook value={this.state.value} handleChange={handleChange} />
                        <TabPanel
                            style={{ backgroundColor: colors.white, display: "block" }}
                            value={this.state.value}
                            index={0}
                        >
                            {this.props.loading ? (
                                ""
                            ) : (
                                <CourseManagementEditForm
                                    category={this.props.row.categoryPrint || ""}
                                    courseName={this.props.row.fullName}
                                    show={this.state.show}
                                    isTemplate={this.props.row.isTemplate}
                                    courseSubjectManagers={this.props.row.courseSubjectManagers}
                                    startDate={this.props.row.startDate ? moment(this.props.row.startDate) : null}
                                    endDate={this.props.row.endDate ? moment(this.props.row.endDate) : null}
                                    isSampleCategory={this.props.row.isSampleCategory}
                                    courseShortName={this.props.row.shortName}
                                    subCoursesCount={this.props.row.subCoursesCount}
                                    changeCategory={this.onChangeCategory.bind(this)}
                                    changeCourseName={this.onChangeCourseName.bind(this)}
                                    changeCourseShortName={this.onChangeCourseShortName.bind(this)}
                                    changeStartDate={this.onChangeStartDate.bind(this)}
                                    changeEndDate={this.onChangeEndDate.bind(this)}
                                    changeShow={this.onChangeShow.bind(this)}
                                    handleSubmit={this.handleSubmit.bind(this)}
                                    onChange={this.onChange.bind(this)}
                                    exams={this.props.exams}
                                    courseType={this.props.row.courseType}
                                    grantContractNumber={this.props.row.grantContractNumber}
                                    sampleExamName={this.props.row.sampleExamName}
                                    sampleExam={this.props.row.sampleExam}
                                    normalExam={this.props.row.normalExam}
                                    changeSampleExamName={this.onChangeSampleExamName.bind(this)}
                                    changeGrantContractNumber={this.onChangeGrantContractNumber.bind(this)}
                                    changeType={this.onChangeType.bind(this)}
                                    changeSampleExam={this.onChangeSampleExam.bind(this)}
                                    changeNormalExam={this.onChangeNormalExam.bind(this)}
                                />
                            )}
                        </TabPanel>
                        <TabPanel
                            style={{ backgroundColor: colors.white, display: "block" }}
                            value={this.state.value}
                            index={1}
                        >
                            <CourseManagementEditList
                                user={this.state.user}
                                elearning={this.state.elearning}
                                changeUser={this.onChangeUser.bind(this)}
                                changeElearning={this.onChangeElearning.bind(this)}
                                courseId={this.props.match.params.id}
                            />
                        </TabPanel>
                        <TabPanel
                            style={{ backgroundColor: colors.white, display: "block" }}
                            value={this.state.value}
                            index={2}
                        >
                            <CourseManagementEditStatement
                                courseId={this.props.match.params.id}
                                onChangeCheckbox={this.onChangeCheckbox.bind(this)}
                            />
                        </TabPanel>
                    </AppBar>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        row: state.courses.row,
        loading: state.courses.loading || state.elearningCourseCategories.loading,
        statementData: state.courses.statementData,
        exams: state.courses.exams,
        validator: state.validator,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCourse: (id) => dispatch(get(id)),
        submitAction: (id, form) => dispatch(patch(id, form)),
        changeForm: (varibleName, form) => dispatch(coursesActions.changeForm(varibleName, form)),
        addNotification: (type, msg, redirect, options) =>
            dispatch(notificationsActions.addNotification(type, msg, redirect, options)),
        getElearningCourseCategories: () => dispatch(elearningCourseCategories.filter({})),
        setValidatorErrors: (data) => dispatch(validator.setErrors(data)),
        statementGetsAction: (course) => dispatch(coursesAction.statementGetsAction(course)),
        updateSettings: (id, form) => dispatch(coursesAction.patchSettings(id, form)),
        getSettings: (id) => dispatch(coursesAction.getSettings(id)),
        getExams: (id) => dispatch(coursesAction.getExams(id)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementEdit);
