import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import notificationsActions from "../../../../store/notifications/actions";
import {
    changeForm,
    createForm,
    createVersionAction,
    getVersionAction,
    updateVersionAction,
} from "../../../../store/pages/actions";
import validator from "../../../../store/validator/actions";
import Validator from "../../../../utils/Validator";
import AntTabs from "../../../Common/AntTabs";
import CancelButton from "../../../Common/CancelButton";
import CkeditorCustom from "../../../Common/CkeditorCustom";
import Copy from "../../../Common/Copy";
import LinkToButton from "../../../Common/LinkToButton";
import Loading from "../../../Common/Loading";
import SubmitButton from "../../../Common/SubmitButton";
import TabPanel from "../../../Common/TabPanel";
import TxtField from "../../../Common/TxtField";
import FormByType from "./FormByType";
import { langCode, mainLang, useStyles } from "./utils";

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function Form(props) {
    const classes = useStyles();
    const { form, versionLoading, loading, selectedPage } = props;
    const pageId = props.pageId ? props.pageId : null;
    const versionId = props.versionId ? props.versionId : null;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    useEffect(() => {
        props.setValidatorErrors({});
        props.getValidationRules("pages/versions");
        if (versionId) {
            props.getVersion(pageId, versionId);
        } else {
            props.createForm();
        }
    }, []);

    //tabváltáshoz kell
    const [actualTab, setActualTab] = useState(0); //tabváltás

    const headerTexts = [i18next.t("hu"), i18next.t("en")];
    const changeTab = (event, newValue) => {
        setActualTab(newValue);
    };
    const lang = langCode[actualTab];
    //eddig

    const setTabByErrors = (validator) => {
        const firstLang = validator.getErrorFirstLang();
        if (firstLang) {
            const firstLangIndex = validator.getKeyByValue(langCode, firstLang);
            if (firstLangIndex != -1 && firstLangIndex != actualTab) {
                setActualTab(parseInt(firstLangIndex));
            }
        }
    };

    const copyAction = () => {
        let newForm = {};
        Object.assign(newForm, {
            ...form,
            content: { ...form.content, [lang]: form.content[mainLang] },
        });
        Object.assign(form, newForm);
        props.changeForm("form", newForm);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        if (versionId) {
            props
                .update(pageId, versionId, form)
                .then(() => {
                    props.addNotification("success", i18next.t("pages.success"), `/pages/${pageId}/versions`);
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("pages.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        } else {
            props
                .create(pageId, form)
                .then(() => {
                    props.addNotification("success", i18next.t("pages.success"), `/pages/${pageId}/versions`);
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("pages.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        }
    };
    if (versionLoading || loading) return <Loading />;

    const UniqueForm = FormByType[selectedPage.type];

    return (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Grid item xs={12} md={6}>
                <div className={classes.tabs}>
                    <AppBar position="static">
                        <AntTabs
                            value={actualTab}
                            onChange={changeTab}
                            aria-label="simple tabs example"
                            className={classes.tabsBg}
                            variant="fullWidth"
                        >
                            {headerTexts.map((value, index) => (
                                <Tab label={value} key={index} />
                            ))}
                        </AntTabs>
                    </AppBar>
                    {headerTexts.map((v, i) => (
                        <TabPanel value={actualTab} index={i} key={i}>
                            <Grid container spacing={1} className={classes.dBlock}>
                                <Grid item container justify="flex-end">
                                    {mainLang === langCode[i] ? "" : <Copy onClick={copyAction} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <TxtField
                                        label={i18next.t("pages.title")}
                                        variant="outlined"
                                        margin="normal"
                                        id="title"
                                        name="title"
                                        value={
                                            selectedPage.title && selectedPage.title[langCode[i]]
                                                ? selectedPage.title[langCode[i]]
                                                : ""
                                        }
                                        fullWidth
                                        disabled={true}
                                    />
                                </Grid>
                                <UniqueForm form={form} validator={validator} versionId={versionId} i={i} lang={lang} />
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    className={classes.submit}
                                    text={i18next.t("save")}
                                />
                                <LinkToButton to={`/pages/${pageId}/versions`}>
                                    <CancelButton
                                        variant="outlined"
                                        className={classes.submit}
                                        text={i18next.t("cancel")}
                                    />
                                </LinkToButton>
                            </Grid>
                        </TabPanel>
                    ))}
                </div>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { form, versionLoading, loading, selectedPage } = state.pages;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, versionLoading, loading, user, validator, selectedPage };
}

const actionCreators = {
    changeForm,
    create: createVersionAction,
    update: updateVersionAction,
    createForm,
    getVersion: getVersionAction,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(Form);
