import React, { Component } from "react";
import Header from "../../Common/Header";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import CourseCategoriesForm from "./CourseCategoriesForm";
import i18next from "i18next";

class CourseCategoriesNew extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Header
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        "/e-learning/course-categories": i18next.t("sidebar.course_categories"),
                        "new-course_categories": i18next.t("course_categories.new"),
                    }}
                />
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={i18next.t("course_categories.new")} />
                        <CardContent>
                            <CourseCategoriesForm parentId={this.props.match.params.parent} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default CourseCategoriesNew;
