export const types = {
    FILTER_REQUEST: "@@elearning-course-categories/FILTER_REQUEST",
    FILTER_SUCCESS: "@@elearning-course-categories/FILTER_SUCCESS",
    FILTER_FAILURE: "@@elearning-course-categories/FILTER_FAILURE",

    CHANGE_FORM: "@@elearning-course-categories/CHANGE_FORM",
    CREATE_FORM: "@@elearning-course-categories/CREATE_FORM",
    DEFAULT_FORM: "@@elearning-course-categories/DEFAULT_FORM",

    GET_PARENT_INFO_REQUEST: "@@elearning-course-categories/GET_PARENT_INFO_REQUEST",
    GET_PARENT_INFO_SUCCESS: "@@elearning-course-categories/GET_PARENT_INFO_SUCCESS",
    GET_PARENT_INFO_FAILURE: "@@elearning-course-categories/GET_PARENT_INFO_FAILURE",

    CREATE_REQUEST: "@@elearning-course-categories/CREATE_REQUEST",
    CREATE_SUCCESS: "@@elearning-course-categories/CREATE_SUCCESS",
    CREATE_FAILURE: "@@elearning-course-categories/CREATE_FAILURE",

    UPDATE_REQUEST: "@@elearning-course-categories/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@elearning-course-categories/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@elearning-course-categories/UPDATE_FAILURE",

    GET_REQUEST: "@@elearning-course-categories/GET_REQUEST",
    GET_SUCCESS: "@@elearning-course-categories/GET_SUCCESS",
    GET_FAILURE: "@@elearning-course-categories/GET_FAILURE",

    DELETE_REQUEST: "@@elearning-course-categories/DELETE_REQUEST",
    DELETE_SUCCESS: "@@elearning-course-categories/DELETE_SUCESS",
    DELETE_FAILURE: "@@elearning-course-categories/DELETE_FAILURE",
};
