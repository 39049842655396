import { Grid } from "@material-ui/core";
import i18next from "i18next";
import React, { useState } from "react";
import DatePicker from "../../../../Common/DatePicker";
import { langCode, useStyles } from "../utils";
import { convertDateToHyphenFormat } from "../../../../../utils/AppConst";
import { connect } from "react-redux";
import { changeForm } from "../../../../../store/pages/actions";
import CkeditorCustom from "../../../../Common/CkeditorCustom";

function PrivacyPolicy({ form, validator, changeForm, versionId, i, lang }) {
    const [needCData, setNeedCData] = useState(!!versionId);
    const classes = useStyles();
    const handleDateChange = (date, name) => {
        changeForm("form", {
            ...form,
            [name]: convertDateToHyphenFormat(date, false),
        });
    };

    const ckeditorChange = (event, editor, newVal) => {
        setNeedCData(true);
        const value = event ? editor.getData() : newVal;
        const name = "content";
        changeForm("form", {
            ...form,
            [name]: {
                ...form[name],
                [lang]: value,
            },
        });
    };

    return (
        <>
            <Grid item xs={12}>
                <CkeditorCustom
                    label={i18next.t("pages.content")}
                    required={validator.isRequired("content")}
                    getValue={() => {
                        if (!needCData) return "";
                        return form.content[langCode[i]] || "";
                    }}
                    lang={langCode[i]}
                    ckeditorChange={ckeditorChange}
                    error={validator.hasError("content", langCode[i])}
                    helperText={validator.getErrorText("content", langCode[i])}
                />
            </Grid>
            <Grid item xs={12}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "start_date")}
                    className={classes.date}
                    error={validator.hasError("start_date")}
                    helperText={validator.getErrorText("start_date")}
                    required={validator.isRequired("start_date")}
                    value={form.start_date}
                    id="start_date"
                    label={i18next.t("pages.start")}
                    name="start_date"
                    margin="normal"
                    readOnly={form.status ? form.status !== "future" || form.end_date !== null : false}
                    inputProps={{
                        disabled: form.status ? form.status !== "future" || form.end_date !== null : false,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </>
    );
}

const actionCreators = {
    changeForm,
};

export default connect(null, actionCreators)(PrivacyPolicy);
