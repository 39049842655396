import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Tab,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import i18next from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Component from "../../../lib/Component";
import Permissions from "../../../lib/Permissions";
import {
    addSelectedUserAction,
    changeExam,
    clearSelectedExamAction,
    clearSelectedUserAction,
    delSelectedUserAction,
    enrollmentAction,
    fetchCategorizedCoursesAction,
    fetchExamsAction,
    fetchNextUsersAction,
    fetchUsersAction,
    saveSelectedUserAction,
    selectCourseAction
} from "../../../store/elearningExamEnrollmentDismissal/actions";
import importActions from "../../../store/importData/actions";
import notificationsActions from "../../../store/notifications/actions.js";
import "../../../styles/enrollment.scss";
import { hasPermission } from "../../../utils/AuthHelper";
import AntTabs from "../../Common/AntTabs";
import CheckBox from "../../Common/CheckBox";
import GreenOutlineSelect from "../../Common/GreenOutlineSelect";
import Header from "../../Common/Header";
import Import from "../../Common/Import";
import MassImportInfo from "../../Common/MassImportInfo.js";
import TabPanel from "../../Common/TabPanel";
import TreeView from "../../Common/TreeView/TreeView";
import Filters from "../Enrollment/Filters";
import KamgyakWarningMessage from "../Enrollment/KamgyakWarningMessage";
import SelectedUsers from "../Enrollment/SelectedUsers";
import Users from "../Enrollment/Users";

const ENROLLMENT_TYPE = "exam-enrollment";
const ENROLLMENT_ONLY_TYPE = "exam-enrollment-only";

class ExamEnrollmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course: null,
            exam: null,
            emptyHideCb: 1,
            filters: {
                query: "",
                enrolled: null, // null|yes|no
            },
            emailNotification: false,
            value: 0,
            radio: "enrollOnly", // "enrollOnly"|"enrollAndCreate"
        };
    }

    componentDidMount() {
        this.props.fetchCategorizedCoursesAction("exam-enrollment", this.state.emptyHideCb);
    }

    /**
     * Get the users
     */
    getUsers(exam) {
        this.state.exam = exam;
        this.props.fetchUsersAction(ENROLLMENT_TYPE, this.state.exam);
    }

    emptyHideCbChange(event) {
        const val = event.target.checked ? 1 : 0;
        this.setState({ emptyHideCb: val });
        this.props.fetchCategorizedCoursesAction(ENROLLMENT_TYPE, val);
    }

    selectCourse(course) {
        if (this.props.currentSelected > 0 && !window.confirm(i18next.t("elearning.confirmation"))) {
            return false;
        }
        this.state.course = course;
        this.props.clearSelectedExamAction();
        this.props.clearSelectedUserAction();
        this.props.selectCourseAction(course);
        this.updateState("emailNotification", false);
        this.setQueryFilter("");
        this.props.fetchExamsAction(course.id);
        this.props.importDefaultState();
    }

    handleChangeExam(e) {
        this.resetFilters();
        this.getUsers(e.target.value);
        this.props.changeExam(e.target.value);
        this.props.importDefaultState();
    }

    handleUserSelection(event, selectedUser) {
        if (event.target.checked) {
            if (!this.props.selectedUsers.includes(selectedUser.id)) this.props.addSelectedUserAction(selectedUser.id);
        } else {
            this.props.delSelectedUserAction(selectedUser.id);
        }
        this.props.saveSelectedUser(
            this.state.exam,
            selectedUser.id,
            event.target.checked,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    selectAll(value) {
        this.props.saveSelectedUser(
            this.state.exam,
            -1,
            value,
            this.state.filters.query,
            this.state.filters.enrolled
        );

        const users = this.props.users.list;

        if (value) {
            users.forEach((user) => {
                user.selected = true;
            });
        } else {
            users.forEach((user) => {
                if (!user.enrolled){
                    user.selected = false;
                }
            });
        }
        this.updateState("selectAll", value);
    }

    setEnrollmentFilter(value) {
        let filters = this.state.filters;
        filters["enrolled"] = value;
        this.updateState("filters", filters);
        this.props.fetchNextUsersAction(ENROLLMENT_TYPE, this.state.exam, 0, this.state.filters.query, this.state.filters.enrolled);
    }

    resetFilters(){
        let filters = this.state.filters;
        filters["enrolled"] = null;
        filters["query"] = null;
        this.updateState("filters", filters);
    }

    setQueryFilter(value) {
        if(!this.state.exam){
            return;
        }
        let filters = this.state.filters;
        filters["query"] = value;
        this.updateState("filters", filters);
        this.props.fetchNextUsersAction(ENROLLMENT_TYPE, this.state.exam, 0, this.state.filters.query, this.state.filters.enrolled);
    }

    nextPageUsers(currentViewed){
        this.props.fetchNextUsersAction(ENROLLMENT_TYPE, this.state.exam, currentViewed, this.state.filters.query, this.state.filters.enrolled);
    }

    save() {
        let data = {
            courseid: this.props.selectedCourse.id,
            sendEmail: this.state.emailNotification,
        };

        this.props.enrollmentAction(this.props.selectedExam, data).then(
            (data) => {
                this.getUsers(this.props.selectedExam);
                this.props.clearSelectedUserAction();
                this.updateState("emailNotification", false);
                this.props.addNotification("success", i18next.t("elearning.enrollment.success"));
            },
            (err) => {
                //fixme: hibát irunk ki valahova
                this.props.addNotification("error", i18next.t("elearning.enrollment.error"));
            }
        );
    }

    renderUsersCard() {
        const examsOptions = this.props.exams.map((object, key) => {
            return {
                value: object.id,
                label: object.name,
                key: key,
            };
        });

        return (
            <Card>
                {this.props.selectedCourse ? (
                    <CardHeader
                        title={i18next.t("elearning.course_management", {
                            course: this.props.selectedCourse.name,
                        })}
                    />
                ) : (
                    <>
                        <CardHeader title={i18next.t("elearning.management")} />
                        <CardContent>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Alert severity="info">{i18next.t("elearning.select_exam")}</Alert>
                            </Box>
                        </CardContent>
                    </>
                )}
                {this.props.selectedCourse && !this.props.selectedCourse.isKamgyakType ? (
                    this.props.loadingRightSide ? (
                        <CardContent>
                            <Skeleton variant="rect" height={120} />
                        </CardContent>
                    ) : (
                        <CardContent>
                            {examsOptions.length == 0 ? (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Alert severity="error">{i18next.t("elearning.noexam")}</Alert>
                                </Box>
                            ) : (
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <GreenOutlineSelect
                                            margin="normal"
                                            id="exam"
                                            selectLabel={i18next.t("elearning.exam")}
                                            onChange={(event) => this.handleChangeExam(event)}
                                            name="exam"
                                            value={this.props.selectedExam || ""}
                                            fullWidth
                                            shrink={true}
                                            optionList={examsOptions}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {this.props.selectedExam ? (
                                <>
                                    <Filters
                                        type={ENROLLMENT_TYPE}
                                        setQueryFilter={(e) => this.setQueryFilter(e.target.value)}
                                        setEnrollmentFilter={(val) => this.setEnrollmentFilter(val)}
                                        loadingSave={this.props.loadingSave}
                                        filters={this.state.filters}
                                    />

                                    <Box marginTop={3} />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={this.props.loadingSave}
                                                onClick={(e) => {
                                                    this.selectAll(e.target.checked);
                                                }}
                                                checked={this.state.selectAll}
                                                color="primary"
                                            />
                                        }
                                        label={i18next.t("elearning.select_all")}
                                    ></FormControlLabel>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <Users
                                                loadingUserList={this.props.loadingUserList || this.props.loadingSave}
                                                users={this.props.users}
                                                selectedUsers={this.props.selectedUsers}
                                                handleUserSelection={(e, user) => {
                                                    this.handleUserSelection(e, user)
                                                }}
                                                nextPageUsers={(c) =>{
                                                    this.nextPageUsers(c)
                                                }}
                                            />
                                        </Grid>

                                        <SelectedUsers
                                            currentSelected={this.props.currentSelected}
                                            currentSelectedIsLoading={this.props.currentSelectedIsLoading}
                                            loadingSave={this.props.loadingSave}
                                            emailNotification={this.state.emailNotification}
                                            updateState={(e) => {
                                                this.updateState("emailNotification", e.target.checked)
                                            }}
                                            save={() => {
                                                this.save()
                                            }}
                                            btnLabel={i18next.t("elearning.selected.enroll")}
                                        />

                                    </Grid>
                                </>
                            ) : (
                                ""
                            )}
                        </CardContent>
                    )
                ) : (
                    <KamgyakWarningMessage course={this.props.selectedCourse} type={"exam"} />
                )}
            </Card>
        );
    }

    render() {
        const examsOptions = this.props.exams.map((object, key) => {
            return {
                value: object.id,
                label: object.name,
                key: key,
            };
        });
        return (
            <div className="enrollment">
                <Grid item container spacing={2}>
                    <Header
                        title={i18next.t("sidebar.exam_enrollment")}
                        breadcrumbs={{
                            "e-learning": i18next.t("sidebar.elearning"),
                            enrollment: i18next.t("sidebar.exam_enrollment"),
                        }}
                    />
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Card>
                                    <CardHeader title={i18next.t("elearning.select_course")} />

                                    <CardContent>
                                        {hasPermission(Permissions.CourseExamEnrollment) && (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={this.state.emptyHideCb == 1}
                                                        onChange={(event) => {
                                                            this.emptyHideCbChange(event);
                                                        }}
                                                        value={1}
                                                        name="confirmationEmail"
                                                    />
                                                }
                                                label={i18next.t("elearning.hide")}
                                            />
                                        )}
                                        {this.props.loadingLeftSide ? (
                                            <Skeleton variant="rect" height={230} />
                                        ) : (
                                            <TreeView
                                                onItemSelected={(item) => this.selectCourse(item)}
                                                items={this.props.categorizedCourses}
                                            />
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                {hasPermission(Permissions.CourseExamEnrollment) ? (
                                    <>
                                        <AppBar position="static">
                                            <AntTabs
                                                value={this.state.value}
                                                onChange={(e, v) => this.setState({ value: v })}
                                                variant="fullWidth"
                                            >
                                                <Tab label={i18next.t("elearning.enrollment")} />
                                                <Tab label={i18next.t("elearning.mass_enrollment")} />
                                            </AntTabs>
                                        </AppBar>
                                        <TabPanel value={this.state.value} index={0}>
                                            {this.renderUsersCard()}
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={1}>
                                        <MassImportInfo />
                                        <FormControl>
                                            <FormLabel>{i18next.t("elearning.enrollment_type")}</FormLabel>
                                            <RadioGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onClick={() => this.setState({ radio: "enrollOnly" })}
                                                            checked={this.state.radio === "enrollOnly"}
                                                        />
                                                    }
                                                    label={i18next.t("elearning.enroll_only")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onClick={() => this.setState({ radio: "enrollAndCreate" })}
                                                            checked={this.state.radio === "enrollAndCreate"}
                                                        />
                                                    }
                                                    label={i18next.t("elearning.enroll_and_create")}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {this.state.radio === "enrollOnly" ? 
                                                <Import
                                                    handleChangeExam={(val) => this.props.changeExam(val)}
                                                    course={this.props.selectedCourse}
                                                    exam={this.props.selectedExam}
                                                    importSuccess={() => {
                                                        this.getUsers(this.props.selectedExam);
                                                    }}
                                                    type={ENROLLMENT_ONLY_TYPE}
                                                    examOptions={examsOptions}
                                                />
                                                :
                                                <Import
                                                    handleChangeExam={(val) => this.props.changeExam(val)}
                                                    course={this.props.selectedCourse}
                                                    exam={this.props.selectedExam}
                                                    importSuccess={() => {
                                                        this.getUsers(this.props.selectedExam);
                                                    }}
                                                    type={ENROLLMENT_TYPE}
                                                    examOptions={examsOptions}
                                                    rightSide={
                                                    <>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        this.props.importDataForm.confirmationEmail
                                                                    }
                                                                    onClick={(e) =>
                                                                        this.props.importDataChangeForm("form", {
                                                                            ...this.props.importDataForm,
                                                                            confirmationEmail: e.target.checked,
                                                                        })
                                                                    }
                                                                    disabled={this.props.importProgress}
                                                                />
                                                            }
                                                            label={i18next.t("elearning.activation")}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        this.props.importDataForm.emailNotification
                                                                    }
                                                                    onClick={(e) =>
                                                                        this.props.importDataChangeForm("form", {
                                                                            ...this.props.importDataForm,
                                                                            emailNotification: e.target.checked,
                                                                        })
                                                                    }
                                                                    disabled={this.props.importProgress}
                                                                />
                                                            }
                                                            label={i18next.t("elearning.notification")}
                                                        />
                                                    </>
                                                }
                                            />
                                        }
                                        </TabPanel>
                                    </>
                                ) : (
                                    this.renderUsersCard()
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        categorizedCourses: state.elearningExamEnrollmentDismissal.categorizedCourses,
        selectedUsers: state.elearningExamEnrollmentDismissal.selectedUsers,
        selectedCourse: state.elearningExamEnrollmentDismissal.selectedCourse,
        selectedExam: state.elearningExamEnrollmentDismissal.selectedExam,
        exams: state.elearningExamEnrollmentDismissal.exams,
        users: state.elearningExamEnrollmentDismissal.users,
        loadingLeftSide: state.elearningExamEnrollmentDismissal.loadingLeftSide,
        loadingRightSide: state.elearningExamEnrollmentDismissal.loadingRightSide,
        loadingSave: state.elearningExamEnrollmentDismissal.loadingSave,
        importDataForm: state.importData.form,
        importProgress: state.importData.progress,
        loadingUserList: state.elearningExamEnrollmentDismissal.loadingUserList,
        currentSelected: state.elearningExamEnrollmentDismissal.currentSelected,
        currentSelectedIsLoading: state.elearningExamEnrollmentDismissal.currentSelectedIsLoading,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchCategorizedCoursesAction: (type, hideEmptyCategories) =>
            dispatch(fetchCategorizedCoursesAction(type, hideEmptyCategories)),
        addSelectedUserAction: (user) => dispatch(addSelectedUserAction(user)),
        delSelectedUserAction: (user) => dispatch(delSelectedUserAction(user)),
        clearSelectedUserAction: () => dispatch(clearSelectedUserAction()),
        clearSelectedExamAction: () => dispatch(clearSelectedExamAction()),
        fetchExamsAction: (courseId) => dispatch(fetchExamsAction(courseId)),
        fetchUsersAction: (type, moduleId) => dispatch(fetchUsersAction(type, moduleId)),
        fetchNextUsersAction: (type, moduleId, allCount, search, enrolled) => dispatch(fetchNextUsersAction(type, moduleId, allCount, search, enrolled)),
        saveSelectedUser: (moduleId, userId, selected, search, enrolled) => dispatch(saveSelectedUserAction(ENROLLMENT_TYPE, moduleId, userId, selected, search, enrolled)),
        selectCourseAction: (course) => dispatch(selectCourseAction(course)),
        enrollmentAction: (exam, form) => dispatch(enrollmentAction(exam, form)),
        changeExam: (form) => dispatch(changeExam(form)),
        addNotification: (type, msg, redirect) => dispatch(notificationsActions.addNotification(type, msg, redirect)),
        importDefaultState: () => dispatch(importActions.defaultState()),
        importDataChangeForm: (variableName, form) => dispatch(importActions.changeForm(variableName, form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExamEnrollmentList));
