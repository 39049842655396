import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionSum from "../../Common/AccordionSum";
import SubmitButton from "../../Common/SubmitButton";
import SelectOutlined from "../../Common/SelectOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import colors from "../../../styles/colors.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, CardHeader, FormControlLabel, Tooltip } from "@material-ui/core";
import TreeView from "../../Common/TreeView/TreeView";
import AgGridCommon from "../../Common/AgGridCommon";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import CheckBox from "../../Common/CheckBox";
import Skeleton from "@material-ui/lab/Skeleton";
import { fetchCategorizedCoursesAction } from "../../../store/enrollment/actions";
import elearningCourseAchievements from "../../../store/elearningCourseAchievements/actions";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import i18next from "i18next";
import GreenTooltip from "../../Common/GreenTooltip";
import moment from "moment";

const NUMBER_FORMAT_DECIMALS = 1; // Tizedesjegyek száma az értékeknél

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: colors.darkPrimary,
        "&:hover": {
            backgroundColor: colors.darkPrimary,
        },
    },
    accDetails: {
        display: "block",
    },
    menuItem: {
        "&:hover": {
            backgroundColor: colors.primary,
        },
        "&:focus": {
            backgroundColor: colors.primary,
        },
    },
    hide: {
        display: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    listActionsGrid: {
        justifyContent: "unset",
    },
    nameTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    activeTitle: {
        color: colors.primary,
        fontWeight: "bold",
        fontSize: "x-large",
    },
    examPass: {
        color: colors.green,
    },
    examDontPass: {
        color: colors.red,
    },
}));

function getExamPointingMode(exam) {
    const attempts = parseInt(exam.attempts);
    const attemptsString = attempts === 0 ? "Korlátlan" : attempts.toString();
    let attemptsS = "Engedélyezett próbálkozások száma: " + attemptsString;
    let mode = "";

    if (attempts !== 1) {
        mode += ", Pontozási módszer: ";
        switch (exam.grademethod) {
            case "1":
                mode += "Legmagasabb pont";
                break;
            case "2":
                mode += "Átlagpont";
                break;
            case "3":
                mode += "Első próbálkozás";
                break;
            case "4":
                mode += "Utolsó próbálkozás";
                break;
            default:
                mode += "Ismeretlen (" + exam.grademethod + ")";
        }
    }

    return (
        <GreenTooltip title={attemptsS + (mode.length > 0 ? mode : "")} placement="top">
            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                <span>{attemptsS + (mode.length > 0 ? mode : "")}</span>
            </div>
        </GreenTooltip>
    );
}

function AchievementsList(props) {
    const classes = useStyles();
    const { loading, data, user, selectedCourse, courseEmptyHideCb, loadingCourses, categorizedCourses } = props;

    /*
    const columnDefs = [
        {
            headerName: i18next.t("course_achievements.requirements_group"),
            children: [
                {
                    headerName: i18next.t("course_achievements.requirements"),
                    field: "requirements",
                    flex: 1,
                    cellRenderer: "userCellRenderer",
                },
            ],
        },
        {
            headerName: i18next.t("course_achievements.activites"),
            children: data.criteries?.map(function(item, i){
                return {
                    headerName: item.name,
                    flex: 1,
                    field: "assignment_" + item.id,
                    cellRenderer: "checkCellRenderer"
                }
            }),
        },
        {
            headerName: i18next.t("course_achievements.course"),
            children: [
                {headerName: i18next.t("course_achievements.course.done"), field: "assignment_course", flex: 1, cellRenderer: 'checkCellRenderer',},
                {headerName: i18next.t("course_achievements.course.result"), field: "grade", flex: 1,},
            ],
        },
    ];
    */
    let columnDefs = [
        {
            headerName: i18next.t("course_achievements.student"),
            field: "student",
            flex: 1,
            cellRenderer: "userCellRenderer",
            pinned: "left",
            resizable: true,
        },
    ];

    if (data.exams) {
        data.exams.forEach((exam) => {
            columnDefs.push({
                headerName: exam.name,
                headerAlign: "center",
                align: "center",
                textAlign: "center",
                headerTooltip: exam.name,
                children:
                    data.enrollments.length > 0
                        ? [
                              {
                                  headerName: "Pont",
                                  flex: 1,
                                  field: "assignment_" + exam.moduleid,
                                  cellRenderer: "examResultCellRenderer",
                                  exam: exam,
                              },
                              {
                                  headerName: "Pontozási módszer",
                                  flex: 1,
                                  field: "type_" + exam.moduleid,
                                  cellRenderer: "examResultTypeCellRenderer",
                                  exam: exam,
                              },
                              {
                                  headerName: "Kezdés",
                                  flex: 1,
                                  field: "start" + exam.moduleid,
                                  cellRenderer: "examResultStartCellRenderer",
                                  exam: exam,
                              },
                              {
                                  headerName: "Befejezés",
                                  flex: 1,
                                  field: "end" + exam.moduleid,
                                  cellRenderer: "examResultFinishCellRenderer",
                                  exam: exam,
                              },
                          ]
                        : undefined,
            });
        });
    }

    const [paginationPageSize, setPaginationPageSize] = useState(25);
    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleItemNumberChange = (event) => {
        const val = Number(event.target.value);
        setPaginationPageSize(val);
        gridApi.paginationSetPageSize(val);
    };

    const downloadExcel = (event) => {
        event.preventDefault();

        props.download(selectedCourse.id).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "export.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };

    const frameworkComponents = {
        /*
        checkCellRenderer: (params) => {
            const id = params.colDef.field.substring( 11 );
            return (
                <>
                {
                    params.data.completions[id] ?
                        <FontAwesomeIcon icon={faCheck} style={{color: colors.primary, fontSize: 'x-large'}} /> :
                        <FontAwesomeIcon icon={faTimes} style={{color: colors.darkError, fontSize: 'x-large'}} />
                }
                </>
            )
        },
        */
        examResultCellRenderer: (params) => {
            const id = params.colDef.exam.moduleid;

            if (params.data.completions && params.data.completions.grades && params.data.completions.grades[id]) {
                const grade = parseFloat(params.data.completions.grades[id].grade),
                    pass = parseFloat(params.colDef.exam.gradepass),
                    gradeMax = parseFloat(params.colDef.exam.grademax);

                const percent = Math.floor((grade / gradeMax) * 100).toFixed(0);
                const print =
                    grade.toFixed(NUMBER_FORMAT_DECIMALS) +
                    " / " +
                    gradeMax.toFixed(NUMBER_FORMAT_DECIMALS) +
                    " (" +
                    percent +
                    "%)";
                return <span className={grade >= pass ? classes.examPass : classes.examDontPass}>{print}</span>;
            } else {
                if (params.data.examEnrollments[id]) {
                    return (
                        <GreenTooltip title={i18next.t("course_achievements.table.no_result.tooltip")} placement="top">
                            <span>{i18next.t("course_achievements.table.no_result.title")}</span>
                        </GreenTooltip>
                    );
                } else {
                    return (
                        <GreenTooltip
                            title={i18next.t("course_achievements.table.not_enrolled.tooltip")}
                            placement="top"
                        >
                            <span>{i18next.t("course_achievements.table.not_enrolled.title")}</span>
                        </GreenTooltip>
                    );
                }
            }
        },
        examResultStartCellRenderer: (params) => {
            const id = params.colDef.exam.moduleid;
            if (params.data.completions && params.data.completions.grades && params.data.completions.grades[id]) {
                const timestart_and_timefinish_split =
                    params.data.completions.grades[id].timestart_and_timefinish.split("-");

                return moment(new Date(parseInt(timestart_and_timefinish_split[0] + "000"))).format("YYYY-MM-DD HH:mm");
            }
            return "-";
        },
        examResultFinishCellRenderer: (params) => {
            const id = params.colDef.exam.moduleid;
            if (params.data.completions && params.data.completions.grades && params.data.completions.grades[id]) {
                const timestart_and_timefinish_split =
                    params.data.completions.grades[id].timestart_and_timefinish.split("-");

                return moment(new Date(parseInt(timestart_and_timefinish_split[1] + "000"))).format("YYYY-MM-DD HH:mm");
            }
            return "-";
        },
        examResultTypeCellRenderer: (params) => {
            return getExamPointingMode(params.colDef.exam);
        },
        userCellRenderer: (params) => {
            return (
                <Tooltip
                    title={params.data.lastName + " " + params.data.firstName + " / " + params.data.email}
                    placement="top"
                >
                    <div>{params.data.lastName + " " + params.data.firstName + " / " + params.data.email}</div>
                </Tooltip>
            );
        },
    };

    const defaultColDef = {
        suppressMovable: true,
        flex: 1,
        minWidth: 200,
    };

    const selectCourse = (item) => {
        props.selectCourse(item);
        props.get(item.id);
    };

    const emptyHideCbChange = (event) => {
        const val = event.target.checked ? 1 : 0;
        props.changeEmptyHideCb(val);
        props.fetchCategorizedCoursesAction("completions", val);
    };

    useEffect(() => {
        props.fetchCategorizedCoursesAction("completions", courseEmptyHideCb);
    }, []);

    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        achievements: i18next.t("sidebar.course_achievements"),
                    }}
                    title={i18next.t("sidebar.course_achievements")}
                />
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={i18next.t("elearning.select_course")} />
                        <CardContent>
                            {hasPermission(Permissions.CourseCompletions) && (
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={courseEmptyHideCb == 1}
                                            onChange={(event) => {
                                                emptyHideCbChange(event);
                                            }}
                                            value={1}
                                            name="confirmationEmail"
                                        />
                                    }
                                    label={i18next.t("elearning.hide")}
                                />
                            )}
                            {loadingCourses ? (
                                <Skeleton variant="rect" height={230} />
                            ) : (
                                <TreeView onItemSelected={(item) => selectCourse(item)} items={categorizedCourses} />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item container className={selectedCourse == null ? classes.hide : ""}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Accordion className={classes.accordionRoot} expanded>
                                <AccordionSum id="panel2a-header" text={i18next.t("course_enrolled.users")} />
                                <AccordionActions classes={{ root: classes.listActionsGrid }}>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <Typography htmlFor="item-numbers" className={classes.nameTitle}>
                                                    {i18next.t("table.item_number_select")}
                                                </Typography>
                                                <SelectOutlined
                                                    id="item-numbers"
                                                    onChange={handleItemNumberChange}
                                                    defaultValue={25}
                                                    className={classes.select}
                                                    text={[
                                                        <MenuItem value={25} key={0}>
                                                            25
                                                        </MenuItem>,
                                                        <MenuItem value={50} key={1}>
                                                            50
                                                        </MenuItem>,
                                                        <MenuItem value={100} key={2}>
                                                            100
                                                        </MenuItem>,
                                                    ]}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid>
                                            <Typography className={classes.activeTitle}>
                                                {selectedCourse ? selectedCourse.name : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <SubmitButton
                                                type="submit"
                                                variant="contained"
                                                text="Export"
                                                onClick={downloadExcel}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionActions>
                                <AccordionDetails>
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <Grid item container xs={12}>
                                            <div
                                                className="ag-theme-material"
                                                style={{ width: "100%", height: "80svh" }}
                                            >
                                                <AgGridCommon
                                                    columnDefs={columnDefs}
                                                    rowData={data.enrollments}
                                                    pagination={true}
                                                    paginationPageSize={paginationPageSize}
                                                    defaultColDef={defaultColDef}
                                                    frameworkComponents={frameworkComponents}
                                                    onGridReady={onGridReady}
                                                    gridApi={gridApi}
                                                    enableCellTextSelection={true}
                                                    tooltipShowDelay={0}
                                                ></AgGridCommon>
                                            </div>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, selectedCourse, data, courseEmptyHideCb } = state.elearningCourseAchievements;
    const { user } = state.authentication;

    const loadingCourses = state.enrollment.loadingLeftSide;
    const categorizedCourses = state.enrollment.categorizedCourses;

    return {
        loading,
        data,
        selectedCourse,
        user,
        courseEmptyHideCb,
        loadingCourses,
        categorizedCourses,
    };
}

const actionCreators = {
    get: elearningCourseAchievements.get,
    download: elearningCourseAchievements.download,
    selectCourse: elearningCourseAchievements.selectCourse,
    changeEmptyHideCb: elearningCourseAchievements.changeEmptyHideCb,
    fetchCategorizedCoursesAction: fetchCategorizedCoursesAction,
};

export default connect(mapState, actionCreators)(AchievementsList);
