import { Typography } from "@material-ui/core";
import React from "react";

const QuestionInfo = ({ question }) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography variant="body1">Kérdés neve</Typography>
        <Typography variant="body2" style={{ color: "#777" }} gutterBottom>
            {question.name}
        </Typography>
        <Typography variant="body1">Kérdés szövege</Typography>
        <div
            style={{ fontSize: "0.875rem", marginBottom: "0.35em", color: "#777" }}
            dangerouslySetInnerHTML={{ __html: question.text }}
        />
        <Typography variant="body1">Maximális pontszám</Typography>
        <Typography variant="body2" gutterBottom style={{ color: "#777" }}>
            {(question.maxPoint || 0).toFixed(2)}
        </Typography>
    </div>
);

export default QuestionInfo;
