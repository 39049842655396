import { Box, DialogActions, DialogTitle, FormControlLabel, FormGroup, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import i18next from "i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import coursesAction from "../../../store/courses/actions";
import notificationsActions from "../../../store/notifications/actions";
import colors from "../../../styles/colors.scss";
import CancelButton from "../../Common/CancelButton";
import DeleteButton from "../../Common/DeleteButton";
import GreenSwitch from "../../Common/GreenSwitch";
import GreenTooltip from "../../Common/GreenTooltip";
import Loading from "../../Common/Loading";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import DeleteIcon from "../Icons/DeleteIcon";
import DeleteCancelButton from "../../Common/DeleteCancel";

function BtnCellRenderer(params) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const versionId = params.data.id;
    const courseId = params.data.course_id;
    return (
        <>
            <OperationButton
                to={`/e-learning/course-management/edit/${courseId}/privacy-statements/${versionId}/edit`}
                type="edit"
            />
            <Box component="span" marginX={1} />
            {params.data.status === "future" && params.data.end_date === null && (
                <>
                    <GreenTooltip title={i18next.t("ob.delete")}>
                        <Link to={"#"} onClick={() => handleClickOpen()}>
                            <DeleteIcon />
                        </Link>
                    </GreenTooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{ backgroundColor: colors.darkError, color: colors.white }}
                        >
                            {i18next.t("ob.delete")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>{i18next.t("ob.confirmation")}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <DeleteCancelButton
                                text={i18next.t("cancel")}
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <DeleteButton
                                text={i18next.t("ob.delete")}
                                onClick={() => {
                                    handleClose();

                                    params.context["delete"](courseId, versionId)
                                        .then((data) => {
                                            params.context["statementGetsAction"](courseId);
                                            params.context.AddNotification("success", i18next.t("ob.success"));
                                        })
                                        .catch((error) => {
                                            params.context.AddNotification("error", i18next.t("ob.error"));
                                        });
                                }}
                            />
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
}

function DateCellRenderer(params) {
    if (params.value) {
        return new Date(params.value).toLocaleDateString();
    }
    return "-";
}

function StatusCellRenderer(params) {
    switch (params.data.status) {
        case "future":
            return (
                <Box component="span" bgcolor="warning.main" color="white" p={1}>
                    {i18next.t("pages.future")}
                </Box>
            );
        case "active":
            return (
                <Box component="span" bgcolor="primary.main" color="white" p={1}>
                    {i18next.t("pages.active")}
                </Box>
            );
        case "archived":
            return (
                <Box component="span" bgcolor="text.disabled" p={1}>
                    {i18next.t("pages.archive")}
                </Box>
            );
        default:
            return (
                <Box component="span" bgcolor="text.disabled" p={1}>
                    {i18next.t("pages.unknown")}
                </Box>
            );
    }
}

function CourseManagementEditStatement(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("pages.start"),
                field: "start_date",
                cellRenderer: "dateCellRenderer",
                sort: "desc",
            },
            {
                headerName: i18next.t("pages.end"),
                field: "end_date",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: i18next.t("pages.status"),
                field: "status",
                cellRenderer: "statusCellRenderer",
                sortable: false,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
    };

    const handleChange = (event) => props.onChangeCheckbox(event);

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (props.loading) return <Loading />;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        label={
                            <Typography style={{ color: colors.black }}>
                                {i18next.t("course_management.statement.unique")}
                            </Typography>
                        }
                        control={
                            <GreenSwitch
                                checked={props.unique_privacy_statement}
                                onChange={handleChange}
                                name="unique"
                                id="unique"
                            />
                        }
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} style={{ display: !props.unique_privacy_statement ? "none" : "block" }}>
                <Table
                    columnDefs={state.columnDefs}
                    rowData={props.data}
                    frameworkComponents={state.frameworkComponents}
                    onGridReady={onGridReady}
                    gridApi={gridApi}
                    newButtonText={i18next.t("pages.statement.new")}
                    to={`/e-learning/course-management/edit/${props.courseId}/privacy-statements/new`}
                    context={{
                        delete: props.deleteAction,
                        statementGetsAction: props.statementGets,
                        AddNotification: props.addNoti,
                    }}
                />
            </Grid>
        </Grid>
    );
}

function mapStateToProps(state) {
    return {
        data: state.courses.statementData,
        unique_privacy_statement: state.courses.settings.unique_privacy_statement || false,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        statementGets: (course) => dispatch(coursesAction.statementGetsAction(course)),
        deleteAction: (course, statement) => dispatch(coursesAction.statementDeleteRow(course, statement)),
        addNoti: (type, msg, redirect, options) =>
            dispatch(notificationsActions.addNotification(type, msg, redirect, options)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementEditStatement);
