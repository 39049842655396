export const types = {
    FETCH_REQUEST: "@@settings/FETCH_REQUEST",
    FETCH_SUCCESS: "@@settings/FETCH_SUCCESS",
    FETCH_FAILURE: "@@settings/FETCH_FAILURE",

    POST_REQUEST: "@@settings/POST_REQUEST",
    POST_SUCCESS: "@@settings/POST_SUCCESS",
    POST_FAILURE: "@@settings/POST_FAILURE",

    GET_REQUEST: "@@settings/GET_REQUEST",
    GET_SUCCESS: "@@settings/GET_SUCCESS",
    GET_FAILURE: "@@settings/GET_FAILURE",
    CHANGE_FORM: "@@settings/CHANGE_FORM",
    DEFAULT_FORM: "@@settings/DEFAULT_FORM",
};
