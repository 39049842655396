import { useCallback, useEffect, useState } from "react";
import API from "../utils/API";

const IGNORABLE_ERRORS = ["AbortError", "CancelError"];

const useFetch = (endpoint, options, instance) => {
    const [data, setData] = useState({
        data: null,
        error: null,
        loading: true,
    });
    const [refresh, setRefresh] = useState(false);
    const refreshData = useCallback(() => {
        setRefresh((prev) => !prev);
    }, []);
    useEffect(() => {
        if (!endpoint) {
            setData({
                data: null,
                error: "Endpoint is required",
                loading: false,
            });
            return;
        }
        const controller = new AbortController();
        setData((prev) => ({
            data: prev.data,
            error: null,
            loading: true,
        }));
        (instance || API)(endpoint, {
            headers: JSON.parse(options ? options.headers : "{}"),
            method: options ? options.method : "GET",
            signal: controller.signal,
            params: JSON.parse(options ? options.params : "{}"),
            responseType: options ? options.responseType : "json",
        })
            .then((response) => {
                if (response) {
                    setData({
                        data: options && options.responseKey ? response.data[options.responseKey] : response.data,
                        error: "",
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                if (!IGNORABLE_ERRORS.includes(error.name)) {
                    console.error(error);
                    setData({
                        data: null,
                        error: error.message,
                        loading: false,
                    });
                }
            });

        return () => {
            controller.abort();
        };
    }, [endpoint, options, instance, refresh]);

    return { ...data, refreshData };
};

export default useFetch;
