import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../../styles/colors.scss";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../Common/TabPanel";
import TxtField from "../../Common/TxtField";
import PropTypes from "prop-types";
import Header from "../../Common/Header";
import GreenTableCell from "../../Common/GreenTableCell";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import Copy from "../../Common/Copy";
import AntTabs from "../../Common/AntTabs";
import LinkToButton from "../../Common/LinkToButton";
import emailTemplatesActions from "../../../store/emailTemplates/actions.js";
import { connect } from "react-redux";
import notificationsActions from "../../../store/notifications/actions.js";
import Validator from "../../../utils/Validator";
import validator from "../../../store/validator/actions";
import CkeditorCustom from "../../Common/CkeditorCustom";
import Loading from "../../Common/Loading";
import i18next from "i18next";

const useStyles = (theme) => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    tabs: {
        width: "100%",
        margin: theme.spacing(3, 0),
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    subject: {
        margin: theme.spacing(3, 0, 3, 0),
    },
    titleText: {
        fontSize: "large",
        marginBottom: theme.spacing(1),
    },
});

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

class EmailTemplatesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            needCData: this.props.ckeditorNeedData,
            value: 0,
            langCode: {
                0: "hu",
                1: "en",
            },
            headerTexts: [i18next.t("hu"), i18next.t("en")],
        };
    }

    componentDidMount() {
        const { templateId } = this.props.match.params;
        this.props.clearErrors();
        this.setState({ id: templateId });
        this.props.get(templateId);
        this.props.setValidatorErrors({});
        this.props.getValidationRules("email-templates");


    }

    render() {
        const { classes, loading, row, user } = this.props;
        const validator = new Validator(row, this.props.validator.validatorErorrs, this.props.validator.rules);
        const lang = this.state.langCode[this.state.value];
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };

        const handleBlur = (event) => {
            validator.setErrorsClient(event.target.name, lang);
            this.props.setValidatorErrors(validator.getErrors());
        };

        const setTabByErrors = (validator) => {
            const firstLang = validator.getErrorFirstLang();
            if (firstLang) {
                const firstLangIndex = validator.getKeyByValue(this.state.langCode, firstLang);
                if (firstLangIndex != -1 && firstLangIndex != this.state.value) {
                    this.setState({ value: parseInt(firstLangIndex) });
                }
            }
        };

        const handleSubmit = (event) => {
            event.preventDefault();

            this.props
                .post(row, this.state.id)
                .then(() => {
                    this.props.addNotification(
                        "success",
                        i18next.t("email_templates.form.success"),
                        "/email-templates"
                    );
                })
                .catch((error) => {
                    validator.setReponseError(error);
                    this.props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                    this.props.addNotification("error", i18next.t("email_templates.form.error"));
                });
        };

        const handleClick = () => {
            const mainLang = user.language;
            inputChange("", row.subject[mainLang]);
            Object.assign(row, {
                subject: { ...row.subject, [lang]: row.subject[mainLang] },
                name: { ...row.name, [lang]: row.name[mainLang] },
            });
            ckeditorChange("", "", row.content[mainLang]);
        };

        const inputChange = (event, newVal) => {
            this.setState({
                ...this.state,
                needCData: true,
            });
            const value = event ? event.target.value : newVal;
            this.props.changeForm("row", {
                ...row,
                subject: {
                    ...row.subject,
                    [lang]: value,
                },
            });
        };

        const ckeditorChange = (event, editor, newVal) => {
            this.setState({
                ...this.state,
                needCData: true,
            });
            const value = event ? editor.getData() : newVal;
            this.props.changeForm("row", {
                ...row,
                content: {
                    ...row.content,
                    [lang]: value,
                },
            });
        };

        const getValue = (name, lang) => {
            return row[name][lang] || "";
        };

        const headers = [];

        const langTabs = [];

        for (const [index, value] of this.state.headerTexts.entries()) {
            headers.push(<Tab label={value} key={index} />);
        }

        for (var i = 0; i < this.state.headerTexts.length; i++) {
            const lng = this.state.langCode[this.state.value];
            const vars = row.variables[lng] || {};
            langTabs.push(
                <TabPanel value={this.state.value} index={i} key={i}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item container justify="space-between">
                            <Typography className={classes.titleText}>
                                {i18next.t("email_templates.form.variables")}
                                {user.language === this.state.langCode[i] ? (
                                    ""
                                ) : (
                                    <Copy onClick={handleClick} disabled={loading} />
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHead component="thead">
                                    <TableRow key={0} component="tr">
                                        <GreenTableCell>{i18next.t("email_templates.key")}</GreenTableCell>
                                        <GreenTableCell>{i18next.t("email_templates.form.explanation")}</GreenTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component="tbody">
                                    {Object.keys(vars).map((value) => {
                                        return (
                                            <TableRow key={value} component="tr">
                                                <GreenTableCell padding="none">{value}</GreenTableCell>
                                                <GreenTableCell>{row.variables[lng][value]}</GreenTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit} noValidate={true}>
                                <Grid item container xs={12} spacing={1}>
                                    <Grid item xs={12}>
                                        <TxtField
                                            required={validator.isRequired("subject")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={i18next.t("email_templates.email_subject")}
                                            id="subject"
                                            margin="normal"
                                            variant="outlined"
                                            name="subject"
                                            disabled={loading}
                                            className={classes.subject}
                                            error={validator.hasError("subject", this.state.langCode[i])}
                                            helperText={validator.getErrorText("subject", this.state.langCode[i])}
                                            fullWidth
                                            value={row.subject[lng] || ""}
                                            onChange={inputChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CkeditorCustom
                                            label={i18next.t("email_templates.email_content")}
                                            required={validator.isRequired("content")}
                                            getValue={getValue}
                                            lang={this.state.langCode[i]}
                                            ckeditorChange={ckeditorChange}
                                            error={validator.hasError("content", this.state.langCode[i])}
                                            helperText={validator.getErrorText("content", this.state.langCode[i])}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item container wrap="nowrap">
                                        <Grid item>
                                            <SubmitButton
                                                type="submit"
                                                variant="contained"
                                                className={classes.submit}
                                                text={i18next.t("save")}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <LinkToButton to="/email-templates">
                                                <CancelButton
                                                    variant="outlined"
                                                    className={classes.submit}
                                                    disabled={loading}
                                                    text={i18next.t("cancel")}
                                                />
                                            </LinkToButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </TabPanel>
            );
        }
        if (loading) return <Loading />;
        return (
            <React.Fragment>
                <CssBaseline />
                <Grid item container spacing={2}>
                    <Header
                        breadcrumbs={{
                            administration: i18next.t("sidebar.administration"),
                            "email-templates": i18next.t("sidebar.email_templates"),
                            "edit-email-templates": i18next.t("email_templates.edit"),
                        }}
                    />
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={i18next.t("email_templates.edit")} className={classes.header} />
                            <CardContent>
                                <Typography>
                                    {i18next.t("email_templates.key")}: <b>{row.key}</b>
                                </Typography>
                                <Typography>
                                    {i18next.t("email_templates.name")}: <b>{row.name[user.language]}</b>
                                </Typography>
                                <div className={classes.tabs}>
                                    <AppBar position="static">
                                        <AntTabs
                                            value={this.state.value}
                                            onChange={handleChange}
                                            aria-label="simple tabs example"
                                            className={classes.tabsBg}
                                            variant="fullWidth"
                                        >
                                            {headers}
                                        </AntTabs>
                                    </AppBar>
                                    {langTabs}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
function mapState(state) {
    const { loading, row } = state.emailTemplates;
    const { formError } = state.notifications;
    const { user } = state.authentication;
    const validator = state.validator;
    return { loading, row, formError, user, validator };
}

const actionCreators = {
    get: emailTemplatesActions.get,
    post: emailTemplatesActions.post,
    changeForm: emailTemplatesActions.changeForm,
    addNotification: notificationsActions.addNotification,
    removeNotification: notificationsActions.removeNotification,
    addError: notificationsActions.addError,
    removeError: notificationsActions.removeError,
    clearErrors: notificationsActions.clearErrors,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(withStyles(useStyles)(EmailTemplatesEdit));
