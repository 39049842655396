import { types } from "./types";

const initialState = {
    loading: false,
    news: {
        data: [],
    },
    sysMessages: {
        data: [],
    },
    myCourses: [],
    openCourseObject: null,
};

export function main(state = initialState, action) {
    switch (action.type) {
        case types.GET_NEWS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                news: action.data.data,
            };
        case types.GET_NEWS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case types.GET_SYSMESSAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SYSMESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                sysMessages: action.data.data,
            };
        case types.GET_SYSMESSAGES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case types.GET_MY_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_MY_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                myCourses: action.data.data,
            };
        case types.GET_MY_COURSES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case types.SET_LASTSEEN_REQUEST:
            return {
                ...state,
            };
        case types.SET_LASTSEEN_SUCCESS:
            return {
                ...state,
            };
        case types.SET_LASTSEEN_FAILURE:
            return {
                ...state,
            };

        case types.GET_OPEN_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                openCourseObject: null,
            };
        case types.GET_OPEN_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                openCourseObject: action?.data?.data,
            };
        case types.GET_OPEN_COURSE_FAILURE:
            return {
                ...state,
                loading: false,
                openCourseObject: null,
            };
        default:
            return state;
    }
}
