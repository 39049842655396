import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Filter from "../../Common/Filter";
import Header from "../../Common/Header";
import Loading from "../../Common/Loading";
import TxtField from "../../Common/TxtField";
import DatePicker from "../../Common/DatePicker";
import elearningExamManagementActions from "../../../store/elearningExamManagement/actions";
import i18next from "i18next";
import { connect } from "react-redux";
import ExamManagementTable from "./ExamManagementTable";
import DateTimePicker from "../../Common/DateTimePicker";
import chamberActions from "../../../store/chambers/actions";
import notificationsActions from "../../../store/notifications/actions";
import {convertDateToHyphenFormat} from '../../../utils/AppConst';

function ExamManagement(props) {
    const { search, loading, user } = props;

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, true)
        props.changeForm('search', {
            ...search,
            [name]: newDate
        });
    };

    useEffect(() => {
        props.filter(search);
    }, []);

    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.filter({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.filter(search);
    };

    const filtForm = (
        <>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    value={search.category || ""}
                    margin="normal"
                    id="category"
                    label={i18next.t("exam_management.category")}
                    name="category"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    value={search.course || ""}
                    margin="normal"
                    id="course"
                    label={i18next.t("exam_management.course")}
                    name="course"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    value={search.exam || ""}
                    margin="normal"
                    id="exam"
                    label={i18next.t("exam_management.exam")}
                    name="exam"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateTimePicker
                    disablePast={false}
                    onChange={(date) => handleDateChange(date, "examStartFrom")}
                    value={search.examStartFrom || ''}
                    id="examStartFrom"
                    label={i18next.t("exam_management.start") + " (" + i18next.t("course_management.from") + ")"}
                    name="examStartFrom"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateTimePicker
                    disablePast={false}
                    onChange={(date) => handleDateChange(date, "examStartUntil")}
                    value={search.examStartUntil || ''}
                    id="examStartUntil"
                    label={i18next.t("exam_management.start") + " (" + i18next.t("course_management.until") + ")"}
                    name="examStartUntil"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateTimePicker
                    disablePast={false}
                    onChange={(date) => handleDateChange(date, "examEndFrom")}
                    value={search.examEndFrom || ''}
                    id="examEndFrom"
                    label={i18next.t("exam_management.end") + " (" + i18next.t("course_management.from") + ")"}
                    name="examEndFrom"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateTimePicker
                    disablePast={false}
                    onChange={(date) => handleDateChange(date, "examEndUntil")}
                    value={search.examEndUntil || ''}
                    id="examEndUntil"
                    label={i18next.t("exam_management.end") + " (" + i18next.t("course_management.until") + ")"}
                    name="examEndUntil"
                />
            </Grid>
        </>
    );

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.course_management")}
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    exam_management: "Vizsgakezelés", //i18next.t("sidebar.course_management")
                }}
            />
            <Filter filterForm={filtForm} defaultForm={resetForm} onSubmit={handleSubmit} />
            <ExamManagementTable
                data={props.data}
                userLang={user.language}
                delete={props.delete}
                filter={props.filter}
                addNotification={props.addNotification}
                gridApi={gridApi}
                onGridReady={onGridReady}
            />
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data } = state.elearningExamManagement;
    const { user } = state.authentication;
    return { loading, search, data, user };
}

const actionCreators = {
    changeForm: elearningExamManagementActions.changeForm,
    defaultForm: elearningExamManagementActions.defaultForm,
    filter: elearningExamManagementActions.filter,
    delete: elearningExamManagementActions.deleteExam,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(ExamManagement);
