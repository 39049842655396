import { types } from "./types";

const initialState = { snackbar: { type: "", msg: "" }, formError: [] };

export function notifications(state = initialState, action) {
    switch (action.type) {
        case types.ADD_NOTIFICATION:
            return {
                ...state,
                snackbar: action.data,
            };
        case types.REMOVE_NOTIFICATION:
            return {
                ...state,
                snackbar: initialState.snackbar,
            };
        case types.ADD_ERROR:
            return {
                ...state,
                formError: [...state.formError, action.data],
            };
        case types.REMOVE_ERROR:
            return {
                ...state,
                formError: action.data,
            };
        case types.CLEAR_ERRORS:
            return {
                ...state,
                formError: initialState.formError,
            };
        default:
            return state;
    }
}
