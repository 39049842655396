import { types } from "./types";

const initialState = {
    loading: false,
    data: {
        exams: [],
        enrollments: [],
        completions: [],
    },
    selectedCourse: null,
    courseEmptyHideCb: 1,
    downloadLoading: false,
};

export function elearningCourseAchievements(state = initialState, action) {
    switch (action.type) {
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: initialState.data,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.DOWNLOAD_REQUEST:
            return {
                ...state,
                downloadLoading: true,
            };
        case types.DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloadLoading: false,
            };
        case types.DOWNLOAD_FAILURE:
            return {
                ...state,
                downloadLoading: false,
                error: action.error,
            };

        case types.SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: action.course,
            };

        case types.CHANGE_EMPTY_HIDE_CB:
            return {
                ...state,
                courseEmptyHideCb: action.val,
            };

        default:
            return state;
    }
}
