import React, { useEffect, useState } from "react";
import { CardContent, Grid, Typography } from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import UsersTable from "./UsersTable";
import userActions from "../../../store/users/actions.js";
import { connect } from "react-redux";
import Autocomplete from "../../Common/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../Common/Loading";
import Filter from "../../Common/Filter";
import DatePicker from "../../Common/DatePicker";
import i18next from "i18next";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import { setInfo } from "../../../utils/FilterHelper";
import { convertDateToHyphenFormat } from '../../../utils/AppConst';

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function Users(props) {
    const classes = useStyles();

    const typeOptions = [
        { value: 0, label: i18next.t("users.normal"), key: 0 },
        { value: 1, label: i18next.t("isziir"), key: 1 },
    ];

    const subTypeOptions = [
        { value: 'normal', label: i18next.t("users.subtype.normal"), key: 0 },
        { value: 'kamgyak', label: i18next.t("users.subtype.kamgyak"), key: 1 },
    ];

    const { loading, data, search, user } = props;
    const externalOptions = [
        { value: 0, label: i18next.t("no"), key: 0 },
        { value: 1, label: i18next.t("yes"), key: 1 },
    ];
    const passwordExpiresOptions = [
        { value: 0, label: i18next.t("no"), key: 0 },
        { value: 1, label: i18next.t("yes"), key: 1 },
    ];
    const deletedOptions = [
        { value: 0, label: i18next.t("no"), key: 0 },
        { value: 1, label: i18next.t("yes"), key: 1 },
    ];

    useEffect(() => {
        props.fetchChambers().then(() => {
            props.filter(search);
        });
    }, []);

    const chamberOptions = props.chambers.map((object, key) => {
        return {
            value: object.id,
            label: object.name[user.language] + (object.active == 0 ? ` '${i18next.t("users.inactive")}'` : ""),
            key: key,
        };
    });

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });



    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm('search', {
            ...search,
            [name]: newDate
        });
    };

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("search", { ...search, [name]: values.map((v) => v) });
    };

    const propsSearch = {
        ...props,
        search: props.filter
    };

    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.filter({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInfo(propsSearch, "filter", search, 'page', 1);
        //props.filter(search);
    };

    const filtForm = (
        <>
            <Grid item xs={12} sm={3} md={2}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    defaultValue={search.type}
                    options={typeOptions}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    id="type"
                    onChange={(e, v) => handleMultiselectChange(e, v, "type")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.type")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    defaultValue={search.sub_type}
                    options={subTypeOptions}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    id="sub_type"
                    onChange={(e, v) => handleMultiselectChange(e, v, "sub_type")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.subtype")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.last_name || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("users.last_name")}
                    id="last_name"
                    name="last_name"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.first_name || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("users.first_name")}
                    id="first_name"
                    name="first_name"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.email || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("users.email")}
                    id="email"
                    name="email"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    key={search.chamber}
                    defaultValue={search.chamber}
                    options={chamberOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="chamber"
                    margin="normal"
                    variant="outlined"
                    onChange={(e, v) => handleMultiselectChange(e, v, "chamber")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.chamber")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    key={search.external}
                    defaultValue={search.external}
                    options={passwordExpiresOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="external"
                    onChange={(e, v) => handleMultiselectChange(e, v, "external")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.external")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    key={search.password_expires}
                    defaultValue={search.password_expires}
                    options={externalOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="password_expires"
                    onChange={(e, v) => handleMultiselectChange(e, v, "password_expires")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.password_expires")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    key={search.deleted}
                    defaultValue={search.deleted}
                    options={deletedOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="deleted"
                    onChange={(e, v) => handleMultiselectChange(e, v, "deleted")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("users.deleted")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "loginFrom")}
                    value={search.loginFrom}
                    id="loginFrom"
                    label={i18next.t("users.last_login") + " (" + i18next.t("users.loginFrom") + ")"}
                    name="loginFrom"
                    className={classes.autocomplete}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "loginUntil")}
                    value={search.loginUntil}
                    id="loginUntil"
                    label={i18next.t("users.last_login") + " (" + i18next.t("users.loginUntil") + ")"}
                    name="loginUntil"
                    className={classes.autocomplete}
                />
            </Grid>
        </>
    );

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.users")}
                breadcrumbs={{
                    master_datas: i18next.t("sidebar.master_data"),
                    users: i18next.t("sidebar.users"),
                }}
            />
            {loading ? (
                <Loading />
            ) : (
                <Filter
                    filterForm={filtForm}
                    defaultForm={(e) => resetForm(e)}
                    onSubmit={handleSubmit}
                />
            )}
            <Grid item container xs={12}>
                <UsersTable
                    loading={props.loading}
                    tableLoading={props.tableLoading}
                    data={data}
                    user={user}
                    search={search}
                    filter={search}
                    info={props.info}
                    setInfo={props.setInfo}
                />
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data, chambers, info, tableLoading } = state.users;
    const { user } = state.authentication;
    return { loading, search, data, user, chambers, info, tableLoading };
}

const actionCreators = {
    filter: userActions.filter,
    changeForm: userActions.changeForm,
    defaultForm: userActions.defaultForm,
    fetchChambers: userActions.fetchChambers,
    setInfo: userActions.setInfo,
};

export default connect(mapState, actionCreators)(Users);
