import React from 'react'
import GreenTooltip from '../../Common/GreenTooltip'
import { Archive, Unarchive } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import colors from "../../../styles/colors.scss";
import coursesService from '../../../services/courses';
import i18next from "i18next";
import SubmitButton from '../../Common/SubmitButton';
import CancelButton from '../../Common/CancelButton';
import Loading from '../../Common/Loading';

export default function ArchiveButton(props) {
    const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);
    const [openUnArchiveDialog, setOpenUnArchiveDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const closeArchiveDialog = () => {
        setOpenArchiveDialog(false);
        setIsLoading(false)
    };
    const closeUnArchiveDialog = () => {
        setOpenUnArchiveDialog(false);
        setIsLoading(false)
    };

    const reloadCourses = (successMessage) => {
        props.params.context.AddNotification(
            "success",
            i18next.t(successMessage)
        );

        props.params.context.filter(props.params.context.searchData);
    }

    return (
        <>
        {props.params.data.archivatedAt ?
            <GreenTooltip title={i18next.t("course_management.un_archive_modal.submit")}>
                <Button variant="contained" className={"btn-green"} style={{maxHeight: "26px"}} onClick={() => setOpenUnArchiveDialog(true)} >
                        <Unarchive fontSize="small" />
                </Button>
            </GreenTooltip>
            :
            <GreenTooltip title={i18next.t("course_management.archive_modal.submit")}>
                <Button variant="contained" style={{ backgroundColor: colors.orange, color: colors.white, maxHeight: "26px" }} onClick={() => setOpenArchiveDialog(true)} >
                        <Archive fontSize="small" />
                </Button>
            </GreenTooltip>
        }
        {/* archive dialog */}
        <Dialog open={openArchiveDialog} onClose={closeArchiveDialog}>
            <DialogTitle
                style={{ backgroundColor: colors.primary, color: colors.white }}
                id="responsive-dialog-title"
            >
                {i18next.t("course_management.archive_modal.title")}
            </DialogTitle>
                <DialogContent>
                    {isLoading ?
                        <div>
                            <Typography style={{textAlign: "center"}}>{i18next.t("course_management.archive_modal.loading_text")}</Typography>
                            <Loading /> 
                        </div>
                        : 
                        <DialogContentText>{i18next.t("course_management.archive_modal.content")}</DialogContentText>
                    }
                </DialogContent>
            <DialogActions>
                <CancelButton
                    disabled={isLoading}
                    text={i18next.t("cancel")}
                    onClick={() => {
                        closeArchiveDialog();
                    }}
                />
                <SubmitButton
                    disabled={isLoading}
                    text={i18next.t("course_management.archive_modal.submit")}
                    onClick={() => {
                        setIsLoading(true);
                        coursesService.archiveCourse(props.params.data.id)
                        .then(() => reloadCourses("ob.archivateSuccess"))
                        .catch((error) => {
                            props.params.context.AddNotification(
                                "error",
                                i18next.t("ob.archivateError")
                            );
                        });
                    }}
                />
            </DialogActions>
        </Dialog>
        {/* unarchive dialog */}
        <Dialog open={openUnArchiveDialog} onClose={closeUnArchiveDialog}>
            <DialogTitle
                style={{ backgroundColor: colors.primary, color: colors.white }}
                id="responsive-dialog-title"
            >
                {i18next.t("course_management.un_archive_modal.title")}
            </DialogTitle>
                <DialogContent>
                    {isLoading ?
                        <div>
                            <Typography style={{textAlign: "center"}}>{i18next.t("course_management.un_archive_modal.loading_text")}</Typography>
                            <Loading /> 
                        </div>
                        : 
                        <DialogContentText>{i18next.t("course_management.un_archive_modal.content")}</DialogContentText>
                    }
                </DialogContent>
            <DialogActions>
                <CancelButton
                    disabled={isLoading}
                    text={i18next.t("cancel")}
                    onClick={() => {
                        closeUnArchiveDialog();
                    }}
                />
                <SubmitButton
                    disabled={isLoading}
                    text={i18next.t("course_management.un_archive_modal.submit")}
                    onClick={() => {
                        setIsLoading(true);
                        coursesService.unArchiveCourse(props.params.data.id)
                            .then(() => reloadCourses("ob.unArchivateSuccess"))
                            .catch((error) => {
                                 props.params.context.AddNotification(
                                    "error",
                                    i18next.t("ob.unArchivateError")
                                );
                        });
                    }}
                />
            </DialogActions>
        </Dialog>
    </>
    )
}
