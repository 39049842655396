import Cookies from "universal-cookie";

const cookies = new Cookies();

export const logout = () => {
    if (getIsziirUserLoggedIn() === "yes") {
        setIsziirUserLoggedIn("yes", "no");
    }
    removeJWTToken();
    removeUser();
    removeImpersonationToken();
};

export const isLogin = () => {
    return !!getJWTToken() && !!getUser();
    /*
    //figyelembe veszi a lejárati időt
    let token = getJWTToken();
    if (token) {
        let decodedToken = jwt_decode(token);
        //console.log("Decoded Token", decodedToken);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            //console.log("Token expired.");
            logout();
            return false;
        } else {
            //console.log("Valid token");
            return true;
        }
    }

    logout();
    return false;
    */
};

export const authUser = () => {
    return getUser() ? JSON.parse(getUser()) : null;
};

export const hasPermission = (permissionToCheck) => {
    if (!isLogin() || !authUser()) {
        return false;
    }

    return (
        authUser().permissions &&
        authUser().permissions.filter((permission) => {
            return permission === permissionToCheck;
        }).length > 0
    );
};

export const hasPermissions = (permissionsToCheck) => permissionsToCheck.some(hasPermission);

export const hasRole = (roleToCheck) => {
    if (!isLogin() || !authUser()) {
        return false;
    }

    return (
        authUser().roles &&
        authUser().roles.filter((role) => {
            return role === roleToCheck;
        }).length > 0
    );
};

export const getImpersonationToken = () => {
    return cookies.get("impersonation_token");
};
export const setImpersonationToken = (token) => {
    cookies.set("impersonation_token", token, { path: "/" });
};
export const removeImpersonationToken = () => {
    cookies.remove("impersonation_token");
};

export const getJWTToken = () => {
    return cookies.get("jwt");
};
export const setJWTToken = (token) => {
    cookies.set("jwt", token, { path: "/" });
};
export const removeJWTToken = () => {
    cookies.remove("jwt");
};

export const getUser = () => {
    return localStorage.getItem("user");
    //return cookies.get('user');
};
export const setUser1 = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    //cookies.set('user', user, { path: '/' });
};
export const removeUser = () => {
    localStorage.removeItem("user");
    //cookies.remove('user');
};

export const getIsziirUserLoggedIn = () => {
    return localStorage.getItem("isIsziirUserLoggedIn");
};

export const setIsziirUserLoggedIn = (val, inactivateSession) => {
    localStorage.setItem("isIsziirUserLoggedIn", val);
    if (inactivateSession) {
        localStorage.setItem("isIsziirUserLoggedInInactivateSession", inactivateSession);
    }
};

export const isInactive = () => {
    return localStorage.getItem("isIsziirUserLoggedInInactivateSession") === "yes";
};

export const removeIsziirUserLoggedIn = () => {
    localStorage.removeItem("isIsziirUserLoggedIn");
    localStorage.removeItem("isIsziirUserLoggedInInactivateSession");
};

export const isziirLogout = () => {
    window.location.href = process.env.REACT_APP_ISZIIR_LOGOUT_URL;
};

export const setApiUrl = (url) => {
    cookies.set("api_url", url, { path: "/" });
};
