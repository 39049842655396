import API from "../../utils/API";

function getNews() {
    return API.get(process.env.REACT_APP_API_URL + `/news/published`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getSysMessages() {
    return API.get(process.env.REACT_APP_API_URL + `/messages/published`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getMyCourses() {
    return API.get(process.env.REACT_APP_API_URL + `/my-courses`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function setLastSeen(type) {
    return API.patch(process.env.REACT_APP_API_URL + `/users/last-seen`, {
        type: type,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getOpenCourse(courseId) {
    return API.get(process.env.REACT_APP_API_URL + `/courses/${courseId}/base`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const mainService = {
    getNews,
    getSysMessages,
    setLastSeen,
    getMyCourses,
    getOpenCourse,
};

export default mainService;
