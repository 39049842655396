import React from "react";
import { Accordion, Grid, AccordionDetails } from "@material-ui/core";
import Header from "../../Common/Header";
import AccordionSum from "../../Common/AccordionSum";
import UsersForm from "./UsersForm";
import { connect } from "react-redux";
import i18next from "i18next";

function UsersEdit(props) {
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    masterDatas: i18next.t("sidebar.master_data"),
                    "/users": i18next.t("sidebar.users"),
                    new_users: i18next.t("users.edit"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("users.edit")} />
                    <AccordionDetails style={{ display: "block" }}>
                        <UsersForm userId={props.match.params.userId} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default connect()(UsersEdit);
