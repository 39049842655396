import { Autocomplete } from "@material-ui/lab";
import i18next from "i18next";
import React from "react";
import instance from "../../utils/API";
import TxtField from "./TxtField";
import useDebounce from "../../hooks/useDebounce";
import * as Sentry from "@sentry/react";
import { authUser } from "../../utils/AuthHelper";

const baseItemConverter = (item) => ({ value: item.course_id || item.id, label: item.name || item.displayName });

export default function BackendAutocompleteOffsetScroll(props) {
    const { url, error, helperText, label, required, itemConverter, paramsKey, ...rest } = props;
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [currentOffset, setCurrentOffset] = React.useState(0);

    React.useEffect(() => {
        if (!props.disabled) {
            fetch("", 0);
        }
    }, [props.disabled]);

    const fetch = useDebounce((term, offset) => {
        if (url === "") {
            return;
        }

        if (url.includes('/e-learning/exam-generation/courses/undefined/exams')) {
            Sentry.captureMessage('Exam generation [MKIKUZE-347] - Undefined course id. Auth user email: ' + authUser().email);
            return;
        }

        setLoading(true);
        instance
            .get(url, { params: { [paramsKey || "term"]: term, offset: offset } })
            .then((res) => {
                if (offset > 0) {
                    setOptions([...options, ...res.data.map(itemConverter || baseItemConverter)]);
                } else {
                    setOptions(res.data.map(itemConverter || baseItemConverter));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, 500);

    return (
        <Autocomplete
            size="small"
            loading={loading}
            open={open}
            loadingText={i18next.t("permissions.loading")}
            inputValue={inputValue}
            options={options}
            placeholder={i18next.t("permissions.placeholder")}
            noOptionsText={i18next.t("permissions.nooption")}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
                fetch(newInputValue, 0);
                setCurrentOffset(0);
            }}
            onOpen={() => {
                setOpen(true);
                if (options.length === 0) {
                    fetch("", 0);
                }
                setCurrentOffset(0);
            }}
            onClose={() => {
                setOpen(false);
            }}
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        const offset = currentOffset + 1;

                        fetch(inputValue, offset);
                        setCurrentOffset(offset);
                    }
                },
            }}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TxtField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label={label}
                    required={required}
                    margin="normal"
                    variant="outlined"
                />
            )}
            {...rest}
        />
    );
}
