import { Grid } from '@material-ui/core';
import React from 'react';
import InfoIcon from "@material-ui/icons/InfoOutlined";

export default function InfoBox({children}) {
  return (
    <Grid container spacing={2} xs={12} alignItems="center" justify='center' style={{margin: "5px"}} >
        <Grid item container xs={8} style={{background: "rgb(232, 244, 253)"}}>
            <Grid item xs={2} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <InfoIcon style={{color: "#2196f3"}}/>
            </Grid>
            <Grid item xs={10} style={{color: "rgb(13, 60, 97)"}}>
                {children}
            </Grid>
        </Grid>
    </Grid>
  )
}
