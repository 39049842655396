import React from "react";
import Table from "../../Common/Table";
import { TableCellText } from "../../Common/TableCellText";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";

function BtnCellRenderer(params) {
    return <OperationButton to={`/settings/edit/${params.data.id}`} type="edit" />;
}

function ValueCellRenderer(params) {
    if(params.data.value == null){
        return "-";
    }

    return <TableCellText val={params.data.value + (params.data.unit ? " " + params.data.unit : "")} />;
}

export default function SettingsTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("settings.label"),
                field: "label",
                valueGetter: (params) => {
                    if (params.data.label[props.userLang]) return params.data.label[props.userLang];

                    return "-";
                },
                flex: 2,
                cellRenderer: "defaultCellRenderWithTooltip",
                sort: "asc",
            },
            {
                headerName: i18next.t("settings.value"),
                field: "value",
                cellRenderer: "valueCellRenderer",
                sortable: false,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                cellStyle: {
                    "text-align": "center",
                },
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            valueCellRenderer: ValueCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            context={props.userLang}
            onGridReady={props.onGridReady}
            gridApi={props.gridApi}
        />
    );
}
