import API from "../../utils/API";
import moment from "moment";
import { DATE_PICKER_FORMAT_DATE_TIME_JS } from "../../utils/AppConst";

function fetchChambers() {
    return API.get(`/e-learning/course-generation/chambers`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function fetchCourses() {
    return API.get(`/e-learning/course-generation/courses`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function getChamberSubCategories(chamberIds) {
    return API.get(`/e-learning/course-generation/chambers/sub-categories?chambers=${chamberIds.join(",")}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function getStatus(id) {
    return API.get(`/e-learning/course-generation/${id}/status`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function generate(form) {
    if (form.end instanceof moment) {
        form.end = form.end.format(DATE_PICKER_FORMAT_DATE_TIME_JS);
    }
    if (form.start instanceof moment) {
        form.start = form.start.format(DATE_PICKER_FORMAT_DATE_TIME_JS);
    }
    return API.post(`/e-learning/course-generation`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const elearningCourseGenerationService = {
    fetchChambers,
    fetchCourses,
    generate,
    getChamberSubCategories,
    getStatus,
};

export default elearningCourseGenerationService;
