import React from "react";
import "../../../styles/tree-view.scss";
import TreeItem from "./TreeItem";

export default class TreeView extends React.Component {
    _renderTreeItems() {
        const items = this.props.items ? this.props.items : {};
        return Object.keys(items).map((key) => {
            const item = items[key];
            return <TreeItem parent={item.children} item={item} onItemSelected={this.props.onItemSelected} key={key} />;
        });
    }

    render() {
        return <ul className="list-unstyled tree-view">{this._renderTreeItems()}</ul>;
    }
}
