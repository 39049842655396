import moment from "moment";
import React from "react";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import { COURSE_TYPES, ROLES, DATE_PICKER_FORMAT_DATE_TIME } from "../../../utils/AppConst";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import MassOperationDialog from "./MassOperationDialog";
import i18next from "i18next";
import { TableCellText } from "../../Common/TableCellText";
import { TableBadge } from "../../Common/TableBadge";
import { Box, makeStyles } from "@material-ui/core";
import { hasRole } from "../../../utils/AuthHelper";
import CustomDeleteButton from "./CustomDeleteButton";
import ArchiveButton from "./ArchiveButton";

const btnRendererStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: theme.spacing(1),
    },
}));

function checkIfCourseIsDeletable(params) {
    const notActive = new Date(params.data.start) > new Date() || new Date(params.data.end) < new Date();

    return (
        hasRole(ROLES.GLOBAL_ADMINISTRATOR) &&
        params.data.type !== COURSE_TYPES.KAMGYAK &&
        (notActive || params.data.isSampleCategory) &&
        !params.data.hasChildren
    );
}

function BtnCellRenderer(params) {
    const classes = btnRendererStyle();

    return (
        <>
            <OperationButton to={`/e-learning/course-management/edit/${params.data.id}`} type="edit" />
            <Box component="span" marginX={0.2} />
            {checkIfCourseIsDeletable(params) &&
            <>
                <CustomDeleteButton
                    title={i18next.t("course_management.delete.title")}
                    confirmation={
                        params.data.hasExamResults
                            ? i18next.t("course_management.delete.warning")
                            : i18next.t("course_management.delete.confirm")
                    }
                    type="delete"
                    params={params}
                    className={classes.deleteButton}
                />
                <Box component="span" marginX={0.2} />
            </>
            }
            <ArchiveButton params={params} />
        </>
    );
}
//rendezéshez kell
function comparatorString(valueA, valueB) {
    return valueA.localeCompare(valueB);
}

function categoryCellRenderer(params) {
    if (params.data.isSampleCategory) {
        return <TableBadge text={params.value} />;
    }

    if (params.context && params.context.lang && params.value[params.context.lang])
        return <TableCellText val={params.context.lang && params.value[params.context.lang]} />;
    return <TableCellText val={params.value} />;
}

export default function CourseManagementTable(props) {
    const state = {
        columnDefs: [
            {
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                headerName: i18next.t("course_management.category"),
                field: "category",
                sort: "asc",
                comparator: comparatorString,
                valueGetter: (params) => {
                    if (!params.data.category) {
                        return "";
                    }
                    let print = params.data.parentCategory ? params.data.parentCategory + " / " : "";
                    print += params.data.category;
                    return print;
                },
                cellRenderer: "categoryCellRenderer",
                width: 400,
            },
            {
                headerName: i18next.t("course_management.name"),
                field: "fullName",
                comparator: comparatorString,
                width: 350,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("course_management.type"),
                field: "type",
                cellRenderer: "defaultCellRenderWithTooltip",
                valueGetter: (params) => {
                    switch (params.data.type) {
                        case "kamgyak":
                            return i18next.t("course_management.course_type.kamgyak");
                        default:
                            return i18next.t("course_management.course_type.empty");
                    }
                },
                width: 150,
            },
            {
                headerName: i18next.t("course_management.start"),
                field: "startDate",
                cellRenderer: "defaultCellRenderWithTooltip",
                valueGetter: (params) =>
                    params.data.start ? moment(params.data.start).format(DATE_PICKER_FORMAT_DATE_TIME) : "-",
                width: 140,
            },
            {
                headerName: i18next.t("course_management.end"),
                field: "endDate",
                cellRenderer: "defaultCellRenderWithTooltip",
                valueGetter: (params) =>
                    params.data.end ? moment(params.data.end).format(DATE_PICKER_FORMAT_DATE_TIME) : "-",
                width: 140,
            },
            {
                headerName: i18next.t("course_management.status"),
                field: "status",
                cellRenderer: "defaultCellRenderWithTooltip",
                width: 140,
            },
            {
                headerName: i18next.t("table.operations"),
                width: 300,
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
            categoryCellRenderer: categoryCellRenderer,
        },
    };
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <Table
            rowData={props.data}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={state.columnDefs}
            frameworkComponents={state.frameworkComponents}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            context={{
                lang: props.userLang,
                delete: props.delete,
                AddNotification: props.addNotification,
                filter: props.filter,
                searchData: props.searchData,
            }}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
            customAction={selectedRows.length > 0 ? <MassOperationDialog selectedRows={selectedRows} /> : null}
        />
    );
}

// A fix oszlopszélesség miatt szükséges
function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
}
