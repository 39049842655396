import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderMinus, faFolderPlus, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import "../../../styles/tree-view.scss";
import colors from "../../../styles/colors.scss";
import TreeView from "./TreeView";

export default function TreeItem(props) {
    const [active, setActive] = React.useState(false);

    const markActiveItem = (event) => {
        document.querySelectorAll(".tree-view li.active").forEach((el) => el.classList.remove("active"));
        event.currentTarget.classList.add("active");
    };

    const item = props.item;

    const handleItemClick = (event, item) => {
        event.stopPropagation();

        if (item.type === "item") {
            if(props.onItemSelected(item) !== false){
                markActiveItem(event);
            }
            return;
        }

        event.stopPropagation();
        event.currentTarget.classList.toggle("open");
        setActive(!active);
    };

    return props.parent ? (
        <li onClick={(e) => handleItemClick(e, item)} key={`parent-${item.id}`}>
            <FontAwesomeIcon
                icon={active ? faFolderMinus : faFolderPlus}
                className="icon"
                style={{ color: colors.primary, fontSize: "larger" }}
            />
            <span>{item.name}</span>
            <TreeView onItemSelected={(item) => props.onItemSelected(item)} items={item.children} />
        </li>
    ) : (
        <li onClick={(e) => handleItemClick(e, item)} key={`children-${item.id}`}>
            <FontAwesomeIcon
                icon={faGraduationCap}
                className="icon"
                style={{ color: colors.primary, fontSize: "larger" }}
            />
            <span>{item.name}</span>
        </li>
    );
}
