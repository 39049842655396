import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(
        form,
        {
            title: "title",
            publishedAtFrom: "published_at",
            publishedAtUntil: "published_at",
        },
        {
            published_at: "date",
        }
    );
    let sendSearch = searchForm.getSend();

    return API.get("/permissions", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function create(form) {
    let data = { permissions: [], chamber: form.chamber?.id, role: form.role, email: form.email};

    ["administration", "content", "e-learning", "master", "other", "chamber-elearning", "chamber-master"].forEach(
        (category) => {
            if (form[category]) {
                form[category].forEach((permission) => {
                    data.permissions.push(permission.value);
                });
            }
        }
    );

    return API.post(`/users/${form.user.id}/permissions`, data)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(p) {
    return API.get(`/users?email=${p}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function del(id) {
    return API.delete(`/permissions/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function getUserPermissionsByCourse(userId, courseId) {
    return API.get(`/e-learning/course-management/${courseId}/permissions/${userId}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function searchGlobal(filter) {
    let helper = Object.assign({}, filter);

    if (!helper.sort) {
        helper['sort'] = {};
        helper['sort']['last_name'] = 'asc'
    }
    const searchForm = new SearchForm(helper);
    let sendSearch = searchForm.getSend();

    return API.get(`/permissions/users/global`, { params: sendSearch })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function searchChamber(filter) {
    let helper = Object.assign({}, filter);

    if (!helper.sort) {
        helper['sort'] = {};
        helper['sort']['last_name'] = 'asc'
    }
    const searchForm = new SearchForm(helper);
    let sendSearch = searchForm.getSend();

    return API.get(`/permissions/users/chamber`,{ params: sendSearch })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const permissionsService = {
    filter,
    create,
    // update,
    del,
    get,
    getUserPermissionsByCourse,
    searchGlobal,
    searchChamber,
};

export default permissionsService;
