import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import Permissions from "../../../lib/Permissions";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import { COURSE_TYPES, DATE_PICKER_FORMAT_DATE_TIME } from "../../../utils/AppConst";
import { hasPermission } from "../../../utils/AuthHelper";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import RescoreButton from "./RescoreButton";

const btnRendererStyle = makeStyles(() => ({
    deleteButton: {
        marginLeft: 8,
    },
}));

function BtnCellRenderer(params) {
    const classes = btnRendererStyle();
    const isExamManager = hasPermission(Permissions.CourseExamManagement) || params.data?.chamberPermissionsExamManagement || params.data?.coursePermissionsExamManagement;
    const isContentEditor = hasPermission(Permissions.ElearningContentEditor) || params.data?.coursePermissionsContentEditor;
    
    return (
        <>
            {isExamManager && <OperationButton to={`/e-learning/exam-management/edit/${params.data.id}`} type="edit" />}
            {isContentEditor && (<RescoreButton {...params} />)}
            {params.data.hasResult === 0 && params.data.courseType !== COURSE_TYPES.KAMGYAK && isExamManager && (
                <OperationButton
                    type="delete"
                    successmessage={i18next.t("course_management.delete.success")}
                    confirmation={i18next.t("course_management.delete.exam.confirm")}
                    className={classes.deleteButton}
                    params={params}
                />
            )}
        </>
    );
}
//rendezéshez kell
function comparatorString(valueA, valueB) {
    return valueA.localeCompare(valueB);
}
export default function ExamManagementTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("course_management.category"),
                field: "category",
                sort: "asc",
                comparator: comparatorString,
                cellRenderer: "defaultCellRenderWithTooltip",
                width: 400,
                valueGetter: (params) => {
                    let print = params.data.category;
                    if (params.data.parentCategory) {
                        print = params.data.parentCategory + "/" + print;
                    }
                    return print;
                },
            },
            {
                headerName: "Kurzus",
                field: "course",
                comparator: comparatorString,
                width: 350,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: "Vizsga neve",
                field: "exam",
                comparator: comparatorString,
                width: 350,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: "Vizsga kezdése",
                field: "examStart",
                cellRenderer: "defaultCellRenderWithTooltip",
                valueGetter: (params) =>
                    params.data.examStart ? moment(params.data.examStart).format(DATE_PICKER_FORMAT_DATE_TIME) : "-",
                width: 140,
            },
            {
                headerName: "Vizsga befejezése",
                field: "examEnd",
                cellRenderer: "defaultCellRenderWithTooltip",
                valueGetter: (params) =>
                    params.data.examEnd ? moment(params.data.examEnd).format(DATE_PICKER_FORMAT_DATE_TIME) : "-",
                width: 140,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    return (
        <Table
            rowData={props.data}
            columnDefs={state.columnDefs}
            frameworkComponents={state.frameworkComponents}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            context={{
                lang: props.userLang,
                delete: props.delete,
                AddNotification: props.addNotification,
                filter: props.filter,
                searchData: props.searchData,
            }}
        />
    );
}
