import i18next from "i18next";

class Validator {
    constructor(form, errors, rules, fieldOptions) {
        this.form = form;
        this.fieldErrors = errors ? errors : {};
        this.fieldOptions = fieldOptions ? fieldOptions : {};
        if (rules) {
            this.setRules(rules);
        }
    }

    setReponseError(error) {
        if (error.response) {
            if (error.response.status == 422) {
                // Validációs hibák kezelése
                if (error.response.data.errors) {
                    this.fieldErrors = error.response.data.errors;
                }
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
    }
    clearError() {
        this.fieldErrors = {};
    }

    getErrors() {
        return this.fieldErrors;
    }

    /**
     * Első nyelv elem, amivel hiba volt.
     * 'hu' vagy 'en'-t fog visszaadni. Ha nincs ilyen, akkor null-t
     */
    getErrorFirstLang() {
        let ret = null;

        for (const [key, errors] of Object.entries(this.fieldErrors)) {
            if (key !== null && typeof key === typeof "" && key.indexOf(".") > 0) {
                ret = key.toLowerCase().substr(key.toLowerCase().indexOf(".") + 1);
                break;
            }
        }

        return ret;
    }

    getErrorText(fieldName, lang) {
        const errorKey = this.getErrorKey(fieldName, lang);

        let ret = "";

        if (this.fieldErrors[errorKey]) {
            ret = this.fieldErrors[errorKey];
            if(typeof ret === typeof []){
                ret = ret.join("\n");
            }
        }

        return ret;
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    hasError(fieldName, lang) {
        const errorKey = this.getErrorKey(fieldName, lang);

        if (
            this.fieldErrors[errorKey] ||
            (lang &&
                this.fieldErrors[fieldName] &&
                this.fieldErrors[fieldName][0] &&
                this.fieldErrors[fieldName][0][lang])
        ) {
            return true;
        }
        return false;
    }

    setRules(data) {
        this.rules = {};

        for (const [field, rules] of Object.entries(data)) {
            this.rules[field] = rules.split("|"); // Tömböt készítünk a pipeline-ok mentén
        }
    }

    /**
     * Visszaadja, hogy a mezőt kötelező-e kitölteni
     * @param field
     */
    isRequired(field) {
        const minRegEx = /min:[0-9]*/g;
        return (
            this.rules &&
            this.rules[field] &&
            Array.isArray(this.rules[field]) &&
            (this.rules[field].includes("required") ||
                (this.rules[field].includes("array") && this.rules[field].some((e) => minRegEx.test(e))))
        );
    }

    setErrorsClient(field, lang) {
        var errors = [];
        const errorKey = this.getErrorKey(field, lang);

        if (this.rules && this.rules[field] && Array.isArray(this.rules[field])) {
            var err = null;
            var fieldVal = this.getFieldVal(field, lang);

            for (const [indx, rule] of Object.entries(this.rules[field])) {
                err = this.checkClientValidation(rule, fieldVal);
                if (err != null) {
                    errors.push(err);
                }
            }
        }

        if (errors.length > 0) {
            this.fieldErrors[errorKey] = errors.join("\n");
        } else {
            if (this.fieldErrors[errorKey]) {
                //Amennyiben nincs hiba, úgy töröljük
                delete this.fieldErrors[errorKey];
            }
            if (this.fieldErrors[field]) {
                delete this.fieldErrors[field];
            }
        }
    }

    getFieldVal(field, lang) {
        if (this.fieldIsDate(field)) {
            return this.form[field] ? this.form[field] : "";
        }

        return lang
            ? this.form[field] && this.form[field][lang]
                ? this.form[field][lang]
                : ""
            : this.form[field]
            ? this.form[field]
            : "";
    }

    fieldIsDate(field) {
        return this.fieldOptions && this.fieldOptions[field] && this.fieldOptions[field].type == "date";
    }

    getErrorKey(field, lang) {
        if (this.fieldIsDate(field)) {
            return field;
        }

        return field + (lang ? "." + lang : "");
    }

    checkClientValidation(rule, fieldVal) {
        var err = null;
        var ruleArr = rule.split(":");

        switch (ruleArr[0].toLowerCase()) {
            case "required":
                if (fieldVal.length == 0) {
                    err = i18next.t("validation.required");
                }
                break;
            case "max":
                if (fieldVal.length > parseInt(ruleArr[1])) {
                    err = i18next.t("validation.max.string", { max: ruleArr[1] });
                }
                break;
            case "email":
                if (!this.validateEmail(fieldVal)) {
                    err = i18next.t("validation.email");
                }
                break;
            default:
                console.log("Validator.js Ismeretlen RULE", ruleArr[0].toLowerCase());
        }

        return err;
    }

    validateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true;
        }
        return false;
    }
}

export default Validator;
