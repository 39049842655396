import React from "react";
import { AgGridReact } from "ag-grid-react";
import i18next from "i18next";

/*
ez azért kell mert így kevesebb dolog lesz a többi fájlban
 */

export default function AgGridCommon(props) {
    const AG_GRID_LOCALE = {
        loadingOoo: i18next.t("table.loading"), //KELL
        noRowsToShow: i18next.t("table.no_rows"), //KELL
        page: i18next.t("table.page"), //KELL
        nextPage: i18next.t("mainpage.next"), //KELL
        lastPage: i18next.t("table.last"), //KELL
        firstPage: i18next.t("table.first"), //KELL
        previousPage: i18next.t("mainpage.previous"), //KELL
        to: "/", //KELL
        of: i18next.t("table.of"), //KELL
    };
    return <AgGridReact {...props} localeText={AG_GRID_LOCALE} />;
}
