import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip } from "@material-ui/core";
import { useState } from "react";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import TxtField from "../../../Common/TxtField";
import AgGridCommon from "../../../Common/AgGridCommon";
import { defaultColDef } from "./utils";

// extract text from html
const getText = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

const tableColumns = [
    {
        headerName: "Kérdés neve",
        field: "name",
        wrapText: true,
        autoHeight: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: "Kérdés szövege",
        field: "text",
        wrapText: true,
        autoHeight: true,
        cellRenderer: "textCellRenderer",
    },
];

const TextCellRenderer = ({ value }) => (
    <Tooltip title={getText(value)}>
        <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{getText(value)}</div>
    </Tooltip>
);

export default function QuestionSelector({ questions, columns, setFiltered, allColumns }) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const filteredQuestions = questions.filter(
        (q) => q.name.toLowerCase().includes(name.toLowerCase()) && q.text.toLowerCase().includes(text.toLowerCase())
    );

    const handleSelect = () => {
        setFiltered(selectedQuestions);
        setOpen(false);
    };

    return (
        <>
            <Grid item>
                <SubmitButton text="Kérdések kiválasztása" onClick={() => setOpen(true)} />
            </Grid>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
                <DialogTitle style={{ backgroundColor: "#008554", color: "#fff" }}>Kérdések kiválasztása</DialogTitle>
                <DialogContent>
                    <DialogContentText>Kérdések</DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TxtField
                                label="Kérdés neve"
                                value={name}
                                variant="outlined"
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TxtField
                                label="Kérdés szövege"
                                variant="outlined"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="ag-theme-material agGridTable" style={{ width: "100%", height: "400px" }}>
                                <AgGridCommon
                                    domLayout="normal"
                                    defaultColDef={defaultColDef}
                                    checkboxSelection
                                    getRowNodeId={(data) => data.slot}
                                    columnDefs={tableColumns}
                                    rowData={filteredQuestions}
                                    rowSelection="multiple"
                                    suppressRowClickSelection
                                    onSelectionChanged={(params) => {
                                        const selected = params.api.getSelectedRows().map((row) => row.slot);
                                        setSelectedQuestions(selected);
                                    }}
                                    // default selection
                                    onGridReady={(params) => {
                                        params.api.forEachNode((node) => {
                                            if (columns.some((column) => column.field === node.data.slot)) {
                                                node.setSelected(true);
                                                setSelectedQuestions((prev) => [...prev, node.data.slot]);
                                            }
                                        });
                                    }}
                                    frameworkComponents={{ textCellRenderer: TextCellRenderer }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpen(false)} text="Mégsem" />
                    <SubmitButton text="Kiválasztás" onClick={handleSelect} />
                </DialogActions>
            </Dialog>
        </>
    );
}
