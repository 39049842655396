import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import TxtField from "../../Common/TxtField";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import LinkToButton from "../../Common/LinkToButton";
import notificationsActions from "../../../store/notifications/actions";
import validator from "../../../store/validator/actions";
import Loading from "../../Common/Loading";
import Validator from "../../../utils/Validator";
import elearningCourseCategories from "../../../store/elearningCourseCategories/actions";
import i18next from "i18next";

function CourseCategoriesForm(props) {
    const { form, user, loading, parent } = props;
    const courseCategoryId = props.categoryId ? props.categoryId : null;

    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const handleChange = (event) => {
        var val = event.target.value;
        props.changeForm("form", { ...form, [event.target.name]: val });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    useEffect(() => {
        props.setValidatorErrors({});
        props.getParentInfo(props.parentId).then(() => {
            if (courseCategoryId) {
                props.get(props.parentId, courseCategoryId);
            } else {
                props.createForm();
            }
        });
        props.getValidationRules("e-learning/course-categories", courseCategoryId);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        if (courseCategoryId) {
            props
                .update(props.parentId, form, courseCategoryId)
                .then((data) => {
                    props.addNotification(
                        "success",
                        i18next.t("course_categories.update.success"),
                        "/e-learning/course-categories"
                    );
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("course_categories.update.success"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                });
        } else {
            props
                .create(props.parentId, form)
                .then((data) => {
                    props.addNotification(
                        "success",
                        i18next.t("course_categories.save.success"),
                        "/e-learning/course-categories"
                    );
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("course_categories.save.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                });
        }
    };

    return loading ? (
        <Loading />
    ) : (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Grid item container spacing={1}>
                <Grid item sm={12} md={4}>
                    <TxtField
                        label={i18next.t("course_categories.parent")}
                        variant="outlined"
                        id="parent"
                        name="parent"
                        value={parent && parent.name ? parent.name : ""}
                        fullWidth
                        disabled
                        margin="normal"
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <TxtField
                        label={i18next.t("course_categories.name")}
                        variant="outlined"
                        id="name"
                        name="name"
                        value={form.name || ""}
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        fullWidth
                        margin="normal"
                        error={validator.hasError("name")}
                        helperText={validator.getErrorText("name")}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <TxtField
                        label={i18next.t("course_categories.short_name")}
                        variant="outlined"
                        id="short_name"
                        name="short_name"
                        value={form.short_name || ""}
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        fullWidth
                        margin="normal"
                        error={validator.hasError("short_name")}
                        helperText={validator.getErrorText("short_name")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TxtField
                        multiline={true}
                        rows={4}
                        label={i18next.t("course_categories.description")}
                        variant="outlined"
                        multiline
                        id="description"
                        name="description"
                        value={form.description || ""}
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        fullWidth
                        margin="normal"
                        error={validator.hasError("description")}
                        helperText={validator.getErrorText("description")}
                    />
                </Grid>
                <Grid item>
                    <SubmitButton text={i18next.t("save")} type="submit" />
                </Grid>
                <Grid item>
                    <LinkToButton to="/e-learning/course-categories">
                        <CancelButton text={i18next.t("cancel")} />
                    </LinkToButton>
                </Grid>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { form, loading, parent } = state.elearningCourseCategories;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, user, loading, validator, parent };
}

const actionCreators = {
    changeForm: elearningCourseCategories.changeForm,
    getParentInfo: elearningCourseCategories.getParentInfo,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
    create: elearningCourseCategories.create,
    update: elearningCourseCategories.update,
    createForm: elearningCourseCategories.createForm,
    get: elearningCourseCategories.get,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(CourseCategoriesForm);
