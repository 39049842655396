import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: "#b4645d",
        "&:hover": {
            backgroundColor: "#851400",
        },
    },
}))(Button);

class DeleteIcon extends React.Component {
    render() {
        return (
            <ColorButton variant="contained" className={"btn-red"}>
                <FontAwesomeIcon size="1x" icon={faTrashAlt} />
            </ColorButton>
        );
    }
}

export default DeleteIcon;
