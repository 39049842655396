export const setInfo = (props, infokey, infoval, infokey2 = null, infoval2 = null) => {
    if (props.loading || props.tableLoading) {
        return;
    }

    props.setInfo(infokey, infoval);
    const newInfo = JSON.parse(JSON.stringify(props.info));
    newInfo[infokey] = infoval;

    if (infokey2 && infoval2) {
        newInfo[infokey2] = infoval2;
        for (const [key, value] of Object.entries(newInfo[infokey2])) {
            if (Array.isArray(value)) {
                newInfo[infokey2][key] = value.map((val) =>
                    val.value === undefined || val.value === null ? val : val.value
                );
            }
        }
    }

    props.search(newInfo);
};
