import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import Excel from "exceljs";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../hooks";
import AccordionSum from "../../../Common/AccordionSum";
import AgGridCommon from "../../../Common/AgGridCommon";
import CancelButton from "../../../Common/CancelButton";
import Filter from "../../../Common/Filter";
import GreenOutlineSelect from "../../../Common/GreenOutlineSelect";
import Header from "../../../Common/Header";
import ItemNumberSelect from "../../../Common/ItemNumberSelect";
import Loading from "../../../Common/Loading";
import { tableStyles as tableStylesFunction } from "../../../Common/Table";
import TxtField from "../../../Common/TxtField";
import HeaderNameCellRenderer from "./HeaderCellRenderer";
import QuestionSelector from "./QuestionSelector";
import RescoringDialog from "./RescoringDialog";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const defaultColumns = [
    {
        headerName: "Tanuló",
        field: "student",
        sort: "asc",
        comparator: (valueA, valueB) => valueA.localeCompare(valueB),
        minWidth: 300,
        pinned: "left",
    },
];

const defaultColDef = {
    suppressMovable: true,
    flex: 1,
    sortable: true,
    minWidth: 300,
    resizable: true,
};

const initFilter = {
    student: "",
};

const AnswerCellRenderer = ({ cellValues, setQuestion, setStudentToRescore, ...params }) => {
    const { point, answer } = params.data.attempts.find((attempt) => attempt.slot === params.colDef.field);
    const question = params.context.questions.find((question) => question.slot === params.colDef.field);
    const { maxPoint } = question;
    const showPoint = (point || "-") + (point ? " / " + maxPoint.toFixed(2) : "");
    const showAnswer = answer || "-";
    const show = cellValues === "results" ? showPoint : showAnswer;
    const [hover, setHover] = useState(false);

    return (
        <div
            style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                setQuestion(question);
                setStudentToRescore(params.data);
            }}
        >
            {show}{" "}
            <Edit
                color="primary"
                style={{ fontSize: 16, opacity: hover ? 1 : 0, transition: "0.4s opacity", color: "#6DAC81" }}
            />
        </div>
    );
};

const IdCellRenderer = (params) => {
    const { answer } = params.data.attempts[params.colDef.field - 1];

    return <div>{answer}</div>;
};

export default function Rescoring() {
    const { id } = useParams();
    const { data, loading, refreshData } = useFetch("/e-learning/exam-management/" + id + "/results");
    const [student, setStudent] = useState("");
    const [filter, setFilter] = useState(initFilter);
    const [pageSize, setPageSize] = useState(25);
    const tableStyles = tableStylesFunction();
    const [columns, setColumns] = useState(defaultColumns);
    const [idColumn, setIdColumn] = useState("none");
    const [cellValues, setCellValues] = useState("results");
    const [headerName, setHeaderName] = useState("slot");
    const [filtered, setFiltered] = useState([]);
    const [question, setQuestion] = useState(null);
    const [studentToRescore, setStudentToRescore] = useState(null);
    const [isExportLoading, setIsExportLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFilter({ student });
    };
    // export the table to an excel file
    const exportQuestions = () => {
        setIsExportLoading(true);

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Vizsga eredmények");
        // set headers
        const headerNames = [
            {
                header: "Tanuló neve",
                key: "studentName",
                width: 30,
            },
            {
                header: "Tanuló e-mail címe",
                key: "studentEmail",
                width: 30,
            },
            ...columns.slice(1).map((column) => {
                const maxPoint = data.questions.find((question) => question.slot === column.field).maxPoint;

                return {
                    header: column.headerName + (cellValues === "results" ? " / " + maxPoint.toFixed(2) : ""),
                    key: column.field,
                    width: 15,
                };
            }),
            {
                header: "Összpontszám / " + data.maxPoint.toFixed(2),
                key: "totalPoints",
                width: 15,
            },
        ];
        worksheet.columns = headerNames;

        // sort row by student names
        rows.sort((a, b) => {
            const nameA = a.student;
            const nameB = b.student;
            return nameA.localeCompare(nameB);
        });
        // set cell values depending on the value of cellValues
        rows.forEach((row) => {
            const rowData = [...row.student.split(" / ")];

            row.attempts.forEach((attempt) => {
                rowData.push(cellValues === "results" ? (+attempt.point).toFixed(2) : attempt.answer);
            });

            // if an identifier is selected that is the second column and the chosen question's column is missing
            if (idColumn !== "none") {
                rowData[2] = row.attempts[idColumn - 1].answer;
            }

            const totalPoints = row.attempts.reduce((acc, attempt) => acc + +(attempt.point ?? 0), 0).toFixed(2);
            rowData.push(totalPoints);

            // handle numbers as numbers in excel
            rowData.forEach((data, i) => {
                if (!isNaN(data)) {
                    rowData[i] = +data;
                } else {
                    // replace newline characters
                    rowData[i] = data.replaceAll(/(\r\n|\n|\r)/gm, "");
                }
            });

            worksheet.addRow(rowData);
        });

        // download the file
        const fileName = new Date().toLocaleString();
        workbook.xlsx
            .writeBuffer()
            .then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = "Vizsga eredmények " + fileName + ".xlsx";
                anchor.click();
                window.URL.revokeObjectURL(url);
                setIsExportLoading(false);
            })
            .catch((err) => {
                console.error("Error:", err);
                setIsExportLoading(false);
            });
    };

    useEffect(() => {
        if (!loading && data) {
            setFiltered(data.questions.map((question) => question.slot));
        }
    }, [loading, data]);

    const renderQuestionNumber = (i) => {
        if ((i + 1) >= new Number(idColumn)) {
            return (i + 2);
        }
        else {
            return (i + 1);
        }
    }

    const createQuestion = useCallback(
        (question, i) => ({
            headerName: headerName === "slot" ? "Kérdés " + renderQuestionNumber(i) : question[headerName],
            field: question.slot,
            cellRenderer: "answerCellRenderer",
            headerComponent: "headerNameCellRenderer",
            sortable: false,
        }),
        [headerName, idColumn]
    );

    useEffect(() => {
        if (loading || !data) return;
        if (idColumn === "none") {
            setColumns([...defaultColumns, ...data.questions.map(createQuestion)]);
        } else {
            setColumns([
                ...defaultColumns,
                {
                    headerName: `Azonosító (Kérdés ${idColumn})`,
                    field: idColumn,
                    cellRenderer: "idCellRenderer",
                    sortable: false,
                    pinned: "left",
                },
                ...data.questions.filter((question) => question.slot !== idColumn).map(createQuestion),
            ]);
        }
    }, [headerName, idColumn, loading, data]);

    if (loading) {
        return <Loading />;
    }

    const rows = data.results.filter((row) => row.student.toLowerCase().includes(filter.student?.toLowerCase()));

    const idColumnOptions = [
        { value: "none", label: "Nincs" },
        ...data.questions.map((question, i) => ({
            value: question.slot,
            label: "Kérdés " + (i + 1),
        })),
    ];

    const allColumns =
        idColumn === "none"
            ? [
                  ...defaultColumns,
                  ...data.questions.map((question, i) => ({
                      headerName: "Kérdés " + (i + 1),
                      field: question.slot,
                      cellRenderer: "answerCellRenderer",
                      sortable: false,
                  })),
              ]
            : [
                  ...defaultColumns,
                  {
                      headerName: "Azonosító",
                      field: idColumn,
                      cellRenderer: "idCellRenderer",
                      sortable: false,
                  },
                  ...data.questions
                      .filter((question) => question.slot !== idColumn)
                      .map((question, i) => ({
                          headerName: "Kérdés " + (i + 1),
                          field: question.slot,
                          cellRenderer: "answerCellRenderer",
                          sortable: false,
                      })),
              ];

    const filteredColumns = columns
        .filter((column) => column.cellRenderer !== "answerCellRenderer" || filtered.includes(column.field))
        .sort((a, b) => a.field - b.field);

    return (
        <Grid container spacing={2}>
            <RescoringDialog
                examId={id}
                question={question}
                setQuestion={setQuestion}
                students={rows}
                student={studentToRescore}
                idColumn={idColumn}
                setStudentToRescore={setStudentToRescore}
                refreshData={refreshData}
            />
            <Header
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    "/e-learning/exam-management": "Vizsgakezelés",
                    course: data.course,
                    exam: data.exam,
                }}
            />
            <Filter
                filterForm={
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TxtField
                                variant="outlined"
                                fullWidth
                                label="Tanuló"
                                value={student}
                                onChange={(e) => setStudent(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                }
                onSubmit={handleSubmit}
                defaultForm={() => {
                    setFilter(initFilter);
                    setStudent("");
                }}
            />
            <Grid item container className={tableStyles.root}>
                <Grid item xs={12}>
                    <Accordion className={tableStyles.accordionRoot} expanded>
                        <AccordionSum id="panel2a-header" text={i18next.t("table.table")} />
                        <AccordionActions classes={{ root: tableStyles.listActionsGrid }}>
                            <Grid container spacing={2} direction="column">
                                <Grid item container xs={12} md={8} lg={8} xl={8} style={{background: "rgb(232, 244, 253)", margin: "5px 0 5px 0", display: "flex", alignSelf: "center"}}>
                                        <Grid item xs={2} md={1} lg={1} xl={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <InfoIcon style={{color: "#2196f3"}}/>
                                        </Grid>
                                        <Grid item xs={10} md={11} lg={11} xl={11} style={{color: "rgb(13, 60, 97)", textAlign: "left", lineHeight: "1.5", margin: "6px 0px 6px 0px"}}>
                                            {i18next.t("rescoring.info")}
                                        </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6} lg={4} xl={3} alignItems="center">
                                    <GreenOutlineSelect
                                        variant="outlined"
                                        fullWidth
                                        selectLabel="Azonosító"
                                        value={idColumn}
                                        optionList={idColumnOptions}
                                        onChange={(e) => setIdColumn(e.target.value)}
                                    />
                                </Grid>
                                <Grid item container xs={12} md={6} lg={4} xl={3}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Cellaértékek</FormLabel>
                                        <RadioGroup
                                            aria-label="cellValues"
                                            name="cellValues"
                                            value={cellValues}
                                            onChange={(e) => setCellValues(e.target.value)}
                                            row
                                        >
                                            <FormControlLabel
                                                value="results"
                                                control={<Radio color="primary" />}
                                                label="Eredmények mutatása"
                                            />
                                            <FormControlLabel
                                                value="answers"
                                                control={<Radio color="primary" />}
                                                label="Válaszok mutatása"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={12} md={6} lg={4} xl={3}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Oszlopfejlécek</FormLabel>
                                        <RadioGroup
                                            aria-label="headerName"
                                            name="headerName"
                                            value={headerName}
                                            onChange={(e) => setHeaderName(e.target.value)}
                                            row
                                        >
                                            <FormControlLabel
                                                value="slot"
                                                control={<Radio color="primary" />}
                                                label="Kérdés sorszáma"
                                            />
                                            <FormControlLabel
                                                value="name"
                                                control={<Radio color="primary" />}
                                                label="Kérdés neve"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} md={6}>
                                        <ItemNumberSelect onChange={(e) => setPageSize(e.target.value)} />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        xs={12}
                                        md={6}
                                        style={{ justifyContent: "flex-end" }}
                                    >
                                        <Grid item>
                                            <QuestionSelector
                                                questions={data.questions}
                                                columns={filteredColumns}
                                                allColumns={allColumns}
                                                setFiltered={setFiltered}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CancelButton
                                                text="Exportálás"
                                                disabled={isExportLoading}
                                                onClick={() => exportQuestions(rows, columns)}
                                            />
                                            {isExportLoading && (
                                                <div style={{ bottom: "-40%" }}>
                                                    <Loading />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionActions>
                        <AccordionDetails className={tableStyles.accordionDetails}>
                            <Grid item container xs={12}>
                                <div className="ag-theme-material agGridTable" style={{ width: "100%" }}>
                                    <AgGridCommon
                                        pagination
                                        key={pageSize + cellValues + filteredColumns.length}
                                        paginationPageSize={pageSize}
                                        domLayout="autoHeight"
                                        defaultColDef={defaultColDef}
                                        enableCellTextSelection
                                        getRowNodeId={(data) => data.id}
                                        columnDefs={filteredColumns}
                                        rowData={rows}
                                        context={{ questions: data.questions, cellValues }}
                                        frameworkComponents={{
                                            answerCellRenderer: (params) => (
                                                <AnswerCellRenderer
                                                    {...params}
                                                    cellValues={cellValues}
                                                    setStudentToRescore={setStudentToRescore}
                                                    setQuestion={setQuestion}
                                                />
                                            ),
                                            idCellRenderer: IdCellRenderer,
                                            headerNameCellRenderer: (params) => (
                                                <HeaderNameCellRenderer {...params} setQuestion={setQuestion} />
                                            ),
                                        }}
                                    />
                                </div>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
    );
}
