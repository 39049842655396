export const types = {
    CHANGE_FORM: "@@elearning-exam-generation/CHANGE_FORM",
    CREATE_FORM: "@@elearning-exam-generation/CREATE_FORM",

    FETCH_TEMPLATE_COURSES_REQUEST: "@@elearning-exam-generation/FETCH_TEMPLATE_COURSES_REQUEST",
    FETCH_TEMPLATE_COURSES_SUCCESS: "@@elearning-exam-generation/FETCH_TEMPLATE_COURSES_SUCCESS",
    FETCH_TEMPLATE_COURSES_FAILURE: "@@elearning-exam-generation/FETCH_TEMPLATE_COURSES_FAILURE",

    FETCH_EXAMS_REQUEST: "@@elearning-exam-generation/FETCH_EXAMS_REQUEST",
    FETCH_EXAMS_SUCCESS: "@@elearning-exam-generation/FETCH_EXAMS_SUCCESS",
    FETCH_EXAMS_FAILURE: "@@elearning-exam-generation/FETCH_EXAMS_FAILURE",

    FETCH_COURSES_REQUEST: "@@elearning-exam-generation/FETCH_COURSES_REQUEST",
    FETCH_COURSES_SUCCESS: "@@elearning-exam-generation/FETCH_COURSES_SUCCESS",
    FETCH_COURSES_FAILURE: "@@elearning-exam-generation/FETCH_COURSES_FAILURE",

    GENERATE_REQUEST: "@@elearning-exam-generation/GENERATE_REQUEST",
    GENERATE_SUCCESS: "@@elearning-exam-generation/GENERATE_SUCCESS",
    GENERATE_FAILURE: "@@elearning-exam-generation/GENERATE_FAILURE",
};
