import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../../styles/colors.scss";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    tabs: {
        width: "100%",
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    fields: {
        margin: theme.spacing(0, 0, 3),
    },
    root: {
        backgroundColor: colors.white,
    },
    icon: {
        color: colors.primary,
    },
    dBlock: {
        display: "block",
    },
}));

const mainLang = "hu";

const langCode = {
    0: "hu",
    1: "en",
};

export { useStyles, mainLang, langCode };
