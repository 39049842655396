import { types } from "./types";

export const initialState = {
    progress: false, // Folyamatban van-e az import
    errors: null,
    form: {
        import_action: 0,
        file: null,
        course_id: null,
        module_id: null,
        confirmationEmail: true,
        emailNotification: false,
    },
    uploadSuccessInfo: {},
    uploadStatus: {
        loaded: 0,
        total: 0,
    },
};

export function importData(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_FORM:
            return {
                ...state,
                search: initialState.search,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.CHANGE_PROGRESS:
            return {
                ...state,
                progress: action.progress,
            };

        case types.CHANGE_UPLOAD_STATUS:
            return {
                ...state,
                uploadStatus: action.data,
            };

        case types.START_IMPORT_REQUEST:
            return {
                ...state,
                progress: true,
                errors: initialState.errors,
                uploadSuccessInfo: initialState.uploadSuccessInfo,
            };
        case types.START_IMPORT_FAILURE:
            return {
                ...state,
                errors: action.error.response.data.errors,
                progress: false,
            };
        case types.START_IMPORT_SUCCESS:
            return {
                ...state,
                uploadSuccessInfo: action.data.data,
                //     progress: false
            };

        case types.CHECK_IMPORT_REQUEST:
            return {
                ...state,
            };
        case types.CHECK_IMPORT_FAILURE:
            return {
                ...state,
                errors: action.error.response.data.errors,
                progress: false,
            };
        case types.CHECK_IMPORT_SUCCESS:
            return {
                ...state,
                uploadSuccessInfo: action.data.data,
            };

        case types.DEFAULT_STATE:
            return initialState;

        default:
            return state;
    }
}
