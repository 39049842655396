import React from "react";
import { withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import colors from "../../../styles/colors.scss";

const StyledExpIcon = withStyles({
    root: {
        color: colors.darkPrimary,
        fontSize: "xx-large",
    },
})(ExpandMoreIcon);

export default function ExpIcon() {
    return <StyledExpIcon />;
}
