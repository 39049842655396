import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import { ListItemIcon } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Használata ua., mint a MenuItem-nek
const TxtMenuItem = withStyles({
    root: {
        "&:active": {
            backgroundColor: colors.darkPrimary,
        },
        "&:hover": {
            backgroundColor: colors.darkPrimary,
        },
        cursor: "pointer",
        backgroundColor: colors.white,
        color: colors.primary,
        borderRadius: 3,
        justifyContent: "space-between",
    },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
    item: {
        "&:hover, &:hover i": {
            backgroundColor: colors.lightPrimary,
        },
        "&.$selected": {
            color: colors.white,
        },
    },
    icon: {
        color: colors.primary,
    },
    selected: {
        backgroundColor: colors.primary,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.primary,
        },
        "& $icon": {
            color: colors.white,
        },
        "& $item": {
            color: colors.white,
        },
    },
}));

export default function TextMenuItem(props) {
    const classes = useStyles();
    return props.icon ? (
        <TxtMenuItem
            className={classes.item}
            style={{
                justifyContent: "flex-start",
            }}
            {...props}
        >
            <ListItemIcon>
                <FontAwesomeIcon icon={props.icon} className={classes.icon} />
            </ListItemIcon>
            {props.children}
        </TxtMenuItem>
    ) : (
        <TxtMenuItem {...props} />
    );
}
