import { types } from "./types";
import mainService from "../../services/main";

function getNews() {
    return (dispatch) => {
        dispatch({ type: types.GET_NEWS_REQUEST });
        return mainService.getNews().then(
            (data) => {
                dispatch({ type: types.GET_NEWS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.GET_NEWS_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

function getSysMessages() {
    return (dispatch) => {
        dispatch({ type: types.GET_SYSMESSAGES_REQUEST });
        return mainService.getSysMessages().then(
            (data) => {
                dispatch({ type: types.GET_SYSMESSAGES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.GET_SYSMESSAGES_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

function getMyCourses() {
    return (dispatch) => {
        dispatch({ type: types.GET_MY_COURSES_REQUEST });
        return mainService.getMyCourses().then(
            (data) => {
                dispatch({ type: types.GET_MY_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.GET_MY_COURSES_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

function getOpenCourse(courseId) {
    return (dispatch) => {
        dispatch({ type: types.GET_OPEN_COURSE_REQUEST });
        if(!courseId){
            dispatch({ type: types.GET_OPEN_COURSE_SUCCESS});
            return Promise.resolve({});
        }
        return mainService.getOpenCourse(courseId).then(
            (data) => {
                dispatch({ type: types.GET_OPEN_COURSE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.GET_OPEN_COURSE_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

function setLastSeen(seenType) {
    return (dispatch) => {
        dispatch({ type: types.SET_LASTSEEN_REQUEST, seenType });
        return mainService.setLastSeen(seenType).then(
            (data) => {
                dispatch({ type: types.SET_LASTSEEN_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.SET_LASTSEEN_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

const mainActions = {
    getNews,
    getSysMessages,
    setLastSeen,
    getMyCourses,
    getOpenCourse,
};

export default mainActions;
