import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TxtField from "../../Common/TxtField";
import Header from "../../Common/Header";
import ChambersTable from "./ChambersTable";
import chambersActions from "../../../store/chambers/actions.js";
import { connect } from "react-redux";
import Autocomplete from "../../Common/Autocomplete";
import Loading from "../../Common/Loading";
import Filter from "../../Common/Filter";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function Chambers(props) {
    const classes = useStyles();
    const { loading, data, search, user } = props;
    const activeOptions = [
        { value: "0", label: i18next.t("no"), key: 0 },
        { value: "1", label: i18next.t("yes"), key: 1 },
    ];
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    useEffect(() => {
        props.filter(search);
    }, []);

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("search", { ...search, [name]: values.map((v) => v) });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        props.filter(search);
    };

    const filterForm = (
        <>
            <Grid item xs={12} sm={6} lg={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="name"
                    label={i18next.t("chambers.name")}
                    name="name"
                    onChange={handleChange}
                    value={search.name || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="shortname"
                    label={i18next.t("chambers.short_name")}
                    name="short_name"
                    onChange={handleChange}
                    value={search.short_name || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="isziir_id"
                    label={i18next.t("chambers.isziir_id")}
                    name="isziir_id"
                    onChange={handleChange}
                    value={search.isziir_id || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                    multiple
                    defaultValue={search.active}
                    className={classes.autocomplete}
                    key={search.active}
                    options={activeOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="active"
                    onChange={(e, v) => handleMultiselectChange(e, v, "active")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("chambers.active")} />
                    )}
                />
            </Grid>
        </>
    );

    if (loading) return <Loading />;

    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    title={i18next.t("sidebar.chambers")}
                    skeleton={loading}
                    breadcrumbs={{
                        "master-datas": i18next.t("sidebar.master_data"),
                        chambers: i18next.t("sidebar.chambers"),
                    }}
                />
                <Filter filterForm={filterForm} defaultForm={props.defaultForm} onSubmit={handleSubmit} />
                <Grid item container xs={12}>
                    <ChambersTable
                        data={data}
                        user={user}
                        gridApi={gridApi}
                        onGridReady={onGridReady}
                        filter={props.filter}
                        delete={props.deleteRow}
                        searchData={search}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, search, data } = state.chambers;
    const { user } = state.authentication;
    return { loading, search, data, user };
}

const actionCreators = {
    filter: chambersActions.filter,
    changeForm: chambersActions.changeForm,
    defaultForm: chambersActions.defaultForm,
    deleteRow: chambersActions.deleteRow,
};

export default connect(mapState, actionCreators)(Chambers);
