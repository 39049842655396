export const types = {
    FETCH_REQUEST: "@@systemLog/FETCH_REQUEST",
    FETCH_SUCCESS: "@@systemLog/FETCH_SUCCESS",
    FETCH_FAILURE: "@@systemLog/FETCH_FAILURE",

    CHANGE_FORM: "@@systemLog/CHANGE_FORM",
    DEFAULT_FORM: "@@systemLog/DEFAULT_FORM",

    FETCH_COURSES_REQUEST: "@@systemLog/FETCH_COURSES_REQUEST",
    FETCH_COURSES_SUCCESS: "@@systemLog/FETCH_COURSES_SUCCESS",
    FETCH_COURSES_FAILURE: "@@systemLog/FETCH_COURSES_FAILURE",

    FETCH_SET_INFO: "@@systemLog/FETCH_SET_INFO",
};
