import React from "react";
import colors from "../../styles/colors.scss";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GreenTooltip from "./GreenTooltip";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: colors.primary,
    },
}));

export default function Copy(props) {
    const classes = useStyles();

    return (
        <GreenTooltip title={"Értékek másolása"}>
            <IconButton {...props}>
                <FileCopyIcon
                    className={classes.icon}
                    style={{ color: props.disabled ? colors.grey : colors.primary }}
                />
            </IconButton>
        </GreenTooltip>
    );
}
