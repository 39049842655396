import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../../../styles/colors.scss";
import TabPanel from "../../../../Common/TabPanel";
import Validator from "../../../../../utils/Validator";
import Loading from "../../../../Common/Loading";
import AntTabs from "../../../../Common/AntTabs";
import Copy from "../../../../Common/Copy";
import SubmitButton from "../../../../Common/SubmitButton";
import LinkToButton from "../../../../Common/LinkToButton";
import CancelButton from "../../../../Common/CancelButton";
import validator from "../../../../../store/validator/actions";
import notificationsActions from "../../../../../store/notifications/actions";
import CkeditorCustom from "../../../../Common/CkeditorCustom";
import coursesAction from "../../../../../store/courses/actions";
import DatePicker from "../../../../Common/DatePicker";
import i18next from "i18next";
import {convertDateToHyphenFormat} from '../../../../../utils/AppConst';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    tabs: {
        width: "100%",
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    fields: {
        margin: theme.spacing(0, 0, 3),
    },
    root: {
        backgroundColor: colors.white,
    },
    icon: {
        color: colors.primary,
    },
    dBlock: {
        display: "block",
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function Form(props) {
    const classes = useStyles();
    const { form, statementFormLoading } = props;
    const courseId = props.courseId ? props.courseId : null;
    const statementId = props.statementId ? props.statementId : null;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const [needCData, setNeedCData] = useState(false);

    useEffect(() => {
        props.setValidatorErrors({});
        props.getValidationRules("PrivacyStatement");
        if (statementId) {
            setNeedCData(true);
        } else {
            setNeedCData(false);
            props.createForm();
        }
        console.log("mount");
        return () => {
            console.log("unmount");
            props.createForm();
        };
    }, []);

    //tabváltáshoz kell
    const [actualTab, setActualTab] = useState(0); //tabváltás
    const mainLang = "hu";
    const langCode = {
        0: "hu",
        1: "en",
    };
    const headerTexts = [i18next.t("hu"), i18next.t("en")];
    const changeTab = (event, newValue) => {
        setActualTab(newValue);
    };
    const lang = langCode[actualTab];
    //eddig

    const setTabByErrors = (validator) => {
        const firstLang = validator.getErrorFirstLang();
        if (firstLang) {
            const firstLangIndex = validator.getKeyByValue(langCode, firstLang);
            if (firstLangIndex != -1 && firstLangIndex != actualTab) {
                setActualTab(parseInt(firstLangIndex));
            }
        }
    };

    const ckeditorChange = (event, editor, newVal) => {
        setNeedCData(true);
        const value = event ? editor.getData() : newVal;
        const name = "content";

        //FIXME: ez valamiért szétcseszi
        props.changeForm("statementForm", {
            ...form,
            [name]: {
                ...form[name],
                [lang]: value,
            },
        });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name, lang);
        props.setValidatorErrors(validator.getErrors());
    };

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm('statementForm', {
            ...form,
            [name]: newDate
        });
    };

    const copyAction = () => {
        setNeedCData(true);
        let newForm = {};
        Object.assign(newForm, {
            ...form,
            content: { ...form.content, [lang]: form.content[mainLang] },
        });
        Object.assign(form, newForm);
        props.changeForm("statementForm", newForm);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        if (statementId) {
            props
                .update(courseId, statementId, form)
                .then(() => {
                    props.addNotification(
                        "success",
                        i18next.t("form.success"),
                        `/e-learning/course-management/edit/${courseId}`
                    );
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("form.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        } else {
            props
                .create(courseId, form)
                .then(() => {
                    props.addNotification(
                        "success",
                        i18next.t("form.success"),
                        `/e-learning/course-management/edit/${courseId}`
                    );
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("form.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        }
    };
    if (statementFormLoading) return <Loading />;
    return (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Grid item xs={12} md={6}>
                <div className={classes.tabs}>
                    <AppBar position="static">
                        <AntTabs
                            value={actualTab}
                            onChange={changeTab}
                            aria-label="simple tabs example"
                            className={classes.tabsBg}
                            variant="fullWidth"
                        >
                            {headerTexts.map((value, index) => (
                                <Tab label={value} key={index} />
                            ))}
                        </AntTabs>
                    </AppBar>
                    {headerTexts.map((v, i) => (
                        <TabPanel value={actualTab} index={i} key={i}>
                            <Grid container spacing={3} className={classes.dBlock}>
                                <Grid item container justify="flex-end">
                                    {mainLang === langCode[i] ? "" : <Copy onClick={copyAction} />}
                                </Grid>
                                <CkeditorCustom
                                    label={i18next.t("course_management.content")}
                                    required={validator.isRequired("content")}
                                    getValue={() => {
                                        if (!needCData) return "";
                                        return form.content[langCode[i]] || "";
                                    }}
                                    lang={langCode[i]}
                                    ckeditorChange={ckeditorChange}
                                    error={validator.hasError("content", langCode[i])}
                                    helperText={validator.getErrorText("content", langCode[i])}
                                />
                                <DatePicker
                                    onChange={(date) => handleDateChange(date, "start_date")}
                                    onBlur={(event) => handleBlur(event)}
                                    className={classes.date}
                                    error={validator.hasError("start_date")}
                                    helperText={validator.getErrorText("start_date")}
                                    required={validator.isRequired("start_date")}
                                    value={form.start_date}
                                    id="start_date"
                                    label={i18next.t("pages.start")}
                                    name="start_date"
                                    style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                    readOnly={form.status ? form.end_date !== null || form.status !== "future" : false}
                                    inputProps={{
                                        disabled: form.status
                                            ? form.end_date !== null || form.status !== "future"
                                            : false,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    className={classes.submit}
                                    text={i18next.t("save")}
                                />
                                <LinkToButton to={`/e-learning/course-management/edit/${courseId}`}>
                                    <CancelButton
                                        variant="outlined"
                                        className={classes.submit}
                                        text={i18next.t("cancel")}
                                    />
                                </LinkToButton>
                            </Grid>
                        </TabPanel>
                    ))}
                </div>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { statementFormLoading } = state.courses;
    const form = state.courses.statementForm;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, statementFormLoading, user, validator };
}

const actionCreators = {
    changeForm: coursesAction.changeForm,
    create: coursesAction.statementCreateVersionAction,
    update: coursesAction.statementUpdateVersionAction,
    createForm: coursesAction.statementCreateForm,
    getVersion: coursesAction.statementGetVersionAction,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(Form);
