import { Accordion, AccordionActions, AccordionDetails, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import AccordionSum from "./AccordionSum";
import AgGridCommon from "./AgGridCommon";
import ItemNumberSelect from "./ItemNumberSelect";
import LinkToButton from "./LinkToButton";
import SubmitButton from "./SubmitButton";
import colors from "../../styles/colors.scss";
import i18next from "i18next";
import Loading from "./Loading";
import CustomHeader from "./TableCustomHeader.js";
import TableRefresh from "./TableRefresh.js";
import TablePaginator from "./TablePaginator";
import { setInfo } from "../../utils/FilterHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        color: colors.white,
        fontWeight: "bold",
        fontSize: "large",
    },
    listActionsGrid: {
        justifyContent: "unset",
        padding: theme.spacing(1),
    },
    accordionDetails: {
        padding: "unset",
    },
}));

export const tableStyles = useStyles;

/**
 * @param columnDefs - oszlop tulajdonságok
 * @param rowData - adatok
 * @param frameworkComponents - használt függvények (btncellrenderer, stb.)
 * @param context - pluszban feldolgozandó adat (user.name, user.language, stb.)
 * @param gridApi - gridApi (lásd: SettingsList)
 * @param newButtonText - "Új ... létrehozása" szöveg (opcionális)
 * @param to - ".../new" link (opcionális)
 * @param minWidth - oszlopok minimális szélessége (opcionális)
 */

export default function Table(props) {
    const classes = useStyles();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [paginationPageSize, setPaginationPageSize] = useState(25);
    //const theme = useTheme();
    const defaultColDef = {
        suppressMovable: true,
        flex: 1,
        sortable: typeof props.sortable !== typeof undefined ? props.sortable : true,
        minWidth: props.minWidth ? props.minWidth : 125,
    };

    const handleItemNumberChange = (event) => {
        const val = Number(event.target.value);
        if (!props.isPaginated) {
            setPaginationPageSize(val);
            if (gridApi) {
                gridApi.paginationSetPageSize(val);
            }
        } else {
            setInfo(
                {
                    setInfo: props.setInfo,
                    info: props.info,
                    search: props.searchfunc,
                },
                "perpage",
                val
            );
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const frameworkComponents = props.frameworkComponents || {};

    if (props.isPaginated) {
        frameworkComponents.agColumnHeader = CustomHeader;
        frameworkComponents.refreshTable = TableRefresh;
        frameworkComponents.searchfunc = props.searchfunc;
        frameworkComponents.setInfo = props.setInfo;
        frameworkComponents.info = props.info;
    }

    let loading = props.loading === undefined ? false : props.loading;

    return (
        <Grid item container className={classes.root}>
            <Grid item xs={12}>
                <Accordion className={classes.accordionRoot} expanded>
                    <AccordionSum id="panel2a-header" text={i18next.t("table.table")} />
                    {props.showPagination === true || props.showPagination === undefined ? (
                        <AccordionActions classes={{ root: classes.listActionsGrid }}>
                            <Grid item container xs={12} justify="space-between" alignItems="center">
                                <ItemNumberSelect
                                    onChange={handleItemNumberChange}
                                    preKey={props.preKey ? props.preKey : null}
                                />
                                {props.newButtonText ? (
                                    <Grid>
                                        <LinkToButton to={props.to}>
                                            <SubmitButton
                                                type="submit"
                                                variant="contained"
                                                text={props.newButtonText}
                                            />
                                        </LinkToButton>
                                    </Grid>
                                ) : (
                                    props.customAction || null
                                )}
                            </Grid>
                        </AccordionActions>
                    ) : null}
                    <AccordionDetails className={classes.accordionDetails}>
                        <Grid item container xs={12}>
                            <div className="ag-theme-material agGridTable" style={{ width: "100%" }}>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <AgGridCommon
                                        pagination={!props.isPaginated && !props.hidePagination}
                                        paginationPageSize={
                                            props.hidePagination === false || props.hidePagination === undefined
                                                ? 25
                                                : false
                                        }
                                        domLayout="autoHeight"
                                        defaultColDef={defaultColDef}
                                        frameworkComponents={frameworkComponents}
                                        enableCellTextSelection={true}
                                        getRowNodeId={(data) => data.id}
                                        {...props}
                                        onGridReady={(params) => {
                                            if (props.onGridReady) {
                                                props.onGridReady(params);
                                            }
                                            onGridReady(params);
                                        }}
                                    ></AgGridCommon>
                                )}
                                {props.isPaginated && (
                                    <TablePaginator
                                        searchfunc={props.searchfunc}
                                        info={props.info}
                                        setInfo={props.setInfo}
                                    />
                                )}
                            </div>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}
