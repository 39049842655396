import { Edit } from "@material-ui/icons";
import React, { useState } from "react";

const HeaderNameCellRenderer = ({ column, context, setQuestion }) => {
    const { questions } = context;
    const [hover, setHover] = useState(false);
    const question = questions.find((q) => q.slot === column.colDef.field);

    return (
        <>
            <div
                style={{ display: "flex", alignItems: "center", gap: "4px", cursor: "pointer" }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setQuestion(question)}
            >
                {column.colDef.headerName}
                <Edit
                    style={{ fontSize: 16, opacity: hover ? 1 : 0, transition: "0.4s opacity", color: "#6DAC81" }}
                    fontSize={"small"}
                />
            </div>
        </>
    );
};

export default HeaderNameCellRenderer;
