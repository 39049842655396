import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { store } from "./store";
import "./i18n";
import Loading from "./Components/Common/Loading";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: process.env.REACT_APP_SENTRY_ENV_NAME,
        ignoreErrors: [
            'Request failed with status code 401',
            'AxiosError: Network Error',
            'Network Error',
            'Request failed with status code 422',
            'Request aborted',
            'Request failed with status code 502',
        ],
    });
}

ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </Suspense>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
