import React from "react";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";

export default function HelmetTemplate(props) {
    return (
        <Helmet titleTemplate={`${i18next.t("mkik")} - %s`}>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
        </Helmet>
    );
}
