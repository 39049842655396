import React from "react";
import i18next from "i18next";
import Permissions from "../../../lib/Permissions";
import { hasPermission } from "../../../utils/AuthHelper";
import TxtField from "../../Common/TxtField";
import { Grid } from "@material-ui/core";
import Autocomplete from "../../Common/Autocomplete";

export function getColumnDefs() {
    return [
        hasPermission(Permissions.Users) || hasPermission(Permissions.ChamberUsers) ? 
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerName: i18next.t("course_enrolled.name"),
            field: "fullname",
            flex: 1,
            sortable: true,
            sort: "asc",
        }:
        {
            headerName: i18next.t("course_enrolled.name"),
            field: "fullname",
            flex: 1,
            sortable: true,
            sort: "asc",
        },
        {
            headerName: i18next.t("course_enrolled.chamber"),
            field: "chamber",
            flex: 1,
            sortable: true,
        },
        {
            headerName: i18next.t("course_enrolled.email"),
            field: "email",
            flex: 1,
            sortable: true,
        },
        {
            headerName: i18next.t("course_enrolled.enrolled"),
            field: "enrolled",
            flex: 1,
            sortable: true,
        },
        {
            headerName: i18next.t("course_enrolled.system_login"),
            field: "systemLogin",
            flex: 1,
            sortable: true,
        },
        {
            headerName: i18next.t("course_enrolled.last_login"),
            field: "lastLogin",
            flex: 1,
            sortable: true,
        },
        {
            headerName: i18next.t("course_enrolled.status"),
            field: "status",
            cellRenderer: "statusCellRenderer",
            flex: 1,
            sortable: true,
        },
    ];
}

export function filterForm(search, handleChange, handleMultiselectChange) {
    const boolOptions = [
        { value: "yes", label: i18next.t("yes"), key: 1 },
        { value: "no", label: i18next.t("no"), key: 0 },
    ];

    return <>
        <Grid item xs={12} sm={3}>
            <TxtField
                variant="outlined"
                value={search.name || ""}
                margin="normal"
                id="name"
                label={i18next.t("course_enrolled.name")}
                name="name"
                fullWidth
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TxtField
                variant="outlined"
                value={search.email || ""}
                margin="normal"
                id="email"
                label={i18next.t("course_enrolled.email")}
                name="email"
                fullWidth
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs={12} sm={3}>
                <Autocomplete
                    id="activated"
                    multiple
                    value={search.activated || []}
                    options={boolOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "activated")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_enrolled.activated")}
                            margin="normal"
                        />
                    )}
                />
        </Grid>
        <Grid item xs={12} sm={3}>
                <Autocomplete
                    id="courseLoggedIn"
                    multiple
                    value={search.courseLoggedIn || []}
                    options={boolOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "courseLoggedIn")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_enrolled.course_logged_in")}
                            margin="normal"
                        />
                    )}
                />
        </Grid>
        <Grid item xs={12} sm={3}>
                <Autocomplete
                    id="systemLoggedIn"
                    multiple
                    value={search.systemLoggedIn || []}
                    options={boolOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "systemLoggedIn")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_enrolled.system_logged_in")}
                            margin="normal"
                        />
                    )}
                />
        </Grid>
    </>
}