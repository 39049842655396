import React from "react";
import { Switch, withStyles } from "@material-ui/core";
import colors from "../../styles/colors.scss";

const StyledSwitch = withStyles({
    switchBase: {
        color: colors.primary,
        "&$checked": {
            color: colors.darkPrimary,
        },
        "&$checked + $track": {
            backgroundColor: colors.darkPrimary,
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function GreenSwitch(props) {
    return <StyledSwitch {...props} />;
}
