import types from "./types";

const loadingOn = () => dispatch => dispatch({type: types.LOADING_ON});
const loadingOff = () => dispatch => dispatch({type: types.LOADING_OFF});

const actions = {
    loadingOn,
    loadingOff
}

export default actions;