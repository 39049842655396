import { types } from "./types";
import emailLogService from "../../services/emailLog";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultForm() {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM });
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });

        return emailLogService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function get(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, id });

        return emailLogService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchTypes() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_TYPES_REQUEST });

        return emailLogService.fetchTypes().then(
            (data) => {
                dispatch({ type: types.FETCH_TYPES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_TYPES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    filter,
    get,
    changeForm,
    defaultForm,
    fetchTypes,
    setInfo,
};

export default actions;
