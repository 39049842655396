import React from "react";
import Header from "../../Common/Header";
import { getPageAction, getVersionsAction } from "../../../store/pages/actions";
import { connect } from "react-redux";
import { history } from "../../../store";
import Grid from "@material-ui/core/Grid";
import VersionPagesTable from "./Partials/VersionPagesTable";
import Loading from "../../Common/Loading";

class Version extends React.Component {
    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getPage(this.props.match.params.id);
            this.props.getVersions(this.props.match.params.id);
        } else {
            history.push("/404");
        }
    }

    render() {
        if (this.props.loading || this.props.selectedPageLoading) return <Loading />;
        return (
            <div>
                <Header
                    breadcrumbs={{
                        contents: "Tartalmak",
                        "/pages": "Oldalak",
                        "pages-name":
                            this.props.selectedPage.title && this.props.selectedPage.title[this.props.userLang]
                                ? this.props.selectedPage.title[this.props.userLang]
                                : "",
                    }}
                />

                <Grid item xs={12}>
                    <VersionPagesTable pageId={this.props.match.params.id} type={this.props.selectedPage.type} />
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.pages.loading,
        selectedPageLoading: state.pages.selectedPageLoading,
        selectedPage: state.pages.selectedPage,
        userLang: state.authentication.user.language,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getPage: (id) => dispatch(getPageAction(id)),
        getVersions: (id) => dispatch(getVersionsAction(id)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Version);
