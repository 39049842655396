export const types = {
    GET_NEWS_REQUEST: "@@main/GET_NEWS_REQUEST",
    GET_NEWS_SUCCESS: "@@main/GET_NEWS_SUCCESS",
    GET_NEWS_FAILURE: "@@main/GET_NEWS_FAILURE",

    GET_SYSMESSAGES_REQUEST: "@@main/GET_SYSMESSAGES_REQUEST",
    GET_SYSMESSAGES_SUCCESS: "@@main/GET_SYSMESSAGES_SUCCESS",
    GET_SYSMESSAGES_FAILURE: "@@main/GET_SYSMESSAGES_FAILURE",

    SET_LASTSEEN_REQUEST: "@@main/SET_LASTSEEN_REQUEST",
    SET_LASTSEEN_SUCCESS: "@@main/SET_LASTSEEN_SUCCESS",
    SET_LASTSEEN_FAILURE: "@@main/SET_LASTSEEN_FAILURE",

    GET_MY_COURSES_REQUEST: "@@main/GET_MY_COURSES_REQUEST",
    GET_MY_COURSES_SUCCESS: "@@main/GET_MY_COURSES_SUCCESS",
    GET_MY_COURSES_FAILURE: "@@main/GET_MY_COURSES_FAILURE",

    GET_OPEN_COURSE_REQUEST: "@@main/GET_OPEN_COURSE_REQUEST",
    GET_OPEN_COURSE_SUCCESS: "@@main/GET_OPEN_COURSE_SUCCESS",
    GET_OPEN_COURSE_FAILURE: "@@main/GET_OPEN_COURSE_FAILURE",
};
