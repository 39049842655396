import { types } from "./types";

const initialState = {
    loading: false,
    data: [],
    parent: null,
    form: {
        parent: null,
        name: null,
        short_name: null,
        description: null,
    },
    search: {
        name: "",
        shortName: "",
    },
};

export function elearningCourseCategories(state = initialState, action) {
    switch (action.type) {
        case types.FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FILTER_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };

        case types.GET_PARENT_INFO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_PARENT_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                parent: action.data.data,
            };
        case types.GET_PARENT_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                parent: null,
                error: action.error,
            };

        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };

        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: initialState.form,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };

        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
            };

        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };

        default:
            return state;
    }
}
