import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import SubmitButton from "../Common/SubmitButton";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import validator from "../../store/validator/actions";
import API from "../../utils/API";
import {history, store} from "../../store";
import loginActions from "../../store/login/actions";
import { updateUserPrivacyStatementAction } from "../../store/pages/actions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CancelButton from "../Common/CancelButton";
import LinkToButton from "../Common/LinkToButton";
import Parser from "html-react-parser";
import i18next from "i18next";
import {getIsziirUserLoggedIn, setIsziirUserLoggedIn} from "../../utils/AuthHelper";

function PrivacyStatement(props) {
    const [open, setOpen] = React.useState(true);
    const [checkbox, setCheckbox] = React.useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = React.useState(false); //FIXME: true ra kell álitani de akkor nem tudja elfogadni ha nincs scroll tartalom

    const onSubmit = (e) => {
        e.preventDefault();

        props
            .post()
            .then((data) => {
                setOpen(false);
                props.addNotification("success", i18next.t("privacy.success"));

                API.post(`/auth/me`).then((data) => {
                    store.dispatch(loginActions.setUser(data.data));
                });
            })
            .catch((error) => {
                validator.setReponseError(error);
                props.setValidatorErrors(validator.getErrors());
            });
    };

    const handleChange = (e) => {
        setCheckbox(e.target.checked);
    };

    //FIXME ha nincs scroll bar nem műxik
    const onScroll = (e) => {
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            setCheckboxDisabled(false);
        }
    };

    const textareaValue = props.privacyStatementActive.content[props.user.language] || "";

    return (
        <Dialog open={open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                {i18next.t("privacy")}
            </DialogTitle>
            <form onSubmit={onSubmit} noValidate>
                <DialogContent>
                    <DialogContentText>
                        {props.isFirstLogin ? i18next.t("privacy.first_login") : i18next.t("privacy.changed")}
                    </DialogContentText>
                    <div onScroll={onScroll} style={{ maxHeight: "350px", overflow: "auto" }}>
                        {Parser(textareaValue)}
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={checkboxDisabled}
                                checked={checkbox}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={
                            <>
                                {i18next.t("privacy.agree_pre")}
                                <a href={`${process.env.REACT_APP_API_URL}/pages/privacy-policy/active/download`}>
                                    {i18next.t("privacy.agree_link")}
                                </a>
                            </>
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("save")} type="submit" disabled={!checkbox} />
                    <LinkToButton onClick={(e) => {
                        if(getIsziirUserLoggedIn() === 'yes'){
                            setIsziirUserLoggedIn('yes', 'no');
                        }
                        history.push("/logout");
                    }}>
                        <CancelButton variant="outlined" text={i18next.t("layout.logout")} />
                    </LinkToButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState(state) {
    const { user } = state.authentication;
    const { privacyStatementActive } = state.pages;
    return { user, privacyStatementActive };
}

const actionCreators = {
    setValidatorErrors: validator.setErrors,
    addNotification: notificationsActions.addNotification,
    post: updateUserPrivacyStatementAction,
};

export default connect(mapState, actionCreators)(PrivacyStatement);
