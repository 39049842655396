import { Badge, Box, Card, CardContent, CardHeader, Chip, CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import GreenOutlineSelect from "./GreenOutlineSelect";
import SubmitButton from "./SubmitButton";
import TxtField from "./TxtField";
import LinkToButton from "./LinkToButton";
import importActions from "../../store/importData/actions";
import colors from "../../styles/colors.scss";
import { connect } from "react-redux";
import i18next from "i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ImportDialog from "./ImportDialog";
import KamgyakWarningMessage from "../Dashboard/Enrollment/KamgyakWarningMessage";

const POOLLING_TIMEOUT = 2; // Státuszvizsgálat ennyi másodperceknént
const STATUS_FAILED = "failed";
const STATUS_SUCCESS = "success";
const ENROLL_ONLY_TYPES = ["course-enrollment-only", "exam-enrollment-only"];
const DISMISS_ONLY_TYPES = ["course-dismissal-only", "exam-dismissal-only"];

function Import(props) {
    const options = [
        { label: i18next.t("import.error_message"), value: 0, key: 0 },
        { label: i18next.t("import.update_data"), value: 1, key: 1 },
        { label: i18next.t("import.cancel"), value: 2, key: 2 },
    ];

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const inputRef = React.useRef();

    const importType = props.type;
    const { form, progress, uploadStatus, errors, uploadSuccessInfo } = props;
    const realOptions = props.type.includes("enroll") ? options : options.filter((e) => e.key === 0);

    const handleChange = (event) => {
        const val = event.target.name == "file" ? event.target.files[0] : event.target.value;
        props.importDefaultState();
        props.changeForm("form", { ...form, [event.target.name]: val });


        if(event.target.name === 'module_id' && props.handleChangeExam){
            props.handleChangeExam(val);
        }
    };

    const checkStatus = (id) => {
        props.checkImport(id).then((response) => {
            if (response.data.status !== STATUS_FAILED && response.data.status !== STATUS_SUCCESS) {
                setTimeout(() => {
                    checkStatus(id);
                }, POOLLING_TIMEOUT * 1000);
            } else {
                clearFileInputAction();
                props.changeProgress(false);
                if (typeof props.importSuccess !== typeof undefined) {
                    props.importSuccess();
                }
            }
        });
    };

    const clearFileInputAction = () => {
        if (inputRef && inputRef.current && inputRef.current.children) {
            inputRef.current.children[1].children[0].value = "";
        }
    };

    const startImport = (event) => {
        props
            .startImport(importType, form)
            .then((data) => {
                console.log("Sikeres fájlfeltöltés", data);
                checkStatus(data.data.id);
            })
            .catch((error) => {
                console.log("Hibás fájlfeltöltés", error, errors);
            });
    };

    useEffect(() => {
        if (props.course) {
            props.changeForm("form", { ...form, ["course_id"]: props.course.id });
        }

        clearFileInputAction();
    }, [props.course]);

    const errPr =
        errors && Object.keys(errors).length > 0
            ? errors
            : uploadSuccessInfo
            ? uploadSuccessInfo.logs
                ? uploadSuccessInfo.logs
                : uploadSuccessInfo.info
            : "";

    return (
        <>
            <ImportDialog errors={errPr} open={openErrorModal} setOpen={setOpenErrorModal} />
            <Card>
                <CardHeader title={`${i18next.t("import")} ${props.course ? " / " + props.course.name : ""}`} />
                {<KamgyakWarningMessage course={props.course} type={props.type.includes("course") ? 'course' : 'exam'} />}
                {(props.course && props.course.isKamgyakType) ? <></> :
                    getCardContent(form, errors, props, progress, uploadSuccessInfo, handleChange, uploadStatus, inputRef, realOptions, startImport, setOpenErrorModal)
                }
            </Card>
        </>
    );
}

function getCardContent(form, errors, props, progress, uploadSuccessInfo, handleChange, uploadStatus, inputRef, realOptions, startImport, setOpenErrorModal){
    return <CardContent>
        {((props.type.includes("course") && props.course) ||
        (props.course && props.type.includes("exam") && props.examOptions.length > 0)) ? (
            <Grid container spacing={4}>
                {props.examOptions ? (
                    <Grid item xs={12}>
                        <GreenOutlineSelect
                            disabled={progress}
                            id="module_id"
                            selectLabel={i18next.t("import.exam")}
                            name="module_id"
                            value={form.module_id || ""}
                            fullWidth
                            shrink={true}
                            optionList={props.examOptions}
                            onChange={(event) => handleChange(event)}
                        />
                    </Grid>
                ) : (
                    ""
                )}
                {(!props.examOptions || form.module_id) && (
                    <>
                        <Grid item container spacing={1} xs={12} md={6}>
                            <Grid item xs={12}>
                                <TxtField
                                    disabled={progress}
                                    label={i18next.t("import.file")}
                                    type="file"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                    name="file"
                                    ref={inputRef}
                                    onChange={(event) => handleChange(event)}
                                    inputProps={{ accept: ".xlsx" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LinkToButton to={ENROLL_ONLY_TYPES.includes(props.type) ? "/excel/MintaCsakBeiratas.xlsx" : DISMISS_ONLY_TYPES.includes(props.type) ? "/excel/MintaCsakKiiratas.xlsx" : "/excel/Minta.xlsx"} target="_blank" download>
                                    <SubmitButton
                                        variant="contained"
                                        className="btn-gray"
                                        text={i18next.t("import.download")}
                                    />
                                </LinkToButton>
                            </Grid>
                        </Grid>
                        {ENROLL_ONLY_TYPES.includes(props.type) ? null :                             
                            <Grid item xs={12} md={6}>
                                <GreenOutlineSelect
                                    disabled={progress}
                                    id="import_action"
                                    selectLabel={i18next.t("import.wrong_data")}
                                    name="import_action"
                                    fullWidth
                                    shrink={true}
                                    optionList={realOptions}
                                    value={form.import_action}
                                    onChange={(event) => handleChange(event)}
                                />

                                {props.rightSide || null}
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <SubmitButton
                                disabled={progress || !form.file}
                                style={!form.file || progress ? { backgroundColor: colors.disabled } : {}}
                                text={i18next.t("import")}
                                onClick={startImport}
                            />
                        </Grid>
                    </>
                )}

                {((uploadSuccessInfo != null && uploadSuccessInfo.id) ||
                    (errors && Object.keys(errors).length > 0) ||
                    progress) && (
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{i18next.t("import.upload")}</TableCell>
                                    <TableCell>
                                        <CircularProgress
                                            variant="determinate"
                                            value={
                                                uploadStatus.loaded > 0
                                                    ? parseInt(
                                                    (uploadStatus.loaded / uploadStatus.total) * 100
                                                    )
                                                    : 0
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {uploadSuccessInfo && uploadSuccessInfo.id ? (
                                            <Chip label={i18next.t("import.success")} color="primary" />
                                        ) : errors && Object.keys(errors).length > 0 ? (
                                            <Chip
                                                label={i18next.t("import.error")}
                                                clickable
                                                color="secondary"
                                                onClick={() => {
                                                    setOpenErrorModal(true);
                                                }}
                                            />
                                        ) : (
                                            <Badge color="secondary">{i18next.t("import.progress")}</Badge>
                                        )}
                                    </TableCell>
                                </TableRow>

                                {uploadSuccessInfo && uploadSuccessInfo.id && (
                                    <TableRow>
                                        <TableCell>{i18next.t("import.processing")}</TableCell>
                                        <TableCell>
                                            <CircularProgress
                                                variant="determinate"
                                                value={
                                                    uploadSuccessInfo.completed_rows > 0
                                                        ? parseInt(
                                                        (uploadSuccessInfo.completed_rows /
                                                            uploadSuccessInfo.total_rows) *
                                                        100
                                                        )
                                                        : 0
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {uploadSuccessInfo.status == STATUS_FAILED ? (
                                                <Chip
                                                    label={i18next.t("import.error")}
                                                    clickable
                                                    color="secondary"
                                                    onClick={() => {
                                                        setOpenErrorModal(true);
                                                    }}
                                                />
                                            ) : uploadSuccessInfo.status == STATUS_SUCCESS ? (
                                                <Chip label={i18next.t("import.success")} color="primary" />
                                            ) : (
                                                <Badge color="secondary">
                                                    {i18next.t("import.progress")}
                                                </Badge>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                )}
            </Grid>
        ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Alert
                    severity={
                        props.course && props.type.includes("exam") && props.examOptions.length === 0
                            ? "error"
                            : "info"
                    }
                >
                    {props.course && props.type.includes("exam") && props.examOptions.length === 0
                        ? i18next.t("import.no_exam")
                        : i18next.t("import.no_course")}
                </Alert>
            </Box>
        )}
    </CardContent>
}


function mapState(state) {
    const { form, progress, uploadStatus, errors, uploadSuccessInfo } = state.importData;
    return { form, progress, uploadStatus, errors, uploadSuccessInfo };
}

const actionCreators = {
    changeForm: importActions.changeForm,
    startImport: importActions.startImport,
    checkImport: importActions.checkImport,
    changeProgress: importActions.changeProgress,
    importDefaultState: importActions.defaultState,
};

export default connect(mapState, actionCreators)(Import);
