import { types } from "./types";
import elearningCourseEnrollmentsService from "../../services/elearningCourseEnrollments";

function defaultForm(courseId) {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function changeForm(varibleName, form) {
    return (dispatch) => {
        const data = { varibleName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function resetFilter() {
    return (dispatch) => {
        dispatch({ type: types.RESET_FILTER });
    };
}

function filter(form, courseId) {
    return (dispatch) => {
        dispatch(request({ form, courseId }));

        return elearningCourseEnrollmentsService.filter(form, courseId).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.FETCH_REQUEST, data };
    }
    function success(data) {
        return { type: types.FETCH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FETCH_FAILURE, error };
    }
}

function get(courseId) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, courseId });

        return elearningCourseEnrollmentsService.get(courseId).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

export function selectCourse(course) {
    return (dispatch) => {
        dispatch({ type: types.SELECTED_COURSE, course });
    };
}

export function changeEmptyHideCb(val) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_EMPTY_HIDE_CB, val });
    };
}
export function download(courseId, search) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });

        return elearningCourseEnrollmentsService.download(courseId, search).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    filter,
    get,
    changeForm,
    defaultForm,
    selectCourse,
    changeEmptyHideCb,
    download,
    resetFilter,
};

export default actions;
