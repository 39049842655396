import React, { useEffect, useState } from "react";
import SystemMessagesFilters from "./Partials/Filters";
import SystemMessagesTable from "./Partials/Table";
import { withStyles } from "@material-ui/core/styles";
import styles from "./theme";
import Header from "../../Common/Header";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import systemMessagesActions from "../../../store/systemMessages/actions";
import i18next from "i18next";

function SystemMessages(props) {
    useEffect(() => {
        props.fetchSystemMessages(props.search);
    }, []);

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    const { loading } = props;
    if (loading) return <Loading />;
    return (
        <Grid item container spacing={2}>
            <Header
                title={i18next.t("sidebar.system_messages")}
                breadcrumbs={{
                    contents: i18next.t("sidebar.contents"),
                    "system-messages": i18next.t("sidebar.system_messages"),
                }}
            />
            <SystemMessagesFilters />
            <SystemMessagesTable
                data={props.data}
                userLang={props.userLang}
                gridApi={gridApi}
                onGridReady={onGridReady}
            />
        </Grid>
    );
}

function mapStateToProps(state) {
    return {
        data: state.systemMessages.data,
        loading: state.systemMessages.loading,
        userLang: state.authentication.user.language,
        search: state.systemMessages.search,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchSystemMessages: (form) => dispatch(systemMessagesActions.filter(form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SystemMessages));
