export const types = {
    FILTER_REQUEST: "@@elearning-exam-management/FILTER_REQUEST",
    FILTER_SUCCESS: "@@elearning-exam-management/FILTER_SUCCESS",
    FILTER_FAILURE: "@@elearning-exam-management/FILTER_FAILURE",

    CHANGE_FORM: "@@elearning-exam-management/CHANGE_FORM",
    CREATE_FORM: "@@elearning-exam-management/CREATE_FORM",
    DEFAULT_FORM: "@@elearning-exam-management/DEFAULT_FORM",

    POST_REQUEST: "@@elearning-exam-management/POST_REQUEST",
    POST_SUCCESS: "@@elearning-exam-management/POST_SUCCESS",
    POST_FAILURE: "@@elearning-exam-management/POST_FAILURE",

    GET_REQUEST: "@@elearning-exam-management/GET_REQUEST",
    GET_SUCCESS: "@@elearning-exam-management/GET_SUCCESS",
    GET_FAILURE: "@@elearning-exam-management/GET_FAILURE",

    DELETE_REQUEST: "@@elearning-exam-management/DELETE_REQUEST",
    DELETE_SUCCESS: "@@elearning-exam-management/DELETE_SUCCESS",
    DELETE_FAILURE: "@@elearning-exam-management/DELETE_FAILURE",
};
