import { Accordion, AccordionDetails, Grid, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import ExpIcon from "../Dashboard/Icons/ExpIcon";
import AccordionSum from "./AccordionSum";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";
import i18next from "i18next";

/*
    Használat:
    <Filter
        filterForm={form (ezt érdemes ilyenkor egy konstansban tárolni, 
            pl.: 
            const filtForm = (
                <>
                <Grid item xs={12}>
                    <TxtField .../>
                </Grid>
                .
                .
                .
                </>
            );
        )}
        defaultForm={reduxos defaultForm függvényt}
        onSubmit={submit handler-e}
    />
*/

export default function Filter(props) {
    const [expanded, setExpanded] = useState(true);
    const matches = useMediaQuery("(min-width: 360px)");

    return (
        <Grid item xs={12}>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSum expandIcon={<ExpIcon />} text={i18next.t("filter.filter")} />
                <AccordionDetails style={{ display: "block" }}>
                    <form onSubmit={props.onSubmit}>
                        <Grid item container spacing={1}>
                            {props.filterForm}
                            <Grid
                                item
                                container
                                justify="flex-start"
                                spacing={2}
                                xs={12}
                                style={{ marginTop: 3 }}
                            >
                                <Grid item>
                                    <SubmitButton text={i18next.t("filter.submit")} type="submit" />
                                </Grid>
                                <Grid item>
                                    <CancelButton
                                        text={i18next.t("filter.default")}
                                        onClick={props.defaultForm}
                                        type="reset"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}
