import React from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import Checkbox from "@material-ui/core/Checkbox";

const StyledCheckBox = withStyles({
    root: {
        color: colors.primary,
        "&$checked": {
            color: colors.primary,
        },
    },
    checked: {},
})(Checkbox);

export default function CheckBox(props) {
    return <StyledCheckBox {...props} />;
}
