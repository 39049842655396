export const types = {
    GET_REQUEST: "@@elearning-course-enrollments/GET_REQUEST",
    GET_SUCCESS: "@@elearning-course-enrollments/GET_SUCCESS",
    GET_FAILURE: "@@elearning-course-enrollments/GET_FAILURE",

    SELECTED_COURSE: "@@elearning-course-enrollments/SELECTED_COURSE",
    CHANGE_EMPTY_HIDE_CB: "@@elearning-course-enrollments/CHANGE_EMPTY_HIDE_CB",

    DOWNLOAD_REQUEST: "@@elearning-course-enrollments/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@elearning-course-enrollments/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@elearning-course-enrollments/DOWNLOAD_FAILURE",
    
    FETCH_REQUEST: "@@elearning-course-enrollments/FETCH_REQUEST",
    FETCH_SUCCESS: "@@elearning-course-enrollments/FETCH_SUCCESS",
    FETCH_FAILURE: "@@elearning-course-enrollments/FETCH_FAILURE",

    CHANGE_FORM: "@@elearning-course-enrollments/CHANGE_FORM",
    DEFAULT_FORM: "@@elearning-course-enrollments/DEFAULT_FORM",
    RESET_FILTER: "@@elearning-course-enrollments/RESET_FILTER",
};
