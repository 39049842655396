import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../styles/colors.scss";
import {
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import GreenTableCell from "../../Common/GreenTableCell";
import SubmitButton from "../../Common/SubmitButton";
import GreenTooltip from "../../Common/GreenTooltip";
import i18next from "i18next";
import CloseIcon from "@material-ui/icons/Close";
import Course from "./InfoHelpers/Course";
import CourseGeneration from "./InfoHelpers/CourseGeneration";

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
}));

function getTableCellVal(after, type) {

    if(!after){
        return '-';
    }

    if(type === 'Translation'){
        if(after[0] && after[1] && after[0] === 'value'){
            const value = JSON.parse(after[1]);

            return Object.keys(value).map((keyName, i) => (
                <li key={i}>
                    {keyName} - {value[keyName]}
                </li>
            ))
        }
    }

    return after[1] === null ? (
        <i>{i18next.t("system_log.field.empty")}</i>
    ) : after[1] === true ? (
        i18next.t("yes")
    ) : after[1] === false ? (
        i18next.t("no")
    ) : after[0] === "language" ? (
        i18next.t(after[1])
    ) : after[0] === "type" ? (
        after[1] === 0 ? (
            i18next.t("users.normal")
        ) : (
            i18next.t("isziir")
        )
    ) : (
        after[1]
    );
}

function getTableCellValUser(data, type) {
    if (!data) {
        return "-";
    }

    if (data[1] === null) {
        return <i>{i18next.t("system_log.field.empty")}</i>;
    }

    if (data[1] === true) {
        return i18next.t("yes");
    }

    if (data[1] === false) {
        return i18next.t("no");
    }

    switch (data[0]) {
        case "language":
            return i18next.t(data[1]);
        case "type":
            return data[1] === 0 ? i18next.t("users.normal") : i18next.t("isziir");
        case "banned":
        case "external_user":
            return data[1] === 1 ? i18next.t("yes") : i18next.t("no");
        default:
            return data[1];
    }
}

function getUserChangesRows(event, changes, notNeedTypePreString, type) {
    if (typeof changes == typeof undefined || changes === null) {
        return "";
    }

    if (!changes["before"]) {
        return "";
    }

    const printAfter = ["updated"].indexOf(event) !== -1;

    return Object.entries(changes["before"])
        .filter((before) => {
            return (
                [
                    "deleted_by",
                    "last_login",
                    "last_seen",
                    "privacy_statement_id",
                    "remember_token",
                    "impersonation_token",
                    "impersonation_expires_at",
                    "deleted_at",
                ].indexOf(before[0]) === -1
            );
        })
        .map((before) => {
            const after = changes["after"]
                ? Object.entries(changes["after"]).filter((afterVal) => before[0] === afterVal[0])
                : null;

            return (
                <TableRow key={before[0]}>
                    <TableCell>
                        {notNeedTypePreString.includes(before[0])
                            ? i18next.t(`system_log.field.${before[0]}`)
                            : i18next.t(`system_log.field.User.${before[0]}`)}
                    </TableCell>
                    <TableCell>{getTableCellValUser(before, type)}</TableCell>
                    <TableCell>{printAfter && after ? getTableCellValUser(after[0], type) : ""}</TableCell>
                </TableRow>
            );
        });
}

export default function SystemLogModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const data = props.data;
    let type = "";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const allowed = ["id", "type_translated", "changes", "user", "event_translated", "moodle_id"];
    const notNeedTypePreString = ["created_at", "updated_at", "moodle_id", "status"];

    return (
        <>
            <GreenTooltip title={i18next.t("system_log.infos")}>
                <IconButton onClick={handleOpen}>
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.primary} />
                </IconButton>
            </GreenTooltip>
            <Dialog open={open} onClose={handleClose} fullScreen={false}>
                <DialogTitle className={classes.title}>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    {i18next.t("system_log.infos")}
                </DialogTitle>
                <DialogContent>
                    <Card style={{ overflow: "auto" }}>
                        <CardContent className={classes.content}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <GreenTableCell>{i18next.t("system_log.key")}</GreenTableCell>
                                        <GreenTableCell>{i18next.t("system_log.value_before")}</GreenTableCell>
                                        <GreenTableCell>{i18next.t("system_log.value_after")}</GreenTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(data)
                                        .filter((val) => {
                                            if (val[0] === "type") {
                                                type = val[1];
                                            }

                                            //return true;
                                            return allowed.includes(val[0]);
                                        })
                                        .map((val) => {
                                            if (data.type == "User" && val[0] == "changes") {
                                                return getUserChangesRows(data.event, val[1], notNeedTypePreString, data.type);
                                            }

                                            if (val[1] && val[1]["after"]) {
                                                return Object.entries(val[1]["after"]).map((after) => {
                                                    const before = Object.entries(val[1]["before"])
                                                        .filter((beforeVal) => after[0] === beforeVal[0])
                                                        .map((before) => before);

                                                    return (
                                                        <TableRow key={after[0]}>
                                                            <TableCell>
                                                                {notNeedTypePreString.includes(after[0])
                                                                    ? i18next.t(`system_log.field.${after[0]}`)
                                                                    : i18next.t(`system_log.field.${type}.${after[0]}`)}
                                                            </TableCell>
                                                            <TableCell>{getTableCellVal(before[0], data.type)}</TableCell>
                                                            <TableCell>{getTableCellVal(after, data.type)}</TableCell>
                                                        </TableRow>
                                                    );
                                                });
                                            } else {
                                                if (val[0] !== "changes") {
                                                    if (val[0] === "user") {
                                                        if (val[1]) {
                                                            val[1] = val[1]["name"] + " / " + val[1]["email"];
                                                        } else {
                                                            val[1] = "-";
                                                        }
                                                    }

                                                    return (
                                                        <TableRow key={val[0]}>
                                                            <TableCell>
                                                                {i18next.t(`system_log.field.${val[0]}`)}
                                                            </TableCell>
                                                            <TableCell colSpan={2}>{val[1]}</TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            }
                                        })}

                                    <Course data={data} />
                                    <CourseGeneration data={data} />
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("system_log.close")} onClick={handleClose} />
                </DialogActions>
            </Dialog>
        </>
    );
}
