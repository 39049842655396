import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import TxtField from "../../Common/TxtField";
import { useDebounce } from "../../../hooks";

function FilterCheckboxes(props) {
    return (
        <Grid item xs={12} sm={6} md={9}>
            <RadioGroup row>
                <FormControlLabel
                    control={
                        <Radio
                            color="primary"
                            value="all"
                            onClick={() => props.setEnrollmentFilter(null)}
                            checked={props.filters.enrolled === null}
                        />
                    }
                    label={i18next.t("elearning.all")}
                />
                <FormControlLabel
                    control={
                        <Radio
                            color="primary"
                            onClick={() => props.setEnrollmentFilter("yes")}
                            checked={props.filters.enrolled === "yes"}
                        />
                    }
                    label={i18next.t("elearning.enrolled")}
                />
                <FormControlLabel
                    control={
                        <Radio
                            color="primary"
                            onClick={() => props.setEnrollmentFilter("no")}
                            checked={props.filters.enrolled === "no"}
                        />
                    }
                    label={i18next.t("elearning.enrollable")}
                />
            </RadioGroup>
        </Grid>
    );
}

function Filters(props) {
    const [query, setQuery] = useState(props.filters?.query || "");
    const handleQueryChange = useDebounce((value) => {
        props.setQueryFilter({ target: { value } });
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleQueryChange(e.target.value);
    };

    useEffect(() => {
        setQuery(props.filters?.query || "");
    }, [props.filters?.query]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
                <TxtField
                    label={i18next.t("elearning.filter")}
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    disabled={props.loadingSave}
                    value={query}
                />
            </Grid>
            {["enrollment", "exam-enrollment"].includes(props.type) && <FilterCheckboxes {...props} />}
        </Grid>
    );
}

export default Filters;
