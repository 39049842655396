import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Dialog, DialogActions, DialogContent, DialogContentText, Grid, makeStyles} from "@material-ui/core";
import GreenOutlineSelect from "../../Common/GreenOutlineSelect";
import TxtField from "../../Common/TxtField";
import SubmitButton from "../../Common/SubmitButton";
import notificationsActions from "../../../store/notifications/actions";
import Loading from "../../Common/Loading";
import Validator from "../../../utils/Validator";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../Common/Header";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "../../Common/Autocomplete";
import elearningCourseGeneration from "../../../store/elearningCourseGeneration/actions";
import CancelButton from "../../Common/CancelButton";
import DateTimePicker from "../../Common/DateTimePicker";
import i18next from "i18next";
import axios from "axios";
import CourseGenerationStatus from "./CourseGenerationStatus";
import { convertDateToHyphenFormat } from '../../../utils/AppConst';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "../../Common/CheckBox";
import BackendAutocompleteOffsetScroll from "../../Common/BackendAutocompleteOffsetScroll";
import colors from "../../../styles/colors.scss";
import validator from "../../../store/validator/actions";

const useStyles = makeStyles((theme) => ({
    warningMessageSampleCourse: {
        color: colors.warn
    },
}));

function CourseGenerationForm(props) {
    const classes = useStyles();
    const { form, user, loading, status, selectedSampleExam } = props;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);
    const [labels, setLabels] = useState([]);
    const [showCopyPrivacyStatement, setShowCopyPrivacyStatement] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        var val = event.target.value;

        if (event.target.type === "checkbox") {
            // checkboxnál máshogy kezeljük
            val = event.target.checked ? 1 : 0;
        }
        props.changeForm("form", { ...form, [event.target.name]: val });
        if(event.target.name === 'course'){
            let selected = null;
            props.courses.forEach((c) => {
                if(c.id == val){
                    selected = c;
                }
            });
            props.changeForm("selectedSampleExam", selected.sample_exam);
        }
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    const handleMultiselectChange = (event, values, name) => {
        //props.changeForm('form', {...form, [name]: values.map((v) => v.value)});
        setLabels(values);

        if (name == "chambers") {
            if (values && values.length > 0) {
                //props.changeForm('form', {...form, ['subCategory']: -1});
                props.getChamberSubCategories(
                    values.map((d) => {
                        return d.value;
                    })
                );
            } else {
                //props.changeForm('form', {...form, ['subCategory']: null});
            }
            props.changeForm("form", {
                ...form,
                [name]: values.map((v) => v.value),
                subCategory: values && values.length > 0 ? -1 : null,
            });
        } else {
            props.changeForm("form", {
                ...form,
                [name]: values.map((v) => v.value),
            });
        }
    };
    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, true)
        props.changeForm('form', {
            ...form,
            [name]: newDate
        });
    };

    useEffect(() => {
        props.setValidatorErrors({});
        axios
            .all([
                props.fetchChambers(),
                props.fetchCourses(),
                props.getValidationRules("e-learning/course-generation"),
            ])
            .then(
                axios.spread(() => {
                    props.createForm();
                })
            );
    }, []);

    const chamberOptions = props.chambers.map((object, key) => {
        return {
            value: object.id,
            label:
                object.name[user.language] +
                (object.active == 0 ? ` (${i18next.t("course_generation.inactive")})` : ""),
            key: key,
        };
    });

    const subCategoryOptions = !props.loadingSubCategory
        ? [
            {
                value: -1,
                label: i18next.t("elearning.course_generation.field.sub_category.default_val"),
                key: 0,
            },
        ].concat(
            props.chamberSubCategories.map((object, key) => {
                return {
                    value: object.id,
                    label: object.name,
                    key: key,
                };
            })
        )
        : [];

    const coursesOptions = props.courses.map((object, key) => {
        return {
            value: object.id,
            label: object.fullName + (object.course_type === 'kamgyak' ? ' (típus: Kamgyak)' : ''),
            key: key,
        };
    }).sort((a, b) => a.label.localeCompare(b.label));

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        props
            .generate({ ...form, course: form.course?.value || form.course || '' })
            .then(() => {
                props.createForm();
                setShowCopyPrivacyStatement(false);
                setLabels([]);
            })
            .catch((error) => {
                props.addNotification("error", i18next.t("course_generation.error"));
                validator.setReponseError(error);
                props.setValidatorErrors(validator.getErrors());
            });
    };

    return loading ? (
        <Loading />
    ) : status.id != null ? (
        <CourseGenerationStatus status={status} refreshStatus={props.refreshStatus} resetStatus={props.resetStatus} />
    ) : (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        course_generation: i18next.t("sidebar.course_generation"),
                    }}
                />
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={i18next.t("sidebar.course_generation")} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate={true} id="course-generation-form">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BackendAutocompleteOffsetScroll
                                            onChange={(_, value) => {
                                                setShowCopyPrivacyStatement(!!value?.hasPrivacyStatement)
                                                props.changeForm("form",
                                                    {
                                                        ...form,
                                                        copyPrivacyStatement: value?.hasPrivacyStatement ? 1 : 0,
                                                        course: value
                                                    }
                                                );
                                            }}
                                            value={form.course}
                                            label={i18next.t("course_generation.course")}
                                            required={validator.isRequired("course")}
                                            error={validator.hasError("course")}
                                            helperText={validator.getErrorText("course")}
                                            url="/e-learning/course-generation/courses"
                                            itemConverter={(item) => ({ value: item['course_id'], label: item["course_name"], hasPrivacyStatement: item.hasPrivacyStatement })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            multiple
                                            className={"multiselect-autocomplete-required"}
                                            id="chambers"
                                            name="chambers"
                                            options={chamberOptions}
                                            getOptionLabel={(option) => option.label}
                                            defaultValue={labels || ""}
                                            filterSelectedOptions
                                            getOptionSelected={(option, value) =>
                                                option.value === value.value || option.value === value.id
                                            }
                                            onChange={(e, v) => handleMultiselectChange(e, v, "chambers")}
                                            style={{ marginTop: 0 }}
                                            renderInput={(params) => (
                                                <TxtField
                                                    {...params}
                                                    required={validator.isRequired("chambers")}
                                                    error={validator.hasError("chambers")}
                                                    helperText={validator.getErrorText("chambers")}
                                                    variant="outlined"
                                                    label={i18next.t("course_generation.chambers")}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <GreenOutlineSelect
                                            disabled={labels.length == 0 || subCategoryOptions.length == 0}
                                            margin="normal"
                                            id="subCategory"
                                            selectLabel={i18next.t("course_generation.subcategory")}
                                            onChange={(event) => handleChange(event)}
                                            name="subCategory"
                                            value={form.subCategory || ""}
                                            fullWidth
                                            shrink={true}
                                            optionList={subCategoryOptions}
                                            required={validator.isRequired("subCategory")}
                                            error={validator.hasError("subCategory")}
                                            helperText={validator.getErrorText("subCategory")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TxtField
                                            variant="outlined"
                                            margin="normal"
                                            value={form.name || ""}
                                            id="name"
                                            label={i18next.t("course_generation.name")}
                                            name="name"
                                            onChange={(event) => handleChange(event)}
                                            fullWidth
                                            onBlur={(event) => handleBlur(event)}
                                            required={validator.isRequired("name")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validator.hasError("name")}
                                            helperText={validator.getErrorText("name")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TxtField
                                            variant="outlined"
                                            margin="normal"
                                            value={form.short_name || ""}
                                            id="short_name"
                                            label={i18next.t("course_generation.short_name")}
                                            name="short_name"
                                            onChange={(event) => handleChange(event)}
                                            fullWidth
                                            onBlur={(event) => handleBlur(event)}
                                            required={validator.isRequired("short_name")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validator.hasError("short_name")}
                                            helperText={validator.getErrorText("short_name")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            onChange={(date) => handleDateChange(date, "start")}
                                            error={validator.hasError("start")}
                                            helperText={validator.getErrorText("start")}
                                            required={validator.isRequired("start")}
                                            value={form.start || ''}
                                            id="start"
                                            label={i18next.t("course_generation.start")}
                                            name="start"
                                            style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            onChange={(date) => handleDateChange(date, "end")}
                                            error={validator.hasError("end")}
                                            helperText={validator.getErrorText("end")}
                                            required={validator.isRequired("end")}
                                            value={form.end || ''}
                                            id="end"
                                            label={i18next.t("course_generation.end")}
                                            name="end"
                                            style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    {showCopyPrivacyStatement ?
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={form.copyPrivacyStatement}
                                                        onChange={(event) => handleChange(event)}
                                                        value={1}
                                                        name="copyPrivacyStatement"
                                                    />
                                                }
                                                label={i18next.t("course_generation.copyPrivacyStatement")}
                                            />
                                        </Grid> : null}
                                    {selectedSampleExam &&
                                        <Grid item xs={12} className={classes.warningMessageSampleCourse}>
                                            {i18next.t('course_generation.sample_exam_warn')}
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <SubmitButton
                                            text={i18next.t("course_generation.save")}
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpen(true);
                                            }}
                                        />
                                        <Dialog open={open} onClose={() => setOpen(false)}>
                                            <DialogContent>
                                                <DialogContentText>
                                                    {i18next.t("course_generation.save")}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <SubmitButton
                                                    text={i18next.t("yes")}
                                                    onClick={(e) => {
                                                        setOpen(false);
                                                        handleSubmit(e)
                                                    }}
                                                />
                                                <CancelButton
                                                    text={i18next.t("no")}
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { form, chambers, courses, chamberSubCategories, loading, loadingSubCategory, status, selectedSampleExam } =
        state.elearningCourseGeneration;
    const { user } = state.authentication;
    const validator = state.validator;
    return {
        form,
        user,
        loading,
        loadingSubCategory,
        chambers,
        courses,
        chamberSubCategories,
        validator,
        status,
        selectedSampleExam
    };
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
    getChamberSubCategories: elearningCourseGeneration.getChamberSubCategories,
    changeForm: elearningCourseGeneration.changeForm,
    createForm: elearningCourseGeneration.createForm,
    generate: elearningCourseGeneration.generate,
    fetchCourses: elearningCourseGeneration.fetchCourses,
    fetchChambers: elearningCourseGeneration.fetchChambers,
    refreshStatus: elearningCourseGeneration.refreshStatus,
    resetStatus: elearningCourseGeneration.resetStatus,
};

export default connect(mapState, actionCreators)(CourseGenerationForm);
