import React from "react";
import EmailLogAlert from "./EmailLogAlert";
import "./EmailLogTable.scss";
import { Box } from "@material-ui/core";
import Table from "../../Common/Table";
import moment from "moment";
import * as AppConst from "../../../utils/AppConst";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import { TableCellText } from "../../Common/TableCellText";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";

function BtnCellRenderer(params) {
    return <OperationButton to={`/email-log/email-sheet/${params.data.id}`} type="view" />;
}

function StatusCellRenderer(params) {
    switch (params.data.status) {
        case 0:
            return (
                <Box component="span" bgcolor="warning.main" color="white" p={1}>
                    {i18next.t("email_log.waiting")}
                </Box>
            );
        case 1:
            return (
                <Box component="span" bgcolor="primary.main" color="white" p={1}>
                    {i18next.t("email_log.success")}
                </Box>
            );
        case 2:
            return <EmailLogAlert info={params.data.mailer_response ?? i18next.t("email_log.noinfo")} />;
        default:
            return (
                <Box component="span" bgcolor="text.disabled" p={1}>
                    {i18next.t("email_log.unknown")}
                </Box>
            );
    }
}
function TypeCellRenderer(params) {
    return (
        <TableCellText
            val={params.data.email_template ? params.data.email_template.name[params.context.userLang] : "-"}
        />
    );
}
function DateCellRenderer(params) {
    return <TableCellText val={params.value ? moment(params.value).format(AppConst.DATE_FORMAT) : "-"} />;
}

export default function EmailLogTable(props) {
    const table = {
        columnDefs:  [
            {
                headerName: i18next.t("email_log.name"),
                field: "name",
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("email_log.email"),
                field: "email",
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("email_log.type"),
                field: "type",
                cellRenderer: "typeCellRenderer",
            },
            {
                headerName: i18next.t("email_log.subject"),
                field: "subject",
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("email_log.content"),
                field: "content",
                cellRenderer: "btnCellRenderer",
                cellStyle: { textAlign: "center" },
                sortable: false,
            },
            {
                headerName: i18next.t("email_log.send"),
                field: "sent_at",
                cellRenderer: "dateCellRenderer",
                sort: "desc",
            },
            {
                headerName: i18next.t("email_log.generation"),
                field: "created_at",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: i18next.t("email_log.status"),
                field: "state",
                cellRenderer: "statusCellRenderer",
                sortable: false,
            },
        ],
        loading: props.tableLoading,
        rowData: props.data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
            typeCellRenderer: TypeCellRenderer,
            dateCellRenderer: DateCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
        autoSize: "true",
        filterData: props.filter,
        searchfunc: props.filter,
        info: props.info,
        setInfo: props.setInfo,
        isPaginated: true,
        context: {
            filter: props.filter,
            userLang: props.userLang,
            searchData: props.search,
        },
        minWidth : 100
    };

    return (
        <Table {...table} />
    );
}
