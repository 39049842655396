export default {
    //Törzsadatok
    Chambers: "Chambers",
    Users: "Users",

    //Adminisztrátor
    Settings: "Settings",
    EmailLogs: "Email Logs",
    EmailTemplates: "Email Templates",
    Permissions: "Permissions",
    Logs: "Logs",
    Translations: "Translations",

    //Tartalmak
    News: "News",
    SystemMessages: "System Messages",
    Pages: "Pages",

    //E-learning
    CourseCategories: "Course Categories",
    CourseGeneration: "Course Generation",
    CourseManagement: "Course Management",
    CourseEnrollment: "Course Enrollment",
    CourseDismissal: "Course Dismissal",
    CourseEnrollmentList: "Course Enrollment List",
    CourseCompletions: "Course Completions",
    CourseExamGeneration: "Course Exam Generation",
    CourseExamManagement: "Course Exam Management",
    CourseExamEnrollment: "Course Exam Enrollment",
    CourseExamDismissal: "Course Exam Dismissal",

    //Egyéb
    ElearningContentEditor: "Elearning Content Editor",
    ElearningOwnQuestionBankEditor: "Elearning Own Question Bank Editor",
    ElearningQuestionBankEditor: "Elearning Question Bank Editor",
    Impersonation: "Impersonation",

    // Kurzusszintű jogok
    CourseCourseEnrollment: "Course - Course Enrollment",
    CourseCourseDismissal: "Course - Course Dismissal",
    CourseCourseEnrollmentList: "Course - Course Enrollment List",
    CourseCourseCompletions: "Course - Course Completions",
    CourseCourseExamGeneration: "Course - Course Exam Generation",
    CourseCourseExamManagement: "Course - Course Exam Management",
    CourseCourseExamEnrollment: "Course - Course Exam Enrollment",
    CourseCourseExamDismissal: "Course - Course Exam Dismissal",

    // Kamarai admin jogok
    ChamberUsers: "Chamber - Users",
    ChamberCourseEnrollment: "Chamber - Course Enrollment",
    ChamberCourseDismissal: "Chamber - Course Dismissal",
    ChamberCourseEnrollmentList: "Chamber - Course Enrollment List",
    ChamberCourseCompletions: "Chamber - Course Completions",
    ChamberCourseExamGeneration: "Chamber - Exam Generation",
    ChamberCourseExamManagement: "Chamber - Exam Management",
    ChamberCourseExamEnrollment: "Chamber - Exam Enrollment",
    ChamberCourseExamDismissal: "Chamber - Exam Dismissal",

    CourseElearningTeacher: "Course - E-learning - Teacher",
    CourseElearningQuestionBankEditor: "Course - E-learning - Question Bank Editor",
    CourseElearningOwnQuestionBankEditor: "Course - E-learning - Own Question Bank Editor",
    CourseElearningContentEditor: "Course - E-learning - Content Editor",
};
