import React from "react";
import TxtField from "../../../Common/TxtField";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import styles from "../theme";
import { connect } from "react-redux";
import systemMessagesActions from "../../../../store/systemMessages/actions";
import Filter from "../../../Common/Filter";
import DatePicker from "../../../Common/DatePicker";
import i18next from "i18next";
import {convertDateToHyphenFormat} from '../../../../utils/AppConst';

function Filters(props) {
    const handleSubmit = (event) => {
        event.preventDefault();
        const filt = props.search;
        if (filt.title || filt.publishedAtFrom || filt.publishedAtUntil) props.submitAction(props.search);
    };

    const handleChange = (event) => {
        props.changeForm({
            ...props.search,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm({
            ...props.search,
            [name]: newDate
        });
    };
    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.submitAction({});
    };

    const filtForm = (
        <>
            <Grid item xs={12} md={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="title"
                    label={i18next.t("system_messages.title")}
                    name="title"
                    onChange={handleChange}
                    value={props.search.title || ""}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "publishedAtFrom")}
                    value={props.search.publishedAtFrom}
                    id="publishedAtFrom"
                    label={i18next.t("system_messages.from")}
                    name="publishedAtFrom"
                    margin="normal"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "publishedAtUntil")}
                    value={props.search.publishedAtUntil}
                    id="publishedAtUntil"
                    label={i18next.t("system_messages.to")}
                    name="publishedAtUntil"
                    margin="normal"
                />
            </Grid>
        </>
    );

    return <Filter filterForm={filtForm} defaultForm={resetForm} onSubmit={handleSubmit} />;
}

function mapStateToProps(state) {
    return {
        search: state.systemMessages.search,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        submitAction: (form) => dispatch(systemMessagesActions.filter(form)),
        changeForm: (form) => dispatch(systemMessagesActions.changeForm("search", form)),
        defaultForm: () => dispatch(systemMessagesActions.defaultForm()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filters));
