import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form, {});
    let sendSearch = searchForm.getSend();

    if (sendSearch.value) {
        const valueCurrent = Object.assign({}, sendSearch.value);
        delete sendSearch.value;
        for (const [lang, val] of Object.entries(valueCurrent)) {
            if (val !== null && val !== "") {
                sendSearch["value-" + lang] = val;
            }
        }
    }

    return API.get("/translations", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function patch(form, id) {
    return API.patch(`/translations/${id}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const translationsService = {
    filter,
    patch,
};

export default translationsService;
