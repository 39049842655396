import {
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@material-ui/core";
import { ArrowDropDown, Close } from "@material-ui/icons";
import i18next from "i18next";
import React from "react";
import instance from "../../../utils/API";
import Autocomplete from "../../Common/Autocomplete";
import CancelButton from "../../Common/CancelButton";
import SubmitButton from "../../Common/SubmitButton";
import TxtField from "../../Common/TxtField";
import notificationsActions from "../../../store/notifications/actions.js";
import { useDispatch } from "react-redux";
import useDebounce from "../../../hooks/useDebounce.js";
import MassOperationGroupAddDialog from "./MassOperationGroupAddDialog.js";

export default function MassOperationDialog(props) {
    const { selectedRows } = props;
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [groupAddOpen, setGroupAddOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [user, setUser] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [userLoading, setUserLoading] = React.useState(false);
    const [courseLoading, setCourseLoading] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);
    const [form, setForm] = React.useState({email: false});
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const ORDER = ["course", "exam", "elearning", "special"].map((type) =>
        i18next.t("course_management.permission_groups." + type)
    );
    const [originalPerimissions , setOriginalPermissions] = React.useState({});

    const debouncedUserSearch = useDebounce((value) => {
        setUserLoading(true);
        instance
            .get(`/users/search`, { params: { nameOrEmail: value } })
            .then((res) =>
                setOptions(
                    res.data
                        .map((user) => ({ value: user.id, label: user.name + " / " + user.email }))
                        .sort((a, b) => a.label.localeCompare(b.label))
                )
            )
            .catch(console.error)
            .finally(() => setUserLoading(false));
    }, 500);

    const handleClose = () => {
        setOpen(false);
        setForm({email: false});
        setSelectedUser(null);
        setPermissions([]);
        setOptions([]);
        setUser("");
        setMenuOpen(false);
    };

    const handleDelete = (course, del) => {
        const foundCourse = form.courses.findIndex((elem) => course.id == elem.id);
        let courses = form.courses;
        courses[foundCourse] = {
            ...courses[foundCourse],
            roles: courses[foundCourse].roles.filter((elem) => elem.id != del.id), 
        };
        setForm({
            ...form,
            courses: courses,
        });
    }

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef}>
                <Button onClick={() => setMenuOpen(true)}>{i18next.t("course_management.mass_operation")}</Button>
                <Button size="small" onClick={() => setMenuOpen(true)}>
                    <ArrowDropDown />
                </Button>
                <Popper
                    style={{
                        zIndex: 1,
                    }}
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        <MenuItem key={"mass_operation"} onClick={() => setOpen(true)}>
                                            {i18next.t("course_management.set_course_permission")}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ButtonGroup>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Grid container justify="space-between">
                        <Grid item>{i18next.t("course_management.mass_operation")}</Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item lg={9} sm={6} xs={12}>
                            <Autocomplete
                                options={options}
                                multiple={false}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                filterSelectedOptions
                                placeholder={i18next.t("permissions.placeholder").replace("2", "3")}
                                noOptionsText={
                                    !userLoading && user.length < 3
                                        ? i18next.t("permissions.placeholder").replace("2", "3")
                                        : userLoading
                                        ? i18next.t("permissions.loading")
                                        : i18next.t("permissions.nooption")
                                }
                                id="user"
                                value={selectedUser}
                                inputValue={user}
                                onChange={(_, newValue) => {
                                    setSelectedUser(newValue);
                                    if (newValue?.value) {
                                        setCourseLoading(true);
                                        instance
                                            .post(`/e-learning/course-management/permissions/${newValue.value}/get-course`, {
                                                courses: selectedRows.map((row) => row.id),
                                            })
                                            .then((res) => {
                                                setPermissions(res.data.permissions);
                                                setForm({
                                                    ...form,
                                                    userid: newValue.value,
                                                    courses: selectedRows.map(
                                                        (row) =>
                                                            res.data.courses.find((course) => course.id == row.id) || {
                                                                id: row.id,
                                                                roles: [],
                                                            }
                                                    ),
                                                });
                                                setOriginalPermissions({
                                                    courses: selectedRows.map(
                                                        (row) =>
                                                            res.data.courses.find((course) => course.id == row.id) || {
                                                                id: row.id,
                                                                roles: [],
                                                            }
                                                    )
                                                });
                                            })
                                            .finally(() => setCourseLoading(false));
                                    } else {
                                        setForm({});
                                        setOriginalPermissions({});
                                    }
                                }}
                                onInputChange={(_, value) => {
                                    setUser(value);
                                    if (value.length < 3) {
                                        setOptions([]);
                                    } else {
                                        debouncedUserSearch(value);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TxtField {...params} variant="outlined" label={i18next.t("permissions.user")} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="email"
                                        checked={form.email}
                                        onChange={() => setForm((prev) => ({...prev, email: !form.email}))}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant={"subtitle1"} color={selectedUser ? "textPrimary" : "textSecondary"}>{i18next.t("permissions.send_email")}</Typography>
                                }
                                disabled={!selectedUser}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CancelButton text={i18next.t("course_management.mass_add")} onClick={() => setGroupAddOpen(true)} disabled={!selectedUser} />
                        </Grid>
                        {courseLoading ? (
                            <Grid item xs={12}>
                                {i18next.t("table.loading")}
                            </Grid>
                        ) : (
                            selectedRows.map((course) => (
                                <Grid item xs={12} lg={6} key={course.id}>
                                    <Autocomplete
                                        options={permissions.sort(
                                            (a, b) =>
                                                ORDER.indexOf(a.type) - ORDER.indexOf(b.type) ||
                                                a.label.localeCompare(b.label)
                                        )}
                                        multiple
                                        getOptionLabel={(option) => option.label}
                                        label={course.courseDisplayName}
                                        value={form.courses?.find((c) => c.id === course.id)?.roles || []}
                                        filterSelectedOptions
                                        getOptionSelected={(option, value) =>
                                            option.id === value.id ||
                                            option === value ||
                                            option.id === value ||
                                            option === value.id
                                        }
                                        groupBy={(option) => option.type}
                                        onChange={(_, newValue) => {
                                            setForm({
                                                ...form,
                                                courses: form.courses.map((c) =>
                                                    c.id == course.id ? { ...c, roles: newValue } : c
                                                ),
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TxtField {...params} variant="outlined" label={course.fullName} />
                                        )}
                                        renderTags={(value) => {
                                            return(<>{
                                                value.map((val) => {
                                                    if (originalPerimissions.courses.find((elem) => elem.id === course.id).roles.find((e) => e.id === val.id)) {
                                                        return (<Chip
                                                            key={val.id}
                                                            label={val.label}
                                                            onDelete={() => handleDelete(course, val)}
                                                            style={{margin: '2px'}}
                                                        />)
                                                    } else {
                                                        return (<Chip
                                                            key={val.id}
                                                            label={val.label}
                                                            onDelete={() => handleDelete(course, val)}
                                                            color="primary"
                                                            style={{margin: '2px'}}
                                                        />)
                                                    }
                                                })
                                            }</>)
                                        }}
                                    />
                                </Grid>
                            ))
                        )}
                    </Grid>
                    <DialogActions>
                        <CancelButton text={i18next.t("cancel")} onClick={handleClose} />
                        <SubmitButton
                            text={i18next.t(loading || courseLoading ? "permissions.loading" : "save")}
                            disabled={loading || !selectedUser?.value || courseLoading}
                            onClick={() => {
                                setLoading(true);
                                instance
                                    .post(
                                        `/e-learning/course-management/permissions/${selectedUser.value}/course`,
                                        form
                                    )
                                    .then(() => {
                                        handleClose();
                                        dispatch(
                                            notificationsActions.addNotification(
                                                "success",
                                                i18next.t("course_management.update.success")
                                            )
                                        );
                                    })
                                    .catch(() => {
                                        dispatch(
                                            notificationsActions.addNotification(
                                                "error",
                                                i18next.t("course_management.update.error")
                                            )
                                        );
                                    })
                                    .finally(() => setLoading(false));
                            }}
                        />
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <MassOperationGroupAddDialog
                open={groupAddOpen}
                setOpen={setGroupAddOpen}
                permissions={permissions}
                order={ORDER}
                form={form}
                setForm={setForm}
            />
        </>
    );
}
