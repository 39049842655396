import types from "./types";

const initialState = {
    loading: false
}

export function loading(state = initialState, action) {
    switch(action.type) {
        case types.LOADING_ON:
            return {
                loading: true
            }
        case types.LOADING_OFF:
            return {
                loading: false 
            }
        default: 
            return {
                ...state
            }
    }
}