import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";
import i18next from "i18next";

export default function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {i18next.t("mkik")}
            <br /> {i18next.t("copyright.supported")}
        </Typography>
    );
}
