import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import SubmitButton from "../Common/SubmitButton";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import CancelButton from "../Common/CancelButton";
import i18next from "i18next";
import { removeIsziirUserLoggedIn, isziirLogout, isInactive } from "../../utils/AuthHelper";

/**
 * Amennyiben isziir userrel van bent a felhasználó és kijelentkezik a bejelentkezés felületen ezt a kérdést tesszük fel neki
 */
function IsziirUserInvalidateModalOnLogin(props) {
    const [open, setOpen] = React.useState(true);

    return (
        <Dialog open={"boolean" === typeof props.open ? props.open : open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                {i18next.t("isziir_user_invalidate_modal_on_login.title")}
            </DialogTitle>
            <form noValidate>
                <DialogContent>
                    {isInactive()
                        ? i18next.t("isziir_user_invalidate_modal_on_login.inactive_text")
                        : i18next.t("isziir_user_invalidate_modal_on_login.text")}
                </DialogContent>
                <DialogActions>
                    <SubmitButton
                        text={i18next.t("isziir_user_invalidate_modal_on_login.btn.yes")}
                        onClick={() => {
                            removeIsziirUserLoggedIn();
                            setOpen(false);
                            isziirLogout();
                        }}
                    />
                    <CancelButton
                        text={i18next.t("isziir_user_invalidate_modal_on_login.btn.no")}
                        onClick={() => {
                            removeIsziirUserLoggedIn();
                            setOpen(false);
                        }}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState() {
    return {};
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(IsziirUserInvalidateModalOnLogin);
