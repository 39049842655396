import React from "react";
import BtnCellRenderer from "./BtnCellRenderer";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import Table from "../../Common/Table";
import i18next from "i18next";

export default function TranslationsTable(props) {
    const state = {
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    const columnDefs = [
        {
            headerName: i18next.t("translations.key"),
            field: "key",
            editable: false,
            cellRenderer: "defaultCellRenderWithTooltip",
            sort: "asc",
        },
    ];

    for (let lang of props.languages)
        columnDefs.push({
            headerName: `${i18next.t("translations.value")} (${lang})`,
            field: `value.${lang}`,
            cellRenderer: "defaultCellRenderWithTooltip",
            editable: true,
            sortable: false,
        });

    columnDefs.push({
        headerName: i18next.t("table.operations"),
        field: "operations",
        cellRenderer: "btnCellRenderer",
        editable: false,
        sortable: false,
    });

    return (
        <Table
            columnDefs={columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            editType="fullRow"
            minWidth={250}
        />
    );
}
