import { types } from "./types";

const initialState = {
    loading: false,
    data: [],
    letter: {},
    search: {
        name: null,
        email: null,
        subject: null,
        sentFrom: null,
        sentUntil: null,
        generateFrom: null,
        generateUntil: null,
        type: [],
        status: [],
    },
    types: [],
    tableLoading: true,
    info: {
        filter: {},
        sort: {
            sent_at: "desc",
        },
        page: 1,
        perpage: 25,
        metadata: {},
    },
};

export function emailLog(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                info: {
                    ...state.info,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                letter: {},
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                letter: action.data.data,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };

        case types.FETCH_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                types: action.data.data,
            };
        case types.FETCH_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
                types: [],
                error: action.error,
            };

        case types.FETCH_SET_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.key]: action.value,
                },
            };
        default:
            return state;
    }
}
