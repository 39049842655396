import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import HelmetTemplate from "./HelmetTemplate";
import { Skeleton } from "@material-ui/lab";
import { history } from "../../store";

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        color: colors.primary,
        fontSize: "medium",
    },
}));
//megj: abban állapodtunk meg, hogy csak az aloldalakon legyen linkes breadcrumbs
//pl.: /chambers/edit oldalról vissza tudjunk menni a /chambers-re,
//de a /chambers oldalról ne is legyenek kattinthatóak a breadcrumbok és stb.
//a "témák" egyáltalán ne legyenek kattinthatóak (törzsadatok, adminisztráció, stb.)
//ugyanarról az oldalról ne menjen link ugyanarra az oldalra

// Használat:
// <Header title="Main title" breadcrumbs={{routePath1: title1, routePath2: title2}} />
//ha nem szeretnénk, hogy linkes breadcrumb legyen, akkor ne '/'-rel kezdődjön a neve

export default function Header(props) {
    const classes = useStyles();
    const skeleton = props.skeleton;

    let mainTitle = Object.values(props.breadcrumbs)[Object.values(props.breadcrumbs).length - 1];

    let breadcrumbs = Object.entries(props.breadcrumbs).map(([route, title]) => {
        if (!skeleton) {
            if (route[0] !== "/") {
                return (
                    <Typography className={classes.breadcrumbs} key={title}>
                        {title}
                    </Typography>
                );
            } else {
                return (
                    <Link
                        href={route}
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(route);
                        }}
                        key={title}
                    >
                        <Typography className={classes.breadcrumbs} key={title}>
                            {title}
                        </Typography>
                    </Link>
                );
            }
        } else {
            return (
                <Skeleton key={title}>
                    <Typography className={classes.breadcrumbs} key={title}>
                        {title}
                    </Typography>
                </Skeleton>
            );
        }
    });

    return (
        <>
            <HelmetTemplate title={mainTitle} />
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
            </Grid>
        </>
    );
}
