import { types } from "./types";

const initialState = {
    loading: false,
    form: {
        templateCourse: null,
        exam: null,
        courses: [],
        name: null,
        start: null,
        end: null,
    },
    courses: [],
    exams: [],
    templateCourses: [],
    data: null,
    selectedSampleExam: null,
};

export function elearningExamGeneration(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
                exams: initialState.exams,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };

        case types.FETCH_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_COURSES_SUCCESS:
            return {
                ...state,
                courses: action.data.data,
                loading: false,
            };
        case types.FETCH_COURSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.FETCH_TEMPLATE_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_TEMPLATE_COURSES_SUCCESS:
            return {
                ...state,
                templateCourses: action.data.data,
                loading: false,
            };
        case types.FETCH_TEMPLATE_COURSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.FETCH_EXAMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_EXAMS_SUCCESS:
            return {
                ...state,
                exams: action.data.data,
                loading: false,
            };
        case types.FETCH_EXAMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.GENERATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GENERATE_SUCCESS:
            return {
                ...state,
                data: action.data.data,
                loading: false,
            };
        case types.GENERATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}
