import { types } from "./types";
import elearningCourseAchievementsService from "../../services/elearningCourseAchievements";

function get(courseId) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, courseId });

        return elearningCourseAchievementsService.get(courseId).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
function download(courseId, filename) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });

        return elearningCourseAchievementsService.download(courseId, filename).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

export function selectCourse(course) {
    return (dispatch) => {
        dispatch({ type: types.SELECTED_COURSE, course });
    };
}

export function changeEmptyHideCb(val) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_EMPTY_HIDE_CB, val });
    };
}

const actions = {
    get,
    selectCourse,
    changeEmptyHideCb,
    download,
};

export default actions;
