import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Loading from "../../Common/Loading";
import i18next from "i18next";
import LinkToButton from "../../Common/LinkToButton";
import SubmitButton from "../../Common/SubmitButton";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "40%",
    },
    grid: {
        display: "block",
        width: "100%",
        textAlign: "center",
    },
    infoContainer: {
        display: "block",
        fontSize: "14pt",
        color: "green",
    },
    successText: {
        display: "block",
        fontSize: "16pt",
        color: "green",
    },
    errorsList: {
        display: "block",
        fontSize: "12pt",
        color: "red",
        listStyle: "none",
    },
}));

function CourseGenerationStatus(props) {
    const classes = useStyles();
    const { status, refreshStatus, resetStatus } = props;

    if (status.data == null || status.data.isDone !== true) {
        setTimeout(function () {
            refreshStatus(status.id);
        }, 2000);
    }

    return (
        <Grid item xs={12} className={classes.grid}>
            <h1>{i18next.t("sidebar.course_generation")}</h1>

            <span className={classes.infoContainer}>
                {status.data != null && status.data.all
                    ? status.data.actual + " / " + status.data.all + " kész"
                    : i18next.t("course_generation.please_wait")}
            </span>

            {status.data != null && status.data.errors && status.data.errors.length > 0 ? (
                <>
                    <h4>Hiba a kurzusgenerálás(ok) során</h4>
                    <ul className={classes.errorsList}>
                        {status.data.errors.map((err) => {
                            return <li>{err}</li>;
                        })}
                    </ul>
                </>
            ) : (
                ""
            )}

            {status.data != null && status.data.all && status.data.actual == status.data.all ? (
                <>
                    {status.data.errors.length == 0 ? (
                        <span className={classes.successText}>{i18next.t("course_generation.success")}</span>
                    ) : (
                        ""
                    )}

                    <Button>
                        <SubmitButton
                            type="submit"
                            variant="contained"
                            text={i18next.t("course_generation.return")}
                            onClick={(e) => {
                                e.preventDefault();
                                resetStatus();
                            }}
                        />
                    </Button>
                </>
            ) : (
                <Loading />
            )}
        </Grid>
    );
}

export default connect()(CourseGenerationStatus);
