import { types } from "./types";

const initialState = {
    loading: false,
    data: [],
    form: {},
    search: {
        name: "",
        shortName: "",
    },
    editForm: {},
};

export function elearningExamManagement(state = initialState, action) {
    switch (action.type) {
        case types.FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FILTER_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };

        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };

        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };

        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                editForm: initialState.editForm,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                editForm: action.data.data,
            };

        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}
