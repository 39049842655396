import React from "react";
import Table from "../../Common/Table";
import { makeStyles } from "@material-ui/core/styles";
import chamberActions from "../../../store/chambers/actions";
import notificationsActions from "../../../store/notifications/actions";
import { connect } from "react-redux";
import OperationButton from "../../Common/OperationButton";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import i18next from "i18next";

const btnRendererStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: theme.spacing(1),
    },
}));

function BtnCellRenderer(params) {
    const classes = btnRendererStyle();
    return (
        <>
            <OperationButton type="edit" to={`/chambers/edit/${params.data.id}`} />

            {params.data.users_count == 0 ? (
                <>
                    <OperationButton
                        type="delete"
                        successmessage={i18next.t("chambers.delete.success")}
                        confirmation={i18next.t("chambers.delete.confirmation")}
                        className={classes.deleteButton}
                        params={params}
                    />
                </>
            ) : (
                ""
            )}
        </>
    );
}

function valueGetter(params) {
    const val = params.data[params.column.colId][params.context.lang];
    return val ? val : "-";
}

function ActiveCellRenderer(params) {
    const val = params.data.active;
    if (val) return i18next.t("yes");
    else return i18next.t("no");
    /*else
        return "-";*/
}
//rendezéshez kell
function comparatorString(valueA, valueB, nodeA, nodeB, isInverted) {
    return valueA.localeCompare(valueB);
}

function ChambersTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("chambers.name"),
                field: "name",
                valueGetter,
                sort: "asc",
                comparator: comparatorString,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("chambers.short_name"),
                field: "short_name",
                valueGetter,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("chambers.isziir_id"),
                field: "isziir_id",
            },
            {
                headerName: i18next.t("chambers.active"),
                field: "active",
                cellRenderer: "activeCellRenderer",
                sortable: false,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            context={{
                lang: props.userLang,
                delete: props.delete,
                AddNotification: props.addNotification,
                filter: props.filter,
                searchData: props.searchData,
            }}
            onGridReady={props.onGridReady}
            gridApi={props.gridApi}
            newButtonText={`${i18next.t("chambers.new")}`}
            to="/chambers/new"
            minWidth={200}
        />
    );
}

function mapStateToProps(state) {
    return {
        userLang: state.authentication.user.language,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        delete: (userId) => dispatch(chamberActions.deleteRow(userId)),
        addNotification: (type, message) => dispatch(notificationsActions.addNotification(type, message)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChambersTable);
