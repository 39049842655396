const common = {
    toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "link",
        "numberedList",
        "bulletedList",
        "imageUpload",
        "insertTable",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "mediaEmbed",
        "|",
        "undo",
        "redo",
    ],
};

export { common };
