import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "../Dashboard/Icons/DeleteIcon";
import DeleteRepealIcon from "../Dashboard/Icons/DeleteRepealIcon";
import EditIcon from "../Dashboard/Icons/EditIcon";
import SendMailIcon from "../Dashboard/Icons/SendMailIcon";
import SendPassMailIcon from "../Dashboard/Icons/SendPassMailIcon";
import ViewContentIcon from "../Dashboard/Icons/ViewContentIcon";
import DeleteButton from "./DeleteButton";
import DeleteCancelButton from "./DeleteCancel";
import GreenTooltip from "./GreenTooltip";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";
import colors from "../../styles/colors.scss";

function getIcon(type) {
    //igény szerint bővíthető
    switch (type) {
        case "edit":
            return <EditIcon />;
        case "delete":
            return <DeleteIcon />;
        case "activationEmail":
            return <SendMailIcon />;
        case "passwordReminder":
            return <SendPassMailIcon />;
        case "repeal":
            return <DeleteRepealIcon />;
        case "view":
            return <ViewContentIcon />;
        default:
            return;
    }
}

function getFunction(type) {
    switch (type) {
        case "delete":
            return "delete";
        case "activationEmail":
            return "SendActivationMail";
        case "passwordReminder":
            return "SendPswReset";
        case "repeal":
            return "deleteRepeal";
        default:
            return "";
    }
}

/*
    Használat:
        <OperationButton
            type="gomb típusa, lehetséges verziókat lásd fent"
            successmessage="Sikeres művelet esetén megjelenő üzenet" (opcionális, default: "Sikeres törlés")
            errormessage="Sikertelen művelet esetén megjelenő üzenet" (opcionális, default: "Hiba történt a törlés során")
            submittext="Megerősítő gombon megjelenező üzenet" (opcionális, default: "Törlés")
            canceltext="Elvetés gombon megjelenező üzenet" (opcionális, default: "Mégsem")
            confirmation="Biztos, hogy végre akarja hajtani a ...-ot?" (opcionális, default: ""Biztosan törölni kívánja? A művelet nem visszavonható."")
            params={params, amit a btncellrenderer is kap}
            special (ez csak a kurzuskategóriák oldalhoz kellett, mert ott más a delete fgv. paraméterezése)
            minden további paraméter a Link-nek lesz átadva (to, onClick, stb.)
        />
*/

export default function OperationButton(props) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const Cancel = props.type === "delete" ? DeleteCancelButton : CancelButton;
    const Submit = props.type === "delete" ? DeleteButton : SubmitButton;

    return (
        <>
            <GreenTooltip title={i18next.t(`ob.${props.type}`) || ""}>
                <Link to={props.to || "#"} onClick={props.type !== "edit" ? () => setOpen(true) : ""} {...props}>
                    {getIcon(props.type)}
                </Link>
            </GreenTooltip>
            {props.type !== "edit" ? (
                <Dialog open={open} onClose={handleClose}>
                    {props.type === "delete" && (
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{ backgroundColor: colors.darkError, color: colors.white }}
                        >
                            {props.title || i18next.t("ob.delete")}
                        </DialogTitle>
                    )}
                    <DialogContent>
                        <DialogContentText>{props.confirmation || i18next.t("ob.confirmation")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Cancel
                            text={props.canceltext || i18next.t("cancel")}
                            onClick={() => {
                                handleClose();
                            }}
                        />
                        <Submit
                            text={props.submittext || i18next.t("ob.delete")}
                            onClick={() => {
                                handleClose();
                                (props.special
                                    ? props.params.context[getFunction(props.type)](
                                          props.params.data.parent,
                                          props.params.data.elementId
                                      )
                                    : props.params.context[getFunction(props.type)](props.params.data.id)
                                )
                                    .then((data) => {
                                        props.params.context.AddNotification(
                                            "success",
                                            props.successmessage || i18next.t("ob.success")
                                        );
                                        if (props.type === "delete" || props.type === "repeal")
                                            props.params.context.filter(props.params.context.searchData);
                                    })
                                    .catch((error) => {
                                        props.params.context.AddNotification(
                                            "error",
                                            props.errormessage || i18next.t("ob.error")
                                        );
                                    });
                            }}
                        />
                    </DialogActions>
                </Dialog>
            ) : (
                ""
            )}
        </>
    );
}
