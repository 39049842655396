import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import TxtField from "../Common/TxtField";
import SubmitButton from "../Common/SubmitButton";
import notificationsActions from "../../store/notifications/actions";
import loadingActions from "../../store/loading/actions";
import { connect } from "react-redux";
import CancelButton from "../Common/CancelButton";
import Autocomplete from "../Common/Autocomplete";
import userActions from '../../store/users/actions';
import API from "../../utils/API";
import loginActions from "../../store/login/actions";
import i18next from "i18next";
import { setImpersonationToken } from "../../utils/AuthHelper";
import useDebounce from "../../hooks/useDebounce";

function Impersonation(props) {
    const [shorter, setShorter] = React.useState(true);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        setSelectedUser(null);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        props.loadingOn();
        props.postImpersonation(selectedUser.key).then((response) => {
            setImpersonationToken(response.data.impersonation);

            API.post(`/auth/me`).then((data) => {
                props.setUser(data.data);

                props.addNotification("success", i18next.t("impersonation.success", { name: data.data.name }));
                props.changeOpenFn(false);
                window.location.href = '/';
                //props.loadingOff();
            });

        });
    };

    const getUsers = useDebounce((p) => {
        props.usersSearch({
            nameOrEmail: p,
            withPermissions: 1,
            withTrashed: 0,
            onlyDontBanned: 1,
            sort: 'last_name'
        }).then((r) => setUsers(r.data));
    }, 500);

    const userOptions = users.map((object) => {
        return {
            value: object,
            name: object.name + " / " + object.email,
            key: object.id,
        };
    });

    return (
        <Dialog open={props.open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                {i18next.t("impersonation")}
            </DialogTitle>
            <form onSubmit={onSubmit} noValidate>
                <DialogContent>
                    <DialogContentText>{i18next.t("impersonation.description")}</DialogContentText>

                    <Autocomplete
                        id="user"
                        value={selectedUser}
                        options={userOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.key === value.key}
                        filterSelectedOptions
                        fullWidth={true}
                        loading={props.loadingImpersonation}
                        loadingText={i18next.t("permissions.loading")}
                        noOptionsText={i18next.t("permissions.nooption")}
                        freeSolo={shorter}
                        onInputChange={(event, val, reason) => {
                            setShorter(val.length < 2);
                            if (reason === "clear") setSelectedUser(null);
                            if (val.length < 2) setUsers([]);
                            if (val.length > 1) getUsers(val);
                        }}
                        onChange={(event, values) => {
                            if (values)
                                setSelectedUser({
                                    value: values.value,
                                    name: values.value.name + " / " + values.value.email,
                                    key: values.value.id,
                                });
                        }}
                        renderInput={(params) => (
                            <TxtField
                                {...params}
                                placeholder={i18next.t("permissions.placeholder")}
                                variant="outlined"
                                label={i18next.t("permissions.users")}
                                margin="normal"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("logging.login")} type="submit" />
                    <CancelButton
                        text={i18next.t("cancel")}
                        onClick={() => {
                            props.closeAction();
                        }}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState(state) {
    const loadingImpersonation = state.users.loadingImpersonation;
    return { loadingImpersonation };
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
    usersSearch: userActions.searchImpersonation,
    postImpersonation: userActions.postImpersonationAction,
    setUser: loginActions.setUser,

    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Impersonation);
