import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TxtField from "../../Common/TxtField";
import Header from "../../Common/Header";
import CourseCategoriesTable from "./CourseCategoriesTable";
import elearningCourseCategories from "../../../store/elearningCourseCategories/actions.js";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import Filter from "../../Common/Filter";
import i18next from "i18next";

function CourseCategoriesList(props) {
    const { loading, data, search, user } = props;

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    const handleSubmit = (event) => {
        event.preventDefault();
        props.filter(search);
    };

    useEffect(() => {
        props.filter(search);
    }, []);

    const filtForm = (
        <>
            <Grid item xs={12} md={6}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label={i18next.t("course_categories.name")}
                    value={search.name || ""}
                    name="name"
                    onChange={(event) => handleChange(event)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="shortName"
                    label={i18next.t("course_categories.short_name")}
                    value={search.shortName || ""}
                    name="shortName"
                    onChange={(event) => handleChange(event)}
                />
            </Grid>
        </>
    );

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.course_categories")}
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    course_categories: i18next.t("sidebar.course_categories"),
                }}
            />
            <Filter filterForm={filtForm} defaultForm={props.defaultForm} onSubmit={handleSubmit} />
            <CourseCategoriesTable
                data={data}
                user={user}
                search={search}
                filter={props.filter}
                searchData={props.search}
                gridApi={gridApi}
                onGridReady={onGridReady}
            />
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data } = state.elearningCourseCategories;
    const { user } = state.authentication;
    return { loading, search, data, user };
}

const actionCreators = {
    filter: elearningCourseCategories.filter,
    changeForm: elearningCourseCategories.changeForm,
    defaultForm: elearningCourseCategories.defaultForm,
};

export default connect(mapState, actionCreators)(CourseCategoriesList);
