import { types } from "./types";
import { history } from "../index";

function addNotification(type, msg, redirect, options) {
    return (dispatch) => {
        const data = { type, msg, options };
        if (redirect) history.push(redirect);
        dispatch({ type: types.ADD_NOTIFICATION, data });
    };
}

function removeNotification() {
    return (dispatch) => dispatch({ type: types.REMOVE_NOTIFICATION });
}

function addError(type, msg) {
    const data = { [type]: msg };
    return (dispatch) => dispatch({ type: types.ADD_ERROR, data });
}

function removeError(array, name) {
    let data = [];
    for (let a of array) {
        for (const [key, value] of Object.entries(a)) {
            if (key !== name) data.push({ [key]: value });
        }
    }
    return (dispatch) => dispatch({ type: types.REMOVE_ERROR, data });
}

function clearErrors() {
    return (dispatch) => dispatch({ type: types.CLEAR_ERRORS });
}

const actions = {
    addNotification,
    removeNotification,
    addError,
    removeError,
    clearErrors,
};

export default actions;
