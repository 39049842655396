import { types } from "./types";
import translationsService from "../../services/translations";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultForm() {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM });
}

function filter(form) {
    return (dispatch) => {
        dispatch(request({ form }));

        return translationsService.filter(form).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.FETCH_REQUEST, data };
    }
    function success(data) {
        return { type: types.FETCH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FETCH_FAILURE, error };
    }
}

function patch(form, id) {
    return (dispatch) => {
        dispatch(request({ form }));

        return translationsService.patch(form, id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.PATCH_REQUEST, data };
    }
    function success(data) {
        return { type: types.FETCH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FETCH_FAILURE, error };
    }
}

const actions = {
    filter,
    patch,
    changeForm,
    defaultForm,
};

export default actions;
