import React from "react";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import AccordionSum from "../../Common/AccordionSum";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./Partials/Form";
import i18next from "i18next";

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block",
    },
}));

function New(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    contents: i18next.t("sidebar.contents"),
                    "/news": i18next.t("sidebar.news"),
                    "news-new": i18next.t("news.create"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text="Új bejegyzés" />
                    <AccordionDetails className={classes.accordionDetails}>
                        <Form />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default New;
