import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TxtField from "../../Common/TxtField";
import InputAdornment from "@material-ui/core/InputAdornment";
import colors from "../../../styles/colors.scss";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import Header from "../../Common/Header";
import LinkToButton from "../../Common/LinkToButton";
import { connect } from "react-redux";
import settingsActions from "../../../store/settings/actions.js";
import notificationsActions from "../../../store/notifications/actions.js";
import Loading from "../../Common/Loading";
import i18next from "i18next";
import Validator from "../../../utils/Validator";
import validator from "../../../store/validator/actions";
import userActions from "../../../store/users/actions";
import GreenOutlineSelect from "../../Common/GreenOutlineSelect";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    submit: {
        margin: theme.spacing(3, 3, 0, 0),
    },
}));

function SettingsEdit(props) {
    const { settingId } = props.match.params;
    const classes = useStyles();
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const { loading, row, formError, user } = props;
    const validator = new Validator(row, props.validator.validatorErorrs, props.validator.rules);

    useEffect(() => {
        props.setValidatorErrors({});
        props
            .get(settingId)
            .then((d) => {
                props.clearErrors();
                /*
                d.data.rules.split("|").find((e) => {
                    if (e.startsWith("min")) setMin(parseInt(e.split(":")[1]));
                    else if (e.startsWith("max")) setMax(parseInt(e.split(":")[1]));
                    return null;
                });
                 */
            })
            .catch((error) => {});
    }, [settingId]);

    const handleChange = (e) => {
        if (row.type === "number" && isNaN(e.target.value)) return;
        props.changeForm("row", { ...row, [e.target.name]: e.target.value });
        //functions[row.type](e.target.value);
    };

    const functions = {
        number: (val) => {
            if (!isNaN(val)) {
                let num = parseInt(val);
                (min && num < min) || (max && num > max)
                    ? props.addError("value", i18next.t("validation.between.numeric", { min: min, max: max }))
                    : props.removeError(props.formError, "value");
            }
        },
        email: (val) => {
            const re =
                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            re.test(val)
                ? props.removeError(props.formError, "value")
                : props.addError("value", i18next.t("validation.email"));
        },
        text: (val) => {
            let length = val ? parseInt(val.length) : 0;
            (min && length < min) || (max && length > max)
                ? props.addError("value", i18next.t("validation.between.string", { min: min, max: max }))
                : props.removeError(props.formError, "value");
        },
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        functions[row.type](row.value);

        if (formError.length === 0) {
            props
                .post(row.value, settingId)
                .then(() => {
                    props.addNotification("success", i18next.t("settings.form.success"), "/settings");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("settings.form.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                });
        }
    };
    if (loading) return <Loading />;
    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    title={i18next.t("settings.edit")}
                    skeleton={loading}
                    breadcrumbs={{
                        administration: i18next.t("sidebar.administration"),
                        "/settings": i18next.t("sidebar.settings"),
                        "edit-settings": i18next.t("settings.edit"),
                    }}
                />
                <Grid item container xs={12} md={6}>
                    <Card>
                        <CardHeader title={row.label[user.language]} className={classes.header} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate={true}>
                                <Grid item xs={12}>
                                    <TxtField
                                        required={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        fullWidth
                                        maxLength={5}
                                        variant="outlined"
                                        name="value"
                                        value={row.value}
                                        error={validator.hasError("value")}
                                        helperText={validator.getErrorText("value")}
                                        onChange={(event) => handleChange(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {row.unit ? row.unit : ""}
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            textOverflow: "ellipsis",
                                        }}
                                    />
                                </Grid>
                                <Grid item container justify="flex-start" xs={12} wrap="nowrap">
                                    <Grid item>
                                        <SubmitButton
                                            type="submit"
                                            variant="contained"
                                            className={classes.submit}
                                            text={i18next.t("save")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LinkToButton to="/settings">
                                            <CancelButton
                                                variant="outlined"
                                                className={classes.submit}
                                                text={i18next.t("cancel")}
                                            />
                                        </LinkToButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, row } = state.settings;
    const { formError } = state.notifications;
    const { user } = state.authentication;
    const validator = state.validator;
    return { loading, row, formError, user, validator };
}

const actionCreators = {
    get: settingsActions.get,
    post: settingsActions.post,
    changeForm: settingsActions.changeForm,
    addNotification: notificationsActions.addNotification,
    removeNotification: notificationsActions.removeNotification,
    addError: notificationsActions.addError,
    removeError: notificationsActions.removeError,
    clearErrors: notificationsActions.clearErrors,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(SettingsEdit);
