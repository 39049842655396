import React, { Component } from "react";
import { connect } from "react-redux";
import TxtField from "../../Common/TxtField";
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import LinkToButton from "../../Common/LinkToButton";
import Validator from "../../../utils/Validator";
import DateTimePicker from "../../Common/DateTimePicker";
import i18next from "i18next";
import BackendAutocomplete from "../../Common/BackendAutocomplete";
import { convertDateToHyphenFormat } from "../../../utils/AppConst";
import validator from "../../../store/validator/actions";
import GreenOutlineSelect from "../../Common/GreenOutlineSelect";

class CourseManagementEditForm extends Component {
    constructor(props) {
        super(props);
        let exams = [{ label: "Nincs", value: "" }].concat(
            props.exams.map((item) => ({
                label: item.exam,
                value: item.id,
            }))
        );

        this.state = {
            category: props.category,
            courseName: props.courseName,
            courseShortName: props.courseShortName,
            startDate: props.startDate,
            endDate: props.endDate,
            show: props.show,
            isTemplate: props.isTemplate,
            courseSubjectManagers: props.courseSubjectManagers,
            isSampleCategory: props.isSampleCategory,
            courseType: props.courseType,
            grantContractNumber: props.grantContractNumber,
            exams: exams,
            sampleExam: props.sampleExam,
            sampleExamName: props.sampleExamName,
            normalExam: props.normalExam,
            subCoursesCount: props.subCoursesCount,
            openDialog: false,
            openDialogMessage: "",
        };
    }

    onCategoryChange() {
        this.props.changeCategory(this.state.category);
    }

    onShowChange() {
        this.props.changeShow(this.state.show);
    }

    handleShowChange(event) {
        this.setState(
            {
                show: event.target.value,
            },
            () => {
                this.onShowChange();
            }
        );
    }

    handleCategoryChange(event) {
        this.setState(
            {
                category: event.target.value,
            },
            () => {
                this.onCategoryChange();
            }
        );
    }

    onCourseNameChange() {
        this.props.changeCourseName(this.state.courseName);
    }

    handleCourseNameChange(event) {
        this.setState(
            {
                courseName: event.target.value,
            },
            () => {
                this.onCourseNameChange();
            }
        );
    }

    onCourseShortNameChange() {
        this.props.changeCourseShortName(this.state.courseShortName);
    }

    handleCourseShortNameChange(event) {
        this.setState(
            {
                courseShortName: event.target.value,
            },
            () => {
                this.onCourseShortNameChange();
            }
        );
    }

    handleUsersChange = (_, values) => {
        this.setState(
            {
                courseSubjectManagers: values,
            },
            () => {
                this.props.onChange("courseSubjectManagers", values);
            }
        );
    };

    onStartDateChange() {
        this.props.changeStartDate(this.state.startDate);
    }

    handleStartDateNameChange(date) {
        let newDate = convertDateToHyphenFormat(date, true);
        this.setState(
            {
                startDate: newDate,
            },
            () => {
                this.onStartDateChange();
            }
        );
    }

    onEndDateChange() {
        this.props.changeEndDate(this.state.endDate);
    }

    handleEndDateNameChange(date) {
        let newDate = convertDateToHyphenFormat(date, true);
        this.setState(
            {
                endDate: newDate,
            },
            () => {
                this.onEndDateChange();
            }
        );
    }

    handleTypeChange(event) {
        this.setState(
            {
                courseType: event.target.value,
            },
            () => {
                this.props.changeType(this.state.courseType);
            }
        );
    }

    handleSampleExamChange(event) {
        this.setState(
            {
                sampleExam: event.target.value,
            },
            () => {
                this.props.changeSampleExam(this.state.sampleExam);
            }
        );
    }

    handleNormalExamChange(event) {
        this.setState(
            {
                normalExam: event.target.value,
            },
            () => {
                this.props.changeNormalExam(this.state.normalExam);
            }
        );
    }

    handleSampleNameChange(event) {
        this.setState(
            {
                sampleExamName: event.target.value,
            },
            () => {
                this.props.changeSampleExamName(this.state.sampleExamName);
            }
        );
    }

    handleGrantContractNumberChange(event) {
        this.setState(
            {
                grantContractNumber: event.target.value,
            },
            () => {
                this.props.changeGrantContractNumber(this.state.grantContractNumber);
            }
        );
    }

    render() {
        const kamgyakType = "kamgyak";

        const validator = new Validator({}, this.props.validator.validatorErorrs, {
            fullName: "required|max:256",
            shortName: "required|max:128",
            grantContractNumber: "required",
            normalExam: "required",
        });
        const typeOptions = [
            //    { value: "Chamber", label: i18next.t("logging.Chamber"), key: 0 }, // FIXME, nyelvesíteni ezeket:
            { value: "-", label: "Nincs", key: 0 },
            { value: kamgyakType, label: "Kamgyak", key: 1 },
        ];

        return (
            <form onSubmit={this.props.handleSubmit} noValidate={true}>
                <Grid item container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TxtField
                            label={i18next.t("course_management.name")}
                            variant="outlined"
                            id="course-name"
                            name="course-name"
                            value={this.state.courseName}
                            required={validator.isRequired("fullName")}
                            error={validator.hasError("fullName")}
                            helperText={validator.getErrorText("fullName")}
                            onChange={(event) => this.handleCourseNameChange(event)}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TxtField
                            label={i18next.t("course_management.short_name")}
                            variant="outlined"
                            margin="normal"
                            id="course-shortname"
                            name="course-shortname"
                            value={this.state.courseShortName}
                            required={validator.isRequired("shortName")}
                            error={validator.hasError("shortName")}
                            helperText={validator.getErrorText("shortName")}
                            onChange={(event) => this.handleCourseShortNameChange(event)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TxtField
                            label={i18next.t("course_management.category")}
                            variant="outlined"
                            margin="normal"
                            id="category"
                            name="category"
                            value={this.state.category}
                            fullWidth
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateTimePicker
                            required={validator.isRequired("startDate")}
                            error={validator.hasError("startDate")}
                            helperText={validator.getErrorText("startDate")}
                            onChange={(date) => this.handleStartDateNameChange(date)}
                            value={this.state.startDate || ""}
                            id="courseEndUntil"
                            label={i18next.t("course_management.start")}
                            name="courseEndUntil"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateTimePicker
                            required={validator.isRequired("endDate")}
                            error={validator.hasError("endDate")}
                            helperText={validator.getErrorText("endDate")}
                            onChange={(date) => this.handleEndDateNameChange(date)}
                            value={this.state.endDate || ""}
                            id="course-endDate"
                            label={i18next.t("course_management.end")}
                            name="course-endDate"
                            margin="normal"
                        />
                    </Grid>
                    {this.state.isTemplate && (
                        <Grid item xs={12} md={3}>
                            <BackendAutocomplete
                                multiple
                                label={i18next.t("course_management.course_subject_managers")}
                                value={this.state.courseSubjectManagers}
                                onChange={this.handleUsersChange}
                                url="users/search"
                                error={validator.hasError("courseSubjectManagers")}
                                helperText={validator.getErrorText("courseSubjectManagers")}
                                paramsKey="nameOrEmail"
                                itemConverter={(item) => ({ value: item.id, label: item.name + " / " + item.email })}
                            />
                        </Grid>
                    )}
                    <Grid item container xs={12} spacing={2}>
                        {this.state.isSampleCategory && (
                            <Grid item xs={6} sm={4} md={2}>
                                <GreenOutlineSelect
                                    id="courseType"
                                    selectLabel={i18next.t("course_management.type")}
                                    name="courseType"
                                    value={this.state.courseType || "-"}
                                    fullWidth
                                    shrink={true}
                                    optionList={typeOptions}
                                    onChange={(event) => this.handleTypeChange(event)}
                                    disabled={this.state.subCoursesCount > 0}
                                    onClick={
                                        this.state.subCoursesCount > 0
                                            ? () => {
                                                  this.setState({
                                                      openDialog: true,
                                                      openDialogMessage: "type_disabled_message",
                                                  });
                                              }
                                            : null
                                    }
                                />
                            </Grid>
                        )}
                        {this.state.isSampleCategory && this.state.courseType === kamgyakType && (
                            <Grid item sm={4} md={2}>
                                <TxtField
                                    label={i18next.t("course_management.grant_contract_number")}
                                    variant="outlined"
                                    margin="normal"
                                    id="grant_contract_number"
                                    name="grant_contract_number"
                                    value={this.state.grantContractNumber}
                                    disabled={this.state.subCoursesCount > 0}
                                    fullWidth
                                    onChange={(event) => this.handleGrantContractNumberChange(event)}
                                    required={validator.isRequired("grantContractNumber")}
                                    error={validator.hasError("grantContractNumber")}
                                    helperText={validator.getErrorText("grantContractNumber")}
                                    onClick={
                                        this.state.subCoursesCount > 0
                                            ? () => {
                                                  this.setState({
                                                      openDialog: true,
                                                      openDialogMessage: "grant_contract_number_disabled_message",
                                                  });
                                              }
                                            : null
                                    }
                                />
                            </Grid>
                        )}
                        {this.state.isSampleCategory && this.state.courseType === kamgyakType && (
                            <Grid item sm={4} md={3}>
                                <GreenOutlineSelect
                                    selectLabel={i18next.t("course_management.sample_exam")}
                                    id="sample_exam"
                                    name="sample_exam"
                                    value={this.state.sampleExam || ""}
                                    fullWidth
                                    shrink={true}
                                    optionList={this.state.exams}
                                    onChange={(event) => this.handleSampleExamChange(event)}
                                    required={validator.isRequired("sampleExam")}
                                    error={validator.hasError("sampleExam")}
                                    helperText={validator.getErrorText("sampleExam")}
                                />
                            </Grid>
                        )}
                        {this.state.isSampleCategory && this.state.courseType === kamgyakType && (
                            <Grid item sm={4} md={2}>
                                <TxtField
                                    label={i18next.t("course_management.sample_exam_name")}
                                    variant="outlined"
                                    margin="normal"
                                    id="sample_exam_name"
                                    name="sample_exam_name"
                                    value={!this.state.sampleExam ? "" : this.state.sampleExamName}
                                    fullWidth
                                    onChange={(event) => this.handleSampleNameChange(event)}
                                    required={validator.isRequired("sampleExamName")}
                                    error={validator.hasError("sampleExamName")}
                                    helperText={validator.getErrorText("sampleExamName")}
                                    disabled={this.state.sampleExam ? false : true}
                                />
                            </Grid>
                        )}

                        {this.state.isSampleCategory && this.state.courseType === kamgyakType && (
                            <Grid item sm={4} md={3}>
                                <GreenOutlineSelect
                                    selectLabel={i18next.t("course_management.normal_exam")}
                                    id="normal_exam"
                                    name="normal_exam"
                                    value={this.state.normalExam || ""}
                                    fullWidth
                                    shrink={true}
                                    optionList={this.state.exams}
                                    onChange={(event) => this.handleNormalExamChange(event)}
                                    required={validator.isRequired("normalExam")}
                                    error={validator.hasError("normalExam")}
                                    helperText={validator.getErrorText("normalExam")}
                                />
                            </Grid>
                        )}

                        <Grid container spacing={24} style={{ marginTop: "15px" }}>
                            <Grid item style={{ marginLeft: "4px" }}>
                                <SubmitButton text={i18next.t("save")} type="submit" />
                            </Grid>
                            <Grid item style={{ marginLeft: "10px" }}>
                                <LinkToButton to="/e-learning/course-management">
                                    <CancelButton variant="outlined" text={i18next.t("cancel")} />
                                </LinkToButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.openDialog}
                    onClose={() => {
                        this.setState({
                            openDialog: false,
                        });
                    }}
                >
                    <DialogContent>
                        <DialogContentText>
                            {i18next.t("course_management." + this.state.openDialogMessage)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton
                            text={i18next.t("OK")}
                            onClick={() => {
                                this.setState({
                                    openDialog: false,
                                });
                            }}
                        />
                    </DialogActions>
                </Dialog>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        validator: state.validator,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setValidatorErrors: (data) => dispatch(validator.setErrors(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementEditForm);
