import React from "react";
import {Box, CardContent} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import i18next from "i18next";

export default function KamgyakWarningMessage(props) {
    const type = props.type ?? 'course';
    if(!props.course || !props.course.isKamgyakType){
        return <React.Fragment></React.Fragment>
    }

    return (
        <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Alert severity="warning">{i18next.t("elearning." + type + "_enrollment_dismissal.kamgyak_type.warning")}</Alert>
            </Box>
        </CardContent>
    );
}
