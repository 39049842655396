import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubmitButton from "../../Common/SubmitButton";
import { Box } from "@material-ui/core";
import i18next from "i18next";

export default function EmailLogAlert(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box
                component="span"
                bgcolor="secondary.main"
                color="white"
                p={1}
                variant="outlined"
                onClick={handleClickOpen}
            >
                Hibás
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="paper"
            >
                <DialogTitle id="alert-dialog-title">{i18next.t("error_description")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.info}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("back")} onClick={handleClose} />
                </DialogActions>
            </Dialog>
        </div>
    );
}
