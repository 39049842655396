import { types } from "./types";
import { fetchCategorizedCourses, fetchUsers, postEnrollment, postUnenrollment, saveSelectedUser } from "../../services/enrollment";

export function fetchCategorizedCoursesAction(type, hideEmptyCategories) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_CATEGORIZED_COURSES_REQUEST });
        return fetchCategorizedCourses(type, hideEmptyCategories).then(
            (response) => {
                const data = response.data;
                dispatch({ type: types.FETCH_CATEGORIZED_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.FETCH_CATEGORIZED_COURSES_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function fetchUsersAction(type, courseId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USER_REQUEST });
        return fetchUsers(type, courseId).then(
            (data) => {
                dispatch({ type: types.FETCH_USER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.FETCH_USER_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

export function fetchNextUsersAction(type, courseId, actualCount, search, enrolled) {
    return (dispatch) => {
        const token = Date.now();
        dispatch({ type: types.FETCH_NEXT_PAGE_USER_REQUEST, token });
        return fetchUsers(type, courseId, actualCount, search, enrolled, token).then(
            (data) => {
                dispatch({ type: types.FETCH_NEXT_PAGE_USER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.FETCH_NEXT_PAGE_USER_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

export function selectCourseAction(course) {
    return (dispatch) => {
        dispatch({ type: types.SELECTED_COURSE, course });
    };
}
export function addSelectedUserAction(user) {
    return (dispatch) => {
        dispatch({ type: types.ADD_SELECTED_USER, user });
    };
}
export function delSelectedUserAction(user) {
    return (dispatch) => {
        dispatch({ type: types.DEL_SELECTED_USER, user });
    };
}
export function clearSelectedUserAction() {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_SELECTED_USER });
    };
}

export function enrollmentAction(form) {
    return (dispatch) => {
        dispatch({ type: types.POST_ENROLLMENT_REQUEST });
        return postEnrollment(form).then(
            (data) => {
                dispatch({ type: types.POST_ENROLLMENT_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.POST_ENROLLMENT_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function saveSelectedUserAction(type, courseId, userId, selected, search, enrolled) {
    return (dispatch) => {
        dispatch({ type: types.POST_SAVE_SELECTED_USER_REQUEST });
        return saveSelectedUser(type, courseId, userId, selected, search, enrolled).then(
            (data) => {
                dispatch({ type: types.POST_SAVE_SELECTED_USER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.POST_SAVE_SELECTED_USER_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function unenrollmentAction(form) {
    return (dispatch) => {
        dispatch({ type: types.POST_UNENROLLMENT_REQUEST });
        return postUnenrollment(form).then(
            (data) => {
                dispatch({ type: types.POST_UNENROLLMENT_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.POST_UNENROLLMENT_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
