import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import "./CourseCategoriesTable.scss";
import { connect } from "react-redux";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import elearningCourseCategories from "../../../store/elearningCourseCategories/actions";
import notificationsActions from "../../../store/notifications/actions";
import Table from "../../Common/Table";
import LinkToButton from "../../Common/LinkToButton";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";

const btnRendererStyle = makeStyles((theme) => ({
    noPadding: {
        padding: "unset",
    },
    button: {
        padding: theme.spacing(2),
    },
}));

function CategoryCellRenderer(params) {
    const classes = btnRendererStyle();

    if (params.data.name !== "Archivált kurzusok") {
        return (
            <LinkToButton to={"/e-learning/course-categories/" + params.data.elementId + "/new"}>
                <SubmitButton text={i18next.t("course_categories.new.sub")} className={classes.button} />
            </LinkToButton>
        );
    }
    
    return <></>;
}

function SubCategoryCellRenderer(params) {
    const classes = btnRendererStyle();

    return (
        <>
            <OperationButton
                to={"/e-learning/course-categories/" + params.data.parent + "/edit/" + params.data.elementId}
                className={classes.noPadding}
                type="edit"
            />
            <Box component="span" marginX={1} />
            {params.data.coursesCount == 0 ? (
                <OperationButton
                    className={classes.deleteButton}
                    type="delete"
                    confirmation={i18next.t("course_categories.confirmation")}
                    successmessage={i18next.t("course_categories.success")}
                    params={params}
                    special={`true`}
                />
            ) : (
                ""
            )}
        </>
    );
}

function CourseCategoriesTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("course_categories.name"),
                field: "name",
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("course_categories.short_name"),
                field: "id",
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("table.operations"),
                field: "ops", //más név kellett neki a formázás miatt
                cellRendererSelector: function (params) {
                    var categoryRow = {
                        component: "categoryCellRenderer",
                    };

                    var subcategoryRow = {
                        component: "subCategoryCellRenderer",
                    };

                    return params.data.type === "category" ? categoryRow : subcategoryRow;
                },
                sortable: false,
                flex: 0.5,
            },
        ],
        rowClassRules: {
            category: function (params) {
                return params.data.type === "category";
            },
            subcategory: function (params) {
                return params.data.type === "subcategory";
            },
        },
        frameworkComponents: {
            subCategoryCellRenderer: SubCategoryCellRenderer,
            categoryCellRenderer: CategoryCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            rowClassRules={state.rowClassRules}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            showPagination={false}
            context={{
                delete: props.delete,
                filter: props.filter,
                searchData: props.searchData,
                AddNotification: props.addNotification,
            }}
            sortable={false}
            minWidth={200}
        />
    );
}

function mapState(state) {
    //const { loading, search, data } = state.chambers;
    const { user } = state.authentication;
    //return { loading, search, data, user };
    return { user };
}

const actionCreators = {
    delete: elearningCourseCategories.deleteRow,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(CourseCategoriesTable);
