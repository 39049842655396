import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import TxtField from "../../Common/TxtField";
import newsActions from "../../../store/news/actions";
import { connect } from "react-redux";
import Validator from "../../../utils/Validator";
import colors from "../../../styles/colors.scss";
import SubmitButton from "../../Common/SubmitButton";
import newsService from "../../../services/news";
import InfoBox from "../../Common/InfoBox";
import i18next from "i18next";
import CancelButton from "../../Common/CancelButton";
import validator from "../../../store/validator/actions";
import notificationsActions from "../../../store/notifications/actions";
import Loading from '../../Common/Loading';
import CloseIcon from "@material-ui/icons/Close";

function SendOutNews(props) {
    const MAX_DURATION = 120000; // 2 minutes in milliseconds
    const INTERVAL = 2000; // 2 seconds in milliseconds

    const { sendOutNewsForm, loading } = props;
    const newsId = props.newsId ? props.newsId : null;
    const validator = new Validator(sendOutNewsForm, props.validator.validatorErorrs, props.validator.rules);

    const [filteredUserCount, setFilteredUserCount] = React.useState(0);
    const [allUserCount, setAllUserCount] = React.useState(0);
    const [types, setTypes] = React.useState([]);
    const [prevSentOut, setPrevSentOut] = React.useState([]);
    const [sendOutNewsLoading, setSendOutNewsLoading] = React.useState(false);
    const [isFiltering, setIsFiltering] = React.useState(false);

    const sendFilterLastLoginDayRequest = () => {
        newsService
            .filterLastLoginDay(newsId, sendOutNewsForm.lastLoginDayFilter)
            .then((res) => {
                setFilteredUserCount(res?.data?.allUserCount ?? 0);
                setAllUserCount(res?.data?.allUserCount ?? 0);
                setTypes(res?.data?.types ?? []);
                setPrevSentOut(res?.data?.prevSentOut ?? []);
            })
            .catch((error) => {
                props.addNotification("error", i18next.t("news.modal.error"));
            });
    }
    // initialize form
    useEffect(() => {
        if (props.open) {
            props.createSendOutNewsForm();
            props.setValidatorErrors({});
            props.setValidatorRules({
                lastLoginDayFilter: "nullable|integer|min:1",
            });
            // get all users count and user type
            sendFilterLastLoginDayRequest();
        }
    }, [props.open]);

    const getNotifyRequest = (intervalId, processId) => {
        return newsService.checkNotify(newsId, processId)
          .then((res) => {
            if(res.data.status === "success") {
                setSendOutNewsLoading(false);
                clearInterval(intervalId);
                props.addNotification("success", i18next.t("news.modal.success_sent"));

                props.handleClose();
                setIsFiltering(false);
            }
            if(res.data.status === "error") {
                setSendOutNewsLoading(false);
                clearInterval(intervalId);
                props.addNotification("error", i18next.t("news.modal.error"));
            }
          })
          .catch((error) => {
            setSendOutNewsLoading(false);
            props.addNotification("error", i18next.t("news.modal.error"));
            console.error("Error:", error);
          });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});
        setSendOutNewsLoading(true);

        newsService.sendNotify(newsId, isFiltering ? sendOutNewsForm.lastLoginDayFilter : null, isFiltering ? filteredUserCount : allUserCount).then((res) => {
            let elapsedTime = 0;
            const intervalId = setInterval(async () => {
                // Check if the elapsed time exceeds the maximum duration
                if (elapsedTime >= MAX_DURATION) {
                  clearInterval(intervalId);
                  i18next.t("news.modal.timeout");
                } else {
                  elapsedTime += INTERVAL;

                  await getNotifyRequest(intervalId, res?.data?.processId ?? 0);
                }
            }, INTERVAL);
        })
        .catch((error) => {
            props.addNotification("error", i18next.t("news.modal.error"));
            validator.setReponseError(error);
            props.setValidatorErrors(validator.getErrors());
            setSendOutNewsLoading(false);
        });
    }

    const handleChange = (event) => {
        const value = event.target.value;
        if (isNaN(value)) {
            return;
        }

        props.changeSendOutNewsForm("sendOutNewsForm", {
            ...sendOutNewsForm,
            lastLoginDayFilter: value,
        });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    return (
        <Dialog open={props.open} onClose={() => {
            props.handleClose(); 
            setIsFiltering(false);
        }}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                {i18next.t("news.modal.title")}
            </DialogTitle>
            <form onSubmit={handleSubmit} noValidate={true}>
                <DialogContent>
                    <Grid item xs={12} container style={{ gap: "15px" }}>
                        <Typography style={{ fontWeight: "bold" }}>{i18next.t("news.modal.filter_text")}</Typography>
                        <Typography>{i18next.t("news.modal.filter_info")}</Typography>
                        <Grid container spacing={2} xs={12} alignItems="center">
                            <Grid item xs={6} md={4} >
                                <TxtField
                                    required={validator.isRequired("lastLoginDayFilter")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={sendOutNewsForm.lastLoginDayFilter}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(event) => handleChange(event)}
                                    onBlur={(event) => handleBlur(event)}
                                    label={i18next.t("news.modal.filter_label")}
                                    id="lastLoginDayFilter"
                                    name="lastLoginDayFilter"
                                    error={validator.hasError("lastLoginDayFilter")}
                                    helperText={validator.getErrorText("lastLoginDayFilter")}
                                />
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <SubmitButton
                                    fullWidth
                                    variant="contained"
                                    text={i18next.t("news.modal.filter")}
                                    onClick={() => newsService.filterLastLoginDay(newsId, sendOutNewsForm.lastLoginDayFilter).then((res) => {
                                        setFilteredUserCount(res?.data?.filteredUserCount ?? 0);
                                        setAllUserCount(res?.data?.allUserCount ?? 0);
                                        setIsFiltering(true);
                                    })
                                    .catch((error) => {
                                        props.addNotification("error", i18next.t("news.modal.error"));
                                        validator.setReponseError(error);
                                        props.setValidatorErrors(validator.getErrors());
                                    })
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <CancelButton
                                    text={i18next.t("news.modal.filter.default")}
                                    onClick={() => {
                                        props.defaultSendOutNewsForm();
                                        sendFilterLastLoginDayRequest();
                                        setIsFiltering(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: "bold" }}>{i18next.t("news.modal.recipients")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {types.includes("isziir") && types.includes("normal")
                                    ? i18next.t("news.modal.isziir_and_normal")
                                    : types.includes("isziir")
                                    ? i18next.t("news.modal.isziir")
                                    : i18next.t("news.modal.normal")}
                                {` (${filteredUserCount}/${allUserCount})`}
                            </Typography>
                            {validator.hasError("filteredUserCount") && <Typography style={{marginTop: "5px", color: "red"}}>{i18next.t("news.modal.user_count_error")}</Typography>}
                        </Grid>
                        <InfoBox>
                            <div style={{maxHeight: 300, overflowY: "auto" }}>
                                
                                {prevSentOut.length === 0 ? <Typography style={{fontSize: "14px"}}>{i18next.t("news.modal.not_sent_out")}</Typography> :
                                <>
                                    <Typography style={{fontSize: "14px"}}>{i18next.t("news.modal.previously_sent_out")}</Typography>
                                    <List sx={{ width: '100%', maxWidth: 360}}>
                                    {prevSentOut.map((prev, index) => (
                                    <ListItem
                                        key={index}
                                        disableGutters
                                        style={{paddingTop: "4px", paddingBottom: "4px"}}
                                    >
                                        <ListItemText primary={
                                            <Typography style={{fontSize: "14px"}}>
                                                {`${prev?.createdAt ?? i18next.t("news.modal.date")} (${prev?.userCount ?? 0} ${i18next.t("news.modal.recipient")})`}
                                            </Typography>} 
                                        />
                                    </ListItem>
                                    ))}
                                    </List>
                                </>
                                }
                            </div>
                        </InfoBox>
                        {loading || (sendOutNewsLoading && <Loading />)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("news.modal.send_out")} type="submit" disabled={props.loading} />
                    <CancelButton
                        text={i18next.t("cancel")}
                        onClick={() => {
                            props.defaultSendOutNewsForm();
                            props.handleClose();
                            setIsFiltering(false);
                        }}
                        disabled={props.loading}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState(state) {
    const { sendOutNewsForm, loading } = state.news;
    const { user } = state.authentication;
    const validator = state.validator;
    return { sendOutNewsForm, loading, user, validator };
}

const actionCreators = {
    createSendOutNewsForm: newsActions.createSendOutNewsForm,
    changeSendOutNewsForm: newsActions.changeSendOutNewsForm,
    defaultSendOutNewsForm: newsActions.defaultSendOutNewsForm,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    setValidatorRules: validator.setRules,
};

export default connect(mapState, actionCreators)(SendOutNews);
