import { types } from "./types";

const initialState = {
    loading: false,
    password: {
        current_password: null,
        password: null,
        password_confirmation: null,
    },
};

export function changePassword(state = initialState, action) {
    switch (action.type) {
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                password: action.form,
            };
        case types.CLEAR_FORM:
            return {
                ...state,
                password: initialState.password,
            };
        default:
            return state;
    }
}
