import { Autocomplete } from "@material-ui/lab";
import i18next from "i18next";
import React from "react";
import instance from "../../utils/API";
import useDebounce from "../../hooks/useDebounce";
import TxtField from "./TxtField";

const baseItemConverter = (item) => ({ value: item.course_id || item.id, label: item.name || item.displayName });

export default function BackendAutocomplete(props) {
    const { url, error, helperText, label, required, itemConverter, paramsKey, ...rest } = props;
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");

    const fetch = useDebounce((term) => {
        if (url === "") {
            return;
        }

        setLoading(true);
        instance
            .get(url, { params: { [paramsKey || "term"]: term } })
            .then((res) => {
                setOptions(res.data.map(itemConverter || baseItemConverter));
            })
            .finally(() => {
                setLoading(false);
            });
    }, 500);

    const handleChange = (_, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length >= minLength) {
            fetch(newInputValue);
        } else {
            setOptions([]);
        }
    };

    const minLength = props.minLength ?? 3;

    return (
        <Autocomplete
            size="small"
            loading={loading}
            loadingText={i18next.t("permissions.loading")}
            inputValue={inputValue}
            options={options}
            placeholder={i18next.t("permissions.placeholder")}
            noOptionsText={
                inputValue.length < minLength
                    ? i18next.t("permissions.placeholder").replace((minLength - 1).toString(), minLength.toString())
                    : i18next.t("permissions.nooption")
            }
            onInputChange={handleChange}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TxtField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label={label}
                    required={required}
                    margin="normal"
                    variant="outlined"
                />
            )}
            {...rest}
        />
    );
}
