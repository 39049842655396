import { types } from "./types";
import elearningExamGenerationService from "../../services/elearningExamGeneration";

function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function fetchCourses() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_COURSES_REQUEST });

        return elearningExamGenerationService.fetchCourses().then(
            (data) => {
                dispatch({ type: types.FETCH_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_COURSES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchCoursesWithTemplateCourses(templateCourses) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_COURSES_REQUEST });

        return elearningExamGenerationService.fetchCoursesWithTemplateCourses(templateCourses).then(
            (data) => {
                dispatch({ type: types.FETCH_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_COURSES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchTemplateCourses() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_TEMPLATE_COURSES_REQUEST });

        return elearningExamGenerationService.fetchTemplateCourses().then(
            (data) => {
                dispatch({ type: types.FETCH_TEMPLATE_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_TEMPLATE_COURSES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchExams(courseId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_EXAMS_REQUEST });

        return elearningExamGenerationService.fetchExams(courseId).then(
            (data) => {
                dispatch({ type: types.FETCH_EXAMS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_EXAMS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function generate(form) {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_REQUEST, form });

        return elearningExamGenerationService.generate(form).then(
            (data) => {
                dispatch({ type: types.GENERATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GENERATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
const actions = {
    createForm,
    changeForm,
    fetchCourses,
    fetchExams,
    fetchCoursesWithTemplateCourses,
    fetchTemplateCourses,
    generate,
};

export default actions;
