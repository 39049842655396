import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import TxtField from "../Common/TxtField";
import SubmitButton from "../Common/SubmitButton";
import changePasswordActions from "../../store/changePassword/actions";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import Validator from "../../utils/Validator";
import validator from "../../store/validator/actions";
import API from "../../utils/API";
import { store } from "../../store";
import loginActions from "../../store/login/actions";
import CancelButton from "../Common/CancelButton";
import i18next from "i18next";

/**
 * LEJÁRT JELSZÓT KEZELI
 * Ujjelszó megadása
 */
function ChangePassword(props) {
    const [open, setOpen] = React.useState(true);
    const validator = new Validator({}, props.validatorErrors);

    useEffect(() => {
        props.clearForm();
        props.setValidatorErrors({});
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        props
            .post(props.password)
            .then((data) => {
                props.addNotification("success", "Jelszava sikeressen frissítve!");

                API.post(`/auth/me`).then((data) => {
                    store.dispatch(loginActions.setUser(data.data));
                });
                if ("function" === typeof props.changeOpenFn) props.changeOpenFn(false);
                else setOpen(false);
            })
            .catch((error) => {
                validator.setReponseError(error);
                props.setValidatorErrors(validator.getErrors());
            });
    };

    const handleChange = (e) => {
        props.changeForm({
            ...props.password,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <Dialog open={"boolean" === typeof props.open ? props.open : open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>{props.title}</DialogTitle>
            <form onSubmit={onSubmit} noValidate>
                <DialogContent>
                    {props.contentText ? <DialogContentText>{props.contentText}</DialogContentText> : null}
                    <TxtField
                        required
                        margin="normal"
                        id="current_password"
                        name="current_password"
                        label={i18next.t("change.current")}
                        variant="outlined"
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        error={validator.hasError("current_password")}
                        helperText={validator.getErrorText("current_password")}
                        FormHelperTextProps={{
                            style: {
                                overflow: "auto",
                            },
                        }}
                    />
                    <TxtField
                        required
                        margin="normal"
                        id="password"
                        name="password"
                        label={i18next.t("change.new")}
                        variant="outlined"
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        error={validator.hasError("password")}
                        helperText={validator.getErrorText("password")}
                        FormHelperTextProps={{
                            style: {
                                overflow: "auto",
                            },
                        }}
                    />
                    <TxtField
                        required
                        margin="normal"
                        id="password_confirmation"
                        name="password_confirmation"
                        variant="outlined"
                        label={i18next.t("change.new_again")}
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        error={validator.hasError("password_confirmation")}
                        helperText={validator.getErrorText("password_confirmation")}
                        FormHelperTextProps={{
                            style: {
                                overflow: "auto",
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("save")} type="submit" disabled={props.loading} />
                    {props.needCloseBtn ? (
                        <CancelButton
                            text={i18next.t("cancel")}
                            onClick={() => {
                                if ("function" === typeof props.closeAction) {
                                    props.closeAction();
                                } else {
                                    setOpen(false);
                                }
                            }}
                            disabled={props.loading}
                        />
                    ) : null}
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState(state) {
    const { loading, password } = state.changePassword;
    const validatorErrors = state.validator.validatorErorrs;
    return { loading, password, validatorErrors };
}

const actionCreators = {
    setValidatorErrors: validator.setErrors,
    addNotification: notificationsActions.addNotification,
    post: changePasswordActions.post,
    changeForm: changePasswordActions.changeForm,
    clearForm: changePasswordActions.clearForm,
};

export default connect(mapState, actionCreators)(ChangePassword);
