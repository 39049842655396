import { types } from "./types";

const initialLanguages = { hu: null, en: null };

const initialState = {
    loading: false,
    data: [],
    search: {
        name: null,
        short_name: null,
        isziir_id: null,
        active: [],
    },
    row: {
        name: initialLanguages,
        short_name: initialLanguages,
        active: true,
    },
    form: {
        name: initialLanguages,
        short_name: initialLanguages,
        isziir_id: null,
        active: true,
    },
};

export function chambers(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };
        case types.DEFAULT_ROW:
            return {
                ...state,
                row: initialState.row,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
            };

        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
