import React from "react";
import Message from "./Message";
import Grid from "@material-ui/core/Grid";
import UserTabs from "./UserTabs";
import HelmetTemplate from "../Common/HelmetTemplate";
import Notification from "./Notification";

export default function Main(props) {
    return (
        <>
            <HelmetTemplate title="Főoldal" />
            <Grid container spacing={3}>
                <Notification />
                <Grid item xs={12}>
                    <Message />
                </Grid>
                <Grid item xs={12}>
                    <UserTabs search={props.location.search} />
                </Grid>
            </Grid>
        </>
    );
}
