import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import colors from "../../../styles/colors.scss";

class SendPassMailIcon extends React.Component {
    render() {
        return (
            <Button variant="contained" style={{ backgroundColor: colors.orange, color: colors.white }}>
                <FontAwesomeIcon size="1x" icon={faEnvelopeSquare} />
            </Button>
        );
    }
}

export default SendPassMailIcon;
