import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import newsActions from "../../../../store/news/actions";
import { connect } from "react-redux";
import Table from "../../../Common/Table";
import notificationsActions from "../../../../store/notifications/actions";
import { defaultCellRenderWithTooltip } from "../../../../utils/AgGridPlussFn";
import OperationButton from "../../../Common/OperationButton";
import i18next from "i18next";
import SendOutNewsButton from "../SendOutNewsButton";

const btnRendererStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: theme.spacing(1),
    },
}));

function BtnCellRenderer(params) {
    const classes = btnRendererStyle();
    const id = params.data.id;
    const publishedAt = params.data.published_at

    return (
        <>
            <OperationButton to={`/news/edit/${id}`} type="edit" />
            <Box component="span" marginX={1} />
            <OperationButton
                className={classes.deleteButton}
                type="delete"
                successmessage={i18next.t("news.delete.success")}
                params={params}
            />
            <Box component="span" marginX={1} />
            {new Date(publishedAt) <= new Date() &&
                <SendOutNewsButton newsId={id} />
            }
        </>
    );
}

function NewsTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("news.title"),
                field: "title",
                valueGetter: (params) => {
                    if (params.data.title[props.userLang]) return params.data.title[props.userLang];

                    return "-";
                },
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("news.date"),
                field: "published_at",
                valueGetter: (params) => {
                    return new Date(params.data.published_at).toLocaleDateString();
                },
                cellRenderer: "defaultCellRenderWithTooltip",
                sort: "asc",
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };
    return (
        <Table
            rowData={props.data}
            columnDefs={state.columnDefs}
            frameworkComponents={state.frameworkComponents}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            context={{
                delete: props.deleteNews,
                filter: props.filter,
                searchData: props.search,
                AddNotification: props.addNotification,
            }}
            newButtonText={i18next.t("news.create")}
            to="/news/create"
            minWidth={250}
        />
    );
}

function mapStateToProps(state) {
    return {
        search: state.news.search,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        deleteNews: (id) => dispatch(newsActions.deleteRow(id)),
        filter: (search) => dispatch(newsActions.filter(search)),
        addNotification: (type, message) => dispatch(notificationsActions.addNotification(type, message)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsTable);
