import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { store } from "../../store";

function filter(form) {
    const searchForm = new SearchForm(form, {
        name: "name-" + store.getState().authentication.user.language,
        short_name: "short_name-" + store.getState().authentication.user.language,
    });
    let sendSearch = searchForm.getSend();

    return API.get("/chambers", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function search(form) {
    const searchForm = new SearchForm(form, {
        name: "name-" + store.getState().authentication.user.language,
        short_name: "short_name-" + store.getState().authentication.user.language,
    });
    let sendSearch = searchForm.getSend();

    return API.get("/chambers/search", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function create(form) {
    return API.post(`/chambers`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function update(form, id) {
    return API.patch(`/chambers/${id}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(`/chambers/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function del(id) {
    return API.delete(`/chambers/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const chambersService = {
    filter,
    create,
    update,
    del,
    get,
    search,
};

export default chambersService;
