import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TxtField from "../../Common/TxtField";
import Autocomplete from "../../Common/Autocomplete";
import EmailLogTable from "./EmailLogTable";
import Header from "../../Common/Header";
import { connect } from "react-redux";
import emailLogActions from "../../../store/emailLog/actions.js";
import Filter from "../../Common/Filter";
import Loading from "../../Common/Loading";
import DatePicker from "../../Common/DatePicker";
import i18next from "i18next";
import {setInfo} from "../../../utils/FilterHelper";
import {convertDateToHyphenFormat} from '../../../utils/AppConst';

function EmailLogList(props) {
    const { loading, search, data, types, user } = props;

    useEffect(() => {
        props.fetchTypes().then(() => {
            props.filter(search);
        });
    }, []);

    const typeOptions = types.map((object, key) => {
        return {
            value: object.id,
            label: object.name[user.language],
            key: key,
        };
    });
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    const propsSearch = {
        ...props,
        search: props.filter
    };

    const resetForm = (event) => {
        event.preventDefault();
        propsSearch.defaultForm();
        setInfo(propsSearch, "filter", {}, "sort", {"created_at" : "desc"});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInfo(propsSearch, "filter", search);
    };

    const handleChange = (event) => {
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm('search', {
            ...search,
            [name]: newDate
        });
    };

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("search", { ...search, [name]: values.map((v) => v) });
    };


    const statuses = [
        { value: 0, label: i18next.t("email_log.waiting"), key: 0 },
        { value: 1, label: i18next.t("email_log.success"), key: 1 },
        { value: 2, label: i18next.t("email_log.error"), key: 2 },
    ];

    const filterForm = (
        <>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="name"
                    label={i18next.t("email_log.name")}
                    name="name"
                    value={search.name || ""}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="email"
                    label={i18next.t("email_log.email")}
                    name="email"
                    value={search.email || ""}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    id="subject"
                    label={i18next.t("email_log.subject")}
                    name="subject"
                    value={search.subject || ""}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "sentFrom")}
                    value={search.sentFrom}
                    id="sentFrom"
                    label={i18next.t("email_log.send") + " (" + i18next.t("email_log.from") + ")"}
                    name="sentFrom"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "sentUntil")}
                    value={search.sentUntil}
                    id="sentUntil"
                    label={i18next.t("email_log.send") + " (" + i18next.t("email_log.to") + ")"}
                    name="sentUntil"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "generateFrom")}
                    value={search.generateFrom}
                    id="generateFrom"
                    label={i18next.t("email_log.generation") + " (" + i18next.t("email_log.from") + ")"}
                    name="generateFrom"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "generateUntil")}
                    value={search.generateUntil}
                    id="generateUntil"
                    label={i18next.t("email_log.generation") + " (" + i18next.t("email_log.to") + ")"}
                    name="generateUntil"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    multiple
                    key={search.type}
                    value={search.type}
                    options={typeOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="type"
                    onChange={(e, v) => handleMultiselectChange(e, v, "type")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("email_log.type")} />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    multiple
                    key={search.status}
                    value={search.status}
                    options={statuses}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="status"
                    onChange={(e, v) => handleMultiselectChange(e, v, "status")}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("email_log.status")} />
                    )}
                />
            </Grid>
        </>
    );

    if (loading) return <Loading />;

    return (
        <Grid item container spacing={2}>
            <Header
                title={i18next.t("sidebar.email_log")}
                breadcrumbs={{
                    administration: i18next.t("sidebar.administration"),
                    "email-log": i18next.t("sidebar.email_log"),
                }}
            />
            <Filter filterForm={filterForm} defaultForm={resetForm} onSubmit={handleSubmit} />
            <Grid item container xs={12}>
                <EmailLogTable
                    loading={props.loading}
                    tableLoading={props.tableLoading}
                    data={data}
                    search={search}
                    filter={props.filter}
                    info={props.info}
                    setInfo={props.setInfo}
                    userLang={user.language}
                />
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data, types, info, tableLoading } = state.emailLog;
    const { user } = state.authentication;
    return { loading, search, data, types, user, info, tableLoading };
}

const actionCreators = {
    filter: emailLogActions.filter,
    changeForm: emailLogActions.changeForm,
    defaultForm: emailLogActions.defaultForm,
    fetchTypes: emailLogActions.fetchTypes,
    setInfo: emailLogActions.setInfo,
};

export default connect(mapState, actionCreators)(EmailLogList);
