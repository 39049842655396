import { AppBar, Box, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tab } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import i18next from "i18next";
import React from "react";
import { connect } from "react-redux";
import Component from "../../../lib/Component";
import Permissions from "../../../lib/Permissions";
import {
    addSelectedUserAction,
    clearSelectedUserAction,
    delSelectedUserAction,
    fetchCategorizedCoursesAction,
    fetchNextUsersAction,
    fetchUsersAction,
    saveSelectedUserAction,
    selectCourseAction,
    unenrollmentAction,
} from "../../../store/enrollment/actions";
import importActions from "../../../store/importData/actions";
import notificationsActions from "../../../store/notifications/actions";
import "../../../styles/enrollment.scss";
import { hasPermission } from "../../../utils/AuthHelper";
import AntTabs from "../../Common/AntTabs";
import CheckBox from "../../Common/CheckBox";
import Header from "../../Common/Header";
import Import from "../../Common/Import";
import TabPanel from "../../Common/TabPanel";
import TreeView from "../../Common/TreeView/TreeView";
import Filters from "../Enrollment/Filters";
import KamgyakWarningMessage from "../Enrollment/KamgyakWarningMessage";
import SelectedUsers from "../Enrollment/SelectedUsers";
import Users from "../Enrollment/Users";
import MassImportInfo from '../../Common/MassImportInfo';

const TYPE = "dismissal";

class Dismissal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course: null,
            emptyHideCb: 1,
            filters: {
                query: "",
                enrolled: 'yes', // null|true|false
            },
            emailNotification: false,
            selectAll: false,
            value: 0,
            radio: "dismissalOnly", // dismissalAndEdit | dismissalOnly
        };
    }

    componentDidMount() {
        this.props.clearSelectedUserAction();
        this.updateState("selectAll", false);
        this.props.fetchCategorizedCoursesAction("dismissal", this.state.emptyHideCb);
    }

    nextPageUsers(currentViewed){
        this.props.fetchNextUsersAction(TYPE, this.state.course.id, currentViewed, this.state.filters.query, this.state.filters.enrolled);
    }

    /**
     * Get the users
     */
    getUsers(course) {
        this.state.course = course;
        this.props.fetchUsersAction("dismissal", course.id);
    }

    emptyHideCbChange(event) {
        const val = event.target.checked ? 1 : 0;
        this.setState({ emptyHideCb: val });
        this.props.fetchCategorizedCoursesAction("dismissal", val);
    }

    selectCourse(course) {
        if (this.props.currentSelected > 0 && !window.confirm(i18next.t("elearning.confirmation"))) {
            return false;
        }
        this.props.clearSelectedUserAction();
        this.props.selectCourseAction(course);
        this.updateState("emailNotification", false);
        this.setQueryFilter("");
        this.getUsers(course);
        this.props.importDefaultState();
    }


    handleUserSelection(event, selectedUser) {
        if (event.target.checked) {
            if (!this.props.selectedUsers.includes(selectedUser.id)) this.props.addSelectedUserAction(selectedUser.id);
        } else {
            this.props.delSelectedUserAction(selectedUser.id);
        }
        this.props.saveSelectedUser(
            this.state.course.id,
            selectedUser.id,
            event.target.checked,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    setEnrollmentFilter(value) {
        let filters = this.state.filters;

        filters["enrolled"] = value;

        this.updateState("filters", filters);
    }

    setQueryFilter(value) {
        if(!this.state.course){
            return;
        }

        let filters = this.state.filters;
        filters["query"] = value;
        this.updateState("filters", filters);
        this.props.fetchNextUsersAction("enrollment", this.state.course.id, 0, this.state.filters.query, this.state.filters.enrolled);
    }

    selectAll(value) {
        this.props.saveSelectedUser(
            this.state.course.id,
            -1,
            value,
            this.state.filters.query,
            this.state.filters.enrolled
        );

        const users = this.props.users.list;

        if (value) {
            users.forEach((user) => {
                user.selected = true;
            });
        } else {
            users.forEach((user) => {
                user.selected = false;
            });
            this.props.clearSelectedUserAction();
        }
        this.updateState("selectAll", value);
    }

    save() {
        let data = {
            courseid: this.props.selectedCourse.id,
            sendEmail: this.state.emailNotification,
        };

        this.props.unenrollmentAction(data).then(
            (data) => {
                //fixme: mit csinálunk?
                this.props.fetchNextUsersAction("enrollment", this.state.course.id, 0, this.state.filters.query, this.state.filters.enrolled);
                this.props.clearSelectedUserAction();
                this.updateState("emailNotification", false);
                this.props.addNotification("success", i18next.t("elearning.dismissal.success"));
            },
            (err) => {
                //fixme: hibát irunk ki valahova
                this.props.addNotification("error", i18next.t("elearning.dismissal.error"));
            }
        );
    }

    renderUsersCard() {
        return (
            <Card>
                {this.props.selectedCourse ? (
                    <CardHeader
                        title={i18next.t("elearning.course_management", {
                            course: this.props.selectedCourse.name,
                        })}
                    />
                ) : (
                    <>
                        <CardHeader title={i18next.t("elearning.management")} />
                        <CardContent>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Alert severity="info">{i18next.t("elearning.select")}</Alert>
                            </Box>
                        </CardContent>
                    </>
                )}
                <KamgyakWarningMessage course={this.props.selectedCourse} />
                {this.props.selectedCourse && !this.props.selectedCourse.isKamgyakType ? (
                    this.props.loadingRightSide ? (
                        <CardContent>
                            <Skeleton variant="rect" height={120} />
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Filters
                                type={TYPE}
                                setQueryFilter={(e) => this.setQueryFilter(e.target.value)}
                                setEnrollmentFilter={(val) => this.setEnrollmentFilter(val)}
                                loadingSave={this.props.loadingSave}
                                filters={this.state.filters}
                            />

                            <Box marginTop={3} />

                            <FormControlLabel
                                control={
                                    <CheckBox
                                        onClick={(e) => {
                                            this.selectAll(e.target.checked);
                                        }}
                                        checked={this.state.selectAll}
                                        color="primary"
                                    />
                                }
                                label={i18next.t("elearning.select_all")}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <Users
                                        type="dismissal"
                                        loadingUserList={this.props.loadingUserList || this.props.loadingSave}
                                        users={this.props.users}
                                        selectedUsers={this.props.selectedUsers}
                                        handleUserSelection={(e, user) => {
                                            this.handleUserSelection(e, user)
                                        }}
                                        nextPageUsers={(c) =>{
                                            this.nextPageUsers(c)
                                        }}
                                    />
                                </Grid>
                                <SelectedUsers
                                    currentSelected={this.props.currentSelected}
                                    currentSelectedIsLoading={this.props.currentSelectedIsLoading}
                                    loadingSave={this.props.loadingSave}
                                    emailNotification={this.state.emailNotification}
                                    updateState={(e) => {
                                        this.updateState("emailNotification", e.target.checked)
                                    }}
                                    save={() => {
                                        this.save()
                                    }}
                                    btnLabel={i18next.t("elearning.selected.dismiss")}
                                />
                            </Grid>
                        </CardContent>
                    )
                ) : (
                    ""
                )}
            </Card>
        );
    }

    render() {
        return (
            <div className="enrollment">
                <Grid item container spacing={2}>
                    <Header
                        title={i18next.t("sidebar.course_dismissal")}
                        breadcrumbs={{
                            "e-learning": i18next.t("sidebar.elearning"),
                            dismissal: i18next.t("sidebar.course_dismissal"),
                        }}
                    />
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Card>
                                    <CardHeader title={i18next.t("elearning.select_course")} />
                                    <CardContent>
                                        {hasPermission(Permissions.CourseDismissal) && (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={this.state.emptyHideCb == 1}
                                                        onChange={(event) => {
                                                            this.emptyHideCbChange(event);
                                                        }}
                                                        value={1}
                                                        name="confirmationEmail"
                                                    />
                                                }
                                                label={i18next.t("elearning.hide")}
                                            />
                                        )}
                                        <TreeView
                                            onItemSelected={(item) => this.selectCourse(item)}
                                            items={this.props.categorizedCourses}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                {hasPermission(Permissions.CourseDismissal) ? (
                                    <>
                                        <AppBar position="static">
                                            <AntTabs
                                                value={this.state.value}
                                                onChange={(e, v) => this.setState({ value: v })}
                                                variant="fullWidth"
                                            >
                                                <Tab label={i18next.t("elearning.dismiss")} />
                                                <Tab label={i18next.t("elearning.mass_dismiss")} />
                                            </AntTabs>
                                        </AppBar>
                                        <TabPanel value={this.state.value} index={0}>
                                            {this.renderUsersCard()}
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={1}>
                                        <MassImportInfo />
                                        <FormControl>
                                            <FormLabel>{i18next.t("elearning.dismiss_type")}</FormLabel>
                                            <RadioGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onClick={() => this.setState({ radio: "dismissalOnly" })}
                                                            checked={this.state.radio === "dismissalOnly"}
                                                        />
                                                    }
                                                    label={i18next.t("elearning.dismiss_only")}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onClick={() => this.setState({ radio: "dismissalAndEdit" })}
                                                            checked={this.state.radio === "dismissalAndEdit"}
                                                        />
                                                    }
                                                    label={i18next.t("elearning.dismiss_and_edit")}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {this.state.radio === "dismissalOnly" ? 
                                                <Import
                                                    course={this.props.selectedCourse}
                                                    importSuccess={() => {
                                                        this.props.clearSelectedUserAction();
                                                        this.getUsers(this.props.selectedCourse);
                                                    }}
                                                    type={"course-dismissal-only"}
                                                />
                                                :
                                                <Import
                                                    course={this.props.selectedCourse}
                                                    importSuccess={() => {
                                                        this.props.clearSelectedUserAction();
                                                        this.getUsers(this.props.selectedCourse);
                                                    }}
                                                    type="course-dismissal"
                                                    rightSide={
                                                        <>
                                                            <FormControlLabel
                                                                control={
                                                                    <CheckBox
                                                                        color="primary"
                                                                        checked={
                                                                            this.props.importDataForm.emailNotification
                                                                        }
                                                                        onClick={(e) =>
                                                                            this.props.importDataChangeForm("form", {
                                                                                ...this.props.importDataForm,
                                                                                emailNotification: e.target.checked,
                                                                            })
                                                                        }
                                                                        disabled={this.props.importProgress}
                                                                    />
                                                                }
                                                                label={i18next.t("elearning.notification")}
                                                            />
                                                        </>
                                                    }
                                                />
                                        }
                                        </TabPanel>
                                    </>
                                ) : (
                                    this.renderUsersCard()
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        categorizedCourses: state.enrollment.categorizedCourses,
        selectedUsers: state.enrollment.selectedUsers,
        selectedCourse: state.enrollment.selectedCourse,
        users: state.enrollment.users,
        loadingLeftSide: state.enrollment.loadingLeftSide,
        loadingRightSide: state.enrollment.loadingRightSide,
        loadingSave: state.enrollment.loadingSave,
        currentSelected: state.enrollment.currentSelected,
        currentSelectedIsLoading: state.enrollment.currentSelectedIsLoading,
        importDataForm: state.importData.form,
        importProgress: state.importData.progress,
        loadingUserList: state.enrollment.loadingUserList,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchCategorizedCoursesAction: (type, hideEmptyCategories) =>
            dispatch(fetchCategorizedCoursesAction(type, hideEmptyCategories)),
        addSelectedUserAction: (user) => dispatch(addSelectedUserAction(user)),
        delSelectedUserAction: (user) => dispatch(delSelectedUserAction(user)),
        clearSelectedUserAction: () => dispatch(clearSelectedUserAction()),
        fetchUsersAction: (type, courseId) => dispatch(fetchUsersAction(type, courseId)),
        selectCourseAction: (course) => dispatch(selectCourseAction(course)),
        unenrollmentAction: (form) => dispatch(unenrollmentAction(form)),
        fetchNextUsersAction: (type, courseId, allCount, search, enrolled) => dispatch(fetchNextUsersAction(type, courseId, allCount, search, enrolled)),
        saveSelectedUser: (courseId, userId, selected, search, enrolled) => dispatch(saveSelectedUserAction('dismissal', courseId, userId, selected, search, enrolled)),
        addNotification: (type, msg, redirect) => dispatch(notificationsActions.addNotification(type, msg, redirect)),
        importDefaultState: () => dispatch(importActions.defaultState()),
        importDataChangeForm: (variableName, form) => dispatch(importActions.changeForm(variableName, form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dismissal);
