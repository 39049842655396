import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import colors from "../../styles/colors.scss";
import Logo from "../../img/mkik-logo.png";
import Copyright from "../Common/Copyright";
import SubmitButton from "../Common/SubmitButton";
import TxtField from "../Common/TxtField";
import HelmetTemplate from "../Common/HelmetTemplate";
import { connect } from "react-redux";
import loginActions from "../../store/login/actions.js";
import { CircularProgress } from "@material-ui/core";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { useParams } from "react-router";
import Validator from "../../utils/Validator";
import validator from "../../store/validator/actions";
import Loading from "../Common/Loading";
import notificationsActions from "../../store/notifications/actions";
import CustomError from "../../ErrorPages/CustomError";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 0),
    },
    title: {
        color: colors.primary,
    },
    subTitle: {
        color: colors.primary,
        textAlign: "center",
        fontSize: "16px",
        marginTop: 10,
    },
    wrapper: {
        position: "relative",
        padding: "unset",
    },
    buttonProgress: {
        color: colors.primary,
        position: "absolute",
        top: "65%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    disabledButton: {
        backgroundColor: colors.disabled,
    },
}));

function ForgottenPassword({
    t,
    i18n,
    loading,
    forgottenPasswordSet,
    setValidatorErrors,
    validatorErrors,
    checkToken,
    addNotification,
}) {
    const classes = useStyles();
    let params = useParams();
    const validator = new Validator({}, validatorErrors);

    const [password1x, setPassword1x] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setValidatorErrors({});
        // Token ellenőrzés
        checkToken(params.token)
            .then((d) => {
                if(!(d.data && d.data.status === 'valid')){
                    setShowError(true);
                }
            })
            .catch((error) => {
            if (error.response) {
                if (error.response.status == 400) {
                    if (error.response.data.isDeleted) {
                        //addNotification('error', 'Nem érvényes a link, kérjük vegye kapcsolatot a rendszer adminisztrátoraival!', '/login')
                        setShowError(true);
                    }
                }
            }
        }); // 404 esetén Az API.js miatt a 404 felületre dobjuk a usert
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        forgottenPasswordSet(params.token, password, password1x)
            .then((data) => {
                addNotification("success", i18next.t("forgot.success"), "/login");
            })
            .catch((error) => {
                validator.setReponseError(error);
                setValidatorErrors(validator.getErrors());
            });
    };

    const onChangePsw = (event) => setPassword(event.target.value);
    const onChangePsw1x = (event) => setPassword1x(event.target.value);

    if (showError) return <CustomError text={i18next.t("activation_code.error")} />;

    return (
        <Container component="main" maxWidth="xs">
            <HelmetTemplate title={i18next.t("forgot")} />
            <CssBaseline />
            {loading ? (
                <Loading />
            ) : (
                <div className={classes.paper}>
                    <img className={classes.avatar} src={Logo} style={{ height: "160px", width: "auto" }} />
                    <Typography component="h1" variant="h5" className={classes.title}>
                        {i18next.t("mkik")}
                    </Typography>
                    <Typography component="h1" variant="h6" className={classes.subTitle}>
                        {i18next.t("forgot.new")}
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit} noValidate>
                        <TxtField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={i18next.t("login.password")}
                            type="password"
                            id="password"
                            value={password}
                            onChange={onChangePsw}
                            error={validator.hasError("password")}
                            helperText={validator.getErrorText("password")}
                        />
                        <TxtField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password1x"
                            label={i18next.t("forgot.again")}
                            type="password"
                            id="password1x"
                            value={password1x}
                            onChange={onChangePsw1x}
                            error={validator.hasError("password1x")}
                            helperText={validator.getErrorText("password1x")}
                        />
                        <div className={classes.wrapper}>
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={loading}
                                text={i18next.t("forgot.change")}
                            />
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
            )}
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

function mapState(state) {
    const { loading } = state.authentication;
    const validatorErrors = state.validator.validatorErorrs;
    return { loading, validatorErrors };
}

const actionCreators = {
    setValidatorErrors: validator.setErrors,
    forgottenPasswordSet: loginActions.forgottenPasswordSet,
    checkToken: loginActions.checkToken,
    addNotification: notificationsActions.addNotification,
};

export default compose(withTranslation(), connect(mapState, actionCreators))(ForgottenPassword);
