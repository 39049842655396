import React from "react";
import { Tabs, withStyles } from "@material-ui/core";
import colors from "../../styles/colors.scss";

const StyledTabs = withStyles({
    indicator: {
        backgroundColor: colors.darkPrimary,
        height: "5px",
    },
})(Tabs);

export default function AntTabs(props) {
    return <StyledTabs variant="scrollable" scrollButtons="auto" {...props} />;
}
