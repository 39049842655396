import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: "rgb(168,167,163)",
        "&:hover": {
            backgroundColor: "rgb(168,167,163)",
        },
    },
}))(Button);

class DeleteRepealIcon extends React.Component {
    render() {
        return (
            <ColorButton variant="contained" color="secondary">
                <FontAwesomeIcon size="1x" icon={faRedoAlt} />
            </ColorButton>
        );
    }
}

export default DeleteRepealIcon;
