import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import { makeStyles } from "@material-ui/core/styles";
import CourseManagementTable from "./CourseManagementTable";
import coursesActions from "../../../store/courses/actions";
import Filter from "../../Common/Filter";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import DatePicker from "../../Common/DatePicker";
import i18next from "i18next";
import { convertDateToHyphenFormat } from '../../../utils/AppConst';
import Autocomplete from "../../Common/Autocomplete";
import SubmitButton from "../../Common/SubmitButton";
import {Refresh} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GreenTooltip from "../../Common/GreenTooltip";
import notificationsActions from "../../../store/notifications/actions";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(2),
    },
    btnHolder: {
        position: 'relative',
        width: '100%',
    },
    btn: {
        position: 'absolute',
        right: '8px',
        top: '-30px'
    }
}));

function CourseManagementList(props) {
    const courseIsTemplateOptions = [
        { value: "no", label: i18next.t("course_management.is_sample_course.no"), key: 0 },
        { value: "yes", label: i18next.t("course_management.is_sample_course.yes"), key: 1 },
    ];
    
    const courseTypeOptions = [
        { value: "empty", label: i18next.t("course_management.course_type.empty"), key: 0 },
        { value: "kamgyak", label: i18next.t("course_management.course_type.kamgyak"), key: 1 },
    ];

    const classes = useStyles();
    const { loading, search, user } = props;

    const [refreshDialogIsOpen, setRefreshDialogIsOpen] = React.useState(false);

    useEffect(() => {
        props.filter({ ...search, ["courseIsActive"]: [courseIsTemplateOptions[0]] });
    }, []);

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("search", { ...search, [name]: values.map((v) => v) });
    };
    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm('search', {
            ...search,
            [name]: newDate
        });
    };

    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.filter({ ["courseIsActive"]: [courseIsTemplateOptions[0]] });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        props.filter(search);
    };

    const filtForm = (
        <>
            <Grid item xs={12} sm={3}>
                <TxtField
                    variant="outlined"
                    value={search.category || ""}
                    margin="normal"
                    id="category"
                    label={i18next.t("course_management.category")}
                    name="category"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TxtField
                    variant="outlined"
                    value={search.name || ""}
                    margin="normal"
                    id="name"
                    label={i18next.t("course_management.name")}
                    name="name"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Autocomplete
                    id="course_is_template"
                    multiple
                    value={search.courseIsTemplate || []}
                    options={courseIsTemplateOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "courseIsTemplate")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_management.is_sample_course")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Autocomplete
                    id="course_is_active"
                    multiple
                    value={search.courseIsActive || []}
                    options={courseIsTemplateOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "courseIsActive")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_management.show_archivated_courses")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Autocomplete
                    id="course_type"
                    multiple
                    value={search.courseType || []}
                    options={courseTypeOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(e, v) => handleMultiselectChange(e, v, "courseType")}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("course_management.type")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "courseStartFrom")}
                    value={search.courseStartFrom}
                    id="courseStartFrom"
                    label={i18next.t("course_management.start") + " (" + i18next.t("course_management.from") + ")"}
                    name="courseStartFrom"
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "courseStartUntil")}
                    value={search.courseStartUntil}
                    id="courseStartUntil"
                    label={i18next.t("course_management.start") + " (" + i18next.t("course_management.until") + ")"}
                    name="courseStartUntil"
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "courseEndFrom")}
                    value={search.courseEndFrom}
                    id="courseEndFrom"
                    label={i18next.t("course_management.end") + " (" + i18next.t("course_management.from") + ")"}
                    name="courseEndFrom"
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "courseEndUntil")}
                    value={search.courseEndUntil}
                    id="courseEndUntil"
                    label={i18next.t("course_management.end") + " (" + i18next.t("course_management.until") + ")"}
                    name="courseEndUntil"
                    style={{ marginRight: 6, marginTop: 0, marginBottom: 8 }}
                />
            </Grid>
        </>
    );
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.course_management")}
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    course_management: i18next.t("sidebar.course_management"),
                }}
            />
            <div className={classes.btnHolder}>
                <SubmitButton className={classes.btn} text={
                    <GreenTooltip title={i18next.t("Kurzuslista frissítése")}>
                        <Refresh />
                    </GreenTooltip>
                } onClick={() => {
                    setRefreshDialogIsOpen(true);
                    props.refreshCourses().then(() => {
                        setRefreshDialogIsOpen(false);
                        props.filter(search);
                    });
                }} />
            </div>
            <Filter filterForm={filtForm} defaultForm={resetForm} onSubmit={handleSubmit} />
            <CourseManagementTable
                data={props.data}
                userLang={user.language}
                gridApi={gridApi}
                onGridReady={onGridReady}
                delete={props.delete}
                addNotification={props.addNotification}
                filter={props.filter}
                search={search}
                searchData={props.search}
            />

            <Dialog open={refreshDialogIsOpen}>
                <DialogTitle>
                    A kurzuslista frissítése folyamatban van, kérjük, várjon...
                </DialogTitle>
            </Dialog>
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data } = state.courses;
    const { user } = state.authentication;
    return { loading, search, data, user };
}

const actionCreators = {
    filter: coursesActions.filter,
    changeForm: coursesActions.changeForm,
    defaultForm: coursesActions.defaultForm,
    refreshCourses: coursesActions.refreshCourses,
    delete: coursesActions.deleteCourse,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(CourseManagementList);
