import { useCallback, useRef } from "react";

const useDebounce = (fn, delay) => {
    const timeoutRef = useRef(null);
    return useCallback(
        (...args) => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                fn(...args);
            }, delay);
        },
        [fn, delay]
    );
};

export default useDebounce;