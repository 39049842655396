import { Button, withStyles } from "@material-ui/core";
import { RateReview } from "@material-ui/icons";
import React from "react";
import colors from "../../../styles/colors.scss";
import GreenTooltip from "../../Common/GreenTooltip";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import { history } from "../../../store";
import { useParams } from "react-router-dom";

const ColorButton = withStyles(() => ({
    root: {
        marginLeft: "8px",
        backgroundColor: colors.orange,
        "&:hover": {
            filter: "brightness(1.1)",
            backgroundColor: colors.orange,
        },
    },
}))(Button);

export default function RescoreButton({ data }) {
    if (
        !(
            data.hasResult &&
            (hasPermission(Permissions.ElearningContentEditor) ||
                hasPermission(Permissions.CourseElearningContentEditor))
        )
    )
        return null;

    return (
        <GreenTooltip title={"Vizsga újrapontozása"}>
            <ColorButton
                variant="contained"
                onClick={() => history.push(`/e-learning/exam-management/${data.id}/rescoring`)}
            >
                <RateReview
                    style={{
                        fontSize: "14px",
                        color: colors.white,
                    }}
                />
            </ColorButton>
        </GreenTooltip>
    );
}
