import elearningCourseEnrollments from "../../../store/elearningCourseEnrollments/actions";
import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionSum from "../../Common/AccordionSum";
import SubmitButton from "../../Common/SubmitButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../../styles/colors.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Card, CardContent, CardHeader, FormControlLabel } from "@material-ui/core";
import TreeView from "../../Common/TreeView/TreeView";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import CheckBox from "../../Common/CheckBox";
import Skeleton from "@material-ui/lab/Skeleton";
import { fetchCategorizedCoursesAction } from "../../../store/enrollment/actions";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import i18next from "i18next";
import EnrolledListTable from "./EnrolledListTable.js";
import Filter from "../../Common/Filter.js";
import { filterForm } from "./utils.js";

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: colors.darkPrimary,
        "&:hover": {
            backgroundColor: colors.darkPrimary,
        },
    },
    accDetails: {
        display: "block",
    },
    menuItem: {
        "&:hover": {
            backgroundColor: colors.primary,
        },
        "&:focus": {
            backgroundColor: colors.primary,
        },
    },
    hide: {
        display: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    listActionsGrid: {
        justifyContent: "unset",
    },
    nameTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    activeTitle: {
        color: colors.primary,
        fontWeight: "bold",
        fontSize: "x-large",
    },
}));

function EnrolledList(props) {
    const classes = useStyles();
    const { loading, data, user, selectedCourse, courseEmptyHideCb, loadingCourses, categorizedCourses, search } = props;
    const [gridApi, setGridApi] = useState(null);

    const downloadExcel = (event) => {
        event.preventDefault();

        props.download(selectedCourse.id, search).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "export.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const selectCourse = (item) => {
        props.selectCourse(item);
        props.get(item.id);
        // clear the filter when switching to a new course
        props.resetFilter();
    };

    const emptyHideCbChange = (event) => {
        const val = event.target.checked ? 1 : 0;
        props.changeEmptyHideCb(val);
        props.fetchCategorizedCoursesAction("enrolled", val);
    };

    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm(selectedCourse.id);
        props.filter({}, selectedCourse.id);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        props.filter(search, selectedCourse.id);
    };

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("search", { ...search, [name]: values.map((v) => v) });
    };

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    useEffect(() => {
        props.fetchCategorizedCoursesAction("enrolled", courseEmptyHideCb);
    }, []);

    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        course_enrolled: i18next.t("sidebar.course_enrollments"),
                    }}
                    title={i18next.t("sidebar.course_enrollments")}
                />
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={i18next.t("elearning.select_course")} />
                        <CardContent>
                            {hasPermission(Permissions.CourseEnrollmentList) && (
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={courseEmptyHideCb == 1}
                                            onChange={(event) => {
                                                emptyHideCbChange(event);
                                            }}
                                            value={1}
                                            name="confirmationEmail"
                                        />
                                    }
                                    label={i18next.t("elearning.hide")}
                                />
                            )}
                            {loadingCourses ? (
                                <Skeleton variant="rect" height={230} />
                            ) : (
                                <TreeView onItemSelected={(item) => selectCourse(item)} items={categorizedCourses} />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container className={selectedCourse == null ? classes.hide : ""}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Accordion className={classes.accordionRoot} expanded>
                                <AccordionActions classes={{ root: classes.listActionsGrid }} style={{padding: '15px'}}>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid>
                                            <Typography className={classes.activeTitle}>
                                                {selectedCourse ? selectedCourse.name : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <SubmitButton
                                                type="submit"
                                                variant="contained"
                                                text="Export"
                                                onClick={downloadExcel}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionActions>
                                <AccordionDetails>
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <Grid item container xs={12}>
                                            <Filter filterForm={filterForm(search, handleChange, handleMultiselectChange)} defaultForm={resetForm} onSubmit={handleSubmit} />
                                            <EnrolledListTable
                                                data={data}
                                                userLang={user.language}
                                                gridApi={gridApi}
                                                onGridReady={onGridReady}
                                            />
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, selectedCourse, data, courseEmptyHideCb, search } = state.elearningCourseEnrollments;
    const { user } = state.authentication;

    const loadingCourses = state.enrollment.loadingLeftSide;
    const categorizedCourses = state.enrollment.categorizedCourses;

    return {
        loading,
        data,
        selectedCourse,
        user,
        courseEmptyHideCb,
        loadingCourses,
        categorizedCourses,
        search,
    };
}

const actionCreators = {
    filter: elearningCourseEnrollments.filter,
    changeForm: elearningCourseEnrollments.changeForm,
    defaultForm: elearningCourseEnrollments.defaultForm,
    get: elearningCourseEnrollments.get,
    selectCourse: elearningCourseEnrollments.selectCourse,
    changeEmptyHideCb: elearningCourseEnrollments.changeEmptyHideCb,
    fetchCategorizedCoursesAction: fetchCategorizedCoursesAction,
    download: elearningCourseEnrollments.download,
    resetFilter: elearningCourseEnrollments.resetFilter,
};

export default connect(mapState, actionCreators)(EnrolledList);
