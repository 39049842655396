import API from "../../utils/API";
import { store } from "../../store";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form, {
        name: "name-" + store.getState().authentication.user.language,
        subject: "subject-" + store.getState().authentication.user.language,
    });
    let sendSearch = searchForm.getSend();

    return API.get(process.env.REACT_APP_API_URL + "/email-templates", {
        params: sendSearch,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function post(form, id) {
    return API.patch(process.env.REACT_APP_API_URL + `/email-templates/${id}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(process.env.REACT_APP_API_URL + `/email-templates/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const emailTemplatesService = {
    filter,
    post,
    get,
};

export default emailTemplatesService;
