export const types = {
    GET_REQUEST: "@@elearning-course-achievements/GET_REQUEST",
    GET_SUCCESS: "@@elearning-course-achievements/GET_SUCCESS",
    GET_FAILURE: "@@elearning-course-achievements/GET_FAILURE",

    DOWNLOAD_REQUEST: "@@elearning-course-achievements/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@elearning-course-achievements/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@elearning-course-achievements/DOWNLOAD_FAILURE",

    SELECTED_COURSE: "@@elearning-course-achievements/SELECTED_COURSE",
    CHANGE_EMPTY_HIDE_CB: "@@elearning-course-achievements/CHANGE_EMPTY_HIDE_CB",
};
