import API from "../../utils/API";

export function fetchCategorizedCourses(type, hideEmptyCategories) {
    return API.get(`/e-learning/${type}/categorized-courses`, {
        params: {
            hideEmptyCategories: hideEmptyCategories,
        },
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function postEnrollment(form) {
    return API.post(`/enrollment/enrol`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function postUnenrollment(form) {
    return API.post(`/enrollment/unenrol`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function fetchUsers(type, courseId, actualCount, search, enrolled, token) {
    let param = actualCount ? '?offset=' + actualCount : '';
    if(search){
        param += (param.length > 0 ? "&" : '?') + `search=${search}`;
    }
    if(enrolled){
        param += (param.length > 0 ? "&" : '?') + `enrolled=${enrolled}`;
    }
    if(token){
        param += (param.length > 0 ? "&" : '?') + `token=${token}`;
    }
    //lekérjük az összes felhasználót
    return API.get(`/e-learning/${type}/all-users/${courseId}${param}`)
        .then((response) => {
            return {
                list: response.data.list,
                count: response.data.count,
                token: response.data.token,
                actualCount: actualCount
            }
        })
        .catch((error) => Promise.reject(error));
}

export function saveSelectedUser(type, courseId, userId, selected, search, enrolled) {
    return API.post(`/e-learning/${type}/session-save`, {
        "courseid" : courseId,
        "method" : selected ? "put" : "del",
        "userid" : userId,
        "search" : search,
        "enrolled" : enrolled,
    })
        .then((response) => {
            return response.data
        })
        .catch((error) => Promise.reject(error));
}
