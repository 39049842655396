import { types } from "./types";
import {
    createVersion,
    del,
    fetchPages,
    getPage,
    getPrivacyStatementActive,
    getUserPrivacyStatementCourse,
    getVersion,
    getVersions,
    updateUserPrivacyStatement,
    updateUserPrivacyStatementCourse,
    updateVersion,
} from "../../services/pages";

export function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}
export function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}
export function fetch() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST });
        return fetchPages().then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function getVersionsAction(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_VERSIONS_REQUEST });
        return getVersions(id).then(
            (data) => {
                dispatch({ type: types.GET_VERSIONS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_VERSIONS_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function getPageAction(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_PAGE_REQUEST });
        return getPage(id).then(
            (data) => {
                dispatch({ type: types.GET_PAGE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_PAGE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function getVersionAction(id, versionId) {
    return (dispatch) => {
        dispatch({ type: types.GET_PAGE_VERSION_REQUEST });
        return getVersion(id, versionId).then(
            (data) => {
                dispatch({ type: types.GET_PAGE_VERSION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_PAGE_VERSION_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function getPrivacyStatementActiveAction() {
    return (dispatch) => {
        dispatch({ type: types.GET_PRIVACY_STATEMENT_ACTIVE_REQUEST });
        return getPrivacyStatementActive().then(
            (data) => {
                dispatch({ type: types.GET_PRIVACY_STATEMENT_ACTIVE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_PRIVACY_STATEMENT_ACTIVE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
export function getUserPrivacyStatementCourseAction(courseId) {
    return (dispatch) => {
        dispatch({ type: types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_REQUEST });
        return getUserPrivacyStatementCourse(courseId).then(
            (data) => {
                dispatch({
                    type: types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({
                    type: types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_FAILURE,
                    error,
                });
                return Promise.reject(err);
            }
        );
    };
}
export function updateUserPrivacyStatementAction() {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_USER_PRIVACY_STATEMENT_REQUEST });

        return updateUserPrivacyStatement().then(
            (data) => {
                dispatch({ type: types.UPDATE_USER_PRIVACY_STATEMENT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_USER_PRIVACY_STATEMENT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function updateUserPrivacyStatementCourseAction(courseId) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_REQUEST });

        return updateUserPrivacyStatementCourse(courseId).then(
            (data) => {
                dispatch({
                    type: types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

export function createVersionAction(id, form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST });

        return createVersion(id, form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

export function updateVersionAction(id, versionId, form) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST });

        return updateVersion(id, versionId, form).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function deleteRow(id, versionId) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return del(id, versionId).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

export function reset() {
    return (dispatch) => {
        dispatch({ type: types.RESET });
    };
}
