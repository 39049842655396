import { types } from "./types";
import userService from "../../services/users";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultRow() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_ROW });
    };
}

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return userService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetch(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return userService.fetch(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function search(form) {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_REQUEST, form });
        return userService.search(form).then(
            (data) => {
                dispatch({ type: types.SEARCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEARCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function searchImpersonation(form) {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_IMPERSONATION_REQUEST, form });
        return userService.search(form).then(
            (data) => {
                dispatch({ type: types.SEARCH_IMPERSONATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEARCH_IMPERSONATION_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function create(form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST, form });

        return userService.create(form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(form, id) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, form });

        return userService.update(form, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function get(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, id });

        return userService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchChambers(isForm, userId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_CHAMBERS_GET, isForm, userId });

        return userService.fetchChambers(isForm, userId).then(
            (data) => {
                dispatch({ type: types.FETCH_CHAMBERS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_CHAMBERS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function sendPasswordReset(userId) {
    return (dispatch) => {
        dispatch({ type: types.SEND_PSW_RESET_REQUEST, userId });
        return userService.sendPasswordReset(userId).then(
            (data) => {
                dispatch({ type: types.SEND_PSW_RESET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEND_PSW_RESET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function sendActivationMail(userId) {
    return (dispatch) => {
        dispatch({ type: types.SEND_ACTIVATION_MAIL_REQUEST, userId });
        return userService.sendActivationMail(userId).then(
            (data) => {
                dispatch({ type: types.SEND_ACTIVATION_MAIL_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEND_ACTIVATION_MAIL_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deleteRow(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return userService.deleteUser(id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

function deleteRepeal(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REPEAL_REQUEST });
        return userService.deleteRepealUser(id).then(
            (data) => {
                dispatch({ type: types.DELETE_REPEAL_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_REPEAL_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
function postImpersonationAction(userId) {
    return (dispatch) => {
        dispatch({ type: types.POST_IMPERSONATION_REQUEST });
        return userService.postImpersonation(userId).then(
            (data) => {
                dispatch({ type: types.POST_IMPERSONATION_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.POST_IMPERSONATION_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    fetch,
    filter,
    create,
    update,
    deleteRow,
    deleteRepeal,
    get,
    changeForm,
    defaultForm,
    defaultRow,
    fetchChambers,
    createForm,
    sendPasswordReset,
    sendActivationMail,
    postImpersonationAction,
    search,
    setInfo,
    searchImpersonation
};

export default actions;
