import { types } from "./types";
import * as AppConst from "../../utils/AppConst";

let user = JSON.parse(AppConst.CLIENT_STORAGE.getItem("user"));
const initialState = user ? { loggedIn: true, user, loading: false } : { loading: false, loginIsziirReturn: null };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                loading: true,
                user: action.user,
            };
        case types.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
            };
        case types.LOGIN_FAILURE:
            return {};
        case types.LOGOUT:
            return {};
        case types.SET_USER:
            return {
                ...state,
                user: action.user,
            };

        case types.CHECK_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CHECK_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.NEED_RE_CAPTCHA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.NEED_RE_CAPTCHA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.NEED_RE_CAPTCHA_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.CHANGE_LAST_SEEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    last_seen: {
                        ...state.user.last_seen,
                        [action.data.name]: action.data.value,
                    },
                },
            };

        case types.LOGIN_ISZIIR_REQUEST:
            return {
                loginIsziirReturn: {},
                user: null,
                loggedIn: false,
                authcode: action.authcode,
            };
        case types.LOGIN_ISZIIR_SUCCESS:
            return {
                loginIsziirReturn: action.data,
                user: action.data.user ? action.data.user : null,
                loggedIn: action.data.user ? true : false,
                authcode: action.authcode,
            };
        case types.LOGIN_ISZIIR_FAILURE:
            return {};

        default:
            return state;
    }
}
