export const types = {
    CHANGE_FORM: "@@elearning-course-generation/CHANGE_FORM",
    CREATE_FORM: "@@elearning-course-generation/CREATE_FORM",

    FETCH_CHAMBERS_REQUEST: "@@elearning-course-generation/FETCH_CHAMBERS_REQUEST",
    FETCH_CHAMBERS_SUCCESS: "@@elearning-course-generation/FETCH_CHAMBERS_SUCCESS",
    FETCH_CHAMBERS_FAILURE: "@@elearning-course-generation/FETCH_CHAMBERS_FAILURE",

    FETCH_COURSES_REQUEST: "@@elearning-course-generation/FETCH_COURSES_REQUEST",
    FETCH_COURSES_SUCCESS: "@@elearning-course-generation/FETCH_COURSES_SUCCESS",
    FETCH_COURSES_FAILURE: "@@elearning-course-generation/FETCH_COURSES_FAILURE",

    GENERATE_REQUEST: "@@elearning-course-generation/GENERATE_REQUEST",
    GENERATE_SUCCESS: "@@elearning-course-generation/GENERATE_SUCCESS",
    GENERATE_FAILURE: "@@elearning-course-generation/GENERATE_FAILURE",

    GET_CHAMBER_SUB_CATEGORIES_REQUEST: "@@elearning-course-generation/GET_CHAMBER_SUB_CATEGORIES_REQUEST",
    GET_CHAMBER_SUB_CATEGORIES_SUCCESS: "@@elearning-course-generation/GET_CHAMBER_SUB_CATEGORIES_SUCCESS",
    GET_CHAMBER_SUB_CATEGORIES_FAILURE: "@@elearning-course-generation/GET_CHAMBER_SUB_CATEGORIES_FAILURE",

    GET_STATUS_REQUEST: "@@elearning-course-generation/GET_STATUS_REQUEST",
    GET_STATUS_SUCCESS: "@@elearning-course-generation/GET_STATUS_SUCCESS",
    GET_STATUS_FAILURE: "@@elearning-course-generation/GET_STATUS_FAILURE",

    RESET_STATUS: "@@elearning-course-generation/RESET_STATUS",
};
