import { types } from "./types";
import moment from "moment";

const initialLanguages = { hu: null, en: null };

export const initialState = {
    loading: false,
    data: [],
    search: {
        title: null,
        content: null,
        publishedAtFrom: null,
        publishedAtUntil: null,
    },
    row: {
        title: initialLanguages,
        content: initialLanguages,
        published_at: moment(new Date()).format("YYYY-MM-DD"),
    },
    form: {
        id: null,
        title: initialLanguages,
        content: initialLanguages,
        published_at: moment(new Date()).format("YYYY-MM-DD"),
        addresses: ["isziir", "normal"],
    },
    sendOutNewsForm: {
        lastLoginDayFilter: "",
    }
};

export function news(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.CHANGE_SEND_OUT_NEWS_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.sendOutNewsForm,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };
        case types.DEFAULT_SEND_OUT_NEWS_FORM:
            return {
                ...state,
                sendOutNewsForm: initialState.sendOutNewsForm,
            };
        case types.DEFAULT_ROW:
            return {
                ...state,
                row: initialState.row,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
            };
        case types.CREATE_SEND_OUT_NEWS_FORM:
            return {
                ...state,
                sendOutNewsForm: initialState.sendOutNewsForm,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
