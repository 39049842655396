import { Grid } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import { connect } from "react-redux";
import { changeForm } from "../../../../../store/pages/actions";
import DateTimePicker from "../../../../Common/DateTimePicker";
import { langCode, useStyles } from "../utils";
import TxtField from "../../../../Common/TxtField";

function NotificationBar({ form, validator, changeForm, i, lang }) {
    const classes = useStyles();
    const handleDateChange = (date, name) => {
        changeForm("form", {
            ...form,
            [name]: date ? date.format("YYYY-MM-DD HH:mm:ss") : "",
        });
    };

    return (
        <>
            <Grid item xs={12}>
                <TxtField
                    label={i18next.t("pages.content")}
                    variant="outlined"
                    margin="normal"
                    id="content"
                    name="content"
                    value={form.content[langCode[i]] || ""}
                    fullWidth
                    error={validator.hasError("content", langCode[i])}
                    helperText={validator.getErrorText("content", langCode[i])}
                    onChange={(e) =>
                        changeForm("form", {
                            ...form,
                            content: {
                                ...form.content,
                                [lang]: e.target.value,
                            },
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <DateTimePicker
                    onChange={(date) => handleDateChange(date, "start_date")}
                    className={classes.date}
                    error={validator.hasError("start_date")}
                    helperText={validator.getErrorText("start_date")}
                    required={validator.isRequired("start_date")}
                    value={form.start_date}
                    id="start_date"
                    label={i18next.t("pages.start")}
                    name="start_date"
                    margin="normal"
                    readOnly={form.status === "archive"}
                    inputProps={{
                        disabled: form.status === "archive",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <DateTimePicker
                    onChange={(date) => handleDateChange(date, "end_date")}
                    className={classes.date}
                    error={validator.hasError("end_date")}
                    helperText={validator.getErrorText("end_date")}
                    required={validator.isRequired("end_date")}
                    value={form.end_date}
                    id="end_date"
                    label={i18next.t("pages.end")}
                    name="end_date"
                    margin="normal"
                    readOnly={form.status === "archive"}
                    inputProps={{
                        disabled: form.status === "archive",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </>
    );
}

const actionCreators = {
    changeForm,
};

export default connect(null, actionCreators)(NotificationBar);
