import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import i18next from "i18next";
import { Grid, IconButton, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import CancelButton from "../Common/CancelButton";
import { authUser } from "../../utils/AuthHelper";
import {
    faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import API from "../../utils/API";

function DownloadManual(props) {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const download = async (role) => {
        const {data} = await API.get(`/manual/${role}`, {responseType: 'blob'});

        const fileURL = window.URL.createObjectURL(data);
        window.open(fileURL);
    }

    const roles = authUser()?.roles ?? [];

    return (
        <Dialog open={"boolean" === typeof props.open ? props.open : open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>{props.title}</DialogTitle>
                <DialogContent>
                    {props.contentText ? <DialogContentText>{props.contentText}</DialogContentText> : null}
        <Grid container style={{width: matches ? '500px' : '100%', rowGap: '30px'}}>
            <Grid item sm={10} xs={8}>
            <Typography style={{fontWeight: 'bold'}}>
            {i18next.t('download_manual.document_name')}
                </Typography>
                </Grid>
            <Grid item sm={2} xs={4} style={{textAlign: 'center'}}>
            <Typography style={{fontWeight: 'bold'}}>
                {i18next.t('download_manual.actions')}
            </Typography>
            </Grid>
            {
                (roles.includes('global_administrator') && (
                    <>
                        <Grid item sm={10} xs={8} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography >
                                {i18next.t(`download_manual.global_administrator`)}
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={4} style={{textAlign: 'center'}}>
                            <IconButton
                                    aria-label="download manual"
                                    onClick={() => download('global_administrator')}
                                >
                                <FontAwesomeIcon icon={faFileDownload} color={colors.primary} />
                            </IconButton>
                        </Grid>
                        <Grid item sm={10} xs={8} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography >
                                {i18next.t(`download_manual.chamber_administrator`)}
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={4} style={{textAlign: 'center'}}>
                            <IconButton
                                    aria-label="download manual"
                                    onClick={() => download('chamber_administrator')}
                                >
                                <FontAwesomeIcon icon={faFileDownload} color={colors.primary} />
                            </IconButton>
                        </Grid>
                    </>
                ))
            }
            {
                (roles.includes('chamber_administrator') && !roles.includes('global_administrator') && (
                    <>
                        <Grid item sm={10} xs={8} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography >
                                {i18next.t(`download_manual.chamber_administrator`)}
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={4} style={{textAlign: 'center'}}>
                            <IconButton
                                    aria-label="download manual"
                                    onClick={() => download('chamber_administrator')}
                                >
                                <FontAwesomeIcon icon={faFileDownload} color={colors.primary} />
                            </IconButton>
                        </Grid>
                    </>
                ))
            }
            {
                <></>
                    /* (authUser().roles.includes('course_level') && (
                    <>
                        <Grid item sm={10} xs={8} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography >
                                {i18next.t(`download_manual.course_level`)}
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={4} style={{textAlign: 'center'}}>
                            <IconButton
                                    aria-label="download manual"
                                    onClick={() => download('course_level')}
                                >
                                <FontAwesomeIcon icon={faFileDownload} color={colors.primary} />
                            </IconButton>
                        </Grid>
                    </>
                )) */
            }
        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton
                            text={i18next.t("cancel")}
                            onClick={() => {
                                if ("function" === typeof props.closeAction) {
                                    props.closeAction();
                                } else {
                                    setOpen(false);
                                }
                            }}
                            disabled={props.loading}
                        />
                </DialogActions>
            </Dialog>
    );
}

export default (DownloadManual);
