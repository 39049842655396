import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import colors from "../../styles/colors.scss";

const useStyles = makeStyles(() => ({
    firstCardRow: {
        marginBottom: 10,
    },
    cardText: {
        color: colors["dark-grey"],
    },
    cardStyle: {
        width: "100%",
        borderColor: "#ddd",
        backgroundColor: colors.lightPrimary,
        borderWidth: "1px",
        borderStyle: "solid",
    },
}));

export default function Message() {
    const classes = useStyles();
    const user = useSelector((state) => state.authentication.user);

    return (
        <Card variant="outlined" elevation={0} square className={classes.cardStyle}>
            <CardContent>
                <Typography className={classes.firstCardRow} variant="h4">
                    {i18next.t("mainpage.message.title", {
                        name: user.name,
                    })}
                </Typography>
                <Typography className={classes.cardText}>{i18next.t("mainpage.message.firstline")}</Typography>
                <Typography className={classes.cardText}>{i18next.t("mainpage.message.secondline")}</Typography>
            </CardContent>
        </Card>
    );
}
