import { types } from "./types";
import elearningExamManagementService from "../../services/elearningExamManagement";
import elearningCourseCategoriesService from "../../services/elearningCourseCategories";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch(request({ form }));
        return elearningExamManagementService.filter(form).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.FILTER_REQUEST, data };
    }
    function success(data) {
        return { type: types.FILTER_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FILTER_FAILURE, error };
    }
}

export function getData(id) {
    return (dispatch) => {
        dispatch(request({ id }));

        return elearningExamManagementService.getData(id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.GET_REQUEST, data };
    }
    function success(data) {
        return { type: types.GET_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_FAILURE, error };
    }
}

export function update(id, data) {
    return (dispatch) => {
        dispatch(request({ data }));
        return elearningExamManagementService.update(id, data).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.POST_REQUEST, data };
    }
    function success(data) {
        return { type: types.POST_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.POST_FAILURE, error };
    }
}

function deleteExam(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return elearningExamManagementService.deleteExam(id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

const actions = {
    changeForm,
    defaultForm,
    filter,
    getData,
    update,
    deleteExam,
};

export default actions;
