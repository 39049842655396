import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import colors from "../../../styles/colors.scss";
import DeleteCancelButton from "../../Common/DeleteCancel";
import DeleteButton from "../../Common/DeleteButton";
import DeleteIcon from "../Icons/DeleteIcon";
import Loading from "../../Common/Loading";
import GreenTooltip from "../../Common/GreenTooltip";
import coursesService from "../../../services/courses";

export default function CustomDeleteButton(props) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        setIsLoading(false)
    };

    return (
        <>
            <GreenTooltip title={i18next.t(`ob.${props.type}`) || ""}>
                <Link to={props.to || "#"} onClick={() => setOpen(true)} {...props}>
                    <DeleteIcon />
                </Link>
            </GreenTooltip>
            <Dialog open={open} onClose={handleClose}>
                {props.type === "delete" && (
                    <DialogTitle
                        id="responsive-dialog-title"
                        style={{ backgroundColor: colors.darkError, color: colors.white }}
                    >
                        {props.title || i18next.t("ob.delete")}
                    </DialogTitle>
                )}
                <DialogContent>
                    {isLoading ?
                        <div>
                            <Typography>{i18next.t("course_management.delete.loading")}</Typography>
                            <Loading /> 
                        </div>
                        : 
                        <DialogContentText>{props.confirmation || i18next.t("ob.confirmation")}</DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <DeleteCancelButton
                        disabled={isLoading}
                        text={props.canceltext || i18next.t("cancel")}
                        onClick={() => {
                            handleClose();
                        }}
                    />
                    <DeleteButton
                        disabled={isLoading}
                        text={props.submittext || i18next.t("ob.delete")}
                        onClick={() => {
                            setIsLoading(true);
                            (props.special
                                ? props.params.context["delete"](
                                        props.params.data.parent,
                                        props.params.data.elementId
                                    )
                                : props.params.context["delete"](props.params.data.id)
                            )
                                .then((data) => {
                                    handleClose();

                                    props.params.context.AddNotification(
                                        "success",
                                        props.successmessage || i18next.t("ob.success")
                                    );
                                    props.params.context.filter(props.params.context.searchData);
                                })
                                .catch((error) => {
                                    handleClose();

                                    props.params.context.AddNotification(
                                        "error",
                                        props.errormessage || i18next.t("ob.error")
                                    );
                                });
                        }}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
