import API from "../../utils/API";

export function fetchCategorizedCourses(hideEmptyCategories) {
    return API.get(`/e-learning/exam/get-courses`, {
        params: {
            hideEmptyCategories: hideEmptyCategories,
        },
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function fetchExams(courseId) {
    return API.get(`/e-learning/exam/${courseId}/get-course-exam-modules`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function fetchUsers(type, exam, actualCount, search, enrolled, token) {
    let param = actualCount ? '?offset=' + actualCount : '';
    if(search){
        param += (param.length > 0 ? "&" : '?') + `search=${search}`;
    }
    if(enrolled){
        param += (param.length > 0 ? "&" : '?') + `enrolled=${enrolled}`;
    }
    if(token){
        param += (param.length > 0 ? "&" : '?') + `token=${token}`;
    }
    //lekérjük az összes felhasználót
    return API.get(`/e-learning/${type}/${exam}/all-users${param}`)
        .then((response) => {
            return {
                list: response.data.list,
                count: response.data.count,
                token: response.data.token,
                actualCount: actualCount
            }
        })
        .catch((error) => Promise.reject(error));
}

export function postEnrollment(exam, form) {
    return API.post(`/e-learning/exam-enrollment/${exam}/enrol`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function postDismissal(exam, form) {
    return API.post(`/e-learning/exam-dismissal/${exam}/dismiss`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function saveSelectedUser(type, moduleId, userId, selected, search, enrolled) {
    return API.post(`/e-learning/${type}/session-save`, {
        "moduleid" : moduleId,
        "method" : selected ? "put" : "del",
        "userid" : userId,
        "search" : search,
        "enrolled" : enrolled,
    })
        .then((response) => {
            return response.data
        })
        .catch((error) => Promise.reject(error));
}