import React from "react";
import MenuList from "@material-ui/core/MenuList";
import Topic from "../Common/Topic";
import NavMenuItem from "../Common/NavMenuItem";
import { hasPermission, hasPermissions } from "../../utils/AuthHelper";
import Permissions from "../../lib/Permissions";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faBook,
    faBookOpen,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCogs,
    faEdit,
    faFileAlt,
    faGraduationCap,
    faGripVertical,
    faHome,
    faInbox,
    faLanguage,
    faNewspaper,
    faPen,
    faScroll,
    faUnlockAlt,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";

import i18next from "i18next";
import { faFirstdraft } from "@fortawesome/free-brands-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";

export default function SidebarMenu() {
    return (
        <MenuList>
            <NavMenuItem to="/" key={0} text={i18next.t("sidebar.mainpage")} exact icon={faHome} />
            <Topic
                key={1}
                text={i18next.t("sidebar.master_data")}
                children={
                    <React.Fragment>
                        {hasPermission(Permissions.Chambers) && (
                            <NavMenuItem to="/chambers" key={1} icon={faUsers} text={i18next.t("sidebar.chambers")} />
                        )}
                        {(hasPermission(Permissions.Users) || hasPermission(Permissions.ChamberUsers)) && (
                            <NavMenuItem to="/users" key={2} icon={faUser} text={i18next.t("sidebar.users")} />
                        )}
                    </React.Fragment>
                }
            />
            <Topic
                key={2}
                text={i18next.t("sidebar.administration")}
                children={
                    <React.Fragment>
                        {hasPermission(Permissions.Settings) && (
                            <NavMenuItem to="/settings" key={1} icon={faCogs} text={i18next.t("sidebar.settings")} />
                        )}
                        {hasPermission(Permissions.EmailLogs) && (
                            <NavMenuItem to="/email-log" key={2} icon={faBook} text={i18next.t("sidebar.email_log")} />
                        )}
                        {hasPermission(Permissions.EmailTemplates) && (
                            <NavMenuItem
                                to="/email-templates"
                                key={3}
                                icon={faScroll}
                                text={i18next.t("sidebar.email_templates")}
                            />
                        )}
                        {hasPermission(Permissions.Permissions) && (
                            <NavMenuItem
                                to="/permissions"
                                key={4}
                                icon={faUnlockAlt}
                                text={i18next.t("sidebar.permissions")}
                            />
                        )}
                        {hasPermission(Permissions.Logs) && (
                            <NavMenuItem
                                to="/system-log"
                                key={5}
                                icon={faBookOpen}
                                text={i18next.t("sidebar.system_log")}
                            />
                        )}
                        {hasPermission(Permissions.Translations) && (
                            <NavMenuItem
                                to="/translations"
                                key={6}
                                icon={faLanguage}
                                text={i18next.t("sidebar.translations")}
                            />
                        )}
                    </React.Fragment>
                }
            />
            <Topic
                key={3}
                text={i18next.t("sidebar.contents")}
                children={
                    <React.Fragment>
                        {hasPermission(Permissions.News) && (
                            <NavMenuItem to="/news" key={1} icon={faNewspaper} text={i18next.t("sidebar.news")} />
                        )}
                        {hasPermission(Permissions.SystemMessages) && (
                            <NavMenuItem
                                to="/system-messages"
                                key={2}
                                icon={faInbox}
                                text={i18next.t("sidebar.system_messages")}
                            />
                        )}
                        {hasPermission(Permissions.Pages) && (
                            <NavMenuItem to="/pages" key={3} icon={faFileAlt} text={i18next.t("sidebar.pages")} />
                        )}
                    </React.Fragment>
                }
            />
            <Topic
                key={4}
                text={i18next.t("sidebar.elearning")}
                children={
                    <React.Fragment>
                        {hasPermission(Permissions.CourseCategories) && (
                            <NavMenuItem
                                to="/e-learning/course-categories"
                                key={0}
                                icon={faGripVertical}
                                text={i18next.t("sidebar.course_categories")}
                            />
                        )}
                        {hasPermission(Permissions.CourseGeneration) && (
                            <NavMenuItem
                                to="/e-learning/course-generation"
                                key={1}
                                icon={faClone}
                                text={i18next.t("sidebar.course_generation")}
                            />
                        )}
                        {hasPermission(Permissions.CourseManagement) && (
                            <NavMenuItem
                                to="/e-learning/course-management"
                                key={2}
                                icon={faPen}
                                text={i18next.t("sidebar.course_management")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseEnrollment) ||
                            hasPermission(Permissions.CourseCourseEnrollment) ||
                            hasPermission(Permissions.ChamberCourseEnrollment)) && (
                            <NavMenuItem
                                to="/e-learning/enrollment"
                                key={3}
                                icon={faAngleDoubleRight}
                                text={i18next.t("sidebar.course_enrollment")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseDismissal) ||
                            hasPermission(Permissions.CourseCourseDismissal) ||
                            hasPermission(Permissions.ChamberCourseDismissal)) && (
                            <NavMenuItem
                                to="/e-learning/dismissal"
                                key={4}
                                icon={faAngleDoubleLeft}
                                text={i18next.t("sidebar.course_dismissal")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseEnrollmentList) ||
                            hasPermission(Permissions.CourseCourseEnrollmentList) ||
                            hasPermission(Permissions.ChamberCourseEnrollmentList)) && (
                            <NavMenuItem
                                to="/e-learning/course-enrollments"
                                key={5}
                                icon={faCheck}
                                text={i18next.t("sidebar.course_enrollments")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseCompletions) ||
                            hasPermission(Permissions.CourseCourseCompletions) ||
                            hasPermission(Permissions.ChamberCourseCompletions)) && (
                            <NavMenuItem
                                to="/e-learning/course-achievements"
                                key={6}
                                icon={faGraduationCap}
                                text={i18next.t("sidebar.course_achievements")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseExamGeneration) ||
                            hasPermission(Permissions.CourseCourseExamGeneration) ||
                            hasPermission(Permissions.ChamberCourseExamGeneration)) && (
                            <NavMenuItem
                                to="/exam-generation"
                                key={7}
                                icon={faFirstdraft}
                                text={i18next.t("sidebar.exam_generation")}
                            />
                        )}
                        {hasPermissions([
                            Permissions.ElearningContentEditor,
                            Permissions.CourseElearningContentEditor,
                            Permissions.CourseExamManagement,
                            Permissions.CourseCourseExamManagement,
                            Permissions.ChamberCourseExamManagement,
                        ]) && (
                            <NavMenuItem
                                to="/e-learning/exam-management"
                                key={10}
                                icon={faEdit}
                                text={"Vizsgakezelés"}
                            />
                        )}
                        {(hasPermission(Permissions.CourseExamEnrollment) ||
                            hasPermission(Permissions.CourseCourseExamEnrollment) ||
                            hasPermission(Permissions.ChamberCourseExamEnrollment)) && (
                            <NavMenuItem
                                to="/e-learning/exam-enrollment"
                                key={8}
                                icon={faCaretRight}
                                text={i18next.t("sidebar.exam_enrollment")}
                            />
                        )}
                        {(hasPermission(Permissions.CourseExamDismissal) ||
                            hasPermission(Permissions.CourseCourseExamDismissal) ||
                            hasPermission(Permissions.ChamberCourseExamDismissal)) && (
                            <NavMenuItem
                                to="/e-learning/exam-dismissal"
                                key={9}
                                icon={faCaretLeft}
                                text={i18next.t("sidebar.exam_dismissal")}
                            />
                        )}
                    </React.Fragment>
                }
            />
            {/*<NavMenuItem*/}
            {/*    to={"/elearning/login/index.php?token="+ getJWTToken() }*/}
            {/*    key={0}*/}
            {/*    icon={faShare}*/}
            {/*    text="E-Learning belépés"*/}
            {/*/>*/}
        </MenuList>
    );
}
