import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import SettingsTable from "./SettingsTable";
import Header from "../../Common/Header";
import { connect } from "react-redux";
import settingsActions from "../../../store/settings/actions.js";
import Filter from "../../Common/Filter";
import TxtField from "../../Common/TxtField";
import Loading from "../../Common/Loading";
import i18next from "i18next";

function SettingsList(props) {
    const { loading, kereso, user } = props;
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    useEffect(() => {
        props.filter(kereso);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.filter(kereso);
    };

    const handleChange = (event) => props.changeForm("kereso", { [event.target.name]: event.target.value });

    const filterForm = (
        <>
            <Grid item xs={12}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    value={kereso.label}
                    onChange={handleChange}
                    fullWidth
                    id="label"
                    label={i18next.t("settings.label")}
                    name="label"
                />
            </Grid>
        </>
    );

    if (loading) return <Loading />;
    return (
        <div>
            <Grid item container spacing={2}>
                <Header
                    title={i18next.t("sidebar.settings")}
                    breadcrumbs={{
                        administration: i18next.t("sidebar.administration"),
                        settings: i18next.t("sidebar.settings"),
                    }}
                />
                <Filter filterForm={filterForm} defaultForm={props.defaultForm} onSubmit={handleSubmit} />
                <Grid item container xs={12}>
                    <SettingsTable
                        data={props.data}
                        userLang={user.language}
                        gridApi={gridApi}
                        onGridReady={onGridReady}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, kereso, data } = state.settings;
    const { user } = state.authentication;
    return { loading, kereso, data, user };
}

const actionCreators = {
    filter: settingsActions.filter,
    changeForm: settingsActions.changeForm,
    defaultForm: settingsActions.defaultForm,
};

export default connect(mapState, actionCreators)(SettingsList);
