import { types } from "./types";
import permissionsService from "../../services/permissions";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultRow() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_ROW });
    };
}

function defaultForm(variableName) {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM, variableName });
    };
}
function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return permissionsService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function usersSearchGlobal(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USERS_GLOBAL_REQUEST, form });
        return permissionsService.searchGlobal(form).then(
            (data) => {
                dispatch({ type: types.FETCH_USERS_GLOBAL_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_USERS_GLOBAL_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function usersSearchChamber(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USERS_CHAMBER_REQUEST, form });
        return permissionsService.searchChamber(form).then(
            (data) => {
                dispatch({ type: types.FETCH_USERS_CHAMBER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_USERS_CHAMBER_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfoGlobal(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO_GLOBAL, key, value });
    };
}

function setInfoChamber(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO_CHAMBER, key, value });
    };
}

function create(form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST, form });

        return permissionsService.create(form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(form, id) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, form });

        return permissionsService.update(form, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function get(p) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, p });

        return permissionsService.get(p).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getUserPermissionsByCourse(userId, courseId) {
    return (dispatch) => {
        dispatch({
            type: types.GET_USER_COURSE_PERMISSIONS_REQUEST,
            userId,
            courseId,
        });

        return permissionsService.getUserPermissionsByCourse(userId, courseId).then(
            (data) => {
                dispatch({ type: types.GET_USER_COURSE_PERMISSIONS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_USER_COURSE_PERMISSIONS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deleteRow(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return permissionsService.del(id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

const actions = {
    filter,
    create,
    update,
    deleteRow,
    get,
    changeForm,
    defaultForm,
    defaultRow,
    createForm,
    getUserPermissionsByCourse,
    usersSearchGlobal,
    usersSearchChamber,
    setInfoGlobal,
    setInfoChamber,
};

export default actions;
