import API from "../../utils/API";
import { DATE_PICKER_FORMAT_DATE } from "../../utils/AppConst";
import moment from "moment";

export function fetchPages() {
    return API.get(`/pages`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function getPage(id) {
    return API.get(`/pages/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function getVersion(id, versionId) {
    return API.get(`/pages/${id}/versions/${versionId}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function getPrivacyStatementActive() {
    return API.get(`/pages/privacy-policy/active`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function getUserPrivacyStatementCourse(courseId) {
    return API.get(`/e-learning/course-management/${courseId}/privacy-statement/active`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function updateUserPrivacyStatement() {
    return API.patch(`/pages/privacy-policy/active`, { isChecked: true })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function updateUserPrivacyStatementCourse(courseId) {
    return API.patch(`/e-learning/course-management/${courseId}/privacy-statement/active`, { isChecked: true })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function getVersions(id) {
    return API.get(`/pages/${id}/versions`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function createVersion(id, form) {
    if (form.start_date instanceof moment) {
        form.start_date = form.start_date.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.post(`/pages/${id}/versions`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function updateVersion(id, versionId, form) {
    if (form.start_date instanceof moment) {
        form.start_date = form.start_date.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.patch(`/pages/${id}/versions/${versionId}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
export function del(id, versionId) {
    return API.delete(`/pages/${id}/versions/${versionId}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
