import loginService from "../../services/login";
import { types } from "./types";
import { getPrivacyStatementActiveAction } from "../pages/actions";

function login(username, password, reCaptcha) {
    return (dispatch) => {
        dispatch(request({ username }));

        return loginService.login(username, password, reCaptcha).then(
            (data) => {
                dispatch(getPrivacyStatementActiveAction());
                const user = data.user;
                dispatch(success(user));
                this.context.history.push("/");
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error));
                return Promise.reject(error);
            }
        );
    };

    function request(user) {
        return { type: types.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: types.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: types.LOGIN_FAILURE, error };
    }
}
function needReCaptcha() {
    return (dispatch) => {
        dispatch({ type: types.NEED_RE_CAPTCHA_REQUEST });

        return loginService.getNeedReCaptcha().then(
            (data) => {
                dispatch({ type: types.NEED_RE_CAPTCHA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.NEED_RE_CAPTCHA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function confirm(token, password, passwordConfirmation) {
    return (dispatch) => {
        dispatch({ type: types.CONFIRM_REQUEST });
        return loginService.confirm(token, password, passwordConfirmation).then(
            (data) => {
                dispatch({ type: types.CONFIRM_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.CONFIRM_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}
function forgottenPasswordSet(token, password, password1x) {
    return (dispatch) => {
        dispatch({ type: types.FORGOTTEN_PASSWORD_REQUEST });
        return loginService.forgottenPasswordSet(token, password, password1x).then(
            (data) => {
                dispatch({ type: types.FORGOTTEN_PASSWORD_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.FORGOTTEN_PASSWORD_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}

function logout() {
    loginService.logout();
    return { type: types.LOGOUT };
}
function setUser(user) {
    loginService.setUser(user);
    return { type: types.SET_USER, user };
}

function checkToken(token) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_TOKEN_REQUEST });
        return loginService.checkToken(token).then(
            (data) => {
                dispatch({ type: types.CHECK_TOKEN_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const errors = err.toString();
                dispatch({ type: types.CHECK_TOKEN_FAILURE, errors });
                return Promise.reject(err);
            }
        );
    };
}
function refreshLastSeen(name, value) {
    return (dispatch) => {
        const data = { name, value };
        dispatch({ type: types.CHANGE_LAST_SEEN, data });
    };
}

function loginIsziir(authcode) {
    return (dispatch) => {
        dispatch({ type: types.LOGIN_ISZIIR_REQUEST, authcode });

        return loginService.loginIsziir(authcode).then(
            (data) => {
                dispatch({ type: types.LOGIN_ISZIIR_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.LOGIN_ISZIIR_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const loginActions = {
    login,
    logout,
    setUser,
    confirm,
    checkToken,
    needReCaptcha,
    forgottenPasswordSet,
    refreshLastSeen,
    loginIsziir,
};

export default loginActions;
