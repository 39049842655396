import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import SubmitButton from "../Common/SubmitButton";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import CancelButton from "../Common/CancelButton";
import i18next from "i18next";
import { setIsziirUserLoggedIn, isziirLogout, getIsziirUserLoggedIn } from "../../utils/AuthHelper";

/**
 * Amennyiben isziir userrel lép be a felhasználó, úgy megkérdezzük tőle hogy léptessük-e a ki az isziir-ből
 */
function IsziirUserInvalidateModal(props) {
    const [open, setOpen] = React.useState(true);

    return <></>;

    // FIXME: mivel modalban nem kérdezzük meg, ezért ide került a "yes"-re állítása, tehát soha nem léptetjük ki isziir-ből LOGIN után
    if (getIsziirUserLoggedIn() !== "yes") {
        setIsziirUserLoggedIn("yes");
    }

    return (
        <Dialog open={"boolean" === typeof props.open ? props.open : open}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                {i18next.t("isziir_user_invalidate_modal.title")}
            </DialogTitle>
            <form noValidate>
                <DialogContent>{i18next.t("isziir_user_invalidate_modal.text")}</DialogContent>
                <DialogActions>
                    <SubmitButton
                        text={i18next.t("isziir_user_invalidate_modal.btn.onlyThis")}
                        onClick={() => {
                            setIsziirUserLoggedIn("no");
                            setOpen(false);
                            isziirLogout();
                        }}
                    />
                    <CancelButton
                        text={i18next.t("isziir_user_invalidate_modal.btn.both")}
                        onClick={() => {
                            setIsziirUserLoggedIn("yes");
                            setOpen(false);
                        }}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState() {
    return {};
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(IsziirUserInvalidateModal);
