import React, {useEffect} from 'react';
import {Box, Card, CardHeader, Grid, makeStyles} from '@material-ui/core';
import Header from '../../Common/Header';
import SubmitButton from '../../Common/SubmitButton';
import CancelButton from '../../Common/CancelButton';
import LinkToButton from '../../Common/LinkToButton';
import TxtField from '../../Common/TxtField';
import i18next from 'i18next';
import DateTimePicker from '../../Common/DateTimePicker';
import elearningExamManagementActions
    from '../../../store/elearningExamManagement/actions';
import {connect} from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import colors from '../../../styles/colors.scss';
import Loading from '../../Common/Loading';
import Validator from '../../../utils/Validator';
import validator from '../../../store/validator/actions';
import notificationsActions from '../../../store/notifications/actions';
import {convertDateToHyphenFormat} from '../../../utils/AppConst';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    submit: {
        margin: theme.spacing(3, 3, 0, 0),
    },
}));

function ExamManagementEdit(props) {
    const { id } = props.match.params;
    const { editForm, loading } = props;
    const handleChange = (e) => {
        props.changeForm("editForm", {
            ...editForm,
            [e.target.name]: e.target.value,
        });
    };
    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, true)
        props.changeForm('editForm', {
            ...editForm,
            [name]: newDate
        });
    };
    const classes = useStyles();
    const validator = new Validator(editForm, props.validator.validatorErorrs, props.validator.rules);

    useEffect(() => {
        props.getData(id);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        props
            .update(id, editForm)
            .then(() => {
                props.addNotification(
                    "success",
                    i18next.t("course_management.update.success"),
                    "/e-learning/exam-management"
                );
            })
            .catch((error) => {
                props.addNotification("error", i18next.t("course_categories.update.error"));
                validator.setReponseError(error);
                props.setValidatorErrors(validator.getErrors());
            });
    };

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    "/e-learning/course-management": "Vizsgakezelés",
                    "edit-course_management": id,
                }}
            />
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Vizsga szerkesztése" className={classes.header} />
                    <CardContent>
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit} noValidate={true}>
                                {
                                    !!editForm.kamgyakType &&
                                    <Box display="flex" justifyContent="left" alignItems="left">
                                        <Alert severity="warning">{i18next.t("exam_management.kamgyak_redonly_message")}</Alert>
                                    </Box>
                                }
                                <Grid item container spacing={1}>
                                    <Grid item sm={12}>
                                        <TxtField
                                            label="Kategória/alkategória"
                                            margin="normal"
                                            name="category"
                                            value={editForm?.category || ""}
                                            disabled
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <TxtField
                                            label="Kurzus neve"
                                            margin="normal"
                                            name="course"
                                            value={editForm?.course || ""}
                                            disabled
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <TxtField
                                            label="Vizsga neve"
                                            margin="normal"
                                            name="exam"
                                            value={editForm?.exam || ""}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={validator.hasError("exam")}
                                            helperText={validator.getErrorText("exam")}
                                            disabled={!!editForm.kamgyakType}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <DateTimePicker
                                            disablePast={false}
                                            onChange={(value) => handleDateChange(value, "examStart")}
                                            value={editForm?.examStart || ''}
                                            id="examStart"
                                            label="Vizsga kezdése"
                                            name="examStart"
                                            margin="normal"
                                            fullWidth
                                            error={validator.hasError("examStart")}
                                            helperText={validator.getErrorText("examStart")}
                                            disabled={!!editForm.kamgyakType}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <DateTimePicker
                                            disablePast={false}
                                            onChange={(value) => handleDateChange(value, "examEnd")}
                                            value={editForm?.examEnd || ''}
                                            id="examEnd"
                                            label="Vizsga befejezése"
                                            name="examEnd"
                                            margin="normal"
                                            fullWidth
                                            error={validator.hasError("examEnd")}
                                            helperText={validator.getErrorText("examEnd")}
                                            disabled={!!editForm.kamgyakType}
                                        />
                                    </Grid>
                                    {!editForm.kamgyakType &&
                                        <Grid item>
                                            <SubmitButton text={i18next.t("save")} type="submit"/>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <LinkToButton to="/e-learning/exam-management">
                                            <CancelButton variant="outlined" text={i18next.t("cancel")} />
                                        </LinkToButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { editForm, loading } = state.elearningExamManagement;
    const validator = state.validator;
    return { editForm, loading, validator };
}

const actionCreators = {
    changeForm: elearningExamManagementActions.changeForm,
    getData: elearningExamManagementActions.getData,
    update: elearningExamManagementActions.update,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(ExamManagementEdit);
