import React from 'react'
import GreenTooltip from '../../Common/GreenTooltip';
import SendMailIcon from "../Icons/SendMailIcon";
import { Link } from "react-router-dom";
import SendOutNews from './SendOutNews';

export default function SendOutNewsButton(props) {
    const [open, setOpen] = React.useState(false);

    return (
    <>
         <GreenTooltip title={"E-mail kiküldése a hírről"}>
            <Link onClick={() => setOpen(true)}>
                <SendMailIcon />
            </Link>
        </GreenTooltip>
        <SendOutNews open={open} handleClose={() => setOpen(false)} newsId={props.newsId} />
    </>
    )
}
