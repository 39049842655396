import React from "react";
import SystemLogModal from "./SystemLogModal";
import "./SystemLogTable.scss";
import Table from "../../Common/Table";
import i18next from "i18next";

function BtnCellRenderer(params) {
    return <SystemLogModal data={params.data} />;
}

export default function SystemLogTable(props) {

    const table = {
        columnDefs: [
            {
                headerName: i18next.t("system_log.date"),
                field: "created_at",
                sort: "desc",
            },
            {
                headerName: i18next.t("system_log.user"),
                field: "user_id",
                valueGetter(params) {
                    return params.data.user ? params.data.user.name : "—";
                },
            },
            { headerName: i18next.t("system_log.event"), field: "event_translated" },
            { headerName: i18next.t("system_log.type"), field: "type_translated" },
            {
                headerName: i18next.t("system_log.affected_user"),
                field: "affected_user",
                valueGetter(params) {
                    return params.data.affected_user
                        ? params.data.affected_user.name + " / " + params.data.affected_user.email
                        : "-";
                },
            },
            {
                headerName: i18next.t("system_log.affected_course"),
                field: "affected_course",
                valueGetter(params) {
                    if (params.data.affected_course == null) {
                        return "-";
                    }
                    return params.data.affected_course.course_name;
                },
            },
            {
                headerName: i18next.t("system_log.affected_exam"),
                field: "affected_exam",
                valueGetter(params) {
                    if (params.data.affected_exam == null) {
                        return "-";
                    }
                    return params.data.affected_exam.exam_name;
                },
            },
            {
                headerName: i18next.t("system_log.changes"),
                field: "changes",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        loading: props.tableLoading,
        rowData: props.data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
        },
        autoSize: "true",
        filterData: props.filter,
        searchfunc: props.filter,
        info: props.info,
        setInfo: props.setInfo,
        isPaginated: true,
        context: {
            filter: props.filter,
            searchData: props.search,
        },
        minWidth : 100
    };

    return (
        <Table {...table} />
    );
}
