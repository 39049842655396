import React, { useEffect } from "react";
import TxtField from "../../../../Common/TxtField";
import { useDebounce } from "../../../../../hooks";

const PointCellRenderer = ({ id, points, setPoints, all }) => {
    const { point } = points.find((p) => p.id === id);
    const [localPoint, setLocalPoint] = React.useState(point);
    const handleChangeParent = useDebounce((value) => {
        const newPoints = [...points];
        newPoints.forEach((p) => (p.point = value));
        setPoints(newPoints);
    }, 500);

    const handleChange = (e) => {
        if (e.target.value !== "" && !/^\d*\.?\d{0,4}$/.test(e.target.value)) return;

        setLocalPoint(e.target.value);
        if (all) {
            handleChangeParent(e.target.value);
        }
    };

    const handleBlur = () => {
        if (all) return;
        const newPoints = [...points];
        const index = newPoints.findIndex((p) => p.id === id);
        newPoints[index].point = localPoint;
        setPoints(newPoints);
    };

    useEffect(() => {
        setLocalPoint(point);
    }, [point]);

    return (
        <div style={{ width: "100%", height: "100%", padding: "8px", display: "flex", alignItems: "center" }}>
            <TxtField
                key={id + " " + point}
                onBlur={handleBlur}
                value={localPoint}
                variant="outlined"
                onChange={handleChange}
            />
        </div>
    );
};

export default PointCellRenderer;
