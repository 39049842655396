import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../styles/colors.scss";
import CancelButton from "../../Common/CancelButton";
import Header from "../../Common/Header";
import MailIcon from "../Icons/MailIcon";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import LinkToButton from "../../Common/LinkToButton";
import { connect } from "react-redux";
import emailLogActions from "../../../store/emailLog/actions.js";
import Loading from "../../Common/Loading";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
    },
    header: {
        padding: theme.spacing(1, 2),
    },
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    letterStyle: {
        backgroundColor: colors.white,
    },
}));

function EmailLogSheet(props) {
    const classes = useStyles();
    const { loading, letter } = props;

    useEffect(() => {
        props.get(props.match.params.emailId);
    }, []);

    if (loading) return <Loading />;
    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    breadcrumbs={{
                        administration: i18next.t("sidebar.administration"),
                        "email-log": i18next.t("sidebar.email_log"),
                        "email-sheet": i18next.t("email_log.sheet"),
                    }}
                />
                <Card className={classes.card}>
                    <CardHeader title={<MailIcon />} className={classes.header} />
                    <CardContent>
                        <Grid item>
                            <div className={classes.letterStyle}>
                                <Typography>
                                    <b>{i18next.t("email_log.name")}:</b> {letter.email}
                                </Typography>
                                <Typography>
                                    <b>{i18next.t("email_log.subject")}:</b> {letter.subject}
                                </Typography>
                                <Typography>
                                    <b>{i18next.t("email_log.content")}:</b>
                                </Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: letter.content }} />
                            </div>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid item>
                            <LinkToButton to="/email-log">
                                <CancelButton text={i18next.t("back")} />
                            </LinkToButton>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, letter } = state.emailLog;
    return { loading, letter };
}

const actionCreators = {
    get: emailLogActions.get,
};

export default connect(mapState, actionCreators)(EmailLogSheet);
