import React from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import { Select } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        border: "1px solid",
        borderColor: colors.primary,
        padding: "10px 26px 10px 12px",
        "&:focus": {
            borderRadius: 4,
            borderColor: colors.primary,
        },
    },
}))(InputBase);

export default function SelectOutlined(props) {
    return (
        <Select input={<BootstrapInput />} {...props}>
            {props.text}
        </Select>
    );
}
