import { types } from "./types";

const initialState = {
    loading: false,
    data: [],
    selectedCourse: null,
    courseEmptyHideCb: 1,
    search: {},
};

export function elearningCourseEnrollments(state = initialState, action) {
    switch (action.type) {
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.varibleName]: action.data.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.data.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: initialState.data,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };

        case types.SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: action.course,
            };

        case types.CHANGE_EMPTY_HIDE_CB:
            return {
                ...state,
                courseEmptyHideCb: action.val,
            };
        case types.RESET_FILTER:
            return {
                ...state,
                search: {},
            }
        default:
            return state;
    }
}
