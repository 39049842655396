import React from "react";
import { withStyles } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import colors from "../../../styles/colors.scss";

const StyledMailIcon = withStyles({
    root: {
        color: colors.white,
        fontSize: "xx-large",
    },
})(EmailIcon);

export default function MailIcon() {
    return <StyledMailIcon />;
}
