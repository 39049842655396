import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import colors from "../../styles/colors.scss";
import Logo from "../../img/mkik-logo.png";
import Copyright from "../Common/Copyright";
import HelmetTemplate from "../Common/HelmetTemplate";
import { connect } from "react-redux";
import loginActions from "../../store/login/actions.js";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Loading from "../Common/Loading";
import i18next from "i18next";

import { useHistory, useLocation } from 'react-router-dom';
import LinkToButton from "../Common/LinkToButton";
import SubmitButton from "../Common/SubmitButton";
import {logout, setIsziirUserLoggedIn} from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    desc: {
        textAlign: "center",
        fontSize: "25px",
    },
    err: {
        textAlign: "center",
        fontSize: "20px",
        color: colors.error,
        marginTop: "20px",
    },
    success: {
        textAlign: "center",
        fontSize: "20px",
        color: colors.primary,
        marginTop: "20px",
    },
    backBtn: {
        marginTop: "10px",
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function IsziirLogin(props) {
    const classes = useStyles();
    const query = useQuery();
    const { loginIsziirReturn, loginIsziir } = props;
    const [redirectSec, setRedirectSec] = useState(4);
    const [authcode] = useState(query.get('authcode'));
    const history = useHistory();

    if(query.get('authcode')){
        query.delete("authcode");
        history.replace({
            search: query.toString(),
        });
    }

    useEffect(() => {
        // Token ellenőrzés
        setTimeout(function () {
            loginIsziir(authcode).then((data) => {
                if (data.err || !data.user) {
                    logout();
                    return;
                }
                let ownRedirectSec = redirectSec;
                const interval = setInterval(function () {
                    setRedirectSec(ownRedirectSec - 1);
                    ownRedirectSec--;
                    if (ownRedirectSec == 0) {
                        setIsziirUserLoggedIn('yes', 'yes');
                        clearTimeout(interval);
                        window.location.href = "/";
                    }
                }, 1000);
            });
        }, 2000);
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <HelmetTemplate title={i18next.t("isziir_login")} />
            <div className={classes.paper}>
                <img className={classes.avatar} src={Logo} style={{ height: "160px", width: "auto" }} />
                <CssBaseline />
                {loginIsziirReturn && (loginIsziirReturn.user || loginIsziirReturn.err) ? (
                    loginIsziirReturn.err ? (
                        <>
                            <Box className={classes.err}>{loginIsziirReturn.err}</Box>
                            <LinkToButton to="/login" className={classes.backBtn}>
                                <SubmitButton
                                    variant="contained"
                                    className="btn-gray"
                                    text={i18next.t("isziir_login.back_to_the_login")}
                                />
                            </LinkToButton>
                        </>
                    ) : (
                        <Box className={classes.success}>
                            {i18next.t("isziir_login.success_text", {
                                redirectSec: redirectSec,
                            })}
                        </Box>
                    )
                ) : (
                    <>
                        <Loading />
                        <CssBaseline />
                        <Box className={classes.desc}>{i18next.t("isziir_login.description")}</Box>
                    </>
                )}
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

function mapState(state) {
    const { loginIsziirReturn } = state.authentication;
    return { loginIsziirReturn };
}

const actionCreators = {
    loginIsziir: loginActions.loginIsziir,
};

export default compose(withTranslation(), connect(mapState, actionCreators))(IsziirLogin);
