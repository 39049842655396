import { Grid, Typography } from "@material-ui/core";
import React from "react";
import useFetch from "../../hooks/useFetch";

export default function Notification() {
    const { loading, data } = useFetch("/notifications");

    if (loading || !(data && data.content)) return null;

    return (
        <Grid item xs={12}>
            <div
                style={{
                    padding: "16px 12px",
                    backgroundColor: "#EEA968",
                    color: "#fff",
                    borderRadius: "4px",
                    boxShadow: "0 0 12px 0 rgba(0,0,0,0.2)",
                }}
            >
                <Typography variant="body1">{data.content}</Typography>
            </div>
        </Grid>
    );
}
