import { types } from "./types";
import coursesService from "../../services/courses";

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function changeForm(varibleName, form) {
    return (dispatch) => {
        const data = { varibleName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function statementCreateForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM_STATEMENT });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch(request({ form }));

        return coursesService.filter(form).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.FETCH_REQUEST, data };
    }
    function success(data) {
        return { type: types.FETCH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FETCH_FAILURE, error };
    }
}

export function patch(id, data) {
    return (dispatch) => {
        // dispatch(request({ value, id }));

        return coursesService.patch(id, data).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    //function request(data) { return { type: types.POST_REQUEST, data } }
    function success(data) {
        return { type: types.POST_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.POST_FAILURE, error };
    }
}

export function get(id) {
    return (dispatch) => {
        dispatch(request({ id }));

        return coursesService.get(id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.GET_REQUEST, data };
    }
    function success(data) {
        return { type: types.GET_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_FAILURE, error };
    }
}

export function getExams(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_EXAMS_REQUEST, id });

        return coursesService.getExams(id).then(
            (data) => {
                dispatch({ type: types.GET_EXAMS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_EXAMS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

export function getSettings(id) {
    return (dispatch) => {
        dispatch(request({ id }));

        return coursesService.getSettings(id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.GET_SETTINGS_REQUEST, data };
    }
    function success(data) {
        return { type: types.GET_SETTINGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_SETTINGS_FAILURE, error };
    }
}
export function patchSettings(id, form) {
    return (dispatch) => {
        dispatch(request({ id }));

        return coursesService.patchSettings(id, form).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.PATCH_SETTINGS_REQUEST, data };
    }
    function success(data) {
        return { type: types.PATCH_SETTINGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.PATCH_SETTINGS_FAILURE, error };
    }
}

// kurzuskezelés oldalon az adatkezelési nyilatkozat

//ez tölti le a listát
export function statementGetsAction(course) {
    return (dispatch) => {
        dispatch({ type: types.GET_STATEMENTS_VERSION_REQUEST });
        return coursesService.statementGetVersions(course).then(
            (data) => {
                dispatch({ type: types.GET_STATEMENTS_VERSION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_STATEMENTS_VERSION_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

//ez tölt le 1darabot
export function statementGetVersionAction(course, statement) {
    return (dispatch) => {
        dispatch({ type: types.GET_STATEMENT_VERSION_REQUEST });
        return coursesService.statementGetVersion(course, statement).then(
            (data) => {
                dispatch({ type: types.GET_STATEMENT_VERSION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.GET_STATEMENT_VERSION_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}
//ezzel lehet létrehozni
export function statementCreateVersionAction(course, form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_STATEMENT_REQUEST });

        return coursesService.statementCreateVersion(course, form).then(
            (data) => {
                dispatch({ type: types.CREATE_STATEMENT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_STATEMENT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
//ezzel lehet frissiteni
export function statementUpdateVersionAction(course, statement, form) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_STATEMENT_REQUEST });

        return coursesService.statementUpdateVersion(course, statement, form).then(
            (data) => {
                dispatch({ type: types.UPDATE_STATEMENT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_STATEMENT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
//ezzel lehet törölni
export function statementDeleteRow(course, statement) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_STATEMENT_REQUEST });
        return coursesService.statementDel(course, statement).then(
            (data) => {
                dispatch({ type: types.DELETE_STATEMENT_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_STATEMENT_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

//kurzushoz adhato jogok lekérése
export function courseGetPermissionsAction(course) {
    return (dispatch) => {
        dispatch({ type: types.COURSE_GET_PERMISSIONS_REQUEST });
        return coursesService.courseGetPermissions(course).then(
            (data) => {
                dispatch({ type: types.COURSE_GET_PERMISSIONS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.COURSE_GET_PERMISSIONS_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

//kurzushoz adunk jogot
export function coursePostPermissionsAction(course, user, permissions, email) {
    return (dispatch) => {
        dispatch({ type: types.COURSE_POST_PERMISSIONS_REQUEST });
        return coursesService.coursePostPermissions(course, user, permissions, email).then(
            (data) => {
                dispatch({ type: types.COURSE_POST_PERMISSIONS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.COURSE_POST_PERMISSIONS_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

//kurzushoz tartozo userek lekérdezése akinek joga van
export function courseGetPermissionsTableAction(course) {
    return (dispatch) => {
        dispatch({ type: types.COURSE_GET_PERMISSIONS_TABLE_REQUEST });
        return coursesService.courseGetPermissionsTable(course).then(
            (data) => {
                dispatch({ type: types.COURSE_GET_PERMISSIONS_TABLE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.COURSE_GET_PERMISSIONS_TABLE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

//Kurzusok temp frissítése
export function refreshCourses() {
    return (dispatch) => {
        dispatch({ type: types.COURSE_REFRESH_REQUEST });
        return coursesService.refreshCourses().then(
            (data) => {
                dispatch({ type: types.COURSE_REFRESH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.COURSE_REFRESH_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

export function deleteCourse(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return coursesService.deleteCourse(id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

const actions = {
    filter,
    patch,
    get,
    getExams,
    changeForm,
    defaultForm,
    statementGetsAction,
    statementGetVersionAction,
    getSettings,
    patchSettings,
    statementCreateForm,
    statementCreateVersionAction,
    statementUpdateVersionAction,
    statementDeleteRow,
    courseGetPermissionsAction,
    coursePostPermissionsAction,
    courseGetPermissionsTableAction,
    refreshCourses,
    deleteCourse,
};

export default actions;
