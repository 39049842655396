import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import GreenOutlineSelect from "../../Common/GreenOutlineSelect";
import TxtField from "../../Common/TxtField";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import LinkToButton from "../../Common/LinkToButton";
import userActions from "../../../store/users/actions";
import notificationsActions from "../../../store/notifications/actions";
import validator from "../../../store/validator/actions";
import Loading from "../../Common/Loading";
import Validator from "../../../utils/Validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "../../Common/CheckBox";
import Autocomplete from "../../Common/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { authUser } from "../../../utils/AuthHelper";
import i18next from "i18next";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import colors from "../../../styles/colors.scss";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function getTypeSelectors(userId, form, handleChange, validator){

    const typeOptions = [
        { value: 0, label: i18next.t("users.normal"), key: 0 },
        { value: 1, label: i18next.t("isziir"), key: 1 },
    ];

    const subTypeOptions = [
        { value: 'normal', label: i18next.t("users.subtype.normal"), key: 0 },
        { value: 'kamgyak', label: i18next.t("users.subtype.kamgyak"), key: 1 },
    ];

    const typeSelector = <GreenOutlineSelect
        variant="outlined"
        margin="normal"
        id="type"
        selectLabel={i18next.t("users.type")}
        onChange={(event) => handleChange(event)}
        name="type"
        value={form.type === null ? "" : form.type}
        fullWidth
        disabled
        optionList={typeOptions}
        error={validator.hasError("type")}
        helperText={validator.getErrorText("type")}
    />;

    if(userId === null || form.type == 1){
        return <Grid item xs={12} sm={6}>
            {typeSelector}
        </Grid>;
    }

    const subTypeSelector = <GreenOutlineSelect
        variant="outlined"
        margin="normal"
        id="sub_type"
        selectLabel={i18next.t("users.subtype")}
        onChange={(event) => handleChange(event)}
        name="type"
        value={form.sub_type === null ? "normal" : form.sub_type}
        fullWidth
        disabled
        optionList={subTypeOptions}
        error={validator.hasError("sub_type")}
        helperText={validator.getErrorText("sub_type")}
    />;

    return <React.Fragment>
        <Grid item xs={12} sm={3}>
            {typeSelector}
        </Grid>
        <Grid item xs={12} sm={3}>
            {subTypeSelector}
        </Grid>
    </React.Fragment>
}

function UsersForm(props) {
    const { form, user, loading } = props;
    const [originalEmail, setOriginalEmail] = useState(null);
    const [open, setOpen] = useState(false);
    const userId = props.userId ? props.userId : null;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const classes = useStyles();
    const handleChange = (event) => {
        var val = event.target.value;
        if (event.target.name == "confirmationEmail") {
            // checkboxnál máshogy kezeljük
            val = event.target.checked ? 1 : 0;
        }
        // set the original email before the first modification
        if (event.target.name === "email" && originalEmail === null) {
            setOriginalEmail(form.email);
        }

        props.changeForm("form", { ...form, [event.target.name]: val });
    };

    const handleMultiselectChange = (event, values, name) => {
        props.changeForm("form", { ...form, [name]: values.map((v) => v) });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    useEffect(() => {
        props.setValidatorErrors({});
        props.getValidationRules("users", userId);
        props.fetchChambers(true, userId).then(() => {
            if (userId) {
                props.get(userId);
            } else {
                props.createForm();
            }
        });
        setOriginalEmail(null);
    }, []);

    const chamberOptions = props.chambers.map((object, key) => {
        return {
            value: object.id,
            label: object.name[user.language] + (object.active == 0 ? ` '${i18next.t("users.inactive")}'` : ""),
            key: key,
        };
    });

    const languageOptions = [
        { value: "hu", label: i18next.t("hu"), key: 0 },
        { value: "en", label: i18next.t("en"), key: 1 },
    ];

    const boolOptions = [
        { value: false, label: i18next.t("no"), key: 0 },
        { value: true, label: i18next.t("yes"), key: 1 },
    ];

    const sendRequest = () => {
        if (userId) {
            props
                .update(form, userId)
                .then(() => {
                    props.addNotification("success", i18next.t("users.form.success.edit"), "/users");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("users.form.error.edit"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                });
        } else {
            props
                .create(form)
                .then(() => {
                    props.addNotification("success", i18next.t("users.form.success.new"), "/users");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("users.form.error.new"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});
        // open confirmation modal if the user's email is changed
        if (userId !== null && originalEmail !== form.email && originalEmail !== null) {
            setOpen(true);
            return;
        }

        sendRequest();
    };

    const isIsziir = userId && form.type == 1;

    let chamberDisabledValue;
    if (form.chambers.length) {
        chamberDisabledValue = form.chambers.map((object) => {
            return object.label ? object.label : object.name[user.language] +
                (object.active == 0 ? ` '${i18next.t("users.inactive")}'` : "");
        }).join(', ');
    } else {
        chamberDisabledValue = chamberOptions.map((object) => {
            return object.label;
        }).join(', ');
    }


    return loading ? (
        <Loading />
    ) : (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Dialog open={open}>
                <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                    {i18next.t("users.confirm_email_change.title")}
                </DialogTitle>
                <DialogContent>{i18next.t("users.confirm_email_change.info")}</DialogContent>
                <DialogActions>
                    <SubmitButton
                        text={i18next.t("yes")}
                        type="submit"
                        onClick={() => {
                            sendRequest();
                            setOpen(false);
                        }}
                    />
                    <CancelButton
                        text={i18next.t("cancel")}
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </DialogActions>
            </Dialog>
            <Grid item container spacing={1}>
                {getTypeSelectors(userId, form, handleChange, validator)}
                <Grid item xs={12} sm={6}>
                    <TxtField
                        required={validator.isRequired("last_name")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={form.last_name || ""}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        label={i18next.t("users.last_name")}
                        id="last_name"
                        name="last_name"
                        error={validator.hasError("last_name")}
                        helperText={validator.getErrorText("last_name")}
                        disabled={isIsziir}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TxtField
                        required={validator.isRequired("first_name")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={form.first_name || ""}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        label={i18next.t("users.first_name")}
                        id="first_name"
                        name="first_name"
                        error={validator.hasError("first_name")}
                        helperText={validator.getErrorText("first_name")}
                        disabled={isIsziir}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TxtField
                        required={validator.isRequired("email")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={form.email || ""}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        label={i18next.t("users.email")}
                        id="email"
                        name="email"
                        error={validator.hasError("email")}
                        helperText={validator.getErrorText("email")}
                        disabled={isIsziir}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {!hasPermission(Permissions.Users) && hasPermission(Permissions.ChamberUsers) ? (
                        <TxtField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={chamberDisabledValue}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label={i18next.t("sidebar.chambers")}
                            disabled
                        />
                    ) : (
                        <Autocomplete
                            required={validator.isRequired("chambers")}
                            multiple
                            className={`${classes.autocomplete} multiselect-autocomplete-required`}
                            value={form.chambers}
                            options={chamberOptions}
                            getOptionLabel={(option) => {
                                return option.label
                                    ? option.label
                                    : option.name[authUser().language] +
                                          (option.active == 0 ? ` '${i18next.t("users.inactive")}'` : "");
                            }}
                            getOptionSelected={(option, value) =>
                                option.value === value.value || option.value === value.id
                            }
                            filterSelectedOptions
                            id="chambers"
                            margin="normal"
                            variant="outlined"
                            onChange={(e, v) => handleMultiselectChange(e, v, "chambers")}
                            renderInput={(params) => (
                                <TxtField
                                    {...params}
                                    required={validator.isRequired("chambers")}
                                    variant="outlined"
                                    label={i18next.t("sidebar.chambers")}
                                    error={validator.hasError("chambers")}
                                    helperText={validator.getErrorText("chambers")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={chamberOptions.length === 1}
                                />
                            )}
                            disabled={isIsziir}
                        />
                    )}
                </Grid>
                {hasPermission(Permissions.Users) ? (
                    <Grid item xs={12} sm={6}>
                        <GreenOutlineSelect
                            variant="outlined"
                            margin="normal"
                            id="external_user"
                            selectLabel={i18next.t("users.external")}
                            onChange={(event) => handleChange(event)}
                            name="external_user"
                            fullWidth
                            value={form.external_user}
                            optionList={boolOptions}
                            error={validator.hasError("external_user")}
                            helperText={validator.getErrorText("external_user")}
                        />
                    </Grid>
                ) : (
                    ""
                )}
                <Grid item xs={12} sm={6}>
                    <GreenOutlineSelect
                        variant="outlined"
                        margin="normal"
                        id="language"
                        selectLabel={i18next.t("users.language")}
                        onChange={(event) => handleChange(event)}
                        name="language"
                        fullWidth
                        value={form.language || ""}
                        optionList={languageOptions}
                        error={validator.hasError("language")}
                        helperText={validator.getErrorText("language")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GreenOutlineSelect
                        variant="outlined"
                        margin="normal"
                        id="banned"
                        selectLabel={i18next.t("users.banned")}
                        onChange={(event) => handleChange(event)}
                        name="banned"
                        fullWidth
                        value={form.banned}
                        optionList={boolOptions}
                        error={validator.hasError("banned")}
                        helperText={validator.getErrorText("banned")}
                        disabled={isIsziir}
                    />
                </Grid>
                {userId == null ? (
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            control={
                                <CheckBox
                                    checked={form.confirmationEmail == 1}
                                    onChange={(event) => handleChange(event)}
                                    value={1}
                                    name="confirmationEmail"
                                />
                            }
                            label={i18next.t("users.activation.send")}
                        />
                    </Grid>
                ) : (
                    ""
                )}
                <Grid item xs={12} sm={12}></Grid>
                <Grid item>
                    <SubmitButton text={i18next.t("save")} type="submit" />
                </Grid>
                <Grid item>
                    <LinkToButton to="/users">
                        <CancelButton text={i18next.t("cancel")} />
                    </LinkToButton>
                </Grid>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { form, chambers, loading } = state.users;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, user, chambers, loading, validator };
}

const actionCreators = {
    fetchChambers: userActions.fetchChambers,
    changeForm: userActions.changeForm,
    create: userActions.create,
    update: userActions.update,
    get: userActions.get,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    createForm: userActions.createForm,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(UsersForm);
