import React from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import PointCellRenderer from "./PointCellRenderer";

export default function Table({ idColumn, rows, points, setPoints, all }) {
    return (
        <MuiTable>
            <TableHead>
                <TableRow>
                    <TableCell>Tanuló</TableCell>
                    {idColumn !== "none" && <TableCell align="center">Azonosító</TableCell>}
                    <TableCell>Pontszám</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    const id = idColumn !== "none" ? row.attempts.find((a) => a.slot === idColumn).answer : null;

                    return (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.student}
                            </TableCell>
                            {id ? <TableCell align="center">{id}</TableCell> : null}
                            <TableCell>
                                <PointCellRenderer id={row.id} points={points} setPoints={setPoints} all={all} />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
}
