import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    let helper = Object.assign({}, form);

    if(helper && helper.filter){
        if (helper.filter["affected_course"] === '') {
            delete helper.filter['affected_course'];
        }

        if (helper.filter["affected_exam"] === '') {
            delete helper.filter['affected_exam'];
        }
    }

    if (!helper.sort) {
        helper['sort'] = {};
        helper['sort']['created_at'] = 'desc'
    }

    const searchForm = new SearchForm(
        helper,
        {
            createdAtFrom: "created_at",
            createdAtUntil: "created_at",
        },
        {
            created_at: "date",
        },
        true
    );
    let sendSearch = searchForm.getSend();

    if(sendSearch && sendSearch.filter && helper.filter) {
        if (typeof helper.filter["user"] !== typeof undefined) {
            sendSearch.filter["user_id"] = helper.filter['user']['key'];
        }

        if (typeof helper.filter["affected_user"] !== typeof undefined && sendSearch.filter["affected_user"]) {
            sendSearch.filter["affected_user"] = helper.filter['affected_user']['key'];
        }

        if (typeof sendSearch.filter["user"] !== typeof undefined) {
            delete sendSearch.filter["user"];
        }
    }

    return API.get("/logs", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const systemLogService = {
    filter,
};

export default systemLogService;
