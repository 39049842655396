import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import moment from "moment";

function filter(form) {
    const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let helper = Object.assign({}, form);


    if (!helper.sort) {
        helper['sort'] = {};
        helper['sort']['last_name'] = 'asc'
    }
    const searchForm = new SearchForm(
        helper,
        {
            chamber: "chambers",
            external: "external_user",
            loginFrom: "last_login",
            loginUntil: "last_login",
        },
        {
            last_login: "date",
            chambers: "comma",
        },
        true
    );
    let sendSearch = searchForm.getSend();
    sendSearch.withTrashed = "1"; // törölteket is hozzuk

    //ez speciális
    if (sendSearch.filter["password_expires"] && sendSearch.filter["password_expires"].in) {
        if (sendSearch.filter["password_expires"].in.split(",").length === 1) {
            sendSearch.filter["password_expires_at"] = {};
            if (sendSearch.filter["password_expires"].in.split(",")[0] === "0") {
                // eslint-disable-next-line no-unused-expressions
                sendSearch.filter["password_expires_at"].gte = currentDateTime;
            } else {
                // eslint-disable-next-line no-unused-expressions
                sendSearch.filter["password_expires_at"].lte = currentDateTime;
            }
        }
        delete sendSearch.filter["password_expires"];
    }

    if (sendSearch.filter["deleted"] && sendSearch.filter["deleted"].in) {
        if (sendSearch.filter["deleted"].in.split(",").length === 1) {
            sendSearch.filter["deleted_at"] = {};
            if (sendSearch.filter["deleted"].in.split(",")[0] === "0") {
                // eslint-disable-next-line no-unused-expressions
                sendSearch.filter["deleted_at"].null = "null";
            } else {
                // eslint-disable-next-line no-unused-expressions
                sendSearch.filter["deleted_at"].notnull = "notnull";
            }
        }
        delete sendSearch.filter["deleted"];
    }

    return API.get("/users", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function fetch(params) {
    return API.get("/users", { params })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
export function search(params) {
    return API.get("/users/search", { params })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function create(form) {
    const f = Object.assign({}, form);
    f.chambers = f.chambers.map((chamber) => {
        return chamber.value;
    });

    return API.post(`/users`, f)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function update(form, id) {
    const f = Object.assign({}, form);
    f.chambers = f.chambers.map((chamber) => {
        return chamber.value ? chamber.value : chamber.id;
    });

    delete f.chamber_id;

    return API.patch(`/users/${id}`, f)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function sendPasswordReset(userId) {
    return API.post(`/users/${userId}/reset-password-email`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function sendActivationMail(userId) {
    return API.post(`/users/${userId}/activation-email`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function fetchChambers(isForm, userId) {
    var params = {
        sort: "name",
    };

    if (isForm) {
        if (userId) {
            params["activeOrUsedBy"] = userId;
        } else {
            params["active"] = 1;
        }
    } else {
        params["onlyUsersAssigned"] = 1;
    }

    return API.get(`/users/fetch-chambers`, {
        params: params,
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function get(id) {
    return API.get(`/users/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function deleteUser(id) {
    return API.delete(`/users/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

export function deleteRepealUser(id) {
    return API.post(`/users/${id}/restore`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
export function postImpersonation(userId) {
    return API.post(`/users/${userId}/impersonate`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const userService = {
    fetch,
    filter,
    fetchChambers,
    create,
    update,
    deleteUser,
    deleteRepealUser,
    get,
    sendActivationMail,
    sendPasswordReset,
    postImpersonation,
    search,
};

export default userService;
