import { types } from "./types";

const initialState = {
    loadingLeftSide: false,
    loadingRightSide: true,
    loadingSave: false,
    categorizedCourses: [],
    users: {},
    selectedUsers: [],
    exams: [],
    selectedCourse: null,
    selectedExam: null,
    loadingUserList: true,
    loadingUserListToken: null,
    currentSelected: 0,
    currentSelectedIsLoading: 0,
};

export function elearningExamEnrollmentDismissal(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_CATEGORIZED_COURSES_REQUEST:
            return {
                ...state,
                selectedCourse: null,
                loadingLeftSide: true,
            };
        case types.FETCH_CATEGORIZED_COURSES_SUCCESS:
            return {
                ...state,
                loadingLeftSide: false,
                categorizedCourses: action.data,
            };
        case types.FETCH_CATEGORIZED_COURSES_FAILURE:
            return {
                ...state,
                loadingLeftSide: false,
                error: action.error,
            };
        case types.FETCH_USER_REQUEST:
            return {
                ...state,
                loadingUserList: true,
                loadingRightSide: true,
            };
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                loadingRightSide: false,
                loadingUserList: false,
                users: action.data,
            };
        case types.FETCH_USER_FAILURE:
            return {
                ...state,
                loadingRightSide: false,
                error: action.error,
            };

        case types.ADD_SELECTED_USER:
            return {
                ...state,
                selectedUsers: [...state.selectedUsers, action.user],
            };
        case types.DEL_SELECTED_USER:
            return {
                ...state,
                selectedUsers: state.selectedUsers.filter((value) => value !== action.user),
            };
        case types.CLEAR_SELECTED_USER:
            return {
                ...state,
                selectedUsers: [],
            };
        case types.SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: action.course,
            };
        case types.SELECTED_EXAM:
            return {
                ...state,
                selectedExam: action.exam,
                currentSelected: 0,
            };

        case types.FETCH_EXAMS_REQUEST:
            return {
                ...state,
                loadingRightSide: true,
            };
        case types.FETCH_EXAMS_SUCCESS:
            return {
                ...state,
                loadingRightSide: false,
                exams: action.data.data,
            };
        case types.FETCH_EXAMS_FAILURE:
            return {
                ...state,
                loadingRightSide: false,
                error: action.error,
            };

        case types.POST_ENROLLMENT_REQUEST:
            return {
                ...state,
                loadingSave: true,
            };
        case types.POST_ENROLLMENT_SUCCESS:
            return {
                ...state,
                loadingSave: false,
                currentSelected: 0,
            };
        case types.POST_ENROLLMENT_FAILURE:
            return {
                ...state,
                loadingSave: false,
                error: action.error,
            };

        case types.POST_DISMISSAL_REQUEST:
            return {
                ...state,
                loadingSave: true,
            };
        case types.POST_DISMISSAL_SUCCESS:
            return {
                ...state,
                loadingSave: false,
                currentSelected: 0,
            };
        case types.POST_DISMISSAL_FAILURE:
            return {
                ...state,
                loadingSave: false,
                error: action.error,
            };

        case types.CLEAR_SELECTED_EXAM:
            return {
                ...state,
                selectedExam: null,
                currentSelected: 0,
            };

        case types.FETCH_NEXT_PAGE_USER_REQUEST:
            return {
                ...state,
                loadingUserList: true,
                loadingUserListToken: action.token
            };
        case types.FETCH_NEXT_PAGE_USER_SUCCESS:
            if(action.data.token != state.loadingUserListToken){
                return {
                    ...state
                }
            }
            return {
                ...state,
                loadingUserList: false,
                loadingRightSide: false,
                users: {
                    count: action.data.count,
                    list: action.data.actualCount === 0 ? action.data.list : [...state.users.list, ...action.data.list]
                },
            };

        case types.POST_SAVE_SELECTED_USER_REQUEST:
            return {
                ...state,
                currentSelectedIsLoading: state.currentSelectedIsLoading+1,
            }

        case types.POST_SAVE_SELECTED_USER_FAILURE:
            return {
                ...state,
                currentSelectedIsLoading: state.currentSelectedIsLoading-1,
            }

        case types.POST_SAVE_SELECTED_USER_SUCCESS:
            return {
                ...state,
                currentSelected: action.data.currentSelected,
                currentSelectedIsLoading: state.currentSelectedIsLoading-1,
            };

        default:
            return state;
    }
}
