import React, { useEffect, useState } from "react";
import { authUser } from "../../../utils/AuthHelper";
import Table from "../../Common/Table";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";

function BtnCellRenderer(params) {
    return (
        <OperationButton
            onClick={() => {
                document.querySelector("main").scrollTo({ top: 0, left: 0, behavior: "smooth" });
                params.context.userSelect(params.data);
            }}
            type="edit"
        />
    );
}

function PermissionsChambersTable(props) {

    const table = {
        columnDefs: [
            { headerName: i18next.t("permissions.email"), field: "email" },
            { headerName: i18next.t("permissions.last_name"), field: "last_name" },
            { headerName: i18next.t("permissions.first_name"), field: "first_name" },
            {
                headerName: i18next.t("sidebar.permissions"),
                field: "permissions",
                sortable: false,
                valueGetter: (params) => {
                    return params.data.permissions
                        .map((permission) => {
                            return permission.label;
                        })
                        .join(", ");
                },
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("permissions.chamber"),
                field: "chamber",
                valueGetter: (params) => (params.data.chamber ? params.data.chamber.name[authUser().language] : "-"),
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        loading: props.context.tableLoadingChamber,
        rowData: props.context.dataChamber,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
        autoSize: "true",
        filterData: props.filter,
        searchfunc: props.context.usersSearchChamber,
        info: props.context.infoChamber,
        setInfo: props.context.setInfoChamber,
        isPaginated: true,
        context: {
            userSelect: props.onUserSelect,
        },
        minWidth : 100
    };

    return (
        <Table {...table} />
    );
}

export default PermissionsChambersTable;
