import { types } from "./types";
import changePasswordService from "../../services/changePassword";

function post(form) {
    return (dispatch) => {
        dispatch({ type: types.POST_REQUEST, form });

        return changePasswordService.post(form).then(
            (data) => {
                dispatch({ type: types.POST_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.POST_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function changeForm(form) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_FORM, form });
    };
}

function clearForm() {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_FORM });
    };
}

const actions = {
    post,
    changeForm,
    clearForm,
};

export default actions;
