import React from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import Button from "@material-ui/core/Button";

const StyledCancelButton = withStyles({
    root: {
        background: colors.white,
        borderRadius: 3,
        border: "2px solid",
        borderColor: colors.primary,
        color: colors.primary,
        height: 36,
        padding: "0 30px",
        boxShadow: colors.black,
        "&:hover": {
            background: colors.scrollbar,
            color: colors.darkPrimary,
        },
    },
    label: {
        fontWeight: "bold",
    },
})(Button);

export default function CancelButton(props) {
    return <StyledCancelButton {...props}>{props.text}</StyledCancelButton>;
}
