import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import i18next from "i18next";

export default function Course(props) {
    const { data } = props;

    return (
        <React.Fragment>
            {["CourseEnrol", "CourseDismiss", "ExamEnrol", "ExamDismiss"].indexOf(data.type) !== -1 ? (
                <TableRow>
                    <TableCell>{i18next.t(`system_log.field.is_import`)}</TableCell>
                    <TableCell colSpan={2}>
                        {data.changes && data.changes.import ? i18next.t("yes") : i18next.t("no")}
                    </TableCell>
                </TableRow>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </React.Fragment>
    );
}
