import { types } from "./types";

const initialLanguages = { hu: null, en: null };

const initialState = {
    loading: false,
    statementLoading: false,
    settingsLoading: false,
    statementFormLoading: false,
    coursePermissionsLoading: false,
    statementForm: {
        id: null,
        content: initialLanguages,
        end_date: null,
        start_date: null,
    },
    statementDefaultForm: {
        id: null,
        content: initialLanguages,
        end_date: null,
        start_date: null,
    },
    data: [],
    statementData: [],
    coursePermissionsData: [],
    coursePermissionsTableData: [],
    coursePermissionsTableLoading: false,
    search: {},
    settings: {},
    row: { label: "", unit: "", value: "" },
    exams: [],
};

export function courses(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.varibleName]: action.data.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.data.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.PATCH_SETTINGS_REQUEST:
            return {
                ...state,
                settingsLoading: true,
            };
        case types.PATCH_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoading: false,
            };
        case types.PATCH_SETTINGS_FAILURE:
            return {
                ...state,
                settingsLoading: false,
                error: action.error,
            };

        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };

        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };

        case types.GET_SETTINGS_REQUEST:
            return {
                ...state,
                settingsLoading: true,
            };
        case types.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoading: false,
                settings: action.data.data,
                error: action.error,
            };
        case types.GET_SETTINGS_FAILURE:
            return {
                ...state,
                settingsLoading: false,
                settings: {},
            };
        case types.GET_STATEMENT_VERSION_REQUEST:
            return {
                ...state,
                statementLoading: true,
            };
        case types.GET_STATEMENT_VERSION_SUCCESS:
            return {
                ...state,
                statementLoading: false,
                statementForm: action.data.data,
            };
        case types.GET_STATEMENT_VERSION_FAILURE:
            return {
                ...state,
                statementLoading: false,
                statementForm: state.statementDefaultForm,
                error: action.error,
            };
        case types.CREATE_FORM_STATEMENT:
            return {
                ...state,
                statementForm: state.statementDefaultForm,
            };

        case types.GET_STATEMENTS_VERSION_REQUEST:
            return {
                ...state,
                statementLoading: true,
            };
        case types.GET_STATEMENTS_VERSION_SUCCESS:
            return {
                ...state,
                statementLoading: false,
                statementData: action.data.data,
            };
        case types.GET_STATEMENTS_VERSION_FAILURE:
            return {
                ...state,
                statementLoading: false,
                statementData: [],
                error: action.error,
            };

        case types.CREATE_STATEMENT_REQUEST:
            return {
                ...state,
                statementFormLoading: true,
            };
        case types.CREATE_STATEMENT_SUCCESS:
            return {
                ...state,
                statementFormLoading: false,
            };
        case types.CREATE_STATEMENT_FAILURE:
            return {
                ...state,
                statementFormLoading: false,
                error: action.error,
            };
        case types.UPDATE_STATEMENT_REQUEST:
            return {
                ...state,
                statementFormLoading: true,
            };
        case types.UPDATE_STATEMENT_SUCCESS:
            return {
                ...state,
                statementFormLoading: false,
            };
        case types.UPDATE_STATEMENT_FAILURE:
            return {
                ...state,
                statementFormLoading: false,
                error: action.error,
            };
        case types.DELETE_STATEMENT_REQUEST:
            return {
                ...state,
                statementLoading: true,
            };
        case types.DELETE_STATEMENT_SUCCESS:
            return {
                ...state,
                statementLoading: false,
            };
        case types.DELETE_STATEMENT_FAILURE:
            return {
                ...state,
                statementLoading: false,
                error: action.error,
            };

        case types.COURSE_GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                coursePermissionsLoading: true,
            };
        case types.COURSE_GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                coursePermissionsLoading: false,
                coursePermissionsData: action.data.data,
            };
        case types.COURSE_GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                coursePermissionsLoading: false,
                coursePermissionsData: [],
                error: action.error,
            };
        case types.COURSE_GET_PERMISSIONS_TABLE_REQUEST:
            return {
                ...state,
                coursePermissionsTableLoading: true,
            };
        case types.COURSE_GET_PERMISSIONS_TABLE_SUCCESS:
            return {
                ...state,
                coursePermissionsTableLoading: false,
                coursePermissionsTableData: action.data.data,
            };
        case types.COURSE_GET_PERMISSIONS_TABLE_FAILURE:
            return {
                ...state,
                coursePermissionsTableLoading: false,
                coursePermissionsTableData: [],
                error: action.error,
            };

        case types.GET_EXAMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_EXAMS_FAILURE:
            return {
                ...state,
                loading: false,
                exams: initialState.exams,
                error: action.error,
            };
        case types.GET_EXAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                exams: action.data.data,
            };

        default:
            return state;
    }
}
