import { types } from "./types";

const initialState = {
    validatorErorrs: {},
    rules: [],
};

export function validator(state = initialState, action) {
    switch (action.type) {
        case types.SET_ERRORS:
            return {
                ...state,
                validatorErorrs: action.data,
            };

        case types.GET_RULES_REQUEST:
            return {
                ...state,
                rules: [],
            };
        case types.GET_RULES_SUCCESS:
            return {
                ...state,
                rules: action.data.data,
            };
        case types.GET_RULES_FAILURE:
            return {
                ...state,
                rules: [],
                error: action.error,
            };
        case types.SET_RULES_SUCCESS:
            return {
                ...state,
                rules: action.data,
            };

        default:
            return state;
    }
}
