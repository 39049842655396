import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { DATE_PICKER_FORMAT_DATE } from "../../utils/AppConst";
import moment from "moment";

function filter(form) {
    const searchForm = new SearchForm(
        form,
        {
            courseEndFrom: "courseEnd",
            courseEndUntil: "courseEnd",
            courseStartFrom: "courseStart",
            courseStartUntil: "courseStart",
        },
        {
            courseEnd: "date",
            courseStart: "date",
        }
    );
    let sendSearch = searchForm.getSend();

    return API.get("/courses-all", {
        params: sendSearch,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function patch(id, data) {
    return API.patch(`/courses/${id}`, data)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(`/courses/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getExams(id) {
    return API.get(`/courses/${id}/exams`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getSettings(course) {
    return API.get(`/e-learning/course-management/${course}/settings`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function patchSettings(course, form) {
    return API.patch(`/e-learning/course-management/${course}/settings`, form)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function statementGetVersion(course, statement) {
    return API.get(`/e-learning/course-management/${course}/privacy-statements/${statement}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
function statementGetVersions(course) {
    return API.get(`/e-learning/course-management/${course}/privacy-statements`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function statementCreateVersion(course, form) {
    if (form.start_date instanceof moment) {
        form.start_date = form.start_date.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.post(`/e-learning/course-management/${course}/privacy-statements`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function statementUpdateVersion(course, statement, form) {
    if (form.start_date instanceof moment) {
        form.start_date = form.start_date.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.patch(`/e-learning/course-management/${course}/privacy-statements/${statement}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function statementDel(course, statement) {
    return API.delete(`/e-learning/course-management/${course}/privacy-statements/${statement}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function courseGetPermissions(course) {
    return API.get(`e-learning/course-management/${course}/permissions`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
function courseGetPermissionsTable(course) {
    return API.get(`e-learning/course-management/${course}/permissions/get-users`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function coursePostPermissions(course, user, permissions, email) {
    const permissionsFormat = permissions.map((object, key) => {
        return object.name;
    });
    console.log('asd', email);
    return API.post(`e-learning/course-management/${course}/permissions/${user}`, { permissions: permissionsFormat, email: email })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function refreshCourses() {
    return API.post(`e-learning/course-management/refresh-courses`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function deleteCourse(id) {
    return API.delete(process.env.REACT_APP_API_URL + `/courses/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function archiveCourse(id) {
    return API.patch(process.env.REACT_APP_API_URL + `/courses/archive/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function unArchiveCourse(id) {
    return API.patch(process.env.REACT_APP_API_URL + `/courses/unarchive/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const coursesService = {
    filter,
    patch,
    get,
    getExams,
    statementGetVersion,
    statementGetVersions,
    statementCreateVersion,
    statementUpdateVersion,
    statementDel,
    getSettings,
    patchSettings,
    courseGetPermissions,
    coursePostPermissions,
    courseGetPermissionsTable,
    refreshCourses,
    deleteCourse,
    archiveCourse,
    unArchiveCourse,
};

export default coursesService;
