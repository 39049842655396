import React from "react";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import i18next from "i18next";

export default function MassImportInfo() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Alert severity="info">{i18next.t("elearning.mass_enrollment_info")}</Alert>
        </Box>
    );
}
