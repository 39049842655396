import React from "react";
import GreenTableCell from "../../Common/GreenTableCell";
import colors from "../../../styles/colors.scss";
import i18next from "i18next";
import {
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../../Common/SubmitButton";
import GreenTooltip from "../../Common/GreenTooltip";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
}));

export default function UsersTooltipTable(props) {
    const classes = useStyles();
    const { data } = props;

    const handleOpen = () => {
        console.log(Object.entries(data));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [open, setOpen] = React.useState(false);

    let creator = "-";
    let editor = "-";
    let deletor = "-";

    if (data.creator) creator = data.creator.last_name + " " + data.creator.first_name + " / " + data.creator.email;
    if (data.updator) editor = data.updator.last_name + " " + data.updator.first_name + " / " + data.updator.email;
    if (data.deletor) deletor = data.deletor.last_name + " " + data.deletor.first_name + " / " + data.deletor.email;

    return (
        <>
            <GreenTooltip title={i18next.t("system_log.infos")}>
                <IconButton onClick={handleOpen}>
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.primary} />
                </IconButton>
            </GreenTooltip>
            <Dialog open={open} onClose={handleClose} fullScreen={false}>
                <DialogTitle className={classes.title}>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    {i18next.t("system_log.infos")}
                </DialogTitle>
                <DialogContent>
                    <Card style={{ overflow: "auto" }}>
                        <CardContent className={classes.content}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <GreenTableCell>{i18next.t("system_log.key")}</GreenTableCell>
                                        <GreenTableCell>{i18next.t("system_log.value")}</GreenTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.id")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{data.id}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.moodle_id")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{data.moodle_id || "-"}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.create_date")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{data.created_at}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.creator")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{creator}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.update_date")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{data.updated_at != data.created_at ? data.updated_at : "-"}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <GreenTableCell className={classes.cell}>
                                            {`${i18next.t("users.modifier")}:`}
                                        </GreenTableCell>
                                        <GreenTableCell className={classes.cell}>
                                            <b>{data.updated_at != data.created_at ? editor : "-"}</b>
                                        </GreenTableCell>
                                    </TableRow>
                                    {data.deleted_at && (
                                        <>
                                            <TableRow>
                                                <GreenTableCell className={classes.cell}>
                                                    {`${i18next.t("users.deleted_at")}:`}
                                                </GreenTableCell>
                                                <GreenTableCell className={classes.cell}>
                                                    <b>{data.deleted_at}</b>
                                                </GreenTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <GreenTableCell className={classes.cell}>
                                                    {`${i18next.t("users.deleted_by")}:`}
                                                </GreenTableCell>
                                                <GreenTableCell className={classes.cell}>
                                                    <b>{deletor}</b>
                                                </GreenTableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("system_log.close")} onClick={handleClose} />
                </DialogActions>
            </Dialog>
        </>
    );
}
