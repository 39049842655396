import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import i18next from "i18next";
import React from "react";
import Autocomplete from "../../Common/Autocomplete";
import TxtField from "../../Common/TxtField";
import CancelButton from "../../Common/CancelButton";
import SubmitButton from "../../Common/SubmitButton";

export default function MassOperationGroupAddDialog(props) {
    const [localForm, setLocalForm] = React.useState([]);

    const handleClose = () => {
        setLocalForm([]);
        props.setOpen(false);
    };

    const handleSubmit = () => {
        props.setForm({
            ...props.form,
            courses: props.form.courses.map((c) =>
                ({ ...c, roles: [...(c.roles.filter((e) => !localForm.find((el) => el.id === e.id))), ...localForm] })
            ),
        });
        setLocalForm([]);
        props.setOpen(false);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <Grid container style={{ justifyContent: 'space-between' }}>
                    <Grid item>{i18next.t("course_management.mass_add")}</Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <Autocomplete
                            options={props.permissions.sort(
                                (a, b) =>
                                    props.order.indexOf(a.type) - props.order.indexOf(b.type) ||
                                    a.label.localeCompare(b.label)
                            )}
                            multiple
                            getOptionLabel={(option) => option.label}
                            label={i18next.t("permissions.permissions")}
                            value={localForm}
                            filterSelectedOptions
                            getOptionSelected={(option, value) =>
                                option.id === value.id ||
                                option === value ||
                                option.id === value ||
                                option === value.id
                            }
                            groupBy={(option) => option.type}
                            onChange={(_, newValue) => {
                                setLocalForm(newValue);
                            }}
                            renderInput={(params) => (
                                <TxtField {...params} variant="outlined" label={i18next.t("permissions.permissions")} />
                            )}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <CancelButton text={i18next.t("cancel")} onClick={handleClose} />
                    <SubmitButton
                        text={i18next.t("save")}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
