import React from "react";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import Table from "../../Common/Table";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";

function BtnCellRenderer(params) {
    return <OperationButton to={`/email-templates/edit/${params.data.id}`} type="edit" />;
}

function valueGetter(params) {
    const val = params.data[params.column.colId][params.context];
    return val ? val : "-";
}

export default function EmailTemplatesTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("email_templates.key"),
                field: "key",
                cellRenderer: "defaultCellRenderWithTooltip",
                sort: "asc",
            },
            {
                headerName: i18next.t("email_templates.name"),
                field: "name",
                valueGetter,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("email_templates.subject"),
                field: "subject",
                valueGetter,
                cellRenderer: "defaultCellRenderWithTooltip",
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                sortable: false,
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                flex: 0.5,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
    };

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            onGridReady={props.onGridReady}
            gridApi={props.gridApi}
            context={props.userLang}
        />
    );
}
