import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import colors from "../../styles/colors.scss";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: colors.lightPrimary,
        color: colors.darkPrimary,
        fontSize: "large",
    },
}))(TableCell);

export default function GreenTableCell(props) {
    return <StyledTableCell component="td" scope="row" {...props} />;
}
