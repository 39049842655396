export const types = {
    LOGIN_REQUEST: "@@login/REQUEST",
    LOGIN_SUCCESS: "@@login/SUCCESS",
    LOGIN_FAILURE: "@@login/FAILURE",
    LOGOUT: "@@logout",
    SET_USER: "@@set_user",

    CHECK_TOKEN_REQUEST: "@@login/CHECK_TOKEN_REQUEST",
    CHECK_TOKEN_SUCCESS: "@@login/CHECK_TOKEN_SUCCESS",
    CHECK_TOKEN_FAILURE: "@@login/CHECK_TOKEN_FAILURE",

    CONFIRM_REQUEST: "@@login/CONFIRM_REQUEST",
    CONFIRM_SUCCESS: "@@login/CONFIRM_SUCCESS",
    CONFIRM_FAILURE: "@@login/CONFIRM_FAILURE",

    NEED_RE_CAPTCHA_REQUEST: "@@login/NEED_RE_CAPTCHA_REQUEST",
    NEED_RE_CAPTCHA_SUCCESS: "@@login/NEED_RE_CAPTCHA_SUCCESS",
    NEED_RE_CAPTCHA_FAILURE: "@@login/NEED_RE_CAPTCHA_FAILURE",

    FORGOTTEN_PASSWORD_REQUEST: "@@login/FORGOTTEN_PASSWORD_REQUEST",
    FORGOTTEN_PASSWORD_SUCCESS: "@@login/FORGOTTEN_PASSWORD_SUCCESS",
    FORGOTTEN_PASSWORD_FAILURE: "@@login/FORGOTTEN_PASSWORD_FAILURE",

    CHANGE_LAST_SEEN: "@@login/CHANGE_LAST_SEEN",

    LOGIN_ISZIIR_REQUEST: "@@login/ISZIIR_REQUEST",
    LOGIN_ISZIIR_SUCCESS: "@@login/ISZIIR_SUCCESS",
    LOGIN_ISZIIR_FAILURE: "@@login/ISZIIR_FAILURE",
};
