import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "./CancelButton";
import i18next from "i18next";

export default function ImportDialog(props) {
    //const classes = useStyles();

    return (
        <Dialog open={props.open}>
            <DialogTitle>
                {props.errors ? i18next.t("import.upload.error") : i18next.t("import.information")}
            </DialogTitle>
            <DialogContent>{props.errors && getErrorPrint(props.errors)}</DialogContent>
            <DialogActions>
                <CancelButton
                    text={i18next.t("cancel")}
                    onClick={() => {
                        props.setOpen(false);
                    }}
                />
            </DialogActions>
        </Dialog>
    );
}

function getErrorPrint(errors) {
    let ulELements = null;

    if (errors.file) {
        ulELements = Object.entries(errors.file).map(function (obj) {
            return <li key={obj[0]}>{obj[1]}</li>;
        });
    } else if (errors.error_message) {
        ulELements = <li key={0}> {errors.error_message}</li>;
    } else if (typeof errors === typeof "") {
        ulELements = errors.split("\n").map(function (item, idx) {
            return <li key={idx}> {item}</li>;
        });
    } else {
        return JSON.stringify(errors); // FIXME: további kezelése
    }

    return <ul>{ulELements}</ul>;
}
