import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import colors from "../../styles/colors.scss";

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: colors.white,
        color: colors.primary,
        //maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

export default function GreenTooltip(props) {
    return <StyledTooltip {...props} />;
}
