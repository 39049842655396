import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function get(courseId) {
    return API.get(`/e-learning/course-enrollments/${courseId}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function filter(form, courseId) {
    const searchForm = new SearchForm(
        form
    );
    let sendSearch = searchForm.getSend();

    return API.get(`/e-learning/course-enrollments/${courseId}`, {
        params: sendSearch,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function download(courseId, search) {
    const searchForm = new SearchForm(
        search
    );
    let sendSearch = searchForm.getSend();

    return API.get(`enrollment/enrolled-users/${courseId}/export`, {
        params: sendSearch,
        responseType: "blob",
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const elearningCourseEnrollmentsService = {
    filter,
    get,
    download,
};

export default elearningCourseEnrollmentsService;
