import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import colors from "../../styles/colors.scss";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";

/*
 * Használat:
 * <NavMenuItem
 *    to="route"
 *    key={kulcs}
 *    icon={ikon neve}
 *    text="oldal neve"
 * />
 */

const useStyles = makeStyles((theme) => ({
    item: {
        color: colors.primary,
        "&:hover, &:hover i": {
            backgroundColor: colors.lightPrimary,
        },
        "&.$selected": {
            color: colors.white,
        },
    },
    icon: {
        color: colors.primary,
    },
    selected: {
        backgroundColor: colors.primary,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.primary,
        },
        "& $icon": {
            color: colors.white,
        },
        "& $item": {
            color: colors.white,
        },
    },
}));

export default function NavMenuItem(props) {
    const classes = useStyles();

    return (
        <MenuItem component={NavLink} className={classes.item} activeClassName={clsx(classes.selected)} {...props}>
            <ListItemIcon>
                <FontAwesomeIcon icon={props.icon} className={classes.icon} />
            </ListItemIcon>
            {props.text ? <Typography variant="inherit">{props.text}</Typography> : props.children}
        </MenuItem>
    );
}
