import React, { Component } from "react";
import { AppBar, CssBaseline, Grid, Tab } from "@material-ui/core";
import Header from "../../Common/Header";
import AntTabs from "../../Common/AntTabs";
import colors from "../../../styles/colors.scss";
import TabPanel from "../../Common/TabPanel";
import PermissionsGlobal from "./PermissionsGlobal";
import PermissionsChambers from "./PermissionsChambers";
import { connect } from "react-redux";
import permissionActions from "../../../store/permissions/actions";
import userActions from "../../../store/users/actions";
import notificationsActions from "../../../store/notifications/actions";
import chamberActions from "../../../store/chambers/actions";
import validator from "../../../store/validator/actions";
import i18next from "i18next";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

class PermissionsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            gridApi: "",
        };
    }

    render() {
        const onGridReady = (params) => this.setState({ gridApi: params.api });
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };

        return (
            <React.Fragment>
                <CssBaseline />
                <Grid item container spacing={2}>
                    <Header
                        id="topNav"
                        title={i18next.t("sidebar.permissions")}
                        breadcrumbs={{
                            administration: i18next.t("sidebar.administration"),
                            chambers: i18next.t("sidebar.permissions"),
                        }}
                    />
                    <Grid item xs={12}>
                        <AppBar position="static">
                            <AntTabs value={this.state.value} variant="fullWidth" onChange={handleChange}>
                                <Tab label={i18next.t("permissions.global_admin")} {...a11yProps(0)} />
                                <Tab label={i18next.t("permissions.chamber_admin")} {...a11yProps(1)} />
                            </AntTabs>
                            <TabPanel
                                style={{ backgroundColor: colors.white, display: "block" }}
                                value={this.state.value}
                                index={0}
                            >
                                <PermissionsGlobal
                                    context={this.props}
                                    gridApi={this.state.gridApi}
                                    onGridReady={onGridReady}
                                />
                            </TabPanel>
                            <TabPanel
                                style={{ backgroundColor: colors.white, display: "block" }}
                                value={this.state.value}
                                index={1}
                            >
                                <PermissionsChambers
                                    context={this.props}
                                    gridApi={this.state.gridApi}
                                    onGridReady={onGridReady}
                                />
                            </TabPanel>
                        </AppBar>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.permissions.data,
        loading: state.permissions.loading,
        userLoading: state.users.loading,
        userLang: state.authentication.user.language,
        search: state.permissions.search,
        formGlobal: state.permissions.formGlobal,
        formChamber: state.permissions.formChamber,
        users: state.permissions.users,
        chambers: state.permissions.chambers,
        validator: state.validator,
        dataGlobal: state.permissions.dataGlobal,
        infoGlobal: state.permissions.infoGlobal,
        infoChamber: state.permissions.infoChamber,
        dataChamber: state.permissions.dataChamber,
        tableLoadingGlobal: state.permissions.tableLoadingGlobal,
        tableLoadingChamber: state.permissions.tableLoadingChamber,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetch: (form) => dispatch(permissionActions.filter(form)),
        get: (p) => dispatch(permissionActions.get(p)),
        create: (form) => dispatch(permissionActions.create(form)),
        fetchUsers: (filter) => dispatch(userActions.fetch(filter || {})),
        usersSearch: (filter) => dispatch(userActions.search(filter || {})),
        usersSearchGlobal: (filter) => dispatch(permissionActions.usersSearchGlobal(filter || {})),
        usersSearchChamber: (filter) => dispatch(permissionActions.usersSearchChamber(filter || {})),
        setInfoGlobal: (key, value) => dispatch(permissionActions.setInfoGlobal(key, value)),
        setInfoChamber: (key, value) => dispatch(permissionActions.setInfoChamber(key, value)),
        addNotification: (...params) => dispatch(notificationsActions.addNotification(...params)),
        fetchChambers: () => dispatch(chamberActions.filter()),
        chambersSearch: (filter) => dispatch(chamberActions.search(filter || {})),
        defaultForm: (name) => dispatch(permissionActions.defaultForm(name)),
        changeForm: (variableName, form) => dispatch(permissionActions.changeForm(variableName, form)),
        setValidatorErrors: (data) => dispatch(validator.setErrors(data)),
        getValidationRules: (model, id) => dispatch(validator.getRules(model, id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsList);
