import React from 'react'
import GreenTooltip from '../../Common/GreenTooltip';
import SendMailIcon from "../Icons/SendMailIcon";
import { Link } from "react-router-dom";
import SendOutMessage from './SendOutMessage';

export default function SendOutMessageButton(props) {
    const [open, setOpen] = React.useState(false);

    return (
    <>
         <GreenTooltip title={"E-mail kiküldése a hírről"}>
            <Link onClick={() => setOpen(true)}>
                <SendMailIcon />
            </Link>
        </GreenTooltip>
        <SendOutMessage open={open} handleClose={() => setOpen(false)} messageId={props.messageId} />
    </>
    )
}
