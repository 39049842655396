import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import classNames from 'classnames';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: '2px',
    },
    badge: {
        padding: '4px',
        borderRadius: '3px',
        fontWeight: 'bold'
    },
    colorSucess: {
        backgroundColor: colors.lightGreen,
        color: colors.white,
    },
}));

export function TableBadge(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span className={classNames(classes.badge, classes.colorSucess)}>
                {props.text}
            </span>
        </div>
    );
}


