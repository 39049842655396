import API from "../../utils/API";
import { history } from "../../store";
import {
    getImpersonationToken,
    removeImpersonationToken, removeIsziirUserLoggedIn,
    removeJWTToken,
    removeUser,
    setJWTToken,
    setUser1,
} from "../../utils/AuthHelper";

function login(email, password, reCaptcha) {
    return API.post("/auth/login", {
        email: email,
        password: password,
        "g-recaptcha-response": reCaptcha,
    })
        .then((response) => {
            setUser(response.data.user, response.data.access_token);
            history.push("/");
            removeIsziirUserLoggedIn();
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function confirm(token, password, passwordConfirmation) {
    return API.post("/users/activate", {
        token: token,
        password: password,
        password_confirmation: passwordConfirmation,
    })
        .then((response) => {
            history.push("/login");
            return response.data;
        })
        .catch((error) => {
            //FIXME: később jol fog jönni ha netán nem tudnánk hogyan műxik
            //https://github.com/axios/axios#handling-errors
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
            return Promise.reject(error);
        });
}
function forgottenPasswordSet(token, password, passwordConfirmation) {
    return API.post("/users/forgotten-password/set", {
        token: token,
        password: password,
        password_confirmation: passwordConfirmation,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function checkToken(token) {
    return API.get(process.env.REACT_APP_API_URL + `/activation-codes/${token}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function getNeedReCaptcha() {
    return API.get(`/auth/captcha-required`)
        .then((data) => {
            return data.data.showCaptcha;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function logout() {
    // remove user from local storage to log user out
    removeJWTToken();
    removeUser();
}

export function logoutImpersonation() {
    removeImpersonationToken();
}

export function needLogoutImpersonationBtn() {
    return !(getImpersonationToken() === undefined);
}

function setUser(user, token) {
    setUser1(user);
    if (token) setJWTToken(token);
}

function loginIsziir(authcode) {
    return API.post("/isziir-auth/redirect", {
       'authcode' : authcode
    })
        .then((response) => {
            if (response.data.user && response.data.access_token) {
                setUser(response.data.user, response.data.access_token);
            }
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const userService = {
    login,
    logout,
    setUser,
    confirm,
    checkToken,
    getNeedReCaptcha,
    forgottenPasswordSet,
    loginIsziir,
};

export default userService;
