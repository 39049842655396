import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Parser from "html-react-parser";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    title: {
        margin: theme.spacing(2),
        fontSize: "large",
    },
    new: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    icon: {
        margin: theme.spacing(1.5, 0, 0, 0),
        fontSize: "xx-large",
    },
    subTitle: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        fontWeight: "bold",
        fontSize: "x-large",
    },
}));

export default function MainNews(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Grid item xs={12}>
            <Paper>
                <div className={classes.header} onClick={handleClick}>
                    <Grid container justify="space-between">
                        <Grid item xs={12} md={4} className={classes.title}>
                            {props.date}
                        </Grid>
                        <Grid item className={classes.title}>
                            {props.title}
                        </Grid>
                        <Grid item>
                            {open ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
                        </Grid>
                    </Grid>
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Typography className={classes.new} component={"div"}>
                        {Parser(props.new)}
                    </Typography>
                </Collapse>
            </Paper>
        </Grid>
    );
}
