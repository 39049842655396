import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import colors from "../styles/colors.scss";
import SubmitButton from "../Components/Common/SubmitButton";
import LinkToButton from "../Components/Common/LinkToButton";
import Logo from "../img/MKIK_vektor.svg";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: colors.primary,
    },
    title: {
        fontSize: "xxx-large",
        marginBottom: theme.spacing(5),
    },
    text: {
        fontSize: "x-large",
        marginBottom: theme.spacing(5),
        textAlign: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function NotFound() {
    const classes = useStyles();
    return (
        <Container component="main">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                    className={classes.avatar}
                    src={Logo}
                    style={{ height: "20vh", width: "auto", marginTop: "10%" }}
                    alt={`Logo`}
                />
                <Typography className={classes.title}>404</Typography>
                <Typography className={classes.text}>Sajnos az Ön által megadott oldal nem található.</Typography>
                <LinkToButton to="/">
                    <SubmitButton text="Vissza a főoldalra" />
                </LinkToButton>
            </div>
        </Container>
    );
}
