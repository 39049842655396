export const types = {
    FETCH_CATEGORIZED_COURSES_REQUEST: "@@enrollment/categorizedCourses/FETCH_REQUEST",
    FETCH_CATEGORIZED_COURSES_SUCCESS: "@@enrollment/categorizedCourses/FETCH_SUCCESS",
    FETCH_CATEGORIZED_COURSES_FAILURE: "@@enrollment/categorizedCourses/FETCH_FAILURE",

    ADD_SELECTED_USER: "@@enrollment/ADD_SELECTED_USER",
    DEL_SELECTED_USER: "@@enrollment/DEL_SELECTED_USER",
    CLEAR_SELECTED_USER: "@@enrollment/CLEAR_SELECTED_USER",

    FETCH_USER_REQUEST: "@@enrollment/user/FETCH_REQUEST",
    FETCH_USER_SUCCESS: "@@enrollment/user/FETCH_SUCCESS",
    FETCH_USER_FAILURE: "@@enrollment/user/FETCH_FAILURE",

    FETCH_NEXT_PAGE_USER_REQUEST: "@@enrollment/user/FETCH_NEXT_PAGE_REQUEST",
    FETCH_NEXT_PAGE_USER_SUCCESS: "@@enrollment/user/FETCH_NEXT_PAGE_SUCCESS",
    FETCH_NEXT_PAGE_USER_FAILURE: "@@enrollment/user/FETCH_NEXT_PAGE_FAILURE",

    POST_ENROLLMENT_REQUEST: "@@enrollment/POST_REQUEST",
    POST_ENROLLMENT_SUCCESS: "@@enrollment/POST_SUCCESS",
    POST_ENROLLMENT_FAILURE: "@@enrollment/POST_FAILURE",

    POST_UNENROLLMENT_REQUEST: "@@unenrollment/POST_REQUEST",
    POST_UNENROLLMENT_SUCCESS: "@@unenrollment/POST_SUCCESS",
    POST_UNENROLLMENT_FAILURE: "@@unenrollment/POST_FAILURE",

    SELECTED_COURSE: "@@enrollment/SELECTED_COURSE",

    POST_SAVE_SELECTED_USER_REQUEST: "@@enrollment/POST_SAVE_SELECTED_USER_REQUEST",
    POST_SAVE_SELECTED_USER_SUCCESS: "@@enrollment/POST_SAVE_SELECTED_USER_SUCCESS",
    POST_SAVE_SELECTED_USER_FAILURE: "@@enrollment/POST_SAVE_SELECTED_USER_FAILURE",
};
