export const types = {
    FETCH_REQUEST: "@@courses/FETCH_REQUEST",
    FETCH_SUCCESS: "@@courses/FETCH_SUCCESS",
    FETCH_FAILURE: "@@courses/FETCH_FAILURE",

    POST_REQUEST: "@@courses/POST_REQUEST",
    POST_SUCCESS: "@@courses/POST_SUCCESS",
    POST_FAILURE: "@@courses/POST_FAILURE",

    GET_REQUEST: "@@courses/GET_REQUEST",
    GET_SUCCESS: "@@courses/GET_SUCCESS",
    GET_FAILURE: "@@courses/GET_FAILURE",

    GET_EXAMS_REQUEST: "@@courses/FETCH_EXAMS_REQUEST",
    GET_EXAMS_SUCCESS: "@@courses/GET_EXAMS_SUCCESS",
    GET_EXAMS_FAILURE: "@@courses/GET_EXAMS_FAILURE",

    GET_SETTINGS_REQUEST: "@@courses/GET_SETTINGS_REQUEST",
    GET_SETTINGS_SUCCESS: "@@courses/GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILURE: "@@courses/GET_SETTINGS_FAILURE",

    PATCH_SETTINGS_REQUEST: "@@courses/PATCH_SETTINGS_REQUEST",
    PATCH_SETTINGS_SUCCESS: "@@courses/PATCH_SETTINGS_SUCCESS",
    PATCH_SETTINGS_FAILURE: "@@courses/PATCH_SETTINGS_FAILURE",

    CHANGE_FORM: "@@courses/CHANGE_FORM",
    DEFAULT_FORM: "@@courses/DEFAULT_FORM",
    CREATE_FORM_STATEMENT: "@@courses/CREATE_FORM_STATEMENT",

    GET_STATEMENT_VERSION_REQUEST: "@@courses/GET_STATEMENT_VERSION_REQUEST",
    GET_STATEMENT_VERSION_SUCCESS: "@@courses/GET_STATEMENT_VERSION_SUCCESS",
    GET_STATEMENT_VERSION_FAILURE: "@@courses/GET_STATEMENT_VERSION_FAILURE",

    GET_STATEMENTS_VERSION_REQUEST: "@@courses/GET_STATEMENTS_VERSION_REQUEST",
    GET_STATEMENTS_VERSION_SUCCESS: "@@courses/GET_STATEMENTS_VERSION_SUCCESS",
    GET_STATEMENTS_VERSION_FAILURE: "@@courses/GET_STATEMENTS_VERSION_FAILURE",

    CREATE_STATEMENT_REQUEST: "@@courses/CREATE_STATEMENT_REQUEST",
    CREATE_STATEMENT_SUCCESS: "@@courses/CREATE_STATEMENT_SUCCESS",
    CREATE_STATEMENT_FAILURE: "@@courses/CREATE_STATEMENT_FAILURE",

    UPDATE_STATEMENT_REQUEST: "@@courses/UPDATE_STATEMENT_REQUEST",
    UPDATE_STATEMENT_SUCCESS: "@@courses/UPDATE_STATEMENT_SUCCESS",
    UPDATE_STATEMENT_FAILURE: "@@courses/UPDATE_STATEMENT_FAILURE",

    DELETE_STATEMENT_REQUEST: "@@courses/DELETE_STATEMENT_REQUEST",
    DELETE_STATEMENT_SUCCESS: "@@courses/DELETE_STATEMENT_SUCCESS",
    DELETE_STATEMENT_FAILURE: "@@courses/DELETE_STATEMENT_FAILURE",

    COURSE_GET_PERMISSIONS_REQUEST: "@@courses/COURSE_GET_PERMISSIONS_REQUEST",
    COURSE_GET_PERMISSIONS_SUCCESS: "@@courses/COURSE_GET_PERMISSIONS_SUCCESS",
    COURSE_GET_PERMISSIONS_FAILURE: "@@courses/COURSE_GET_PERMISSIONS_FAILURE",

    COURSE_GET_PERMISSIONS_TABLE_REQUEST: "@@courses/COURSE_GET_PERMISSIONS_TABLE_REQUEST",
    COURSE_GET_PERMISSIONS_TABLE_SUCCESS: "@@courses/COURSE_GET_PERMISSIONS_TABLE_SUCCESS",
    COURSE_GET_PERMISSIONS_TABLE_FAILURE: "@@courses/COURSE_GET_PERMISSIONS_TABLE_FAILURE",

    COURSE_POST_PERMISSIONS_REQUEST: "@@courses/COURSE_POST_PERMISSIONS_REQUEST",
    COURSE_POST_PERMISSIONS_SUCCESS: "@@courses/COURSE_POST_PERMISSIONS_SUCCESS",
    COURSE_POST_PERMISSIONS_FAILURE: "@@courses/COURSE_POST_PERMISSIONS_FAILURE",

    COURSE_REFRESH_REQUEST: "@@courses/COURSE_REFRESH_REQUEST",
    COURSE_REFRESH_SUCCESS: "@@courses/COURSE_REFRESH_SUCCESS",
    COURSE_REFRESH_FAILURE: "@@courses/COURSE_REFRESH_FAILURE",

    DELETE_REQUEST: "@@courses/DELETE_REQUEST",
    DELETE_SUCCESS: "@@courses/DELETE_SUCCESS",
    DELETE_FAILURE: "@@courses/DELETE_FAILURE",
};
