import React, { Component } from "react";
import TxtField from "../../Common/TxtField";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import Loading from "../../Common/Loading";
import PermissionsGlobalTable from "./PermissionsGlobalTable";
import Autocomplete from "../../Common/Autocomplete";
import colors from "../../../styles/colors.scss";
import i18next from "i18next";

class PermissionsGlobal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                master: [],
                administration: [],
                content: [],
                "e-learning": [],
                other: [],
            },
            shorter: true,
            onChangeWaitingLoading: false,
        };
    }

    /*componentWillReceiveProps = (nextProps) => {
        if (nextProps.position !== this.props.context.position) {
            this.moveMap(nextProps.position)
        }
    }*/

    componentDidMount() {
        this.props.context.usersSearchGlobal();
        this.props.context
            .fetch({
                role: "Global Administrator",
            })
            .then(() => {
                this.props.context.data.forEach((permission) => {
                    if (this.state.options[permission.category] === undefined) {
                        this.setState({
                            ...this.state,
                            options: { ...this.state.options, [permission.category]: [] },
                        });
                    }
                    this.state.options[permission.category].push({
                        value: permission.name,
                        label: permission.label,
                        key: permission.id,
                    });
                    this.setState({
                        ...this.state,
                        options: {
                            ...this.state.options,
                            [permission.category]: this.state.options[permission.category].sort((a, b) =>
                                a.label < b.label ? -1 : a.label === b.label ? 0 : 1
                            ),
                        },
                    });
                });
            });
    }

    getUsers(p) {
        this.setState({
            ...this.state,
            onChangeWaitingLoading: true,
        });
        const time = new Date().getTime().toString();
        localStorage.setItem("queryTime", time);
        this.props.context.usersSearch({ nameOrEmail: p, withPermissions: 1, withTrashed: 0 }).then((r) => {
            if (localStorage.getItem("queryTime") === time) {
                this.props.context.changeForm("users", r.data);
                this.setState({
                    ...this.state,
                    onChangeWaitingLoading: false,
                });
            }
        });
    }

    handleSubmit(event) {
        if (!this.props.context.formGlobal.user) return;

        event.preventDefault();
        this.props.context
            .create({
                ...this.props.context.formGlobal,
                role: "Global Administrator",
            })
            .then(() => {
                this.props.context.changeForm("users", []);
                this.props.context.defaultForm("formGlobal");
                this.props.context.addNotification("success", i18next.t("permissions.success"));
                this.props.context.changeForm("email", false);
                this.props.context.usersSearchGlobal();
            });
    }

    handleUserChange(event) {
        let permissions = {
            user: event.target.value,
            master: [],
            administration: [],
            content: [],
            "e-learning": [],
            other: [],
        };

        permissions.user.permissions.forEach((permission) => {
            const category = permissions[permission.category];
            if (category)
                category.push({
                    value: permission.name,
                    label: permission.label,
                    key: permission.id,
                });
        });

        this.props.context.changeForm("formGlobal", permissions);
    }

    handleMultiselectChange = (event, name, values) => {
        this.props.context.changeForm("formGlobal", {
            ...this.props.context.formGlobal,
            [name]: values,
        });
    };

    selectUser(user) {
        /*let userToSelect = this.props.context.users.filter((u) => u.id === user.id);

        if(userToSelect.length === 0)
            return*/
        if (user.email) user.name = user.name + " / " + user.email;
        this.handleUserChange({ target: { value: user, name: "user" } });
    }

    render() {
        const userOptions = this.props.context.users
            .map((object, key) => {
                return {
                    value: object,
                    name: object.name + " / " + object.email,
                    key: object.id,
                };
            })
            .sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1));

        const handleSearch = (event, val, reason) => {
            this.setState({ shorter: val.length < 2 });
            if (reason === "clear") this.props.context.defaultForm("formGlobal");
            if (val.length < 2) this.props.context.changeForm("users", []);
            if (val.length > 1) this.getUsers(val);
        };

        const { formGlobal, loading } = this.props.context;
        if (loading) return <Loading />;
        return (
            <Grid item xs={12} container spacing={2}>
                <form
                    onSubmit={this.handleSubmit}
                    style={{
                        width: "100%",
                        borderTop: "3px solid " + colors.primary,
                        padding: "12px",
                    }}
                >
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                id="user"
                                value={formGlobal.user || null}
                                options={userOptions}
                                getOptionLabel={(option) => option.name}
                                loading={this.state.onChangeWaitingLoading}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                loadingText={i18next.t("permissions.loading")}
                                noOptionsText={i18next.t("permissions.nooption")}
                                freeSolo={this.state.shorter}
                                onInputChange={(event, val, reason) => {
                                    handleSearch(event, val, reason);
                                }}
                                onChange={(event, values) => {
                                    if (values)
                                        this.selectUser({
                                            value: values.value,
                                            name: values.value.name + " / " + values.value.email,
                                            key: values.value.id,
                                            permissions: values.value.permissions,
                                            id: values.value.id,
                                        });
                                }}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        placeholder={i18next.t("permissions.placeholder")}
                                        variant="outlined"
                                        label={i18next.t("permissions.user")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="master"
                                options={this.state.options.master}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                value={formGlobal.master || []}
                                onChange={(event, values) => this.handleMultiselectChange(event, "master", values)}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("sidebar.master_data")}
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="administration"
                                options={this.state.options.administration}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                value={formGlobal.administration || []}
                                onChange={(event, values) =>
                                    this.handleMultiselectChange(event, "administration", values)
                                }
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("sidebar.administration")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="content"
                                options={this.state.options.content}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                value={formGlobal.content || []}
                                onChange={(event, values) => this.handleMultiselectChange(event, "content", values)}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("sidebar.contents")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="e-learning"
                                options={this.state.options["e-learning"]}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                value={formGlobal["e-learning"] || []}
                                onChange={(event, values) => this.handleMultiselectChange(event, "e-learning", values)}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("sidebar.elearning")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="other"
                                options={this.state.options.other}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                value={formGlobal.other || []}
                                onChange={(event, values) => this.handleMultiselectChange(event, "other", values)}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("permissions.other")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <SubmitButton
                                type="submit"
                                text={i18next.t("permissions.save")}
                                size="small"
                                style={!formGlobal.user ? { backgroundColor: colors.disabled } : {}}
                                disabled={!formGlobal.user}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleSubmit(event);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="email"
                                        checked={formGlobal.email}
                                        onChange={() => this.props.context.changeForm("formGlobal", {
                                                ...this.props.context.formGlobal,
                                                email: !formGlobal.email,
                                        })}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant={"subtitle1"} color={formGlobal.user ? "textPrimary" : "textSecondary"}>{i18next.t("permissions.send_email")}</Typography>
                                }
                                disabled={!formGlobal.user}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container spacing={3} style={{ marginTop: 18 }}>
                    <Grid item xs={12}>
                        <PermissionsGlobalTable
                            onUserSelect={(user) => this.selectUser(user)}
                            context={this.props.context}
                            gridApi={this.props.gridApi}
                            onGridReady={this.props.onGridReady}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default PermissionsGlobal;
