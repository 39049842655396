import React, { Component } from "react";
import TxtField from "../../Common/TxtField";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import PermissionsChambersTable from "./PermissionsChambersTable";
import Autocomplete from "../../Common/Autocomplete";
import { authUser } from "../../../utils/AuthHelper";
import colors from "../../../styles/colors.scss";
import Loading from "../../Common/Loading";
import Validator from "../../../utils/Validator";
import i18next from "i18next";

class PermissionsChambers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                master: [],
                administration: [],
                content: [],
                "e-learning": [],
                other: [],
                "chamber-elearning": [],
                "chamber-master": [],
            },
            shorter: true,
            onChangeWaitingLoading: false
        };
    }

    /*UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.position !== this.props.context.position) {
            this.moveMap(nextProps.position)
        }
    }*/

    componentDidMount() {
        this.props.context.usersSearchChamber();
        this.props.context
            .fetch({
                role: "Chamber Administrator",
            })
            .then(() => {
                this.props.context.data.forEach((permission) => {
                    this.state.options[permission.category].push({
                        value: permission.name,
                        label: permission.label,
                        key: permission.id,
                    });
                    this.setState({
                        ...this.state,
                        options: {
                            ...this.state.options,
                            [permission.category]: this.state.options[permission.category].sort((a, b) =>
                                a.label < b.label ? -1 : a.label === b.label ? 0 : 1
                            ),
                        },
                    });
                });
            });

        this.props.context.chambersSearch().then((response) => {
            this.props.context.changeForm("chambers", response.data);
        });
    }

    getUsers(p) {
        this.setState({
            ...this.state,
            onChangeWaitingLoading: true,
        });
        const time = new Date().getTime().toString();
        localStorage.setItem("queryTime", time);
        this.props.context
            .usersSearch({ nameOrEmail: p, withPermissions: 1, withTrashed: 0 })
            .then((r) => {
            if (localStorage.getItem("queryTime") === time) {
                this.props.context.changeForm('users', r.data);
                this.setState({
                    ...this.state,
                    onChangeWaitingLoading: false,
                });
            }
        });
    }

    handleUserChange(event) {
        let permissions = {
            user: event.target.value,
            chamber: null,
            master: [],
            administration: [],
            content: [],
            "e-learning": [],
            other: [],
            "chamber-elearning": [],
            "chamber-master": [],
        };

        permissions.user.permissions
            .filter((userPermission) => {
                return this.props.context.data.some((permission) => permission.id === userPermission.id);
            })
            .forEach((permission) => {
                const category = permissions[permission.category];
                if (category)
                    category.push({
                        value: permission.name,
                        label: permission.label,
                        key: permission.id,
                    });
            });

        this.props.context.changeForm("formChamber", permissions);

        if (permissions.user.chamber)
            permissions.chamber = {
                value: permissions.user.chamber,
                name: permissions.user.chamber.name[authUser().language],
                key: permissions.user.chamber.id,
                id: permissions.user.chamber.id,
            };
    }

    handleMultiselectChange = (event, name, values) => {
        this.props.context.changeForm("formChamber", {
            ...this.props.context.formChamber,
            [name]: values,
        });
    };

    selectUser(user) {
        if (user.email) user.name = user.name + " / " + user.email;
        this.handleUserChange({ target: { value: user, name: "user" } });
    }

    /*selectChamber(chamber) {
        let chamberToSelect = this.props.context.chambers.filter((c) => c.id === chamber.id);

        if(chamberToSelect.length === 0)
            return

        this.handleGlobalChange({target: {value: chamberToSelect[0], name: 'chamber'}})
    }*/

    render() {
        const validator = new Validator({}, this.props.context.validator.validatorErorrs);

        const handleSubmit = (event) => {
            if (!this.props.context.formChamber.user) return;
    
            event.preventDefault();
    
            this.props.context
                .create({
                    ...this.props.context.formChamber,
                    role: "Chamber Administrator",
                })
                .then(() => {
                    this.props.context.changeForm("users", []);
                    
                    this.props.context.defaultForm("formChamber");
                    this.props.context.usersSearchChamber();
                    
                    this.props.context.addNotification("success", i18next.t("permissions.success"));
                    this.props.context.setValidatorErrors({});
                    })
                .catch((error) => {
                    this.props.context.addNotification("error", i18next.t("permissions.error"));
                    validator.setReponseError(error);
                    this.props.context.setValidatorErrors(validator.getErrors());
                });
        }

        const userOptions = this.props.context.users
            .map((object) => {
                return {
                    value: object,
                    name: object.name + " / " + object.email,
                    key: object.id,
                };
            })
            .sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1));

        const chamberOptions = this.props.context.chambers
            .map((object) => {
                return {
                    value: object,
                    name: object.name[authUser().language],
                    key: object.id,
                    id: object.id,
                };
            })
            .sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1));

        const handleSearch = (event, val, reason) => {
            this.setState({ shorter: val.length < 2 });
            if (reason === "clear") this.props.context.defaultForm("formChamber");
            if (val.length < 2) this.props.context.changeForm("users", []);
            if (val.length > 1) this.getUsers(val);

        }

        if (this.props.context.loading) return <Loading />;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <form
                        style={{
                            width: "100%",
                            borderTop: "3px solid " + colors.primary,
                            padding: "12px",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="user"
                                    options={userOptions}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    getOptionSelected={(option, value) => option.key === value.key}
                                    filterSelectedOptions
                                    loading={this.state.onChangeWaitingLoading}
                                    loadingText={i18next.t("permissions.loading")}
                                    noOptionsText={i18next.t("permissions.nooption")}
                                    freeSolo={this.state.shorter}
                                    value={this.props.context.formChamber.user || null}
                                    onInputChange={(event, val, reason) => {
                                        handleSearch(event, val, reason);
                                    }}
                                    onChange={(event, values) => {
                                        if (values)
                                            this.selectUser({
                                                value: values.value,
                                                name: values.value.name + " / " + values.value.email,
                                                key: values.value.id,
                                                permissions: values.value.permissions,
                                                chamber: values.value.chamber,
                                                id: values.value.id,
                                            });
                                        this.props.context.setValidatorErrors({});
                                    }}
                                    renderInput={(params) => (
                                        <TxtField
                                            {...params}
                                            placeholder={i18next.t("permissions.placeholder")}
                                            variant="outlined"
                                            label={i18next.t("permissions.user")}
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="chamber"
                                    onChange={(event, values) => {
                                        this.props.context.changeForm("formChamber", {
                                            ...this.props.context.formChamber,
                                            chamber: values
                                                ? {
                                                      value: values.value,
                                                      name: values.value.name[authUser().language],
                                                      key: values.value.id,
                                                      id: values.value.id,
                                                  }
                                                : null,
                                        });
                                        this.props.context.setValidatorErrors({});
                                    }}
                                    value={this.props.context.formChamber.chamber || null}
                                    fullWidth
                                    options={chamberOptions}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TxtField
                                            {...params}
                                            variant="outlined"
                                            label={i18next.t("permissions.chamber")}
                                            margin="normal"
                                            error={validator.hasError("chamber")}
                                            helperText={validator.getErrorText("chamber")}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    multiple
                                    id="master"
                                    fullWidth
                                    options={this.state.options["chamber-master"]}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.key === value.key}
                                    filterSelectedOptions
                                    value={this.props.context.formChamber["chamber-master"] || []}
                                    onChange={(event, values) => {
                                        this.handleMultiselectChange(event, "chamber-master", values);
                                        this.props.context.setValidatorErrors({});
                                    }}
                                    renderInput={(params) => (
                                        <TxtField
                                            {...params}
                                            variant="outlined"
                                            label={i18next.t("sidebar.master_data")}
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="e-learning"
                                    fullWidth
                                    options={this.state.options["chamber-elearning"]}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.key === value.key}
                                    filterSelectedOptions
                                    value={this.props.context.formChamber["chamber-elearning"] || []}
                                    onChange={(event, values) => {
                                        this.handleMultiselectChange(event, "chamber-elearning", values);
                                        this.props.context.setValidatorErrors({});
                                    }}
                                    renderInput={(params) => (
                                        <TxtField
                                            {...params}
                                            variant="outlined"
                                            label={i18next.t("sidebar.elearning")}
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <SubmitButton
                                    type="submit"
                                    text={i18next.t("permissions.save")}
                                    size="small"
                                    style={
                                        !this.props.context.formChamber.user ? { backgroundColor: colors.disabled } : {}
                                    }
                                    disabled={!this.props.context.formChamber.user}
                                    onClick={(event) => handleSubmit(event)}
                                />
                            </Grid>
                            <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="email"
                                        checked={this.props.context.formChamber.email}
                                        onChange={() => this.props.context.changeForm("formChamber", {
                                                ...this.props.context.formChamber,
                                                email: !this.props.context.formChamber.email,
                                        })}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant={"subtitle1"} color={this.props.context.formChamber.user ? "textPrimary" : "textSecondary"}>{i18next.t("permissions.send_email")}</Typography>
                                }
                                disabled={!this.props.context.formChamber.user}
                            />
                        </Grid>
                        </Grid>
                    </form>
                    <Grid container spacing={3} style={{ marginTop: 18 }}>
                        <Grid item xs={12}>
                            <PermissionsChambersTable
                                onUserSelect={(user) => this.selectUser(user)}
                                context={this.props.context}
                                gridApi={this.props.gridApi}
                                onGridReady={this.props.onGridReady}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default PermissionsChambers;
