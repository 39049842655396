import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "40%",
    },
    text: {
        display: "block",
        width: "100%",
    },
}));

function Loading(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Loader type="ThreeDots" color="#1C8038" height="100" width="100" />
            {props.text ? <div className={classes.text}>{props.text}</div> : ""}
        </div>
    );
}

export default connect()(Loading);
