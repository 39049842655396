import API from "../../utils/API";
import axios from "axios";
import { getJWTToken } from "../../utils/AuthHelper";

function startImport(importType, form, progressFunc) {
    const AUTH_TOKEN = getJWTToken();
    let formData = new FormData();
    formData.append("file", form.file);
    formData.append("import_action", form.import_action);
    formData.append("module_id", form.module_id);
    formData.append("course_id", form.course_id);

    if (importType === "course-enrollment" || importType === "exam-enrollment")
        formData.append("confirmationEmail", form.confirmationEmail);

    formData.append("emailNotification", form.emailNotification);

    return axios
        .post(process.env.REACT_APP_API_URL + "/imports/" + importType, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${AUTH_TOKEN}`,
            },
            onUploadProgress: progressFunc,
        })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function checkImport(id) {
    return API.get(`/imports/${id}`);
}

const importService = {
    startImport,
    checkImport,
};

export default importService;
