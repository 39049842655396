import API from "../../utils/API";
import moment from "moment";
import { DATE_PICKER_FORMAT_DATE_TIME_JS } from "../../utils/AppConst";

function fetchCourses() {
    return API.get(`/e-learning/exam-generation/courses`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
function fetchCoursesWithTemplateCourses(templateCourses) {
    return API.get(`/e-learning/exam-generation/courses/template-courses/${templateCourses}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function fetchTemplateCourses() {
    return API.get(`/e-learning/exam-generation/template-courses`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function fetchExams(courseId) {
    return API.get(`/e-learning/exam-generation/courses/${courseId}/exams`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function generate(form) {
    if (form.end instanceof moment) {
        form.end = form.end.format(DATE_PICKER_FORMAT_DATE_TIME_JS);
    }
    if (form.start instanceof moment) {
        form.start = form.start.format(DATE_PICKER_FORMAT_DATE_TIME_JS);
    }
    return API.post(`/e-learning/exam-generation`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const elearningExamGenerationService = {
    fetchCourses,
    fetchCoursesWithTemplateCourses,
    fetchTemplateCourses,
    fetchExams,
    generate,
};

export default elearningExamGenerationService;
