import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import colors from "../../styles/colors.scss";

import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        minWidth: 120,
    },
    labelFocusedRoot: {
        "& label.MuiFormLabel-root.Mui-focused": {
            color: colors.primary,
        },
    },
}));
const useOutlinedInputStyles = makeStyles((theme) => ({
    root: {
        "& $notchedOutline": {
            borderColor: colors.primary,
        },
        "&:hover $notchedOutline": {
            borderColor: colors.primary,
        },
        "&$focused $notchedOutline": {
            borderColor: colors.primary,
        },
    },
    focused: {},
    notchedOutline: {},
}));

export default function GreenOutlineSelect(props) {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [preKey, setPreKey] = React.useState(props.preKey ? props.preKey : "gos");
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            margin="normal"
            fullWidth
            variant="outlined"
            disabled={props.disabled}
            className={classes.formControl}
            error={props.error || false}
            classes={{ root: classes.labelFocusedRoot }}
            size="small"
            onClick={props.onClick ? props.onClick : () => {}}
        >
            <InputLabel ref={inputLabel} htmlFor={props.labelId} required={props.required} shrink={props.shrink}>
                {props.selectLabel}
            </InputLabel>
            <Select
                required={props.required}
                shrink={props.required ? "1" : "0"}
                onChange={props.onChange}
                input={
                    <OutlinedInput
                        required={props.required}
                        shrink={props.required ? "1" : "0"}
                        value={props.value}
                        notched={props.shrink}
                        defaultValue={props.defaultValue}
                        labelWidth={labelWidth}
                        name={props.name}
                        id={props.id}
                        margin="none"
                        classes={outlinedInputClasses}
                        size="small"
                    />
                }
                label={props.label}
                multiple={props.multiple}
            >
                {props.optionList.map((item, index) => (
                    <MenuItem value={item.value} key={`${preKey}-${item.label}`}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {props.error ? <FormHelperText> {props.helperText} </FormHelperText> : ""}
        </FormControl>
    );
}
