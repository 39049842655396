export const types = {
    FETCH_CATEGORIZED_COURSES_REQUEST: "@@elearningExamEnrollmentDismissal/categorizedCourses/FETCH_REQUEST",
    FETCH_CATEGORIZED_COURSES_SUCCESS: "@@elearningExamEnrollmentDismissal/categorizedCourses/FETCH_SUCCESS",
    FETCH_CATEGORIZED_COURSES_FAILURE: "@@elearningExamEnrollmentDismissal/categorizedCourses/FETCH_FAILURE",

    ADD_SELECTED_USER: "@@elearningExamEnrollmentDismissal/ADD_SELECTED_USER",
    DEL_SELECTED_USER: "@@elearningExamEnrollmentDismissal/DEL_SELECTED_USER",
    CLEAR_SELECTED_USER: "@@elearningExamEnrollmentDismissal/CLEAR_SELECTED_USER",

    FETCH_USER_REQUEST: "@@elearningExamEnrollmentDismissal/user/FETCH_REQUEST",
    FETCH_USER_SUCCESS: "@@elearningExamEnrollmentDismissal/user/FETCH_SUCCESS",
    FETCH_USER_FAILURE: "@@elearningExamEnrollmentDismissal/user/FETCH_FAILURE",

    FETCH_NEXT_PAGE_USER_REQUEST: "@@elearningExamEnrollmentDismissal/user/FETCH_NEXT_PAGE_REQUEST",
    FETCH_NEXT_PAGE_USER_SUCCESS: "@@elearningExamEnrollmentDismissal/user/FETCH_NEXT_PAGE_SUCCESS",
    FETCH_NEXT_PAGE_USER_FAILURE: "@@elearningExamEnrollmentDismissal/user/FETCH_NEXT_PAGE_FAILURE",

    POST_ENROLLMENT_REQUEST: "@@elearningExamEnrollmentDismissal/POST_REQUEST",
    POST_ENROLLMENT_SUCCESS: "@@elearningExamEnrollmentDismissal/POST_SUCCESS",
    POST_ENROLLMENT_FAILURE: "@@elearningExamEnrollmentDismissal/POST_FAILURE",

    POST_DISMISSAL_REQUEST: "@@elearningExamEnrollmentDismissal/POST_DISMISSAL_REQUEST",
    POST_DISMISSAL_SUCCESS: "@@elearningExamEnrollmentDismissal/POST_DISMISSAL_SUCCESS",
    POST_DISMISSAL_FAILURE: "@@elearningExamEnrollmentDismissal/POST_DISMISSAL_FAILURE",

    FETCH_EXAMS_REQUEST: "@@elearningExamEnrollmentDismissal/FETCH_EXAMS_REQUEST",
    FETCH_EXAMS_SUCCESS: "@@elearningExamEnrollmentDismissal/FETCH_EXAMS_SUCCESS",
    FETCH_EXAMS_FAILURE: "@@elearningExamEnrollmentDismissal/FETCH_EXAMS_FAILURE",

    SELECTED_COURSE: "@@elearningExamEnrollmentDismissal/SELECTED_COURSE",
    SELECTED_EXAM: "@@elearningExamEnrollmentDismissal/SELECTED_EXAM",
    CLEAR_SELECTED_EXAM: "@@elearningExamEnrollmentDismissal/CLEAR_SELECTED_EXAM",

    POST_SAVE_SELECTED_USER_REQUEST: "@@elearningExamEnrollmentDismissal/POST_SAVE_SELECTED_USER_REQUEST",
    POST_SAVE_SELECTED_USER_SUCCESS: "@@elearningExamEnrollmentDismissal/POST_SAVE_SELECTED_USER_SUCCESS",
    POST_SAVE_SELECTED_USER_FAILURE: "@@elearningExamEnrollmentDismissal/POST_SAVE_SELECTED_USER_FAILURE",
};
