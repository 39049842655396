import React, { useEffect } from "react";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import AccordionSum from "../../Common/AccordionSum";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./Partials/Form";
import { getPageAction } from "../../../store/pages/actions";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import i18next from "i18next";

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block",
    },
}));

function CreateVersion(props) {
    const classes = useStyles();
    const pageId = props.match.params.id;

    useEffect(() => {
        props.get(pageId);
    }, []);

    if (props.selectedPageLoading) return <Loading />;

    const pageVersionUrl = `/pages/${pageId}/versions`;
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    contents: i18next.t("sidebar.contents"),
                    "/pages": i18next.t("sidebar.pages"),
                    [pageVersionUrl]:
                        props.selectedPage.title && props.selectedPage.title[props.userLang]
                            ? props.selectedPage.title[props.userLang]
                            : "",
                    "new-page-version": i18next.t("pages.create"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("pages.create")} />
                    <AccordionDetails className={classes.accordionDetails}>
                        <Form pageId={props.match.params.id} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { selectedPage, loading, selectedPageLoading } = state.pages;
    const userLang = state.authentication.user.language;
    return { selectedPage, loading, selectedPageLoading, userLang };
}

const actionCreators = {
    get: getPageAction,
};

export default connect(mapState, actionCreators)(CreateVersion);
