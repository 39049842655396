import { types } from "./types";

const initialLanguages = { hu: null, en: null };

const initInfo = {
    filter: {},
    sort: {
        last_name: "asc",
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

const initialState = {
    loading: false,
    data: [],
    loadingImpersonation: false,
    dataImpersonation: [],
    search: {
        name: null,
        short_name: null,
        active: null,
    },
    row: {
        name: initialLanguages,
        short_name: initialLanguages,
        active: false,
    },
    form: {
        type: 0, // Normál típus létrehozásnál
        last_name: null,
        first_name: null,
        email: null,
        chambers: [],
        external_user: false,
        language: "hu",
        banned: false,
        confirmationEmail: 1,
    },
    chambers: [],
    tableLoading: true,
    info: initInfo,
};

export function users(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
                info: initInfo,
            };
        case types.DEFAULT_ROW:
            return {
                ...state,
                row: initialState.row,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            }
        case types.SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                info: {
                    ...state.info,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.SEARCH_IMPERSONATION_REQUEST:
            return {
                ...state,
                loadingImpersonation: true,
                searchImpersonation: action.form,
            };
        case types.SEARCH_IMPERSONATION_SUCCESS:
            return {
                ...state,
                loadingImpersonation: false,
                dataImpersonation: action.data.data,
            };
        case types.FETCH_FAILURE:
        case types.SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.SEARCH_IMPERSONATION_FAILURE:
            return {
                ...state,
                loadingImpersonation: false,
                dataImpersonation: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.FETCH_CHAMBERS_GET:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_CHAMBERS_SUCCESS:
            return {
                ...state,
                chambers: action.data.data, //itt irjuk bele az adatot
                loading: false, //villogni fog az egész ezért kell ennek külön loading hogy tudd mikor mi töltöt be mert igy nemtudod ellenőrizni hogy jelenleg melyik "ajax" loadingol
            };
        case types.FETCH_CHAMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
            };

        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.DELETE_REPEAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_REPEAL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_REPEAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.SEND_ACTIVATION_MAIL_REQUEST:
        case types.SEND_PSW_RESET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SEND_ACTIVATION_MAIL_SUCCESS:
        case types.SEND_PSW_RESET_SUCCESS:
            return {
                ...state,
                user: action.data.data,
                loading: false,
            };
        case types.SEND_ACTIVATION_MAIL_FAILURE:
        case types.SEND_PSW_RESET_FAILURE:
            return {
                ...state,
                user: action.error,
                loading: false,
            };

        case types.FETCH_SET_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.key]: action.value,
                },
            };
        default:
            return state;
    }
}
