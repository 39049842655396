import { types } from "./types";

const initialLanguages = { hu: null, en: null };
const initialState = {
    languages: ["hu", "en"],
    loading: false,
    search: { key: null, value: initialLanguages },
    data: [],
    row: initialLanguages,
};

export function translations(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };
        default:
            return state;
    }
}
