import {
    faExchangeAlt,
    faFilePdf,
    faSignInAlt,
    faSignOutAlt,
    faTimesCircle,
    faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Snackbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import i18next from "i18next";
import { default as React, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LogoSmall from "../img/mkik-logo-small.png";
import Permissions from "../lib/Permissions";
import { logoutImpersonation, needLogoutImpersonationBtn } from "../services/login";
import changePasswordActions from "../store/changePassword/actions.js";
import loadingActions from "../store/loading/actions";
import loginActions from "../store/login/actions";
import notificationsActions from "../store/notifications/actions.js";
import colors from "../styles/colors.scss";
import API from "../utils/API";
import { authUser, getImpersonationToken, getJWTToken, hasPermission, logout } from "../utils/AuthHelper";
import ChangePassword from "./ChangePassword";
import Copyright from "./Common/Copyright";
import LinkToButton from "./Common/LinkToButton";
import TextMenuItem from "./Common/TextMenuItem";
import SidebarMenu from "./Dashboard/SidebarMenu";
import DownloadManual from "./DownloadManual";
import Impersonation from "./Impersonation";
import moment from "moment";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#5db47c",
            main: "#5db47c",
            dark: "#5db47c",
            contrastText: "#fff",
        },
        // secondary: {
        //     light: "#5db47c",
        //     main: "#5db47c",
        //     dark: "#5db47c",
        //     contrastText: "#fff",
        // },
    },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.48)",
            borderRadius: "5px",
            //outline: '1px solid slategrey'
        },
    },
    root: {
        display: "flex",
        backgroundColor: colors.mainContentBg,
        height: "100vh",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: colors.primary,
        minHeight: 48,
        display: "flex",
        justifyContent: "space-between",
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 8px",
        ...theme.mixins.toolbar,
        backgroundColor: colors.white,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShiftMobile: {
        display: "none",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        visibility: "hidden",
    },
    drawerPaper: {
        position: "relative",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //height: '100vh',
    },
    drawerPaperMobile: {
        position: "absolute",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //height: '100vh',
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        //height: '100vh',
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    copyrightLink: {
        display: "inline",
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    link: {
        textDecoration: "none",
        color: colors.primary,
    },
    userName: {
        paddingLeft: theme.spacing(12),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: colors.white,
        fontSize: "large",
        fontWeight: "bolder",
    },
    account: {
        fontSize: "15px",
        borderRadius: "16px",
    },
}));
function Layout(props) {
    const classes = useStyles();
    const { snackbar, user } = props;
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(anchorEl);
    const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
    const [downloadManualOpen, setDownloadManualOpen] = React.useState(false);
    const [impersonationOpen, setImpersonationOpen] = React.useState(false);
    const authValidToDate = moment(localStorage.getItem("authValidTo"));
    const currentDate = moment();
    const timeDifference = moment.duration(authValidToDate.diff(currentDate));
    const timeDifferenceMilliseconds = timeDifference.asMilliseconds();
    const [remainingTime, setRemainingTime] = React.useState(
        moment.utc(timeDifferenceMilliseconds).format("mm:ss")
    );
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => setOpen(false), [location]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem("authValidTo")) {
                // Parse the date string from localStorage using Moment.js
                const authValidToDate = moment(localStorage.getItem("authValidTo"));

                // Get the current date using Moment.js
                const currentDate = moment();

                // Calculate the time difference
                const timeDifference = moment.duration(authValidToDate.diff(currentDate));

                // Convert the time difference to milliseconds
                const timeDifferenceMilliseconds = timeDifference.asMilliseconds();

                if (timeDifferenceMilliseconds > 0) {
                    setRemainingTime(
                        moment.utc(timeDifferenceMilliseconds).format("mm:ss")
                    );
                } else {
                    logout();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePasswordClick = (e) => {
        e.preventDefault();
        if (user.type === 0) {
            setAnchorEl(false);
            props.clearForm();
            setChangePasswordOpen(true);
        } else {
            props.addNotification("error", i18next.t("login.forgot.isziir_error"));
        }
    };
    const handleDownloadManualClick = (e) => {
        e.preventDefault();
        setAnchorEl(false);
        setDownloadManualOpen(true);
    };
    const handleImpersonationClick = (e) => {
        e.preventDefault();
        setAnchorEl(false);
        setImpersonationOpen(true);
    };
    const handleImpersonationLogoutClick = (e) => {
        e.preventDefault();
        setAnchorEl(false);
        props.loadingOn();
        logoutImpersonation();
        API.post(`/auth/me`).then((data) => {
            props.setUser(data.data);
            props.addNotification("success", i18next.t("layout.quit_impersonation"));
            window.location.href = "/";
        });
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const roles = authUser()?.roles ?? [];

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar className={clsx(classes.appBar)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            style={{ color: colors.white, fontSize: "xx-large" }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" noWrap>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                className={classes.account}
                            >
                                {localStorage.getItem("authValidTo") && `(${remainingTime}) | `}
                                {user?.name} {userMenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transitionDuration={250}
                                open={userMenuOpen}
                                onClose={handleClose}
                            >
                                <TextMenuItem
                                    className={classes.link}
                                    icon={faExchangeAlt}
                                    onClick={handleChangePasswordClick}
                                >
                                    {i18next.t("layout.change_password")}
                                </TextMenuItem>
                                {
                                    (roles.includes('global_administrator') || roles.includes('chamber_administrator')) && (
                                        <TextMenuItem
                                            className={classes.link}
                                            icon={faFilePdf}
                                            onClick={handleDownloadManualClick}
                                        >
                                            {i18next.t("layout.download_manual")}
                                        </TextMenuItem>
                                    )
                                }
                                {!needLogoutImpersonationBtn() && hasPermission(Permissions.Impersonation) && (
                                    <TextMenuItem
                                        className={classes.link}
                                        icon={faUserSecret}
                                        onClick={handleImpersonationClick}
                                    >
                                        {i18next.t("impersonation")}
                                    </TextMenuItem>
                                )}
                                {needLogoutImpersonationBtn() && (
                                    <TextMenuItem
                                        className={classes.link}
                                        icon={faTimesCircle}
                                        onClick={handleImpersonationLogoutClick}
                                    >
                                        {i18next.t("layout.quit_impersonation")}
                                    </TextMenuItem>
                                )}
                                {
                                    //Törzsadatok
                                    (hasPermission(Permissions.Chambers) ||
                                        hasPermission(Permissions.Users) ||
                                        //Adminisztráció
                                        hasPermission(Permissions.Settings) ||
                                        hasPermission(Permissions.EmailLogs) ||
                                        hasPermission(Permissions.EmailTemplates) ||
                                        hasPermission(Permissions.Permissions) ||
                                        hasPermission(Permissions.Logs) ||
                                        hasPermission(Permissions.Translations) ||
                                        //Tartalmak
                                        hasPermission(Permissions.News) ||
                                        hasPermission(Permissions.SystemMessages) ||
                                        hasPermission(Permissions.Pages) ||
                                        //E-learning
                                        hasPermission(Permissions.CourseCategories) ||
                                        hasPermission(Permissions.CourseGeneration) ||
                                        hasPermission(Permissions.CourseManagement) ||
                                        hasPermission(Permissions.CourseEnrollment) ||
                                        hasPermission(Permissions.CourseDismissal) ||
                                        hasPermission(Permissions.CourseEnrollmentList) ||
                                        hasPermission(Permissions.CourseCompletions) ||
                                        hasPermission(Permissions.CourseExamGeneration) ||
                                        hasPermission(Permissions.CourseExamManagement) ||
                                        hasPermission(Permissions.CourseExamEnrollment) ||
                                        hasPermission(Permissions.CourseExamDismissal) ||
                                        //Egyéb
                                        hasPermission(Permissions.ElearningContentEditor) ||
                                        hasPermission(Permissions.ElearningOwnQuestionBankEditor) ||
                                        hasPermission(Permissions.ElearningQuestionBankEditor) ||
                                        hasPermission(Permissions.Impersonation) ||
                                        //Kurzusszintű jogosultságok
                                        hasPermission(Permissions.CourseElearningTeacher) ||
                                        hasPermission(Permissions.CourseElearningQuestionBankEditor) ||
                                        hasPermission(Permissions.CourseElearningOwnQuestionBankEditor) ||
                                        //Kamarai szintű jogosultságok
                                        hasPermission(Permissions.ChamberUsers) ||
                                        hasPermission(Permissions.ChamberCourseEnrollment) ||
                                        hasPermission(Permissions.ChamberCourseDismissal) ||
                                        hasPermission(Permissions.ChamberCourseEnrollmentList) ||
                                        hasPermission(Permissions.ChamberCourseCompletions) ||
                                        hasPermission(Permissions.ChamberCourseExamGeneration) ||
                                        hasPermission(Permissions.ChamberCourseExamManagement) ||
                                        hasPermission(Permissions.ChamberCourseExamEnrollment) ||
                                        hasPermission(Permissions.ChamberCourseExamDismissal) ||
                                        //Kurzuson belüli jogosultságok
                                        hasPermission(Permissions.CourseCourseCompletions) ||
                                        hasPermission(Permissions.CourseCourseEnrollment) ||
                                        hasPermission(Permissions.CourseCourseDismissal) ||
                                        hasPermission(Permissions.CourseCourseExamGeneration) ||
                                        hasPermission(Permissions.CourseCourseExamManagement) ||
                                        hasPermission(Permissions.CourseCourseEnrollmentList) ||
                                        hasPermission(Permissions.CourseCourseExamEnrollment) ||
                                        hasPermission(Permissions.CourseCourseExamDismissal)) && (
                                        <TextMenuItem icon={faSignInAlt}>
                                            <a
                                                className={classes.link}
                                                href={`${
                                                    process.env.REACT_APP_API_URL
                                                }/moodle/open?token=${getJWTToken()}${
                                                    getImpersonationToken()
                                                        ? `&impersonation=${getImpersonationToken()}`
                                                        : ``
                                                }`}
                                            >
                                                {i18next.t("layout.login_elearning")}
                                            </a>
                                        </TextMenuItem>
                                    )
                                }
                                <TextMenuItem icon={faSignOutAlt}>
                                    <LinkToButton to="/logout" className={classes.link}>
                                        {i18next.t("layout.logout")}
                                    </LinkToButton>
                                </TextMenuItem>
                            </Menu>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Drawer
                    classes={{
                        paper: clsx(open && classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                    variant="temporary"
                    onEscapeKeyDown={handleDrawerClose}
                    onBackdropClick={handleDrawerClose}
                >
                    <div className={classes.toolbarIcon}>
                        <Link to="/">
                            <Avatar variant="circle" className={classes.avatar} src={LogoSmall} />
                        </Link>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon style={{ color: colors.primary, fontSize: "xx-large" }} />
                        </IconButton>
                    </div>
                    <Divider />
                    <Grid item xs={false}>
                        <SidebarMenu />
                    </Grid>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={false} className={classes.container}>
                        {props.children}
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Container>
                </main>
                {snackbar.type ? (
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        open={snackbar.type !== ""}
                        autoHideDuration={7000}
                        onClose={() => props.removeNotification()}
                    >
                        <Alert severity={snackbar.type}>{snackbar.msg}</Alert>
                    </Snackbar>
                ) : (
                    ""
                )}
                {changePasswordOpen ? (
                    <ChangePassword
                        open={changePasswordOpen}
                        closeAction={() => setChangePasswordOpen(false)}
                        changeOpenFn={setChangePasswordOpen}
                        title={i18next.t("layout.change_password")}
                        needCloseBtn={true}
                    />
                ) : null}
                {downloadManualOpen ? (
                    <DownloadManual
                        open={downloadManualOpen}
                        closeAction={() => setDownloadManualOpen(false)}
                        changeOpenFn={setDownloadManualOpen}
                        title={i18next.t("layout.download_manual")}
                        needCloseBtn={true}
                    />
                ) : null}
                {impersonationOpen && hasPermission(Permissions.Impersonation) && (
                    <Impersonation
                        open={impersonationOpen}
                        closeAction={() => setImpersonationOpen(false)}
                        changeOpenFn={setImpersonationOpen}
                    />
                )}
            </ThemeProvider>
        </div>
    );
}

function mapState(state) {
    const { snackbar, formError } = state.notifications;
    const { user } = state.authentication;
    const { password } = state.changePassword;
    return { snackbar, user, password, formError };
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
    removeNotification: notificationsActions.removeNotification,
    addError: notificationsActions.addError,
    removeError: notificationsActions.removeError,
    post: changePasswordActions.post,
    changeForm: changePasswordActions.changeForm,
    clearForm: changePasswordActions.clearForm,
    setUser: loginActions.setUser,

    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(Layout);
