import { types } from "./types";
import elearningCourseGenerationService from "../../services/elearningCourseGeneration";

function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function fetchChambers() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_CHAMBERS_REQUEST });

        return elearningCourseGenerationService.fetchChambers().then(
            (data) => {
                dispatch({ type: types.FETCH_CHAMBERS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_CHAMBERS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchCourses() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_COURSES_REQUEST });

        return elearningCourseGenerationService.fetchCourses().then(
            (data) => {
                dispatch({ type: types.FETCH_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_COURSES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getChamberSubCategories(chamberIds) {
    return (dispatch) => {
        dispatch({ type: types.GET_CHAMBER_SUB_CATEGORIES_REQUEST, chamberIds });

        return elearningCourseGenerationService.getChamberSubCategories(chamberIds).then(
            (data) => {
                dispatch({ type: types.GET_CHAMBER_SUB_CATEGORIES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_CHAMBER_SUB_CATEGORIES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function generate(form) {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_REQUEST, form });

        return elearningCourseGenerationService.generate(form).then(
            (data) => {
                dispatch({ type: types.GENERATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GENERATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function refreshStatus(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_STATUS_REQUEST, id });

        return elearningCourseGenerationService.getStatus(id).then(
            (data) => {
                dispatch({ type: types.GET_STATUS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_STATUS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function resetStatus() {
    return (dispatch) => {
        dispatch({ type: types.RESET_STATUS });
    };
}

const actions = {
    createForm,
    changeForm,
    fetchCourses,
    fetchChambers,
    generate,
    getChamberSubCategories,
    refreshStatus,
    resetStatus,
};

export default actions;
