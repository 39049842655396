import { FormControl, Grid, makeStyles, MenuItem } from "@material-ui/core";
import React from "react";
import i18next from "i18next";
import GreenOutlineSelect from "./GreenOutlineSelect";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    nameTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(0),
    },
    nameTitleMobile: {
        fontWeight: "bold",
        fontSize: "smaller",
    },
}));

export default function ItemNumberSelect(props) {
    const options = [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
    ];

    const classes = useStyles();
    //const theme = useTheme();
    //const matches = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
                <GreenOutlineSelect
                    optionList={options}
                    onChange={props.onChange}
                    preKey={props.preKey ? props.preKey : null}
                    defaultValue={25}
                    selectLabel={i18next.t("table.item_number_select")}
                    autoWidth
                    text={[
                        <MenuItem value={25} key={0}>
                            25
                        </MenuItem>,
                        <MenuItem value={50} key={1}>
                            50
                        </MenuItem>,
                        <MenuItem value={100} key={2}>
                            100
                        </MenuItem>,
                    ]}
                />
            </FormControl>
        </Grid>
    );
}
