export const types = {
    DEFAULT_FORM: "@@systemMessages/DEFAULT_FORM",
    DEFAULT_SEND_OUT_MESSAGES_FORM: "@@systemMessages/DEFAULT_SEND_OUT_MESSAGES_FORM",
    CHANGE_FORM: "@@systemMessages/CHANGE_FORM",
    CHANGE_SEND_OUT_MESSAGES_FORM: "@@systemMessages/CHANGE_SEND_OUT_MESSAGES_FORM",
    DEFAULT_ROW: "@@systemMessages/DEFAULT_ROW",
    CREATE_FORM: "@@systemMessages/CREATE_FORM",
    CREATE_SEND_OUT_MESSAGES_FORM: "@@systemMessages/CREATE_SEND_OUT_MESSAGES_FORM",

    FETCH_REQUEST: "@@systemMessages/FETCH_REQUEST",
    FETCH_SUCCESS: "@@systemMessages/FETCH_SUCCESS",
    FETCH_FAILURE: "@@systemMessages/FETCH_FAILURE",

    POST_REQUEST: "@@systemMessages/POST_REQUEST",
    POST_SUCCESS: "@@systemMessages/POST_SUCCESS",
    POST_FAILURE: "@@systemMessages/POST_FAILURE",

    GET_REQUEST: "@@systemMessages/GET_REQUEST",
    GET_SUCCESS: "@@systemMessages/GET_SUCCESS",
    GET_FAILURE: "@@systemMessages/GET_FAILURE",

    CREATE_REQUEST: "@@systemMessages/CREATE_REQUEST",
    CREATE_SUCCESS: "@@systemMessages/CREATE_SUCCESS",
    CREATE_FAILURE: "@@systemMessages/CREATE_FAILURE",

    UPDATE_REQUEST: "@@systemMessages/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@systemMessages/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@systemMessages/UPDATE_FAILURE",

    DELETE_REQUEST: "@@systemMessages/DELETE_REQUEST",
    DELETE_SUCCESS: "@@systemMessages/DELETE_SUCCESS",
    DELETE_FAILURE: "@@systemMessages/DELETE_FAILURE",
};
