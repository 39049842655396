import React, { useEffect } from "react";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import Header from "../../../Common/Header";
import AccordionSum from "../../../Common/AccordionSum";
import { makeStyles } from "@material-ui/core/styles";
import coursesAction from "../../../../store/courses/actions";
import { connect } from "react-redux";
import Loading from "../../../Common/Loading";
import Form from "./Partials/Form";
import i18next from "i18next";

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block",
    },
}));

function Create(props) {
    const classes = useStyles();
    const courseId = props.match.params.course;

    useEffect(() => {
        props.getSettings(courseId).then((data) => {
            props.get(courseId);
        });
    }, []);

    if (props.statementLoading) return <Loading />;

    const helperUrl = `/e-learning/course-management/edit/${courseId}`;
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    "e-learning": i18next.t("sidebar.elearning"),
                    "/e-learning/course-management": i18next.t("sidebar.course_management"),
                    [helperUrl]: i18next.t("course_management.edit", {
                        chamber: props.row.displayName,
                    }),
                    "new-statement": i18next.t("course_management.statement"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("pages.create")} />
                    <AccordionDetails className={classes.accordionDetails}>
                        <Form courseId={courseId} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { statementLoading, row } = state.courses;
    const userLang = state.authentication.user.language;
    return { statementLoading, row, userLang };
}

const actionCreators = {
    get: coursesAction.get,
    getSettings: coursesAction.getSettings,
};

export default connect(mapState, actionCreators)(Create);
