export const types = {
    DEFAULT_FORM: "@@chambers/DEFAULT_FORM",
    CHANGE_FORM: "@@chambers/CHANGE_FORM",
    DEFAULT_ROW: "@@chambers/DEFAULT_ROW",
    CREATE_FORM: "@@chambers/CREATE_FORM",

    FETCH_REQUEST: "@@chambers/FETCH_REQUEST",
    FETCH_SUCCESS: "@@chambers/FETCH_SUCCESS",
    FETCH_FAILURE: "@@chambers/FETCH_FAILURE",

    SEARCH_REQUEST: "@@chambers/SEARCH_REQUEST",
    SEARCH_SUCCESS: "@@chambers/SEARCH_SUCCESS",
    SEARCH_FAILURE: "@@chambers/SEARCH_FAILURE",

    POST_REQUEST: "@@chambers/POST_REQUEST",
    POST_SUCCESS: "@@chambers/POST_SUCCESS",
    POST_FAILURE: "@@chambers/POST_FAILURE",

    GET_REQUEST: "@@chambers/GET_REQUEST",
    GET_SUCCESS: "@@chambers/GET_SUCCESS",
    GET_FAILURE: "@@chambers/GET_FAILURE",

    CREATE_REQUEST: "@@chambers/CREATE_REQUEST",
    CREATE_SUCCESS: "@@chambers/CREATE_SUCCESS",
    CREATE_FAILURE: "@@chambers/CREATE_FAILURE",

    UPDATE_REQUEST: "@@chambers/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@chambers/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@chambers/UPDATE_FAILURE",

    DELETE_REQUEST: "@@chambers/DELETE_REQUEST",
    DELETE_SUCCESS: "@@chambers/DELETE_SUCCESS",
    DELETE_FAILURE: "@@chambers/DELETE_FAILURE",
};
