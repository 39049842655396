import { types } from "./types";
import chambersService from "../../services/chambers";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultRow() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_ROW });
    };
}

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}
function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return chambersService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function search(form) {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_REQUEST, form });
        return chambersService.search(form).then(
            (data) => {
                dispatch({ type: types.SEARCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEARCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function create(form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST, form });

        return chambersService.create(form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(form, id) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, form });

        return chambersService.update(form, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function get(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, id });

        return chambersService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
function deleteRow(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return chambersService.del(id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

const actions = {
    filter,
    create,
    update,
    deleteRow,
    get,
    changeForm,
    defaultForm,
    defaultRow,
    createForm,
    search,
};

export default actions;
