import React, { useEffect, useState } from "react";
import NewsFilters from "./Partials/Filters";
import NewsTable from "./Partials/Table";
import { withStyles } from "@material-ui/core/styles";
import styles from "./theme";
import Header from "../../Common/Header";
import Grid from "@material-ui/core/Grid";
import newsActions from "../../../store/news/actions";
import { connect } from "react-redux";
import Loading from "../../Common/Loading";
import i18next from "i18next";

function NewsIndex(props) {
    useEffect(() => {
        props.fetchNews(props.search);
    }, []);

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    const { loading } = props;
    if (loading) return <Loading />;
    return (
        <Grid item container spacing={2}>
            <Header
                title={i18next.t("sidebar.news")}
                breadcrumbs={{
                    contents: i18next.t("sidebar.contents"),
                    news: i18next.t("sidebar.news"),
                }}
            />
            <NewsFilters />
            <NewsTable data={props.data} userLang={props.userLang} gridApi={gridApi} onGridReady={onGridReady} />
        </Grid>
    );
}

function mapStateToProps(state) {
    return {
        data: state.news.data,
        loading: state.news.loading,
        userLang: state.authentication.user.language,
        search: state.news.search,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchNews: (form) => dispatch(newsActions.filter(form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewsIndex));
