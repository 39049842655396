import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { store } from "../../store";
import moment from "moment";
import { DATE_PICKER_FORMAT_DATE } from "../../utils/AppConst";

function filter(form) {
    const searchForm = new SearchForm(
        form,
        {
            title: "title-" + store.getState().authentication.user.language,
            publishedAtFrom: "published_at",
            publishedAtUntil: "published_at",
        },
        {
            published_at: "date",
        }
    );
    let sendSearch = searchForm.getSend();

    return API.get("/messages", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function create(form) {
    if (form.published_at instanceof moment) {
        form.published_at = form.published_at.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.post(`/messages`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function update(form, id) {
    if (form.published_at instanceof moment) {
        form.published_at = form.published_at.format(DATE_PICKER_FORMAT_DATE);
    }
    return API.patch(`/messages/${id}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(`/messages/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function del(id) {
    return API.delete(`/messages/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function filterLastLoginDay(id, lastLoginDayFilter) {
    return API.get(`/messages/${id}/user-count`, {params: {
        lastLoginDayFilter: lastLoginDayFilter
      }})
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function sendNotify(id, lastLoginDayFilter = null, filteredUserCount = null) {
    const filter = lastLoginDayFilter ? { lastLoginDayFilter: lastLoginDayFilter, filteredUserCount: filteredUserCount } : {};

    return API.post(`/messages/${id}/send-notify`, filter
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function checkNotify(id, processId) {
    return API.get(`/messages/${id}/check-notify/${processId}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const systemMessagesService = {
    filter,
    create,
    update,
    del,
    get,
    filterLastLoginDay,
    sendNotify,
    checkNotify,
};

export default systemMessagesService;
