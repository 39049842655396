import React from "react";
import GreenTooltip from "./GreenTooltip";

export function TableCellText(props) {
    return (
        <GreenTooltip title={props.val} placement="top">
            <span>{props.val}</span>
        </GreenTooltip>
    );
}
