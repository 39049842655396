import React, { useEffect } from "react";
import Layout from "./Components/Layout";
import NotFound from "./ErrorPages/NotFound";
import AccessDenied from "./ErrorPages/AccessDenied";
import LoginForm from "./Components/Login/LoginForm";
import UserConfirm from "./Components/UserConfirm";
import PrivateRoute from "./Components/PrivateRoute";
import Chambers from "./Components/Dashboard/Chambers/Chambers";
import ChambersNew from "./Components/Dashboard/Chambers/ChambersNew";
import SettingsList from "./Components/Dashboard/Settings/SettingsList";
import SettingsEdit from "./Components/Dashboard/Settings/SettingsEdit";
import EmailTemplatesList from "./Components/Dashboard/EmailTemplates/EmailTemplatesList";
import EmailTemplatesEdit from "./Components/Dashboard/EmailTemplates/EmailTemplatesEdit";
import NewsIndex from "./Components/Dashboard/News/Index";
import NewsCreate from "./Components/Dashboard/News/Create";
import NewsEdit from "./Components/Dashboard/News/Edit";
import SystemMessagesIndex from "./Components/Dashboard/SystemMessages/Index";
import SystemMessagesCreate from "./Components/Dashboard/SystemMessages/Create";
import SystemMessagesEdit from "./Components/Dashboard/SystemMessages/Edit";
import CourseGenerationForm from "./Components/Dashboard/CourseGeneration/CourseGenerationForm";
import ExamGenerationForm from "./Components/Dashboard/ExamGeneration/ExamGenerationForm";
import ExamDismissalList from "./Components/Dashboard/ExamDismissal/ExamDismissalList";
import Main from "./Components/Dashboard/Main";
import { getJWTToken, isLogin, logout, setApiUrl } from "./utils/AuthHelper";
import "./App.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history, store } from "./store";
import EmailLogList from "./Components/Dashboard/EmailLog/EmailLogList";
import EmailLogSheet from "./Components/Dashboard/EmailLog/EmailLogSheet";
import Enrollment from "./Components/Dashboard/Enrollment/Enrollment";
import Dismissal from "./Components/Dashboard/Dismissal/Dismissal";
import EnrolledList from "./Components/Dashboard/Enrolled/EnrolledList";
import AchievementsList from "./Components/Dashboard/Achievements/AchievementsList";
import CourseManagementList from "./Components/Dashboard/CourseManagement/CourseManagementList";
import CourseManagementEdit from "./Components/Dashboard/CourseManagement/CourseManagementEdit";
import ExamEnrollmentList from "./Components/Dashboard/ExamEnrollment/ExamEnrollmentList";
import PermissionsList from "./Components/Dashboard/Permissions/PermissionsList";
import CourseCategoriesList from "./Components/Dashboard/CourseCategories/CourseCategoriesList";
import CourseCategoriesNew from "./Components/Dashboard/CourseCategories/CourseCategoriesNew";
import SystemLogList from "./Components/Dashboard/SystemLog/SystemLogList";
import Users from "./Components/Dashboard/Users/Users";
import UsersNew from "./Components/Dashboard/Users/UsersNew";
import UsersEdit from "./Components/Dashboard/Users/UsersEdit";
import TranslationsList from "./Components/Dashboard/Translations/TranslationsList";
import loginActions from "./store/login/actions.js";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ChambersEdit from "./Components/Dashboard/Chambers/ChambersEdit";
import ForgottenPassword from "./Components/ForgottenPassword";
import CourseCategoriesEdit from "./Components/Dashboard/CourseCategories/CourseCategoriesEdit";
import Pages from "./Components/Dashboard/Pages";
import PagesVersion from "./Components/Dashboard/Pages/Version";
import PagesVersionFormCreate from "./Components/Dashboard/Pages/CreateVersion";
import PagesVersionFormUpdate from "./Components/Dashboard/Pages/UpdateVersion";
import CourseManagementEditStatementNew from "./Components/Dashboard/CourseManagement/Statement/Create";
import CourseManagementEditStatementEdit from "./Components/Dashboard/CourseManagement/Statement/Update";
import { getPrivacyStatementActiveAction } from "./store/pages/actions";
import API from "./utils/API";
import IsziirLogin from "./Components/Login/IsziirLogin";
import ExamManagement from "./Components/Dashboard/ExamManagement";
import ExamManagementEdit from "./Components/Dashboard/ExamManagement/ExamManagementEdit";
import loginService from "./services/login";
import Permissions from "./lib/Permissions";
import Loading from "./Components/Common/Loading";
import Rescoring from "./Components/Dashboard/ExamManagement/Rescoring/index.js";

class App extends React.Component {
    // logs out the user on other tabs after 'user' key is removed from localStorage
    handleLogout = e => {
        if (e.key === 'user' && e.oldValue && !e.newValue) {
          history.push('/login');
        }
    }

    componentDidMount() {
        setApiUrl(process.env.REACT_APP_API_URL);
        if (getJWTToken()) {
            //MINDEN MENÜVÁLASZTÁSNÁL MEG KÉNE LÉPNI?
            API.post(`/auth/me`)
                .then((data) => {
                    store.dispatch(loginActions.setUser(data.data));
                    this.props.getPrivacyStatementActive();
                    this.props.i18n.changeLanguage(this.props.userLanguage); //ez álítja be a nyelvet
                })
                .catch(() => {
                    loginService.logout();
                    history.push("/login");
                });
        }

        window.addEventListener('storage', this.handleLogout);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleLogout);
    }
    

    render() {
        const { userLanguage, loading } = this.props;

        if (loading) return <Loading />;
        return (
            <>
                <Helmet htmlAttributes={{ lang: userLanguage }} />
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route
                            path="/login"
                            exact
                            render={(props) => (!isLogin() ? <LoginForm /> : <Redirect to="/" />)}
                        />
                        <Route
                            path="/activation/:token"
                            exact
                            render={(props) => (!isLogin() ? <UserConfirm /> : <Redirect to="/" />)}
                        />
                        <Route
                            path="/forgotten-password/:token"
                            exact
                            render={(props) => (!isLogin() ? <ForgottenPassword /> : <Redirect to="/" />)}
                        />
                        <Route path="/login/isziir" exact render={(props) => <IsziirLogin />} />

                        <PrivateRoute layout={Layout} path="/" exact component={Main} />

                        <PrivateRoute
                            layout={Layout}
                            path="/chambers"
                            exact
                            component={Chambers}
                            hasRole={[Permissions.Chambers]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/chambers/new"
                            exact
                            component={ChambersNew}
                            hasRole={[Permissions.Chambers]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/chambers/edit/:chamberId"
                            exact
                            component={ChambersEdit}
                            hasRole={[Permissions.Chambers]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/users"
                            exact
                            component={Users}
                            hasRole={[Permissions.Users, Permissions.ChamberUsers]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/users/new"
                            exact
                            component={UsersNew}
                            hasRole={[Permissions.Users, Permissions.ChamberUsers]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/users/edit/:userId"
                            exact
                            component={UsersEdit}
                            hasRole={[Permissions.Users, Permissions.ChamberUsers]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/settings"
                            exact
                            component={SettingsList}
                            hasRole={[Permissions.Settings]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/settings/edit/:settingId"
                            exact
                            component={SettingsEdit}
                            hasRole={[Permissions.Settings]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/email-templates"
                            exact
                            component={EmailTemplatesList}
                            hasRole={[Permissions.EmailTemplates]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/email-templates/edit/:templateId"
                            exact
                            component={EmailTemplatesEdit}
                            hasRole={[Permissions.EmailTemplates]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/email-log"
                            exact
                            component={EmailLogList}
                            hasRole={[Permissions.EmailLogs]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/email-log/email-sheet/:emailId"
                            exact
                            component={EmailLogSheet}
                            hasRole={[Permissions.EmailLogs]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/permissions"
                            exact
                            component={PermissionsList}
                            hasRole={[Permissions.Permissions]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/system-log"
                            exact
                            component={SystemLogList}
                            hasRole={[Permissions.Logs]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/translations"
                            exact
                            component={TranslationsList}
                            hasRole={[Permissions.Translations]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/news"
                            exact
                            component={NewsIndex}
                            hasRole={[Permissions.News]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/news/create"
                            exact
                            component={NewsCreate}
                            hasRole={[Permissions.News]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/news/edit/:newsId"
                            exact
                            component={NewsEdit}
                            hasRole={[Permissions.News]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/system-messages"
                            exact
                            component={SystemMessagesIndex}
                            hasRole={[Permissions.SystemMessages]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/system-messages/create"
                            exact
                            component={SystemMessagesCreate}
                            hasRole={[Permissions.SystemMessages]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/system-messages/edit/:systemMessagesId"
                            exact
                            component={SystemMessagesEdit}
                            hasRole={[Permissions.SystemMessages]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/enrollment"
                            exact
                            component={Enrollment}
                            hasRole={[
                                Permissions.CourseEnrollment,
                                Permissions.ChamberCourseEnrollment,
                                Permissions.CourseCourseEnrollment,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/dismissal"
                            exact
                            component={Dismissal}
                            hasRole={[
                                Permissions.CourseDismissal,
                                Permissions.ChamberCourseDismissal,
                                Permissions.CourseCourseDismissal,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-generation"
                            exact
                            component={CourseGenerationForm}
                            hasRole={[Permissions.CourseGeneration]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-enrollments"
                            exact
                            component={EnrolledList}
                            hasRole={[
                                Permissions.CourseEnrollmentList,
                                Permissions.ChamberCourseEnrollmentList,
                                Permissions.CourseCourseEnrollmentList,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-achievements"
                            exact
                            component={AchievementsList}
                            hasRole={[
                                Permissions.CourseCompletions,
                                Permissions.ChamberCourseCompletions,
                                Permissions.CourseCourseCompletions,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/exam-generation"
                            exact
                            component={ExamGenerationForm}
                            hasRole={[
                                Permissions.CourseExamGeneration,
                                Permissions.ChamberCourseExamGeneration,
                                Permissions.CourseCourseExamGeneration,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-management"
                            exact
                            component={CourseManagementList}
                            hasRole={[Permissions.CourseManagement]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-management/edit/:id"
                            exact
                            component={CourseManagementEdit}
                            hasRole={[Permissions.CourseManagement]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-management/edit/:course/privacy-statements/new"
                            exact
                            component={CourseManagementEditStatementNew}
                            hasRole={[Permissions.CourseManagement]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-management/edit/:course/privacy-statements/:statement/edit"
                            exact
                            component={CourseManagementEditStatementEdit}
                            hasRole={[Permissions.CourseManagement]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/exam-enrollment"
                            exact
                            component={ExamEnrollmentList}
                            hasRole={[
                                Permissions.CourseExamEnrollment,
                                Permissions.ChamberCourseExamEnrollment,
                                Permissions.CourseCourseExamEnrollment,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/exam-dismissal"
                            exact
                            component={ExamDismissalList}
                            hasRole={[
                                Permissions.CourseExamDismissal,
                                Permissions.ChamberCourseExamDismissal,
                                Permissions.CourseCourseExamDismissal,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-categories"
                            exact
                            component={CourseCategoriesList}
                            hasRole={[Permissions.CourseCategories]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-categories/:parent/new"
                            exact
                            component={CourseCategoriesNew}
                            hasRole={[Permissions.CourseCategories]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/course-categories/:parent/edit/:categoryId"
                            exact
                            component={CourseCategoriesEdit}
                            hasRole={[Permissions.CourseCategories]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/exam-management"
                            exact
                            component={ExamManagement}
                            hasRole={[
                                Permissions.CourseExamManagement,
                                Permissions.ChamberCourseExamManagement,
                                Permissions.CourseCourseExamManagement,
                                Permissions.ElearningContentEditor,
                                Permissions.CourseElearningContentEditor,
                            ]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/exam-management/:id/rescoring"
                            exact
                            component={Rescoring}
                            hasRole={[Permissions.ElearningContentEditor, Permissions.CourseElearningContentEditor]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/e-learning/exam-management/edit/:id"
                            exact
                            component={ExamManagementEdit}
                            hasRole={[
                                Permissions.CourseExamManagement,
                                Permissions.ChamberCourseExamManagement,
                                Permissions.CourseCourseExamManagement,
                            ]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/pages"
                            exact
                            component={Pages}
                            hasRole={[Permissions.Pages]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/pages/:id/versions"
                            exact
                            component={PagesVersion}
                            hasRole={[Permissions.Pages]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/pages/:id/version/new"
                            exact
                            component={PagesVersionFormCreate}
                            hasRole={[Permissions.Pages]}
                        />
                        <PrivateRoute
                            layout={Layout}
                            path="/pages/:id/version/:version/edit"
                            exact
                            component={PagesVersionFormUpdate}
                            hasRole={[Permissions.Pages]}
                        />

                        <PrivateRoute
                            layout={Layout}
                            path="/logout"
                            exact
                            component={() => {
                                logout();
                                return <Redirect to="/login" />;
                            }}
                        />
                        <Route path="/404" exact component={NotFound} />
                        <Route path="/403" exact component={AccessDenied} />
                        <Route component={NotFound} />
                    </Switch>
                </ConnectedRouter>
            </>
        );
    }
}

function mapStateToProps(state) {
    let userLng = "hu";
    const loading = state.loading.loading;
    if (state.authentication.user && state.authentication.user.language) userLng = state.authentication.user.language;
    return {
        userLanguage: userLng,
        loading: loading,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getPrivacyStatementActive: () => dispatch(getPrivacyStatementActiveAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
