import API from "../../utils/API";

function getRules(model, id) {
    var url = `/${model}/validations`;
    if (id) {
        url += `/${id}`;
    }

    return API.get(url)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const validationService = {
    getRules,
};

export default validationService;
