import CheckBox from "../../Common/CheckBox";
import i18next from "i18next";
import React from "react";
import {connect} from "react-redux";
import {
    Box,
    FormControlLabel,
    Grid, makeStyles,
    Typography,
} from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import Loading from "../../Common/Loading";

function SelectedUsers(props) {
    const isLoading = typeof props.currentSelectedIsLoading !== typeof undefined && props.currentSelectedIsLoading > 0;
    return <Grid item xs={12} lg={6}>
        <Box marginTop={2}>
            {
                isLoading ?
                    <Loading></Loading> :
                    <Typography component="h4">
                        {i18next.t("elearning.selected", {'count' : props.currentSelected})}
                    </Typography>
            }
        </Box>
        <Box marginTop={2}>
            <FormControlLabel
                control={
                    <CheckBox
                        color="primary"
                        disabled={props.loadingSave}
                        onClick={(e) => props.updateState(e)}
                    />
                }
                checked={props.emailNotification}
                label={i18next.t("elearning.notification")}
            />
        </Box>
        <Box marginTop={2}>
            {props.currentSelected > 0 ? (
                <SubmitButton
                    disabled={props.loadingSave || isLoading}
                    onClick={() => props.save()}
                    text={props.btnLabel}
                />
            ) : null}
        </Box>
    </Grid>
}

export default connect(null, null)(SelectedUsers);