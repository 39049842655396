import React from "react";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import AccordionSum from "../../Common/AccordionSum";
import ChambersForm from "./ChambersForm";
import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";

const useStyles = makeStyles(() => ({
    dBlock: {
        display: "block",
    },
}));

function ChambersNew() {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    masterDatas: i18next.t("sidebar.master_data"),
                    "/chambers": i18next.t("sidebar.chambers"),
                    "chamber-new": i18next.t("chambers.new"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("chambers.new")} />
                    <AccordionDetails className={classes.dBlock}>
                        <ChambersForm />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default ChambersNew;
