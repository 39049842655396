import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import i18next from "i18next";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import elearningExamGeneration from "../../../store/elearningExamGeneration/actions";
import notificationsActions from "../../../store/notifications/actions";
import validator from "../../../store/validator/actions";
import { convertDateToHyphenFormat } from '../../../utils/AppConst';
import Validator from "../../../utils/Validator";
import CancelButton from "../../Common/CancelButton";
import DateTimePicker from "../../Common/DateTimePicker";
import Header from "../../Common/Header";
import Loading from "../../Common/Loading";
import SubmitButton from "../../Common/SubmitButton";
import TxtField from "../../Common/TxtField";
import BackendAutocompleteOffsetScroll from "../../Common/BackendAutocompleteOffsetScroll";

function ExamCourseGenerationForm(props) {
    const { form, loading } = props;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        var val = event.target.value;
        props.changeForm("form", { ...form, [event.target.name]: val });

        if (event.target.name === "templateCourse") {
            props.fetchExams(val);
        }
        if (event.target.name === "templateCourse") {
            props.fetchCoursesWithTemplateCourses(val);
        }
    };

    const handleAutocompleteChange = (name, value) => props.changeForm("form", { ...form, [name]: value });

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, true)
        props.changeForm('form', {
            ...form,
            [name]: newDate
        });
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    useEffect(() => {
        props.setValidatorErrors({});

        axios
            .all([
                props.getValidationRules("e-learning/exam-generation"),
            ])
            .then(
                axios.spread(() => {
                    props.createForm();
                })
            );
    }, []);

    const convertForm = (form) => ({
        ...form,
        templateCourse: form.templateCourse ? form.templateCourse.value : null,
        exam: form.exam ? form.exam.value : null,
        courses: form.courses ? form.courses.map((course) => course?.value || course) : [],
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        props
            .generate(convertForm(form))
            .then(() => {
                props.addNotification("success", i18next.t("exam_generation.success"));
                props.createForm();
            })
            .catch((error) => {
                props.addNotification("error", i18next.t("form.error"));
                validator.setReponseError(error);
                props.setValidatorErrors(validator.getErrors());
            });
    };

    const isDisabled = () => Object.values(form).some((value) => {
        if (!value || Array.isArray(value) && value?.length === 0) {
            return true;
        }
    });

    return loading ? (
        <Loading />
    ) : (
        <div>
            <CssBaseline />

            <Grid item container spacing={2}>
                <Header
                    breadcrumbs={{
                        "e-learning": i18next.t("sidebar.elearning"),
                        exam_generation: i18next.t("sidebar.exam_generation"),
                    }}
                />
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={i18next.t("sidebar.exam_generation")} />
                        <CardContent>
                            <form onSubmit={handleSubmit} noValidate={true} id="exam-generation-form">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <BackendAutocompleteOffsetScroll
                                            onChange={(_, value) => handleAutocompleteChange("templateCourse", value)}
                                            value={form.templateCourse}
                                            label={i18next.t("course_generation.course")}
                                            error={validator.hasError("templateCourse")}
                                            helperText={validator.getErrorText("templateCourse")}
                                            required={validator.isRequired("templateCourse")}
                                            url="/e-learning/exam-generation/template-courses"
                                            renderOption={(option) =>
                                                <div style={!option.hasExam ? { color: '#aaa' } : {}}>
                                                    {option.label}
                                                </div>
                                            }
                                            itemConverter={(item) => ({ value: item['course_id'], label: item["course_name"], hasExam: item.hasExam })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <BackendAutocompleteOffsetScroll
                                            onChange={(_, value) => handleAutocompleteChange("exam", value)}
                                            value={form.exam}
                                            label={i18next.t("exam_generation.standard")}
                                            error={validator.hasError("exam")}
                                            helperText={
                                                validator.getErrorText("exam")
                                                || (
                                                    form.templateCourse
                                                    && !form.templateCourse.hasExam
                                                    && i18next.t("exam_generation.no_exam")
                                                )
                                            }
                                            required={validator.isRequired("exam")}
                                            url={form.templateCourse?.value ? "/e-learning/exam-generation/courses/" + form.templateCourse.value + "/exams" : ""}
                                            disabled={!form.templateCourse?.hasExam}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <BackendAutocompleteOffsetScroll
                                            onChange={(_, value) => handleAutocompleteChange("courses", value)}
                                            value={form.courses}
                                            label={i18next.t("elearning.select_course")}
                                            error={validator.hasError("courses")}
                                            helperText={validator.getErrorText("courses")}
                                            required={validator.isRequired("courses")}
                                            url={form.templateCourse?.value ? "e-learning/exam-generation/courses/template-courses/" + form.templateCourse.value : ""}
                                            multiple
                                            itemConverter={(item) => ({ value: item["course_id"], label: item["course_name"] })}
                                            disabled={!form.templateCourse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TxtField
                                            variant="outlined"
                                            margin="normal"
                                            value={form.name || ""}
                                            id="name"
                                            label={i18next.t("exam_generation.exam")}
                                            name="name"
                                            onChange={(event) => handleChange(event)}
                                            fullWidth
                                            onBlur={(event) => handleBlur(event)}
                                            required={validator.isRequired("name")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validator.hasError("name")}
                                            helperText={validator.getErrorText("name")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            onChange={(date) => handleDateChange(date, "start")}
                                            required={validator.isRequired("start")}
                                            error={validator.hasError("start")}
                                            helperText={validator.getErrorText("start")}
                                            value={form.start || ''}
                                            id="start"
                                            label={i18next.t("exam_generation.start")}
                                            name="start"
                                            style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            onChange={(date) => handleDateChange(date, "end")}
                                            required={validator.isRequired("end")}
                                            error={validator.hasError("end")}
                                            helperText={validator.getErrorText("end")}
                                            value={form.end || ''}
                                            id="end"
                                            label={i18next.t("exam_generation.end")}
                                            name="end"
                                            style={{ marginRight: 6, marginTop: 16, marginBottom: 8 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <SubmitButton
                                            text={i18next.t("exam_generation.save")}
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpen(true);
                                            }}
                                            disabled={loading || isDisabled()}
                                        />
                                        <Dialog open={open} onClose={() => setOpen(false)}>
                                            <DialogContent>
                                                <DialogContentText>
                                                    {i18next.t("exam_generation.confirmation")}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <SubmitButton
                                                    text={i18next.t("yes")}
                                                    onClick={(e) => {
                                                        setOpen(false);
                                                        handleSubmit(e);
                                                    }}
                                                />
                                                <CancelButton
                                                    text={i18next.t("no")}
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { form, courses, templateCourses, exams, loading } = state.elearningExamGeneration;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, user, loading, courses, templateCourses, exams, validator };
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
    changeForm: elearningExamGeneration.changeForm,
    createForm: elearningExamGeneration.createForm,
    resetForm: elearningExamGeneration.resetForm,
    generate: elearningExamGeneration.generate,
    fetchCourses: elearningExamGeneration.fetchCourses,
    fetchCoursesWithTemplateCourses: elearningExamGeneration.fetchCoursesWithTemplateCourses,
    fetchTemplateCourses: elearningExamGeneration.fetchTemplateCourses,
    fetchExams: elearningExamGeneration.fetchExams,
};

export default connect(mapState, actionCreators)(ExamCourseGenerationForm);
