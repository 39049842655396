import React from "react";
import Table from "../../Common/Table";
import MassOperationDialog from "./MassOperationDialog";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { Chip } from "@material-ui/core";
import { hasPermission } from "../../../utils/AuthHelper";
import Permissions from "../../../lib/Permissions";
import userActions from "../../../store/users/actions.js";
import notificationsActions from "../../../store/notifications/actions.js";
import OperationButton from "../../Common/OperationButton";
import { getColumnDefs } from "./utils.js";

function BtnCellRenderer(params) {
    return !params.data.status ? <OperationButton
        params={params}
        type={"activationEmail"}
        confirmation={i18next.t("users.activation.confirmation")}
        successmessage={i18next.t("users.activation.success")}
        errormessage={i18next.t("users.error")}
        submittext={i18next.t("yes")}
        canceltext={i18next.t("no")}
    /> : <></>
}

function StatusCellRenderer(params) {
    return params.data.status ? <Chip color="primary" label={i18next.t("course_enrolled.activated")} /> : <Chip label={i18next.t("course_enrolled.not_activated")} />;
}

export default function EnrolledListTable(props) {
    const dispatch = useDispatch();

    const state = {
        columnDefs: getColumnDefs(),
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
    };
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <Table
            rowData={props.data}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={!hasPermission(Permissions.Users) && !hasPermission(Permissions.ChamberUsers) ? 
                state.columnDefs : [...state.columnDefs, {
                    headerName: i18next.t("table.operations"),
                    field: "operations",
                    cellRenderer: "btnCellRenderer",
                    cellStyle: { "text-align": "center" },
            }]}
            frameworkComponents={state.frameworkComponents}
            gridApi={props.gridApi}
            onGridReady={props.onGridReady}
            context={{
                SendActivationMail: (userId) => dispatch(userActions.sendActivationMail(userId)),
                AddNotification: (type, message) => dispatch(notificationsActions.addNotification(type, message)),
            }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onSelectionChanged={e => setSelectedRows(e.api.getSelectedRows())}
            customAction={selectedRows.length > 0 ? <MassOperationDialog selectedRows={selectedRows} /> : null}
        />
    );
}

// A fix oszlopszélesség miatt szükséges
function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
}
