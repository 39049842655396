export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const DATE_PICKER_FORMAT_DATE = "YYYY-MM-DD";
export const DATE_PICKER_FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm";
export const DATE_PICKER_FORMAT_DATE_TIME_JS = "YYYY-MM-DD\\THH:mm";

export const LAST_SEEN_TYPE_NEWS = "news";
export const LAST_SEEN_TYPE_MESSAGES = "messages";

export const CLIENT_STORAGE = localStorage;

export function numberFormat(val) {
    if (val === 0) {
        return "0";
    }
    if (!val) {
        return "";
    }
    return val
        .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

/**
 * Dátumpikkerhez kell tenni
 * @param date
 * @param needTime
 * @returns {string|null}
 */
export const convertDateToHyphenFormat = (date, needTime) => {
    if (!date) {
        return null;
    }
    date = new Date(date);

    if (needTime) {
        return (
            date.toLocaleDateString("hu-HU").replaceAll(" ", "-").replaceAll(".", "") +
            " " +
            date.toLocaleTimeString("hu-HU", { hour12: false, hour: "2-digit", minute: "2-digit" })
        );
    }

    return date.toLocaleDateString("hu-HU").replaceAll(" ", "-").replaceAll(".", "");
};

export const COURSE_TYPES = {
    KAMGYAK: "kamgyak",
};

export const ROLES = {
    GLOBAL_ADMINISTRATOR: "global_administrator",
    CHAMBER_ADMINISTRATOR: "chamber_administrator",
    NORMAL_USER: "course_level",
};