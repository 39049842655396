import { Accordion, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import AccordionSum from "./AccordionSum";
import Header from "./Header";

export default function SkeletonListPage(props) {
    return (
        <Grid item container spacing={2}>
            <Header
                title="Kamarák" //töltelékszöveg
                skeleton
                breadcrumbs={{
                    "master-datas": "Törzsadatok", //töltelékszöveg
                    chambers: "Kamarák", //töltelékszöveg
                }}
            />
            <Grid item xs={12}>
                <Skeleton width="100%">
                    <Accordion>
                        <AccordionSum />
                    </Accordion>
                </Skeleton>
            </Grid>
            <Grid item container xs={12}>
                <Skeleton width="100%" height="800px" variant="rect" />
            </Grid>
        </Grid>
    );
}
