import React from "react";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import AccordionSum from "../../Common/AccordionSum";
import i18next from "i18next";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./Partials/Form";

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block",
    },
}));

function Edit(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Header
                breadcrumbs={{
                    contents: i18next.t("sidebar.contents"),
                    "system-messages": i18next.t("sidebar.system_messages"),
                    "system-messages-edit": i18next.t("system_messages.edit"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("system_messages.edit")} />
                    <AccordionDetails className={classes.accordionDetails}>
                        <Form systemMessagesId={props.match.params.systemMessagesId} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default Edit;
