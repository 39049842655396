import React from "react";

export default class Component extends React.Component {
    /**
     * Update an item in the state with the given key
     *
     * Note: currently supports root level value updates
     *
     * @param {string} key
     * @param {*} value
     */
    updateState(key, value) {
        let state = this.state;

        state[key] = value;

        this.setState(state);
    }
}
