import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import TranslationsTable from "./TranslationsTable";
import { connect } from "react-redux";
import SkeletonListPage from "../../Common/SkeletonListPage";
import translationsActions from "../../../store/translations/actions.js";
import Filter from "../../Common/Filter";
import i18next from "i18next";
import Loading from "../../Common/Loading";

function TranslationsList(props) {
    useEffect(() => {
        props.filter();
    }, []);

    const { loading, data, search, languages } = props;
    const handleChange = (event) => {
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });
    };
    const handleValueChange = (event) => {
        props.changeForm("search", {
            ...search,
            value: { ...search.value, [event.target.name]: event.target.value },
        });
    };

    let langField = [];
    for (let key of languages) {
        langField.push(
            <Grid item md key={key}>
                <TxtField
                    value={search.value[key] || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleValueChange}
                    label={`${i18next.t("translations.value")} (${key})`}
                    id={key}
                    name={`${key}`}
                />
            </Grid>
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        props.filter(search);
    };

    const filtForm = (
        <>
            <Grid item md>
                <TxtField
                    value={search.key || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    label={i18next.t("translations.key")}
                    id="key"
                    name="key"
                />
            </Grid>
            {langField}
        </>
    );

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                title="Nyelvesítés"
                breadcrumbs={{
                    administration: i18next.t("sidebar.administration"),
                    translations: i18next.t("sidebar.translations"),
                }}
            />
            <Filter filterForm={filtForm} defaultForm={props.defaultForm} onSubmit={handleSubmit} />
            <Grid item xs={12}>
                <TranslationsTable
                    data={data}
                    search={search}
                    languages={languages}
                    gridApi={gridApi}
                    onGridReady={onGridReady}
                />
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { loading, data, search, languages } = state.translations;
    return { loading, data, search, languages };
}

const actionCreators = {
    filter: translationsActions.filter,
    defaultForm: translationsActions.defaultForm,
    changeForm: translationsActions.changeForm,
};

export default connect(mapState, actionCreators)(TranslationsList);
