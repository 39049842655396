import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import "../../styles/calendar.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment/locale/hu";
import moment from "moment";
import i18next from "i18next";
import { getImpersonationToken, getJWTToken } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
    calendarDiv: {
        minHeight: "max-content",
        height: "calc(100vh - 100px)",
        width: "100%",
        margin: "auto",
    },
    calendar: {
        color: colors.primary,
        border: "0px",
        width: "100%",
        flex: "1 1",
        minHeight: "580px",
    },
    barPast: {
        backgroundColor: colors.lightGrey,
        color: colors.black,
    },
    barFuture: {
        backgroundColor: colors.darkPrimary,
        color: colors.white,
    },
    barProgress: {
        backgroundColor: colors.lightBlue,
        color: colors.black,
    },
}));

const localizer = momentLocalizer(moment);

export default function CourseCalendar(props) {
    const formats = {
        weekdayFormat: (date, culture, localizer) => localizer.format(date, "dddd", culture),
        dayFormat: (date, culture, localizer) => localizer.format(date, "DD dddd", culture),
    };
    const classes = useStyles();

    const events = [];

    if (props.courses.past && props.courses.future && props.courses.progress) {
        for (let course of props.courses.past) {
            events.push({
                title: course.shortName,
                start: new Date(1000 * course.startDate),
                end: new Date(1000 * course.endDate),
                classname: classes.barPast,
                id: course.id
            });
        }

        for (let course of props.courses.future) {
            events.push({
                title: course.shortName,
                start: new Date(1000 * course.startDate),
                end: new Date(1000 * course.endDate),
                classname: classes.barFuture,
                id: course.id
            });
        }

        for (let course of props.courses.progress) {
            events.push({
                title: course.shortName,
                start: new Date(1000 * course.startDate),
                end: new Date(1000 * course.endDate),
                classname: classes.barProgress,
                id: course.id
            });
        }
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        if (isSelected) {
            const url = `${process.env.REACT_APP_API_URL}/moodle/open?token=${getJWTToken()}&course=${
                event.id
            }${getImpersonationToken() ? `&impersonation=${getImpersonationToken()}` : ``}`;

            window.location.href = url;
        }

        return {
            className: event.classname,
        };
    };
    return (
        <div className={classes.calendarDiv}>
            <Calendar
                formats={formats}
                className={classes.calendar}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                messages={{
                    next: i18next.t("mainpage.next"),
                    previous: i18next.t("mainpage.previous"),
                    today: i18next.t("mainpage.coursecalendar.today"),
                    month: i18next.t("mainpage.coursecalendar.month"),
                    week: i18next.t("mainpage.coursecalendar.week"),
                    day: i18next.t("mainpage.coursecalendar.day"),
                    agenda: i18next.t("mainpage.coursecalendar.agenda"),
                    showMore: (total) => `+${total} kurzus`,
                }}
                views={["month", "week", "day"]}
            />
        </div>
    );
}
