// First we need to import axios.js
import axios from "axios";
import loginService from "../services/login";
import { history } from "../store";
import {getImpersonationToken, getIsziirUserLoggedIn, getJWTToken, setIsziirUserLoggedIn} from "./AuthHelper";

// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    responseType: "json",
});

// Where you would set stuff like your 'Authorization' header, etc ...
/*
const AUTH_TOKEN = "AUTH TOKEN FROM INSTANCE";
instance.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
*/

/*
Using interceptors, it runs on each request so if the token changes (refreshes) then the next request picks up the new token.
Check for existing values in the request to allow overriding of the header.
Consider we are using any token generator and updating token in local storage.
Here we are using keyclock object that is stored in sessionStorage
*/
instance.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            //const token = JSON.parse(sessionStorage.getItem("keyCloak")).token;
            const AUTH_TOKEN = getJWTToken();
            const IMPERSONATION_TOKEN = getImpersonationToken();
            if (AUTH_TOKEN) {
                config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
                config.headers["x-impersonation"] = IMPERSONATION_TOKEN;
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Add a 401 response interceptor
instance.interceptors.response.use(
    function (response) {
        if (response.headers["auth-valid-to"]) {
            localStorage.setItem("authValidTo", response.headers["auth-valid-to"]);
        }
        
        return response;
    },
    function (error) {
        if (error.response && error.response.status) {
            if (401 === error.response.status) {
                //401 hiba esetén ide fut be
                loginService.logout();
                if(getIsziirUserLoggedIn() === 'yes'){
                    setIsziirUserLoggedIn('yes', 'yes');
                }
                history.push("/login");
                return Promise.reject(error);
            } else if (404 === error.response.status) {
                history.push("/404");
                return Promise.reject(error);
            } else if (403 === error.response.status) {
                history.push("/403");
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
