import React from "react";
import { Redirect, Route } from "react-router-dom";
import {isLogin, getIsziirUserLoggedIn, hasPermission} from "../utils/AuthHelper";
import ChangePassword from "./ChangePassword";
import IsziirUserInvalidateModal from "./IsziirUserInvalidateModal";
import { connect } from "react-redux";
import PrivacyStatement from "./PrivacyStatement";
import { needLogoutImpersonationBtn } from "../services/login";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {

    if(rest.hasRole && isLogin()){
        let hasRole = false;

        for(let i = 0; i < rest.hasRole.length; i++){
            if(hasPermission(rest.hasRole[i])){
                hasRole = true;
                break;
            }
        }

        if(!hasRole){
            return <Redirect to="/403" />;
        }
    }

    return isLogin() ? (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    {!needLogoutImpersonationBtn() &&
                    rest.user &&
                    (rest.user.password_expires_at === null ||
                        new Date() >= new Date(rest.user.password_expires_at)) ? (
                        <ChangePassword
                            defaultOpen={true}
                            title={"Lejárt jelszó megújítása"}
                            contentText={"Lejárt a jelszava, legyen szíves egy újat megadni!"}
                        />
                    ) : null}

                    {!needLogoutImpersonationBtn() &&
                    rest.user &&
                    rest.privacyStatementActive &&
                    rest.privacyStatementActive.id !== rest.user.privacy_statement_id ? (
                        <PrivacyStatement isFirstLogin={rest.user.privacy_statement_id === null}/>
                    ) : null}
                    {!needLogoutImpersonationBtn() && rest.user && rest.user.isziir_user_id != null ? (
                        <IsziirUserInvalidateModal/>
                    ) : null}
                    <Component {...props} />
                </Layout>
            )}
        />
    ) : (
        <Redirect to="/login"/>
    )
};

function mapState(state) {
    const { user } = state.authentication;
    const { privacyStatementActive } = state.pages;
    return { user, privacyStatementActive };
}

export default connect(mapState, null)(PrivateRoute);
