import { types } from "./types";
import settingsService from "../../services/settings";

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function changeForm(varibleName, form) {
    return (dispatch) => {
        const data = { varibleName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch(request({ form }));

        return settingsService.filter(form).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.FETCH_REQUEST, data };
    }
    function success(data) {
        return { type: types.FETCH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.FETCH_FAILURE, error };
    }
}

function post(value, id) {
    return (dispatch) => {
        dispatch(request({ value, id }));

        return settingsService.post(value, id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.POST_REQUEST, data };
    }
    function success(data) {
        return { type: types.POST_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.POST_FAILURE, error };
    }
}

function get(id) {
    return (dispatch) => {
        dispatch(request({ id }));

        return settingsService.get(id).then(
            (data) => {
                dispatch(success(data));
                return Promise.resolve(data);
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(data) {
        return { type: types.GET_REQUEST, data };
    }
    function success(data) {
        return { type: types.GET_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_FAILURE, error };
    }
}

const actions = {
    filter,
    post,
    get,
    changeForm,
    defaultForm,
};

export default actions;
