import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import {types} from "../../store/users/types";

function filter(form) {
    let helper = Object.assign({}, form);

    if (!helper.sort) {
        helper['sort'] = {};
        helper['sort']['sent_at'] = 'desc'
    }

    const searchForm = new SearchForm(
        helper,
        {
            generateFrom: "created_at",
            generateUntil: "created_at",
            sentFrom: "sent_at",
            sentUntil: "sent_at",
            type: "email_template_id",
        },
        {
            created_at: "date",
            sent_at: "date",
        },
        true
    );

    return API.get(process.env.REACT_APP_API_URL + "/email-logs", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(process.env.REACT_APP_API_URL + `/email-logs/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function fetchTypes() {
    return API.get(`/email-templates`, {
        params: {
            "email_log[null]": 0,
            sort: "name",
        },
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const emailLogService = {
    filter,
    get,
    fetchTypes,
    setInfo,
};

export default emailLogService;
