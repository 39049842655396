import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import SystemLogTable from "./SystemLogTable";
import { connect } from "react-redux";
import systemLogActions from "../../../store/systemLog/actions.js";
import Filter from "../../Common/Filter";
import Loading from "../../Common/Loading";
import DatePicker from "../../Common/DatePicker";
import Autocomplete from "../../Common/Autocomplete";
import i18next from "i18next";
import userActions from "../../../store/users/actions";
import axios from "axios";
import {setInfo} from "../../../utils/FilterHelper";
import {convertDateToHyphenFormat} from '../../../utils/AppConst';

function SystemLogList(props) {
    const { loading, search, data } = props;

    const [gridApi, setGridApi] = useState(null);
    const [shorter, setShorter] = useState(true);
    const [users, setUsers] = useState([]);
    const onGridReady = (params) => setGridApi(params.api);

    useEffect(() => {
        axios.all([props.filter(search)]).then(axios.spread(() => {}));
    }, []);

    const eventOptions = [
        { value: "created", label: i18next.t("logging.created"), key: 0 },
        { value: "updated", label: i18next.t("logging.updated"), key: 1 },
        { value: "deleting", label: i18next.t("logging.deleting"), key: 2 },
        { value: "restoring", label: i18next.t("logging.restoring"), key: 2 },
        { value: "activating", label: i18next.t("logging.activating"), key: 3 },
        { value: "inactivating", label: i18next.t("logging.inactivating"), key: 4 },
        { value: "course_enrol", label: i18next.t("logging.course_enrol"), key: 5 },
        {
            value: "course_dismiss",
            label: i18next.t("logging.course_dismiss"),
            key: 6,
        },
        { value: "exam_enrol", label: i18next.t("logging.exam_enrol"), key: 7 },
        {
            value: "exam_dismiss",
            label: i18next.t("logging.course_dismiss"),
            key: 8,
        },
        { value: "exam_generation", label: i18next.t("logging.exam_generation"), key: 9 },
        { value: "login", label: i18next.t("logging.login"), key: 10 },
        { value: "logout", label: i18next.t("logging.logout"), key: 11 },
        { value: "error", label: i18next.t("logging.error"), key: 12 },
        { value: "login", label: i18next.t("logging.login"), key: 13 },
        { value: "logout", label: i18next.t("logging.logout"), key: 14 },
        { value: "error", label: i18next.t("logging.error"), key: 15 },
        { value: "course_generation", label: i18next.t("logging.course_generation"), key: 16 },
        { value: "run_start", label: i18next.t("logging.run_start"), key: 17 },
        { value: "run_end", label: i18next.t("logging.run_end"), key: 18 },
        { value: "run_error", label: i18next.t("logging.run_error"), key: 19 },
        { value: "api_call_success", label: i18next.t("logging.api_call_success"), key: 20 },
        { value: "api_call_error", label: i18next.t("logging.api_call_error"), key: 21 },
        { value: "archive", label: i18next.t("logging.archive"), key: 22 },
        { value: "archive_error", label: i18next.t("logging.archive_error"), key: 23 },
        { value: "un_archive", label: i18next.t("logging.un_archive"), key: 24 },
        { value: "un_archive_error", label: i18next.t("logging.un_archive_error"), key: 25 },
    ];

    const typeOptions = [
        { value: "Chamber", label: i18next.t("logging.Chamber"), key: 0 },
        { value: "User", label: i18next.t("logging.User"), key: 1 },
        { value: "Setting", label: i18next.t("logging.Setting"), key: 2 },
        {
            value: "EmailTemplate",
            label: i18next.t("logging.EmailTemplate"),
            key: 3,
        },
        { value: "Translation", label: i18next.t("logging.Translation"), key: 4 },
        { value: "News", label: i18next.t("logging.News"), key: 5 },
        { value: "Message", label: i18next.t("logging.Message"), key: 6 },
        { value: "Page", label: i18next.t("logging.Page"), key: 7 },
        { value: "PageVersion", label: i18next.t("logging.PageVersion"), key: 8 },
        { value: "Import", label: i18next.t("logging.Import"), key: 9 },
        { value: "CourseEnrol", label: i18next.t("logging.CourseEnrol"), key: 10 },
        {
            value: "CourseDismiss",
            label: i18next.t("logging.CourseDismiss"),
            key: 11,
        },
        { value: "ExamEnrol", label: i18next.t("logging.ExamEnrol"), key: 12 },
        { value: "ExamDismiss", label: i18next.t("logging.ExamDismiss"), key: 13 },
        {
            value: "CourseGeneration",
            label: i18next.t("logging.CourseGeneration"),
            key: 14,
        },
        {
            value: "CourseManagement",
            label: i18next.t("logging.CourseManagement"),
            key: 15,
        },
        {
            value: "moodle_exam_results_update",
            label: i18next.t("logging.moodle_exam_results_update"),
            key: 16,
        },
    ];

    const propsSearch = {
        ...props,
        search: props.filter
    };

    const resetForm = (event) => {
        event.preventDefault();
        propsSearch.defaultForm();
        setInfo(propsSearch, "filter", {}, "sort", {"created_at" : "desc"});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInfo(propsSearch, "filter", search);
    };

    const handleDateChange = (date, name) => {
        let newDate = convertDateToHyphenFormat(date, false)
        props.changeForm('search', {
            ...search,
            [name]: newDate
        });
    };

    const handleChange = (event) => props.changeForm({ ...search, [event.target.name]: event.target.value });

    const userOptions = users.map((object, key) => {
        return {
            value: object,
            name: object.name + " / " + object.email,
            key: object.id,
        };
    });

    const selectUser = (user, element) => {
        if (user.email) user.name = user.name + " / " + user.email;

        let changeFormObj = { ...search };
        changeFormObj[element] = {
            value: user.value,
            name: user.value.name + " / " + user.value.email,
            key: user.value.id,
        };

        props.changeForm(changeFormObj);
    };

    const getUsers = (p) => {
        props.usersSearch({ name: p, withPermissions: 1, withTrashed: 0 }).then((r) => setUsers(r.data));
    };

    const filtForm = (
        <>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    id="user"
                    value={search.user || null}
                    options={userOptions}
                    getOptionLabel={(option) => option.name}
                    loading={props.userLoading}
                    getOptionSelected={(option, value) => option.key === value.key}
                    filterSelectedOptions
                    loadingText={i18next.t("permissions.loading")}
                    noOptionsText={i18next.t("permissions.nooption")}
                    freeSolo={shorter}
                    onInputChange={(event, val, reason) => {
                        setShorter(val.length < 2);
                        if (reason === "clear")
                            if (val.length < 2)
                                //this.props.context.defaultForm("formGlobal");
                                //console.log("clear, FIXME:qqqqqqqqq");
                                props.changeForm({ ...search, users: [] });
                        if (val.length > 1) getUsers(val);
                    }}
                    onChange={(event, values) => {
                        if (values)
                            selectUser(
                                {
                                    value: values.value,
                                    name: values.value.name + " / " + values.value.email,
                                    key: values.value.id,
                                },
                                "user"
                            );
                    }}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            placeholder={i18next.t("permissions.placeholder")}
                            variant="outlined"
                            label={i18next.t("permissions.user")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    id="event"
                    multiple
                    value={search.event || []}
                    options={eventOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(event, values) => {
                        if (values)
                            props.changeForm({
                                ...search,
                                event: values,
                            });
                    }}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            variant="outlined"
                            label={i18next.t("system_log.event")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    id="type"
                    multiple
                    value={search.type || []}
                    options={typeOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    onChange={(event, values) => {
                        if (values)
                            props.changeForm({
                                ...search,
                                type: values,
                            });
                    }}
                    renderInput={(params) => (
                        <TxtField {...params} variant="outlined" label={i18next.t("system_log.type")} margin="normal" />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "createdAtFrom")}
                    value={search.createdAtFrom}
                    id="createdAtFrom"
                    label={i18next.t("system_log.date") + " (" + i18next.t("system_log.from") + ")"}
                    name="createdAtFrom"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    onChange={(date) => handleDateChange(date, "createdAtUntil")}
                    value={search.createdAtUntil}
                    id="createdAtUntil"
                    label={i18next.t("system_log.date") + " (" + i18next.t("system_log.to") + ")"}
                    name="createdAtUntil"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    id="affected_user"
                    value={search.affected_user || null}
                    options={userOptions}
                    getOptionLabel={(option) => option.name}
                    loading={props.userLoading}
                    getOptionSelected={(option, value) => option.key === value.key}
                    filterSelectedOptions
                    loadingText={i18next.t("permissions.loading")}
                    noOptionsText={i18next.t("permissions.nooption")}
                    freeSolo={shorter}
                    onInputChange={(event, val, reason) => {
                        setShorter(val.length < 2);
                        if (reason === "clear") if (val.length < 2) props.changeForm({ ...search, users: [] });
                        if (val.length > 1) getUsers(val);
                    }}
                    onChange={(event, values) => {
                        if (values)
                            selectUser(
                                {
                                    value: values.value,
                                    name: values.value.name + " / " + values.value.email,
                                    key: values.value.id,
                                },
                                "affected_user"
                            );
                    }}
                    renderInput={(params) => (
                        <TxtField
                            {...params}
                            placeholder={i18next.t("system_log.affected_user")}
                            variant="outlined"
                            label={i18next.t("system_log.affected_user")}
                            margin="normal"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.affected_course || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("system_log.affected_course")}
                    id="affected_course"
                    name="affected_course"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.affected_exam || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("system_log.affected_exam")}
                    id="affected_exam"
                    name="affected_exam"
                />
            </Grid>
        </>
    );

    if (loading) return <Loading />;

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.system_log")}
                breadcrumbs={{
                    administration: i18next.t("sidebar.administration"),
                    "system-log": i18next.t("sidebar.system_log"),
                }}
            />
            <Filter filterForm={filtForm} defaultForm={resetForm} onSubmit={handleSubmit} />
            <Grid item container xs={12}>
                <SystemLogTable
                    loading={props.loading}
                    tableLoading={props.tableLoading}
                    data={data}
                    search={search}
                    filter={props.filter}
                    info={props.info}
                    setInfo={props.setInfo}
                />
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { loading, data, search, info, tableLoading } = state.systemLog;
    const userLoading = state.users.loading;
    return { loading, data, search, userLoading, info, tableLoading };
}

const actionCreators = {
    filter: systemLogActions.filter,
    changeForm: systemLogActions.changeForm,
    defaultForm: systemLogActions.defaultForm,
    usersSearch: userActions.search,
    setInfo: systemLogActions.setInfo,
};

export default connect(mapState, actionCreators)(SystemLogList);
