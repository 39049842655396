import { types } from "./types";
import elearningCourseCategoriesService from "../../services/elearningCourseCategories";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FILTER_REQUEST, form });
        return elearningCourseCategoriesService.filter(form).then(
            (data) => {
                dispatch({ type: types.FILTER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILTER_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getParentInfo(parentId) {
    return (dispatch) => {
        dispatch({ type: types.GET_PARENT_INFO_REQUEST, parentId });
        return elearningCourseCategoriesService.getParentInfo(parentId).then(
            (data) => {
                dispatch({ type: types.GET_PARENT_INFO_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_PARENT_INFO_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function create(parentId, form) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST, parentId, form });
        return elearningCourseCategoriesService.create(parentId, form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS, parentId, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, parentId, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(parentId, form, id) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, parentId, form });

        return elearningCourseCategoriesService.update(parentId, form, id).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, parentId, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, parentId, error });
                return Promise.reject(error);
            }
        );
    };
}

function get(parentId, id) {
    return (dispatch) => {
        dispatch({ type: types.GET_REQUEST, parentId, id });

        return elearningCourseCategoriesService.get(parentId, id).then(
            (data) => {
                dispatch({ type: types.GET_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deleteRow(parentId, id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_REQUEST });
        return elearningCourseCategoriesService.deleteRow(parentId, id).then(
            (data) => {
                dispatch({ type: types.DELETE_SUCCESS });
                return Promise.resolve(data);
            },
            (err) => {
                console.log("DELETE ROW", err);
                const error = err.toString();
                dispatch({ type: types.DELETE_FAILURE, error });
                return Promise.reject(err);
            }
        );
    };
}

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

const actions = {
    filter,
    changeForm,
    getParentInfo,
    create,
    update,
    get,
    deleteRow,
    createForm,
    defaultForm,
};

export default actions;
