export const types = {
    DEFAULT_SEND_OUT_NEWS_FORM: "@@news/DEFAULT_SEND_OUT_NEWS_FORM",
    DEFAULT_FORM: "@@news/DEFAULT_FORM",
    CHANGE_FORM: "@@news/CHANGE_FORM",
    CHANGE_SEND_OUT_NEWS_FORM: "@@news/CHANGE_SEND_OUT_NEWS_FORM",
    DEFAULT_ROW: "@@news/DEFAULT_ROW",
    CREATE_FORM: "@@news/CREATE_FORM",
    CREATE_SEND_OUT_NEWS_FORM: "@@news/CREATE_SEND_OUT_NEWS_FORM",

    FETCH_REQUEST: "@@news/FETCH_REQUEST",
    FETCH_SUCCESS: "@@news/FETCH_SUCCESS",
    FETCH_FAILURE: "@@news/FETCH_FAILURE",

    POST_REQUEST: "@@news/POST_REQUEST",
    POST_SUCCESS: "@@news/POST_SUCCESS",
    POST_FAILURE: "@@news/POST_FAILURE",

    GET_REQUEST: "@@news/GET_REQUEST",
    GET_SUCCESS: "@@news/GET_SUCCESS",
    GET_FAILURE: "@@news/GET_FAILURE",

    CREATE_REQUEST: "@@news/CREATE_REQUEST",
    CREATE_SUCCESS: "@@news/CREATE_SUCCESS",
    CREATE_FAILURE: "@@news/CREATE_FAILURE",

    UPDATE_REQUEST: "@@news/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@news/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@news/UPDATE_FAILURE",

    DELETE_REQUEST: "@@news/DELETE_REQUEST",
    DELETE_SUCCESS: "@@news/DELETE_SUCCESS",
    DELETE_FAILURE: "@@news/DELETE_FAILURE",
};
