import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../styles/colors.scss";
import SubmitButton from "../Common/SubmitButton";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import { updateUserPrivacyStatementCourseAction } from "../../store/pages/actions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CancelButton from "../Common/CancelButton";
import Parser from "html-react-parser";
import i18next from "i18next";

function PrivacyStatementCourse(props) {
    const [open, setOpen] = React.useState(true);
    const [checkbox, setCheckbox] = React.useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = React.useState(false); //FIXME: true ra kell álitani de akkor nem tudja elfogadni ha nincs scroll tartalom

    const onSubmit = (e) => {
        e.preventDefault();

        props.post(props.courseId).then((data) => {
            setOpen(false);
            //props.addNotification('success', 'Adatvédelmi nyilatkozat sikeresen frissítve!')
            window.location.href = props.url;
        });
    };

    const handleChange = (e) => {
        setCheckbox(e.target.checked);
    };

    //FIXME ha nincs scroll bar nem műxik
    const onScroll = (e) => {
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            setCheckboxDisabled(false);
        }
    };

    return (
        <Dialog open={props.openValue}>
            <DialogTitle style={{ backgroundColor: colors.primary, color: colors.white }}>
                {Parser(props.title)}
            </DialogTitle>
            <form onSubmit={onSubmit} noValidate>
                <DialogContent>
                    <DialogContentText>{i18next.t("privacy.course_open")}</DialogContentText>
                    <div onScroll={onScroll} style={{ maxHeight: "350px", overflow: "auto" }}>
                        {Parser(props.text)}
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={checkboxDisabled}
                                checked={checkbox}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={
                            <>
                                {i18next.t("privacy.agree_pre")}
                                <a
                                    href={`${process.env.REACT_APP_API_URL}/privacy-policy-course/${props.courseId}/download`}
                                >
                                    {i18next.t("privacy.agree_link")}
                                </a>
                            </>
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton text={i18next.t("save")} type="submit" disabled={!checkbox} />

                    <CancelButton
                        variant="outlined"
                        text={i18next.t("cancel")}
                        onClick={() => props.openChange(false)}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
}

function mapState(state) {
    return {};
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
    post: updateUserPrivacyStatementCourseAction,
};

export default connect(mapState, actionCreators)(PrivacyStatementCourse);
