import API from "../../utils/API";

function filter(form) {
    const params = form.label ? form : {};

    return API.get(process.env.REACT_APP_API_URL + "/settings", {
        params: params,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function post(value, id) {
    return API.patch(process.env.REACT_APP_API_URL + `/settings/${id}`, {
        value: value,
    })

        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(id) {
    return API.get(process.env.REACT_APP_API_URL + `/settings/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const settingsService = {
    filter,
    post,
    get,
};

export default settingsService;
