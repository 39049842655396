import { types } from "./types";

const initialState = {
    loading: false,
    loadingSubCategory: false,
    form: {
        course: "",
        chambers: [],
        name: "",
        short_name: "",
        start: "",
        end: "",
        subCategory: -1,
        copyPrivacyStatement: false,
    },
    chambers: [],
    courses: [],
    chamberSubCategories: [],
    data: null,
    status: {
        id: null,
        data: {},
    },
};

export function elearningCourseGeneration(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
                chamberSubCategories: initialState.chamberSubCategories,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };

        case types.FETCH_CHAMBERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_CHAMBERS_SUCCESS:
            return {
                ...state,
                chambers: action.data.data,
                loading: false,
            };
        case types.FETCH_CHAMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.FETCH_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_COURSES_SUCCESS:
            return {
                ...state,
                courses: action.data.data,
                loading: false,
            };
        case types.FETCH_COURSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.GET_CHAMBER_SUB_CATEGORIES_REQUEST:
            return {
                ...state,
                loadingSubCategory: true,
            };
        case types.GET_CHAMBER_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                chamberSubCategories: action.data.data,
                loadingSubCategory: false,
            };
        case types.GET_CHAMBER_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                chamberSubCategories: initialState.chamberSubCategories,
                loadingSubCategory: false,
                error: action.error,
            };

        case types.GENERATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GENERATE_SUCCESS:
            return {
                ...state,
                status: {
                    id: action.data.data.id,
                    data: null,
                },
                loading: false,
            };
        case types.GENERATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.GET_STATUS_REQUEST:
            return {
                ...state,
            };

        case types.GET_STATUS_SUCCESS:
            return {
                ...state,
                status: {
                    id: state.status.id,
                    data: action.data.data,
                },
                loading: false,
            };

        case types.RESET_STATUS:
            return {
                ...state,
                status: {
                    id: null,
                    data: null,
                },
            };

        default:
            return state;
    }
}
