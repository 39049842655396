import { types } from "./types";
import moment from "moment";

const initialLanguages = { hu: null, en: null };

export const initialState = {
    loading: false,
    data: [],
    users: [],
    chambers: [],
    row: {
        title: initialLanguages,
        content: initialLanguages,
        published_at: moment(new Date()).format("YYYY-MM-DD"),
    },
    formGlobal: {
        email: false,
        user: null,
        master: [],
        administration: [],
        content: [],
        "e-learning": [],
        other: [],
    },
    formChamber: {
        email: false,
        user: null,
        chamber: null,
        master: [],
        administration: [],
        content: [],
        "e-learning": [],
        other: [],
    },
    userRoleByCourse: [],

    tableLoadingGlobal: false,
    dataGlobal: [],
    infoGlobal: {
        filter: {},
        sort: {
            lastName: "asc",
        },
        page: 1,
        metadata: {},
    },

    tableLoadingChamber: false,
    dataChamber: [],
    infoChamber: {
        filter: {},
        sort: {
            lastName: "asc",
        },
        page: 1,
        metadata: {},
    },
};

export function permissions(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.variableName]: initialState[action.variableName],
            };
        case types.DEFAULT_ROW:
            return {
                ...state,
                row: initialState.row,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                users: initialState.users,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.data.data,
            };
        case types.CREATE_FORM:
            return {
                ...state,
                form: initialState.form,
            };

        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_USER_COURSE_PERMISSIONS_REQUEST:
            return {
                ...state,
            };
        case types.GET_USER_COURSE_PERMISSIONS_FAILURE:
            return {
                ...state,
                userRoleByCourse: initialState.userRoleByCourse,
                error: action.error,
            };

        case types.GET_USER_COURSE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoleByCourse: action.data.data,
            };


        case types.FETCH_USERS_GLOBAL_REQUEST:
            return {
                ...state,
                tableLoadingGlobal: true,
            };
        case types.FETCH_USERS_GLOBAL_SUCCESS:
            return {
                ...state,
                tableLoadingGlobal: false,
                infoGlobal: {
                    ...state.infoGlobal,
                    metadata: action?.data?.data?.metadata,
                },
                dataGlobal: action?.data?.data?.data,
            };
        case types.FETCH_USERS_GLOBAL_FAILURE:
            return {
                ...state,
                tableLoadingGlobal: false,
                dataGlobal: [],
                error: action.error,
            };
        case types.FETCH_SET_INFO_GLOBAL:
            return {
                ...state,
                infoGlobal: {
                    ...state.infoGlobal,
                    [action.key]: action.value,
                },
            };

        case types.FETCH_USERS_CHAMBER_REQUEST:
            return {
                ...state,
                tableLoadingChamber: true,
            };
        case types.FETCH_USERS_CHAMBER_SUCCESS:
            return {
                ...state,
                tableLoadingChamber: false,
                infoChamber: {
                    ...state.infoChamber,
                    metadata: action?.data?.data?.metadata,
                },
                dataChamber: action?.data?.data?.data,
            };
        case types.FETCH_USERS_CHAMBER_FAILURE:
            return {
                ...state,
                tableLoadingChamber: false,
                dataChamber: [],
                error: action.error,
            };
        case types.FETCH_SET_INFO_CHAMBER:
            return {
                ...state,
                infoChamber: {
                    ...state.infoChamber,
                    [action.key]: action.value,
                },
            };
        default:
            return state;
    }
}
