import API from "../../utils/API";

function post(form) {
    return API.post(`/change-password`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const changePasswordService = {
    post,
};

export default changePasswordService;
