import { types } from "./types";
import importService from "../../services/importData";

function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}
export function defaultState() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_STATE });
    };
}

function changeProgress(progress) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_PROGRESS, progress });
    };
}

function startImport(importType, form) {
    return (dispatch) => {
        dispatch({ type: types.START_IMPORT_REQUEST, form });
        const progressFunc = (progressEvent) => {
            const data = {
                loaded: progressEvent.loaded,
                total: progressEvent.total,
            };
            dispatch({ type: types.CHANGE_UPLOAD_STATUS, data });
        };

        return importService.startImport(importType, form, progressFunc).then(
            (data) => {
                dispatch({ type: types.START_IMPORT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_IMPORT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function checkImport(id) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_IMPORT_REQUEST, id });

        return importService.checkImport(id).then(
            (data) => {
                dispatch({ type: types.CHECK_IMPORT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHECK_IMPORT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    createForm,
    changeForm,
    startImport,
    checkImport,
    changeProgress,
    defaultState,
};

export default actions;
