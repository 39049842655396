import { types } from "./types";

const initialLanguages = { hu: "", en: "" };

const initialState = {
    loading: false,
    data: [],
    kereso: { key: "", name: "", subject: "" },
    row: {
        key: "",
        name: initialLanguages,
        subject: initialLanguages,
        content: initialLanguages,
        variables: initialLanguages,
    },
};

export function emailTemplates(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.varibleName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                kereso: initialState.kereso,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                kereso: action.data.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        default:
            return state;
    }
}
