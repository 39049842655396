import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import i18next from "i18next";

function getPrintVal(val) {
    if (val[0] == "chambers") {
        return val[1] == null ? "-" : val[1].join(", ");
    }

    if (val[0] == "subCategory" && val[1] == null) {
        return <i>nincs megadva</i>;
    }

    return val[1];
}

function getRows(data) {
    if (data.event == "error") {
        return (
            <TableRow key={"cg_err"}>
                <TableCell>Hibák</TableCell>
                <TableCell colSpan={2}>
                    <ul>
                        {Object.entries(data.changes).map((val) => {
                            return <li key={"cg_err_" + val[0]}>{val[1]}</li>;
                        })}
                    </ul>
                </TableCell>
            </TableRow>
        );
    } else {
        return Object.entries(data.changes.before).map((val) => {
            return (
                <TableRow key={"cg_" + val[0]}>
                    <TableCell>{i18next.t(`system_log.field.CourseGeneration.${val[0]}`)}</TableCell>
                    <TableCell colSpan={2}>{getPrintVal(val)}</TableCell>
                </TableRow>
            );
        });
    }
}

export default function CourseGeneration(props) {
    const { data } = props;

    return (
        <React.Fragment>
            {data.type == "CourseGeneration" ? getRows(data) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}
