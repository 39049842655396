import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import notificationsActions from "../../../../store/notifications/actions.js";
import instance from "../../../../utils/API";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import QuestionInfo from "./QuestionInfo";
import Table from "./Table/index.js";

export default function RescoringDialog({
    examId,
    question,
    setQuestion,
    students,
    student,
    idColumn,
    setStudentToRescore,
    refreshData,
}) {
    const [all, setAll] = useState(false);
    const rows = student ? [student] : students;
    rows.sort((a, b) => {
        const nameA = a.student;
        const nameB = b.student;
        return nameA.localeCompare(nameB);
    });
    
    const [points, setPoints] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (question) {
            setPoints(
                rows.map((r) => {
                    const attempt = r.attempts.find((a) => a.slot === question.slot);
                    return { id: r.id, point: attempt.point, fullAttempt: attempt };
                })
            );
        } else {
            setPoints([]);
        }
    }, [question]);

    if (!question) return null;

    const handleClose = () => {
        setQuestion(null);
        setStudentToRescore(null);
    };

    const handleSubmit = () => {
        const { maxPoint } = question;

        const minPoint = -1 * maxPoint;

        if (points.some((p) => p.point > maxPoint || p.point < minPoint)) {
            dispatch(
                notificationsActions.addNotification(
                    "error",
                    "A pontszámoknak minimum (" + minPoint + ") és maximum (" + maxPoint + ") érték közzé kell lenniük!"
                )
            );
            return;
        }

        instance
            .post("/e-learning/exam-management/" + examId.toString() + "/regrade", {
                slot: question.slot,
                scoring: points.reduce((acc, curr) => {
                    acc[curr.id] = {
                        fraction: curr.point / maxPoint,
                        attemptId: curr.fullAttempt.attemptId,
                        questionId: curr.fullAttempt.questionId,
                    };
                    return acc;
                }, {}),
            })
            .then(() => {
                dispatch(notificationsActions.addNotification("success", "A pontszámok mentése sikeres volt!"));
                handleClose();
                refreshData();
            })
            .catch(() => {
                dispatch(notificationsActions.addNotification("error", "A pontszámok mentése sikertelen volt!"));
            });
    };

    return (
        <Dialog open={!!question} onClose={() => setQuestion(null)} fullWidth maxWidth="md">
            <DialogTitle
                style={{
                    backgroundColor: "#008554",
                    color: "white",
                }}
            >
                "{question.name}" pontok szerkesztése
            </DialogTitle>
            <DialogContent style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <QuestionInfo question={question} />
                <FormControlLabel
                    control={
                        <Switch
                            checked={all}
                            onChange={() => {
                                setAll(!all);
                            }}
                            color="primary"
                        />
                    }
                    label="Pontszámok módosítása mindenkinél"
                />
                <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                    <Typography variant="body1">Tanulók pontszámai</Typography>
                </div>
                <Table idColumn={idColumn} rows={rows} points={points} setPoints={setPoints} all={all} />
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose} text="Mégsem" />
                <SubmitButton onClick={handleSubmit} text="Módosítások mentése" />
            </DialogActions>
        </Dialog>
    );
}
