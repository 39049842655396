import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(
        form,
        {
            examStartFrom: "examStart",
            examStartUntil: "examStart",
            examEndFrom: "examEnd",
            examEndUntil: "examEnd",
        },
        {
            examStart: "datetime",
            examEnd: "datetime",
        }
    );

    let sendSearch = searchForm.getSend();

    return API.get(process.env.REACT_APP_API_URL + "/e-learning/exam-management/", {
        params: sendSearch,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getData(id) {
    return API.get(process.env.REACT_APP_API_URL + `/e-learning/exam-management/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function update(id, data) {
    return API.put(process.env.REACT_APP_API_URL + `/e-learning/exam-management/${id}`, data)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function deleteExam(id) {
    return API.delete(process.env.REACT_APP_API_URL + `/e-learning/exam-management/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const elearningExamManagementService = {
    filter,
    getData,
    update,
    deleteExam,
};

export default elearningExamManagementService;
