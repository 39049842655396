import React, { useEffect } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import * as AppConst from "../../utils/AppConst";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";

const CssKeyboardDateTimePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primary,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primary,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primary,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primary,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primary,
            },
            "&:hover fieldset": {
                borderColor: colors.primary,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primary,
            },
        },
    },
})(KeyboardDateTimePicker);

export default function DateTimePicker(props) {
    let value = props.value === "" || props.value === undefined || (((props.value?._d || props.value || "") + "").toLocaleLowerCase() === "invalid date") ? null : props.value;

    const [errorText, setErrorText] = React.useState("");
    const [error, setError] = React.useState(false);

    const disablePast = typeof props.disablePast !== typeof undefined ? props.disablePast : true;
    useEffect(() => {
        setErrorText("");
        setError(false);
    },[])

    const onBlur = () => {
        if (value) {
            if (typeof value === 'string') {
                value = moment(value, 'YYYY-MM-DD HH:mm:ss');
            }
            if (value.isValid()) {
                if (value.isSameOrAfter("2300-01-01")) {
                    setErrorText("Nem lehet nagyobb mint 2300-01-01");
                    setError(true);
                } else if (value.isSameOrBefore("1800-01-01")) {
                    setErrorText("Nem lehet kisebb mint 1800-01-01");
                    setError(true);
                } else {
                    setErrorText("");
                    setError(false);
                }
            } else {
                setErrorText("Hibás formátum");
                setError(true);
            }
        } else if ((props.value?._d || props.value || "") + "" === "Invalid Date") {
            setErrorText("Hibás formátum");
            setError(true);
        } else {
            setErrorText("");
            setError(false);
        }
    };

    return (
        <CssKeyboardDateTimePicker
            {...props}
            disablePast={disablePast}
            ampm={false}
            autoOk
            value={value}
            variant="inline"
            inputVariant="outlined"
            invalidDateMessage={`Hibás formátum`}
            maxDateMessage={`Nem lehet nagyobb, mint 2300.01.01.`}
            minDateMessage={`Nem lehet kisebb, mint 1800.01.01.`}
            format={AppConst.DATE_PICKER_FORMAT_DATE_TIME}
            minDate={moment("1800-01-01")}
            maxDate={moment("2300-01-01")}
            fullWidth
            helperText={errorText ? errorText : props.helperText}
            error={error || props.error}
            onBlur={onBlur}
            size="small"
        />
    );
}
