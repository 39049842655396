import React from "react";
import Header from "../../Common/Header";
import "../../../styles/enrollment.scss";
import TreeView from "../../Common/TreeView/TreeView";
import Component from "../../../lib/Component";
import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Tab,
} from "@material-ui/core";
import TxtField from "../../Common/TxtField";
import Alert from "@material-ui/lab/Alert";
import {
    addSelectedUserAction,
    clearSelectedUserAction,
    delSelectedUserAction,
    enrollmentAction,
    fetchCategorizedCoursesAction,
    fetchUsersAction,
    fetchNextUsersAction,
    selectCourseAction,
    saveSelectedUserAction,
} from "../../../store/enrollment/actions";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import notificationsActions from "../../../store/notifications/actions.js";
import { withTranslation } from "react-i18next";
import CheckBox from "../../Common/CheckBox";
import { hasPermission } from "../../../utils/AuthHelper";
import AntTabs from "../../Common/AntTabs";
import TabPanel from "../../Common/TabPanel";
import Import from "../../Common/Import";
import Permissions from "../../../lib/Permissions";
import importActions from "../../../store/importData/actions";
import i18next from "i18next";
import Users from "./Users";
import SelectedUsers from "./SelectedUsers";
import Filters from "./Filters";
import KamgyakWarningMessage from "./KamgyakWarningMessage";
import MassImportInfo from "../../Common/MassImportInfo.js";

const TYPE = "enrollment";

class Enrollment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emptyHideCb: 1,
            filters: {
                query: "",
                enrolled: null, // null|yes|no
            },
            emailNotification: false,
            selectAll: false,
            value: 0,
            radio: "enrollOnly", // "enrollOnly"|"enrollAndCreate"
        };
    }

    componentDidMount() {
        this.props.clearSelectedUserAction();
        this.props.fetchCategorizedCoursesAction(TYPE, this.state.emptyHideCb);
    }

    /**
     * Get the users
     */
    getUsers(course) {
        this.state.course = course;
        this.props.fetchUsersAction(TYPE, course.id);
    }

    nextPageUsers(currentViewed) {
        this.props.fetchNextUsersAction(
            TYPE,
            this.state.course.id,
            currentViewed,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    emptyHideCbChange(event) {
        const val = event.target.checked ? 1 : 0;
        this.setState({ emptyHideCb: val });
        this.props.fetchCategorizedCoursesAction(TYPE, val);
    }

    selectCourse(course) {
        if (this.props.currentSelected > 0 && !window.confirm(i18next.t("elearning.confirmation"))) {
            return false;
        }
        this.resetFilters();
        this.props.clearSelectedUserAction();
        this.props.selectCourseAction(course);
        this.updateState("emailNotification", false);
        this.getUsers(course);
        this.props.importDefaultState();
    }

    handleUserSelection(event, selectedUser) {
        if (event.target.checked) {
            if (!this.props.selectedUsers.includes(selectedUser.id)) this.props.addSelectedUserAction(selectedUser.id);
        } else {
            this.props.delSelectedUserAction(selectedUser.id);
        }
        this.props.saveSelectedUser(
            this.state.course.id,
            selectedUser.id,
            event.target.checked,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    selectAll(value) {
        this.props.saveSelectedUser(
            this.state.course.id,
            -1,
            value,
            this.state.filters.query,
            this.state.filters.enrolled
        );

        const users = this.props.users.list;

        if (value) {
            users.forEach((user) => {
                user.selected = true;
            });
        } else {
            users.forEach((user) => {
                if (!user.enrolled) {
                    user.selected = false;
                }
            });
            this.props.clearSelectedUserAction();
        }
        this.updateState("selectAll", value);
    }

    resetFilters() {
        let filters = this.state.filters;
        filters["enrolled"] = null;
        filters["query"] = null;
        this.updateState("filters", filters);
    }

    setEnrollmentFilter(value) {
        let filters = this.state.filters;
        filters["enrolled"] = value;
        this.updateState("filters", filters);
        this.props.fetchNextUsersAction(
            TYPE,
            this.state.course.id,
            0,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    setQueryFilter(value) {
        if (!this.state.course) {
            return;
        }

        let filters = this.state.filters;
        filters["query"] = value;
        this.updateState("filters", filters);
        this.props.fetchNextUsersAction(
            TYPE,
            this.state.course.id,
            0,
            this.state.filters.query,
            this.state.filters.enrolled
        );
    }

    save() {
        let data = {
            courseid: this.props.selectedCourse.id,
            sendEmail: this.state.emailNotification,
        };

        this.props.enrollmentAction(data).then(
            (data) => {
                this.props.fetchNextUsersAction(
                    TYPE,
                    this.state.course.id,
                    0,
                    this.state.filters.query,
                    this.state.filters.enrolled
                );
                this.updateState("emailNotification", false);
                this.props.addNotification("success", i18next.t("elearning.enrollment.success"));
            },
            (err) => {
                this.props.addNotification("error", i18next.t("elearning.enrollment.error"));
            }
        );
    }

    renderUsersCard() {
        return (
            <Card>
                {this.props.selectedCourse ? (
                    <CardHeader
                        title={i18next.t("elearning.course_management", {
                            course: this.props.selectedCourse.name,
                        })}
                    />
                ) : (
                    <>
                        <CardHeader title={i18next.t("elearning.management")} />
                        <CardContent>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Alert severity="info">{i18next.t("elearning.select")}</Alert>
                            </Box>
                        </CardContent>
                    </>
                )}
                <KamgyakWarningMessage course={this.props.selectedCourse} />
                {this.props.selectedCourse && !this.props.selectedCourse.isKamgyakType ? (
                    this.props.loadingRightSide ? (
                        <CardContent>
                            <Skeleton variant="rect" height={120} />
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Filters
                                type={TYPE}
                                setQueryFilter={(e) => this.setQueryFilter(e.target.value)}
                                setEnrollmentFilter={(val) => this.setEnrollmentFilter(val)}
                                loadingSave={this.props.loadingSave}
                                filters={this.state.filters}
                            />

                            <Box marginTop={3} />

                            <FormControlLabel
                                control={
                                    <CheckBox
                                        disabled={this.props.loadingSave}
                                        id="checkbox-select-all"
                                        onClick={(e) => {
                                            this.selectAll(e.target.checked);
                                        }}
                                        checked={this.state.selectAll}
                                        color="primary"
                                    />
                                }
                                label={i18next.t("elearning.select_all")}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <Users
                                        loadingUserList={this.props.loadingUserList || this.props.loadingSave}
                                        users={this.props.users}
                                        selectedUsers={this.props.selectedUsers}
                                        handleUserSelection={(e, user) => {
                                            this.handleUserSelection(e, user);
                                        }}
                                        nextPageUsers={(c) => {
                                            this.nextPageUsers(c);
                                        }}
                                    />
                                </Grid>

                                <SelectedUsers
                                    currentSelected={this.props.currentSelected}
                                    currentSelectedIsLoading={this.props.currentSelectedIsLoading}
                                    loadingSave={this.props.loadingSave}
                                    emailNotification={this.state.emailNotification}
                                    updateState={(e) => {
                                        this.updateState("emailNotification", e.target.checked);
                                    }}
                                    save={() => {
                                        this.save();
                                    }}
                                    btnLabel={i18next.t("elearning.selected.enroll")}
                                />
                            </Grid>
                        </CardContent>
                    )
                ) : (
                    ""
                )}
            </Card>
        );
    }

    render() {
        return (
            <div className="enrollment">
                <Grid item container spacing={2}>
                    <Header
                        title={i18next.t("sidebar.course_enrollment")}
                        breadcrumbs={{
                            "e-learning": i18next.t("sidebar.elearning"),
                            enrollment: i18next.t("sidebar.course_enrollment"),
                        }}
                    />
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Card>
                                    <CardHeader title={i18next.t("elearning.select_course")} />

                                    <CardContent>
                                        {hasPermission(Permissions.CourseEnrollment) && (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={this.state.emptyHideCb == 1}
                                                        onChange={(event) => {
                                                            this.emptyHideCbChange(event);
                                                        }}
                                                        value={1}
                                                        name="confirmationEmail"
                                                    />
                                                }
                                                label={i18next.t("elearning.hide")}
                                            />
                                        )}
                                        {this.props.loadingLeftSide ? (
                                            <Skeleton variant="rect" height={230} />
                                        ) : (
                                            <TreeView
                                                onItemSelected={(item) => this.selectCourse(item)}
                                                items={this.props.categorizedCourses}
                                            />
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={9}>
                                {hasPermission(Permissions.CourseEnrollment) ? (
                                    <>
                                        <AppBar position="static">
                                            <AntTabs
                                                value={this.state.value}
                                                onChange={(e, v) => this.setState({ value: v })}
                                                variant="fullWidth"
                                            >
                                                <Tab label={i18next.t("elearning.enrollment")} />
                                                <Tab label={i18next.t("elearning.mass_enrollment")} />
                                            </AntTabs>
                                        </AppBar>
                                        <TabPanel value={this.state.value} index={0}>
                                            {this.renderUsersCard()}
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={1}>
                                            <MassImportInfo />
                                            <FormControl>
                                                <FormLabel>{i18next.t("elearning.enrollment_type")}</FormLabel>
                                                <RadioGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onClick={() => this.setState({ radio: "enrollOnly" })}
                                                                checked={this.state.radio === "enrollOnly"}
                                                            />
                                                        }
                                                        label={i18next.t("elearning.enroll_only")}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onClick={() =>
                                                                    this.setState({ radio: "enrollAndCreate" })
                                                                }
                                                                checked={this.state.radio === "enrollAndCreate"}
                                                            />
                                                        }
                                                        label={i18next.t("elearning.enroll_and_create")}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            {this.state.radio === "enrollOnly" ? (
                                                <Import
                                                    course={this.props.selectedCourse}
                                                    importSuccess={() => {
                                                        this.props.clearSelectedUserAction();
                                                        this.getUsers(this.props.selectedCourse);
                                                    }}
                                                    type={"course-enrollment-only"}
                                                />
                                            ) : (
                                                <Import
                                                    course={this.props.selectedCourse}
                                                    importSuccess={() => {
                                                        this.props.clearSelectedUserAction();
                                                        this.getUsers(this.props.selectedCourse);
                                                    }}
                                                    type="course-enrollment"
                                                    rightSide={
                                                        <>
                                                            <FormControlLabel
                                                                control={
                                                                    <CheckBox
                                                                        color="primary"
                                                                        checked={
                                                                            this.props.importDataForm.confirmationEmail
                                                                        }
                                                                        onClick={(e) =>
                                                                            this.props.importDataChangeForm("form", {
                                                                                ...this.props.importDataForm,
                                                                                confirmationEmail: e.target.checked,
                                                                            })
                                                                        }
                                                                        disabled={this.props.importProgress}
                                                                    />
                                                                }
                                                                label={i18next.t("elearning.activation")}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <CheckBox
                                                                        color="primary"
                                                                        checked={
                                                                            this.props.importDataForm.emailNotification
                                                                        }
                                                                        onClick={(e) =>
                                                                            this.props.importDataChangeForm("form", {
                                                                                ...this.props.importDataForm,
                                                                                emailNotification: e.target.checked,
                                                                            })
                                                                        }
                                                                        disabled={this.props.importProgress}
                                                                    />
                                                                }
                                                                label={i18next.t("elearning.notification")}
                                                            />
                                                        </>
                                                    }
                                                />
                                            )}
                                        </TabPanel>
                                    </>
                                ) : (
                                    this.renderUsersCard()
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        categorizedCourses: state.enrollment.categorizedCourses,
        selectedUsers: state.enrollment.selectedUsers,
        selectedCourse: state.enrollment.selectedCourse,
        users: state.enrollment.users,
        loadingLeftSide: state.enrollment.loadingLeftSide,
        loadingRightSide: state.enrollment.loadingRightSide,
        loadingSave: state.enrollment.loadingSave,
        currentSelected: state.enrollment.currentSelected,
        currentSelectedIsLoading: state.enrollment.currentSelectedIsLoading,
        importDataForm: state.importData.form,
        importProgress: state.importData.progress,
        loadingUserList: state.enrollment.loadingUserList,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchCategorizedCoursesAction: (type, hideEmptyCategories) =>
            dispatch(fetchCategorizedCoursesAction(type, hideEmptyCategories)),
        addSelectedUserAction: (user) => dispatch(addSelectedUserAction(user)),
        delSelectedUserAction: (user) => dispatch(delSelectedUserAction(user)),
        clearSelectedUserAction: () => dispatch(clearSelectedUserAction()),
        fetchUsersAction: (type, courseId) => dispatch(fetchUsersAction(type, courseId)),
        fetchNextUsersAction: (type, courseId, allCount, search, enrolled) =>
            dispatch(fetchNextUsersAction(type, courseId, allCount, search, enrolled)),
        selectCourseAction: (course) => dispatch(selectCourseAction(course)),
        saveSelectedUser: (courseId, userId, selected, search, enrolled) =>
            dispatch(saveSelectedUserAction(TYPE, courseId, userId, selected, search, enrolled)),
        enrollmentAction: (form) => dispatch(enrollmentAction(form)),
        addNotification: (type, msg, redirect) => dispatch(notificationsActions.addNotification(type, msg, redirect)),
        importDefaultState: () => dispatch(importActions.defaultState()),
        importDataChangeForm: (variableName, form) => dispatch(importActions.changeForm(variableName, form)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Enrollment));
