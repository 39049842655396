import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TxtField from "../../Common/TxtField";
import EmailTemplatesTable from "./EmailTemplatesTable";
import Header from "../../Common/Header";
import { connect } from "react-redux";
import emailTemplatesActions from "../../../store/emailTemplates/actions.js";
import Loading from "../../Common/Loading";
import Filter from "../../Common/Filter";
import i18next from "i18next";

function EmailTemplatesList(props) {
    const { loading, kereso, user } = props;

    useEffect(() => {
        props.filter(kereso);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.filter(kereso);
    };
    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.filter({});
    };

    const handleChange = (event) =>
        props.changeForm("kereso", {
            ...kereso,
            [event.target.name]: event.target.value,
        });
    const filtForm = (
        <>
            <Grid item xs={12} md={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="key"
                    value={kereso.key || ""}
                    onChange={handleChange}
                    label={i18next.t("email_templates.key")}
                    name="key"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={kereso.name || ""}
                    onChange={handleChange}
                    id="name"
                    label={i18next.t("email_templates.name")}
                    name="name"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TxtField
                    variant="outlined"
                    margin="normal"
                    onChange={handleChange}
                    fullWidth
                    value={kereso.subject || ""}
                    id="subject"
                    label={i18next.t("email_templates.subject")}
                    name="subject"
                />
            </Grid>
        </>
    );
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => setGridApi(params.api);

    if (loading) return <Loading />;
    return (
        <div>
            <CssBaseline />
            <Grid item container spacing={2}>
                <Header
                    title="E-mail sablonok"
                    breadcrumbs={{
                        administration: i18next.t("sidebar.administration"),
                        "email-templates": i18next.t("sidebar.email_templates"),
                    }}
                />
                <Filter filterForm={filtForm} defaultForm={resetForm} onSubmit={handleSubmit} />
                <Grid item container xs={12}>
                    <EmailTemplatesTable
                        data={props.data}
                        userLang={user.language}
                        gridApi={gridApi}
                        onGridReady={onGridReady}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, kereso, data } = state.emailTemplates;
    const { user } = state.authentication;
    return { loading, kereso, data, user };
}

const actionCreators = {
    filter: emailTemplatesActions.filter,
    changeForm: emailTemplatesActions.changeForm,
    defaultForm: emailTemplatesActions.defaultForm,
};

export default connect(mapState, actionCreators)(EmailTemplatesList);
