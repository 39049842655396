import React from "react";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.scss";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primary,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primary,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primary,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primary,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primary,
            },
            "&:hover fieldset": {
                borderColor: colors.primary,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primary,
            },
        },
    },
})(TextField);

export default React.forwardRef((props, ref) => <CssTextField size="small" component="pre" ref={ref} {...props} />);
