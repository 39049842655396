import { types } from "./types";
import validationService from "../../services/validator";

function setErrors(data) {
    return (dispatch) => dispatch({ type: types.SET_ERRORS, data });
}
function setRules(data) {
    return (dispatch) => dispatch({ type: types.SET_RULES_SUCCESS, data });
}

function getRules(model, id) {
    return (dispatch) => {
        dispatch({ type: types.GET_RULES_REQUEST, model });

        return validationService.getRules(model, id).then(
            (data) => {
                dispatch({ type: types.GET_RULES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_RULES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    setErrors,
    getRules,
    setRules,
};

export default actions;
