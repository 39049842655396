import {FormControlLabel} from "@material-ui/core";
import CheckBox from "../../Common/CheckBox";
import i18next from "i18next";
import React from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    moreUsersBtn: {
        "&:hover" : {
            cursor: 'pointer'
        },
        marginTop: '10px',
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "#000",
        opacity: 0.5,
        zIndex: 100,
    },
    enrollmentUsersContainer: {
        position: "relative",
        height: '300px',
    },
    enrollmentUsers: {

    }
}));

function UserElement(index, user, props){
    const isDismiss = ['dismissal', 'exam-dismissal'].indexOf(props.type) !== -1;
    const disabled = !isDismiss && user.enrolled;
    const checked = (!isDismiss &&
                        (user.enrolled ||
                        props.selectedUsers.includes(user.id) ||
                        user.selected)
    ) || (isDismiss && (props.selectedUsers.includes(user.id) || user.selected));

    return <div key={index}>
        <FormControlLabel
            control={
                <CheckBox
                    onChange={(e) => {
                        user.selected = false;
                        props.handleUserSelection(e, user)
                    }
                    }
                    style={{paddingTop: 0, paddingBottom: 0}}
                    color="primary"
                />
            }
            label={user.name + " / " + user.email}
            disabled={disabled}
            checked={checked}
        />
    </div>
}

function Users(props) {
    const classes = useStyles();

    return <div className={classes.enrollmentUsersContainer}>
        {props.loadingUserList &&
        <div className={classes.loadingOverlay}>
            Betöltés...
        </div>
        }
        <div className="enrollment-users">
            {props.users && props.users.list && props.users.list.length
                ? props.users.list.map((user, index) => {
                    return UserElement(index, user, props);
                })
                : i18next.t("elearning.nooption")}
            {
                props.users.list && props.users.list.length < props.users.count &&
                <span
                    className={classes.moreUsersBtn}
                    onClick={(e) => {
                        props.nextPageUsers(props.users.list.length);
                    }}
                >További felhasználók</span>
            }
        </div>
    </div>
}

function mapState() {
    return {

    };
}

const actionCreators = {

};

export default connect(mapState, actionCreators)(Users);