import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use({
        type: "postProcessor",
        name: "customValidatorProcessor",
        process: (value, key, options, translator) => {
            Object.keys(options).map((v, k) => (value = value.replace(`:${v}`, options[v])));
            return value;
        },
    })
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        postProcess: ["customValidatorProcessor"],
        fallbackLng: "hu",
        debug: true,
        load: "languageOnly",
        keySeparator: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}`,
        },
    });

export default i18n;
