import { types } from "./types";

const initialLanguages = { hu: null, en: null };

export const initialState = {
    error: null,
    loading: false,
    selectedPageLoading: true,
    versionLoading: false,
    data: [],
    versions: [],
    privacyStatementActive: null,
    form: {
        id: null,
        content: initialLanguages,
        end_date: null,
        start_date: null,
    },
    selectedPage: {},
    defaultForm: {
        id: null,
        content: initialLanguages,
        end_date: null,
        start_date: null,
    },
};

export function pages(state = initialState, action) {
    switch (action.type) {
        case types.RESET:
            return initialState;
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_PAGE_VERSION_REQUEST:
            return {
                ...state,
                versionLoading: true,
            };
        case types.GET_PAGE_VERSION_SUCCESS:
            return {
                ...state,
                versionLoading: false,
                form: action.data.data,
            };
        case types.GET_PAGE_VERSION_FAILURE:
            return {
                ...state,
                versionLoading: false,
                form: state.defaultForm,
                error: action.error,
            };
        case types.GET_VERSIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_VERSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                versions: action.data.data,
            };
        case types.GET_VERSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.GET_PAGE_REQUEST:
            return {
                ...state,
                selectedPageLoading: true,
            };
        case types.GET_PAGE_SUCCESS:
            return {
                ...state,
                selectedPageLoading: false,
                selectedPage: action.data.data,
            };
        case types.GET_PAGE_FAILURE:
            return {
                ...state,
                selectedPageLoading: false,
                selectedPage: {},
                error: action.error,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.CREATE_FORM:
            return {
                ...state,
                form: state.defaultForm,
            };

        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.GET_PRIVACY_STATEMENT_ACTIVE_REQUEST:
            return {
                ...state,
            };
        case types.GET_PRIVACY_STATEMENT_ACTIVE_SUCCESS:
            return {
                ...state,
                privacyStatementActive: action.data.data,
            };
        case types.GET_PRIVACY_STATEMENT_ACTIVE_FAILURE:
            return {
                ...state,
                privacyStatementActive: null,
                error: action.error,
            };

        case types.UPDATE_USER_PRIVACY_STATEMENT_REQUEST:
            return {
                ...state,
            };
        case types.UPDATE_USER_PRIVACY_STATEMENT_SUCCESS:
            return {
                ...state,
            };
        case types.UPDATE_USER_PRIVACY_STATEMENT_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_REQUEST:
            return {
                ...state,
            };
        case types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_SUCCESS:
            return {
                ...state,
            };
        case types.UPDATE_USER_PRIVACY_STATEMENT_COURSE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_REQUEST:
            return {
                ...state,
            };
        case types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_SUCCESS:
            return {
                ...state,
            };
        case types.GET_PRIVACY_STATEMENT_ACTIVE_COURSE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}
