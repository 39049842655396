import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import colors from "../../styles/colors.scss";

const StyledAccordionSummary = withStyles({
    root: {
        backgroundColor: colors.lightPrimary,
        minHeight: "40px",
        margin: 0,
        "&$expanded": {
            minHeight: "40px",
        },
    },
    content: {
        margin: 0,
        "&$expanded": {
            margin: "6px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

const useStyles = makeStyles((theme) => ({
    heading: {
        color: colors.darkPrimary,
        fontSize: "large",
    },
}));

export default function AccordionSum(props) {
    const classes = useStyles();
    const skeleton = props.skeleton;

    return (
        <StyledAccordionSummary expandIcon={props.expandIcon} id={props.id}>
            <Typography component={"span"} className={classes.heading}>
                {props.text}
            </Typography>
        </StyledAccordionSummary>
    );
}
