import React from "react";
import i18next from "i18next";
import { connect } from "react-redux";
import OperationButton from "../../../Common/OperationButton";
import Table from "../../../Common/Table";

function BtnCellRenderer(params) {
    const id = params.data.id;
    return <OperationButton type="edit" to={`/pages/${id}/versions`} />;
}

function MainPagesTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("pages.title"),
                field: "title",
                valueGetter: (params) => {
                    if (params.data.title[props.userLang]) return params.data.title[props.userLang];

                    return "-";
                },
                sortable: true,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
        },
    };

    const [gridApi, setGridApi] = React.useState();
    const onGridReady = (params) => setGridApi(params.api);

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            onGridReady={onGridReady}
            gridApi={gridApi}
        />
    );
}
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(null, mapDispatchToProps)(MainPagesTable);
