import { types } from "./types";
import systemLogService from "../../services/systemLog";
import elearningExamGenerationService from "../../services/elearningExamGeneration";

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return systemLogService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function changeForm(form) {
    return (dispatch) => dispatch({ type: types.CHANGE_FORM, form });
}

function defaultForm() {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM });
}

function fetchCourses() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_COURSES_REQUEST });

        // FIXME: ez nem jó, a sysLogService-be kell hasonló funkció
        return elearningExamGenerationService.fetchCourses().then(
            (data) => {
                dispatch({ type: types.FETCH_COURSES_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_COURSES_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    filter,
    changeForm,
    defaultForm,
    fetchCourses,
    setInfo,
};

export default actions;
