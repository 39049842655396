import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import colors from "../../../styles/colors.scss";
import { Button } from "@material-ui/core";

export default function ViewContentIcon() {
    return (
        <Button style={{ backgroundColor: "transparent" }}>
            <VisibilityIcon style={{ color: colors.primary, fontSize: "1.75rem" }} />
        </Button>
    );
}
