export const types = {
    CREATE_FORM: "@@importdata/CREATE_FORM",
    CHANGE_FORM: "@@importdata/CHANGE_FORM",
    CHANGE_PROGRESS: "@@importdata/CHANGE_PROGRESS",
    CHANGE_UPLOAD_STATUS: "@@importdata/CHANGE_UPLOAD_STATUS",

    START_IMPORT_REQUEST: "@@importdata/START_IMPORT_REQUEST",
    START_IMPORT_SUCCESS: "@@importdata/START_IMPORT_SUCCESS",
    START_IMPORT_FAILURE: "@@importdata/START_IMPORT_FAILURE",

    CHECK_IMPORT_REQUEST: "@@importdata/CHECK_IMPORT_REQUEST",
    CHECK_IMPORT_SUCCESS: "@@importdata/CHECK_IMPORT_SUCCESS",
    CHECK_IMPORT_FAILURE: "@@importdata/CHECK_IMPORT_FAILURE",

    DEFAULT_STATE: "@@importdata/DEFAULT_STATE",
};
