import API from "../../utils/API";

function get(courseId) {
    return API.get(`/e-learning/course-achievements/${courseId}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function download(courseId) {
    return API.get(`/e-learning/course-achievements/${courseId}/export`, {
        responseType: "blob",
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const elearningCourseAchievementsService = {
    get,
    download,
};

export default elearningCourseAchievementsService;
