import React, { Component } from "react";
import { Checkbox, DialogActions, FormControlLabel, Grid, Typography } from "@material-ui/core";
import TxtField from "../../Common/TxtField";
import SubmitButton from "../../Common/SubmitButton";
import DeleteIcon from "../Icons/DeleteIcon";
import Table from "../../Common/Table";
import { connect } from "react-redux";
import Autocomplete from "../../Common/Autocomplete";
import permissionActions from "../../../store/permissions/actions";
import userActions from "../../../store/users/actions";
import notificationsActions from "../../../store/notifications/actions";
import chamberActions from "../../../store/chambers/actions";
import coursesActions from "../../../store/courses/actions";
import colors from "../../../styles/colors.scss";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import GreenTooltip from "../../Common/GreenTooltip";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CancelButton from "../../Common/CancelButton";
import i18next from "i18next";

function BtnCellRenderer(params) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const courseId = params.context.courseId;
    const userId = params.data.id;
    return (
        <>
            <GreenTooltip title={i18next.t("ob.delete")}>
                <Link to={"#"} onClick={() => handleClickOpen()}>
                    <DeleteIcon />
                </Link>
            </GreenTooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogContent>
                    <DialogContentText>{i18next.t("ob.confirmation")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SubmitButton
                        text={i18next.t("ob.delete")}
                        onClick={() => {
                            handleClose();

                            params.context
                                .coursePostPermissionsAction(courseId, userId, [])
                                .then((data) => {
                                    params.context["courseGetPermissionsTableAction"](courseId);
                                    params.context.addNotification("success", i18next.t("ob.success"));
                                })
                                .catch((error) => {
                                    params.context.addNotification("error", i18next.t("ob.error"));
                                });
                        }}
                    />
                    <CancelButton
                        text={i18next.t("cancel")}
                        onClick={() => {
                            handleClose();
                        }}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}


class CourseManagementEditList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            users: [],
            email: false,
            "e-learning": [],
            columnDefs: [
                {
                    headerName: i18next.t("permissions.email"),
                    field: "email",
                    cellRenderer: "defaultCellRenderWithTooltip",
                },
                {
                    headerName: i18next.t("permissions.last_name"),
                    field: "last_name",
                    cellRenderer: "defaultCellRenderWithTooltip",
                },
                {
                    headerName: i18next.t("permissions.first_name"),
                    field: "first_name",
                    cellRenderer: "defaultCellRenderWithTooltip",
                },
                {
                    headerName: i18next.t("permissions.chamber"),
                    field: "chambers",
                    sortable: false,
                    cellRenderer: "defaultCellRenderWithTooltip",
                    valueGetter: (params) => {
                        let formatted = "";

                        params.data.chambers.forEach((chamber) => {
                            if (formatted) formatted += ", ";
                            formatted += chamber.name[this.props.userLang];
                        });

                        return formatted;
                    },
                },
                {
                    headerName: i18next.t("permissions.permissions"),
                    field: "coursePermissions",
                    sortable: false,
                    cellRenderer: "defaultCellRenderWithTooltip",
                    valueGetter: (params) => {
                        let formatted = "";

                        params.data.coursePermissions.forEach((permission) => {
                            if (formatted) formatted += ", ";
                            formatted += permission.label;
                        });

                        return formatted;
                    },
                },
                {
                    headerName: i18next.t("table.operations"),
                    field: "operations",
                    cellRenderer: "btnCellRenderer",
                    sortable: false,
                    cellStyle: { "text-align": "center" },
                },
            ],
            options: {
                "e-learning": [],
            },
            frameworkComponents: {
                btnCellRenderer: BtnCellRenderer,
                defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
            },
            gridApi: "",
            shorter: true,
            onChangeWaitingLoading: false,
        };
    }

    componentDidMount() {
        this.props.courseGetPermissionsAction(this.props.courseId);
        //lista lekérés
        this.props.courseGetPermissionsTableAction(this.props.courseId);
    }

    getUsers(p) {
        this.setState({
            ...this.state,
            onChangeWaitingLoading: true,
        });
        const time = new Date().getTime().toString();
        localStorage.setItem("queryTime", time);
        this.props.usersSearch({ nameOrEmail: p, withPermissions: 1, withTrashed: 0 }).then((r) => {
            if (localStorage.getItem("queryTime") === time) {
                this.setState({ users: r.data });
                this.setState({
                    ...this.state,
                    onChangeWaitingLoading: false,
                });
            }
        });
    }

    handleUserChange(user) {
        this.setState({ user: user });

        this.props.getUserPermissionsByCourse(user.id, this.props.courseId).then((data) => {
            let eLearning = data.data;
            const newEL = [];
            eLearning.map((e) =>
                newEL.push({
                    name: e.name,
                    label: e.label,
                    id: e.id,
                })
            );

            this.setState({ "e-learning": newEL });
        });
    }

    /*handleMultiselectChange = (event, name, values) => {
        this.props.context.changeForm("formGlobal", {
            ...this.props.context.formGlobal,
            [name]: values,
        });
    }*/

    onGridReady = (params) => this.setState({ gridApi: params.api });

    handleSubmit(event) {
        if (!this.state.user) return;

        event.preventDefault();
        this.props
            .coursePostPermissionsAction(this.props.courseId, this.state.user.id, this.state["e-learning"], this.state["email"])
            .then(() => {
                this.setState({ user: null });
                this.setState({ email: false });
                this.setState({ "e-learning": [] });
                this.props.addNotification("success", i18next.t("permissions.success"));

                //lista ujra töltés
                this.props.courseGetPermissionsTableAction(this.props.courseId);
            });
    }

    render() {
        const collator = new Intl.Collator('hu', { numeric: true, sensitivity: 'base' });
        const ORDER = ['course', 'exam', 'elearning', 'special'].map(type => i18next.t('course_management.permission_groups.' + type));
        const userOptions = this.state.users
            ? this.state.users.map((object, key) => {
                return {
                    value: object,
                    name: object.name + " / " + object.email,
                    key: object.id,
                };
            })
            : [];

        const handleSearch = (event, val, reason) => {
            this.setState({ shorter: val.length < 2 });
            if (reason === "clear")
                this.setState({
                    user: null,
                    "e-learning": [],
                });
            if (val.length < 2) this.setState({ users: null });
            if (val.length > 1) this.getUsers(val);

        }
        return (
            <>
                <form style={{ marginBottom: "18px" }} onSubmit={this.handleSubmit}>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="user"
                                value={this.state.user || null}
                                options={userOptions}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.key === value.key}
                                filterSelectedOptions
                                loadingText={i18next.t("permissions.loading")}
                                noOptionsText={i18next.t("permissions.nooption")}
                                freeSolo={this.state.shorter}
                                loading={this.state.onChangeWaitingLoading}
                                onInputChange={(event, val, reason) => {
                                    handleSearch(event, val, reason);
                                }}
                                onChange={(_, values) => {
                                    if (values) this.handleUserChange(values.value);
                                }}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        placeholder={i18next.t("permissions.placeholder")}
                                        variant="outlined"
                                        label={i18next.t("permissions.user")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="e-learning"
                                options={this.props.coursePermissionsData.sort((a, b) => {
                                    return ORDER.indexOf(a.type) - ORDER.indexOf(b.type) || collator.compare(a.label, b.label);
                                })}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.id === value.id}
                                filterSelectedOptions
                                value={this.state["e-learning"] || []}
                                onChange={(_, values) => this.setState({ "e-learning": values })}
                                renderInput={(params) => (
                                    <TxtField
                                        {...params}
                                        variant="outlined"
                                        label={i18next.t("permissions.permissions")}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <SubmitButton
                                type="submit"
                                text={i18next.t("permissions.save")}
                                style={!this.state.user ? { backgroundColor: colors.disabled } : {}}
                                disabled={!this.state.user}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSubmit(e);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="email"
                                        checked={this.state.email}
                                        onChange={() => this.setState((prev) => ({...prev, email: !this.state.email}))}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant={"subtitle1"} color={this.state.user? "textPrimary" : "textSecondary"}>{i18next.t("permissions.send_email")}</Typography>
                                }
                                disabled={!this.state.user}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Table
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.coursePermissionsTableData}
                    frameworkComponents={this.state.frameworkComponents}
                    gridApi={this.state.gridApi}
                    onGridReady={this.onGridReady}
                    context={{
                        courseId: this.props.courseId,
                        addNotification: this.props.addNotification,
                        coursePostPermissionsAction: this.props.coursePostPermissionsAction,
                        courseGetPermissionsTableAction: this.props.courseGetPermissionsTableAction,
                    }}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.permissions.data,
        coursePermissionsData: state.courses.coursePermissionsData,
        coursePermissionsTableData: state.courses.coursePermissionsTableData,
        loading: state.permissions.loading,
        userLoading: state.users.loading,
        userLang: state.authentication.user.language,
        search: state.permissions.search,
        formGlobal: state.permissions.formGlobal,
        formChamber: state.permissions.formChamber,
        users: state.permissions.users,
        chambers: state.permissions.chambers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        courseGetPermissionsAction: (course) => dispatch(coursesActions.courseGetPermissionsAction(course)),
        fetch: (form) => dispatch(permissionActions.filter(form)),
        get: (p) => dispatch(permissionActions.get(p)),
        create: (form) => dispatch(permissionActions.create(form)),
        getUserPermissionsByCourse: (userId, courseId) =>
            dispatch(permissionActions.getUserPermissionsByCourse(userId, courseId)),
        fetchUsers: (filter) => dispatch(userActions.fetch(filter || {})),
        usersSearch: (filter) => dispatch(userActions.search(filter || {})),
        addNotification: (...params) => dispatch(notificationsActions.addNotification(...params)),
        fetchChambers: () => dispatch(chamberActions.filter()),
        defaultForm: (name) => dispatch(permissionActions.defaultForm(name)),
        changeForm: (variableName, form) => dispatch(permissionActions.changeForm(variableName, form)),
        coursePostPermissionsAction: (course, user, permissions, email) =>
            dispatch(coursesActions.coursePostPermissionsAction(course, user, permissions, email)),
        courseGetPermissionsTableAction: (course) => dispatch(coursesActions.courseGetPermissionsTableAction(course)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementEditList);
