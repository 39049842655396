import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import colors from "../../../styles/colors.scss";

class EditIcon extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary">
                <FontAwesomeIcon size="1x" icon={faPen} />
            </Button>
        );
    }
}

export default EditIcon;
