import { types } from "./types";

const initialState = {
    loading: false,
    data: [],
    search: {
        user_id: null,
        event: [],
        type: [],
        users: [],
        courses: [],
        affected_user: null,
        affected_course: "",
        affected_exam: "",
    },
    tableLoading: true,
    info: {
        filter: {},
        sort: {
            created_at: "desc",
        },
        page: 1,
        perpage: 25,
        metadata: {},
    },
};

export function systemLog(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                info: {
                    ...state.info,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                data: [],
                error: action.error,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                search: action.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
            };

        case types.FETCH_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_COURSES_SUCCESS:
            return {
                ...state,
                courses: action.data.data,
                loading: false,
            };
        case types.FETCH_COURSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.key]: action.value,
                },
            };

        default:
            return state;
    }
}
