import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { common as ckeditorConfig } from "../../lib/ckeditor/config";
import axios from "axios";
import i18next from "i18next";
import notificationsActions from "../../store/notifications/actions";
import { connect } from "react-redux";
import { getJWTToken } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
    titleText: {
        fontSize: "16px",
        lineHeight: "1",
        fontWeight: "400",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        marginBottom: theme.spacing(1),
    },
    helperText: {
        fontSize: "12px",
        margin: "3px 14px 0px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif;",
        color: "#f44336",
    },
    error: {
        color: "#f44336",
    },
}));

class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    this._sendRequest(file, resolve, reject);
                })
        );
    }

    // Aborts the upload process.
    abort() {}

    _sendRequest(file, resolve, reject) {
        const AUTH_TOKEN = getJWTToken();
        let formData = new FormData();
        formData.append("image", file);
        axios
            .post(process.env.REACT_APP_API_URL + "/images", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                },
            })
            .then((response) => {
                resolve({
                    default: process.env.REACT_APP_API_URL + "/" + response.data.path,
                });
            })
            .catch(function (error) {
                reject(error);
            });
    }
}

function CkeditorCustom(props) {
    const classes = useStyles();

    return (
        <div className={props.error ? classes.error : ""}>
            <Typography className={classes.titleText}>
                {props.label}
                {props.required ? (
                    <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                         *
                    </span>
                ) : (
                    ""
                )}
            </Typography>
            <CKEditor
                editor={ClassicEditor}
                name="content"
                config={ckeditorConfig}
                data={props.getValue("content", props.lang)}
                onChange={props.ckeditorChange}
                className={classes.editor}
                onInit={(editor) => {
                    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
                        return new MyUploadAdapter(loader);
                    };
                    editor.plugins.get("Notification").on("show:warning", (evt, _data) => {
                        evt.stop();
                        props.addNotification("error", i18next.t("layout.image.upload.error"), null, {
                            autoHideDuration: 5000,
                        });
                    });
                }}
            />
            {props.helperText ? (
                <span
                    className={classes.helperText}
                    style={{
                        color: "f44336",
                    }}
                >
                    {props.helperText}
                </span>
            ) : (
                ""
            )}
        </div>
    );
}

function mapState() {
    return {};
}

const actionCreators = {
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(CkeditorCustom);
