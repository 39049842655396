import API from "../../utils/API";

function filter(form) {
    const params = form;

    return API.get(process.env.REACT_APP_API_URL + "/e-learning/course-categories", {
        params: params,
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getParentInfo(parentId) {
    return API.get(process.env.REACT_APP_API_URL + `/e-learning/course-categories/parent-info/${parentId}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function create(parentId, form) {
    return API.post(process.env.REACT_APP_API_URL + `/e-learning/course-categories/${parentId}/new`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function update(parentId, form, id) {
    return API.patch(process.env.REACT_APP_API_URL + `/e-learning/course-categories/${parentId}/edit/${id}`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function get(parentId, id) {
    return API.get(process.env.REACT_APP_API_URL + `/e-learning/course-categories/${parentId}/get/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function deleteRow(parentId, id) {
    return API.delete(process.env.REACT_APP_API_URL + `/e-learning/course-categories/${parentId}/delete/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const elearningCourseCategoriesService = {
    filter,
    getParentInfo,
    create,
    update,
    get,
    deleteRow,
};

export default elearningCourseCategoriesService;
