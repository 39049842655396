export const types = {
    DEFAULT_FORM: "@@permissions/DEFAULT_FORM",
    CHANGE_FORM: "@@permissions/CHANGE_FORM",
    DEFAULT_ROW: "@@permissions/DEFAULT_ROW",
    CREATE_FORM: "@@permissions/CREATE_FORM",

    FETCH_REQUEST: "@@permissions/FETCH_REQUEST",
    FETCH_SUCCESS: "@@permissions/FETCH_SUCCESS",
    FETCH_FAILURE: "@@permissions/FETCH_FAILURE",

    POST_REQUEST: "@@permissions/POST_REQUEST",
    POST_SUCCESS: "@@permissions/POST_SUCCESS",
    POST_FAILURE: "@@permissions/POST_FAILURE",

    GET_REQUEST: "@@permissions/GET_REQUEST",
    GET_SUCCESS: "@@permissions/GET_SUCCESS",
    GET_FAILURE: "@@permissions/GET_FAILURE",

    GET_USER_COURSE_PERMISSIONS_REQUEST: "@@permissions/GET_USER_COURSE_PERMISSIONS_REQUEST",
    GET_USER_COURSE_PERMISSIONS_SUCCESS: "@@permissions/GET_USER_COURSE_PERMISSIONS_SUCCESS",
    GET_USER_COURSE_PERMISSIONS_FAILURE: "@@permissions/GET_USER_COURSE_PERMISSIONS_FAILURE",

    CREATE_REQUEST: "@@permissions/CREATE_REQUEST",
    CREATE_SUCCESS: "@@permissions/CREATE_SUCCESS",
    CREATE_FAILURE: "@@permissions/CREATE_FAILURE",

    UPDATE_REQUEST: "@@permissions/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@permissions/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@permissions/UPDATE_FAILURE",

    DELETE_REQUEST: "@@permissions/DELETE_REQUEST",
    DELETE_SUCCESS: "@@permissions/DELETE_SUCCESS",
    DELETE_FAILURE: "@@permissions/DELETE_FAILURE",

    FETCH_USERS_GLOBAL_REQUEST: "@@permissions/FETCH_USERS_GLOBAL_REQUEST",
    FETCH_USERS_GLOBAL_SUCCESS: "@@permissions/FETCH_USERS_GLOBAL_SUCCESS",
    FETCH_USERS_GLOBAL_FAILURE: "@@permissions/FETCH_USERS_GLOBAL_FAILURE",

    FETCH_USERS_CHAMBER_REQUEST: "@@permissions/FETCH_USERS_CHAMBER_REQUEST",
    FETCH_USERS_CHAMBER_SUCCESS: "@@permissions/FETCH_USERS_CHAMBER_SUCCESS",
    FETCH_USERS_CHAMBER_FAILURE: "@@permissions/FETCH_USERS_CHAMBER_FAILURE",

    FETCH_SET_INFO_GLOBAL: "@@permissions/FETCH_SET_INFO_GLOBAL",

    FETCH_SET_INFO_CHAMBER: "@@permissions/FETCH_SET_INFO_CHAMBER",
};
