import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import TxtField from "../../Common/TxtField";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import LinkToButton from "../../Common/LinkToButton";
import chamberActions from "../../../store/chambers/actions";
import notificationsActions from "../../../store/notifications/actions";
import validator from "../../../store/validator/actions";
import Loading from "../../Common/Loading";
import Validator from "../../../utils/Validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "../../Common/CheckBox";
import AppBar from "@material-ui/core/AppBar";
import AntTabs from "../../Common/AntTabs";
import colors from "../../../styles/colors.scss";
import TabPanel from "../../Common/TabPanel";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Copy from "../../Common/Copy";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    tabs: {
        width: "100%",
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    isziirId: {
        paddingLeft: "25px",
        paddingRight: "20px",
    },
    fields: {
        margin: theme.spacing(0, 0, 3),
    },
    root: {
        backgroundColor: colors.white,
    },
    icon: {
        color: colors.primary,
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function ChambersForm(props) {
    const classes = useStyles();
    const { form, loading } = props;
    const chamberId = props.chamberId ? props.chamberId : null;
    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    //tabváltáshoz kell
    const [actualTab, setActualTab] = useState(0); //tabváltás
    const mainLang = "hu";
    const langCode = {
        0: "hu",
        1: "en",
    };
    const headerTexts = [i18next.t("hu"), i18next.t("en")];
    const changeTab = (event, newValue) => {
        setActualTab(newValue);
    };
    const lang = langCode[actualTab];
    //eddig

    const handleChange = (event) => {
        const name = event.target.name;
        let val = null;
        if (event.target.type === "checkbox") {
            // checkboxnál máshogy kezeljük
            val = event.target.checked ? 1 : 0;
            props.changeForm("form", {
                ...form,
                [name]: val,
            });
        } else {
            const value = event.target.value;

            if (name == "isziir_id") {
                props.changeForm("form", { ...form, [event.target.name]: value });
            } else {
                props.changeForm("form", {
                    ...form,
                    [name]: {
                        ...form[name],
                        [lang]: value,
                    },
                });
            }
        }
    };

    const handleBlur = (event) => {
        validator.setErrorsClient(event.target.name, lang);
        props.setValidatorErrors(validator.getErrors());
    };

    const copyAction = () => {
        let newForm = {};
        Object.assign(newForm, {
            ...form,
            name: { ...form.name, [lang]: form.name[mainLang] },
            short_name: { ...form.short_name, [lang]: form.short_name[mainLang] },
        });
        props.changeForm("form", newForm);
    };

    useEffect(() => {
        props.setValidatorErrors({});
        props.getValidationRules("chambers", chamberId);
        if (chamberId) {
            props.get(chamberId);
        } else {
            props.createForm();
        }
    }, []);

    const setTabByErrors = (validator) => {
        const firstLang = validator.getErrorFirstLang();
        if (firstLang) {
            const firstLangIndex = validator.getKeyByValue(langCode, firstLang);
            if (firstLangIndex != -1 && firstLangIndex != actualTab) {
                setActualTab(parseInt(firstLangIndex));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setValidatorErrors({});

        if (chamberId) {
            props
                .update(form, chamberId)
                .then(() => {
                    props.addNotification("success", i18next.t("chambers.form.success"), "/chambers");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("chambers.form.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        } else {
            props
                .create(form)
                .then(() => {
                    props.addNotification("success", i18next.t("chambers.form.success"), "/chambers");
                })
                .catch((error) => {
                    props.addNotification("error", i18next.t("chambers.form.error"));
                    validator.setReponseError(error);
                    props.setValidatorErrors(validator.getErrors());
                    setTabByErrors(validator);
                });
        }
    };
    if (loading) return <Loading />;
    return (
        <form onSubmit={handleSubmit} noValidate={true}>
            <Grid item xs={12} md={6} container spacing={0}>
                <div className={classes.tabs}>
                    <AppBar position="static">
                        <AntTabs
                            value={actualTab}
                            onChange={changeTab}
                            aria-label="simple tabs example"
                            className={classes.tabsBg}
                            variant="fullWidth"
                        >
                            {headerTexts.map((value, index) => (
                                <Tab label={value} key={index} />
                            ))}
                        </AntTabs>
                    </AppBar>
                    {headerTexts.map((v, i) => (
                        <TabPanel value={actualTab} index={i} key={i}>
                            <Grid container spacing={1} style={{ display: "block" }}>
                                <Grid item container justify="flex-end">
                                    {mainLang === langCode[i] ? "" : <Copy onClick={copyAction} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <TxtField
                                        required={validator.isRequired("name")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={form.name[lang] || ""}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => handleChange(event)}
                                        onBlur={(event) => handleBlur(event)}
                                        label={i18next.t("chambers.name")}
                                        id="name"
                                        name="name"
                                        error={validator.hasError("name", lang)}
                                        helperText={validator.getErrorText("name", lang)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TxtField
                                        required={validator.isRequired("short_name")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={form.short_name[lang] || ""}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => handleChange(event)}
                                        onBlur={(event) => handleBlur(event)}
                                        label={i18next.t("chambers.short_name")}
                                        id="short_name"
                                        name="short_name"
                                        error={validator.hasError("short_name", lang)}
                                        helperText={validator.getErrorText("short_name", lang)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TxtField
                                        required={validator.isRequired("isziir_id")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={form.isziir_id || ""}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => handleChange(event)}
                                        onBlur={(event) => handleBlur(event)}
                                        label={i18next.t("chambers.isziir_id")}
                                        id="isziir_id"
                                        name="isziir_id"
                                        error={validator.hasError("isziir_id")}
                                        helperText={validator.getErrorText("isziir_id")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                checked={form.active}
                                                onChange={(event) => handleChange(event)}
                                                value={1}
                                                name="active"
                                            />
                                        }
                                        label={i18next.t("chambers.active")}
                                    />
                                </Grid>
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    className={classes.submit}
                                    text={i18next.t("save")}
                                />
                                <LinkToButton to="/chambers">
                                    <CancelButton
                                        variant="outlined"
                                        className={classes.submit}
                                        text={i18next.t("cancel")}
                                    />
                                </LinkToButton>
                            </Grid>
                        </TabPanel>
                    ))}
                </div>
            </Grid>
        </form>
    );
}

function mapState(state) {
    const { form, chambers, loading } = state.chambers;
    const { user } = state.authentication;
    const validator = state.validator;
    return { form, chambers, loading, user, validator };
}

const actionCreators = {
    changeForm: chamberActions.changeForm,
    create: chamberActions.create,
    update: chamberActions.update,
    get: chamberActions.get,
    createForm: chamberActions.createForm,
    addNotification: notificationsActions.addNotification,
    setValidatorErrors: validator.setErrors,
    getValidationRules: validator.getRules,
};

export default connect(mapState, actionCreators)(ChambersForm);
