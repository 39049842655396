export const types = {
    FETCH_REQUEST: "@@pages/FETCH_REQUEST",
    FETCH_SUCCESS: "@@pages/FETCH_SUCCESS",
    FETCH_FAILURE: "@@pages/FETCH_FAILURE",

    GET_VERSIONS_REQUEST: "@@pages/GET_VERSIONS_REQUEST",
    GET_VERSIONS_SUCCESS: "@@pages/GET_VERSIONS_SUCCESS",
    GET_VERSIONS_FAILURE: "@@pages/GET_VERSIONS_FAILURE",

    GET_PAGE_REQUEST: "@@pages/GET_PAGE_REQUEST",
    GET_PAGE_SUCCESS: "@@pages/GET_PAGE_SUCCESS",
    GET_PAGE_FAILURE: "@@pages/GET_PAGE_FAILURE",

    CHANGE_FORM: "@@pages/CHANGE_FORM",
    CREATE_FORM: "@@pages/CREATE_FORM",

    CREATE_REQUEST: "@@pages/CREATE_REQUEST",
    CREATE_SUCCESS: "@@pages/CREATE_SUCCESS",
    CREATE_FAILURE: "@@pages/CREATE_FAILURE",

    UPDATE_REQUEST: "@@pages/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@pages/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@pages/UPDATE_FAILURE",

    DELETE_REQUEST: "@@pages/DELETE_REQUEST",
    DELETE_SUCCESS: "@@pages/DELETE_SUCCESS",
    DELETE_FAILURE: "@@pages/DELETE_FAILURE",

    GET_PAGE_VERSION_REQUEST: "@@pages/GET_PAGE_VERSION_REQUEST",
    GET_PAGE_VERSION_SUCCESS: "@@pages/GET_PAGE_VERSION_SUCCESS",
    GET_PAGE_VERSION_FAILURE: "@@pages/GET_PAGE_VERSION_FAILURE",

    GET_PRIVACY_STATEMENT_ACTIVE_REQUEST: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_REQUEST",
    GET_PRIVACY_STATEMENT_ACTIVE_SUCCESS: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_SUCCESS",
    GET_PRIVACY_STATEMENT_ACTIVE_FAILURE: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_FAILURE",

    UPDATE_USER_PRIVACY_STATEMENT_REQUEST: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_REQUEST",
    UPDATE_USER_PRIVACY_STATEMENT_SUCCESS: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_SUCCESS",
    UPDATE_USER_PRIVACY_STATEMENT_FAILURE: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_FAILURE",

    UPDATE_USER_PRIVACY_STATEMENT_COURSE_REQUEST: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_COURSE_REQUEST",
    UPDATE_USER_PRIVACY_STATEMENT_COURSE_SUCCESS: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_COURSE_SUCCESS",
    UPDATE_USER_PRIVACY_STATEMENT_COURSE_FAILURE: "@@pages/UPDATE_USER_PRIVACY_STATEMENT_COURSE_FAILURE",

    GET_PRIVACY_STATEMENT_ACTIVE_COURSE_REQUEST: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_COURSE_REQUEST",
    GET_PRIVACY_STATEMENT_ACTIVE_COURSE_SUCCESS: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_COURSE_SUCCESS",
    GET_PRIVACY_STATEMENT_ACTIVE_COURSE_FAILURE: "@@pages/GET_PRIVACY_STATEMENT_ACTIVE_COURSE_FAILURE",

    RESET: "@@pages/RESET",
};
