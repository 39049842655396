export const types = {
    FETCH_REQUEST: "@@translations/FETCH_REQUEST",
    FETCH_SUCCESS: "@@translations/FETCH_SUCCESS",
    FETCH_FAILURE: "@@translations/FETCH_FAILURE",

    PATCH_REQUEST: "@@translations/PATCH_REQUEST",
    PATCH_SUCCESS: "@@translations/PATCH_SUCCESS",
    PATCH_FAILURE: "@@translations/PATCH_FAILURE",

    CHANGE_FORM: "@@translations/CHANGE_FORM",
    DEFAULT_FORM: "@@translations/DEFAULT_FORM",
};
