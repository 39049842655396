import React, { useEffect, useRef, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import colors from "../../styles/colors.scss";
import Logo from "../../img/MKIK_vektor.svg";
import Copyright from "../Common/Copyright";
import SubmitButton from "../Common/SubmitButton";
import TxtField from "../Common/TxtField";
import HelmetTemplate from "../Common/HelmetTemplate";
import { connect } from "react-redux";
import loginActions from "../../store/login/actions.js";
import { CircularProgress, Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Validator from "../../utils/Validator";
import validator from "../../store/validator/actions";
import ReCAPTCHA from "react-google-recaptcha";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelButton from "../Common/CancelButton";
import notificationsActions from "../../store/notifications/actions";
import { Alert } from "@material-ui/lab";
import API from "../../utils/API";
import i18next from "i18next";
import IsziirUserInvalidateModalOnLogin from "../IsziirUserInvalidateModalOnLogin";
import { removeIsziirUserLoggedIn, getIsziirUserLoggedIn, isInactive } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 0),
    },
    title: {
        margin: "2% 0",
        color: "#22533e",
    },
    wrapper: {
        position: "relative",
        padding: "unset",
    },
    ReCAPTCHADiv: {
        position: "relative",
        padding: "unset",
        marginTop: "15px",
        textAlign: "center",
    },
    buttonProgress: {
        color: colors.primary,
        position: "absolute",
        top: "65%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    disabledButton: {
        backgroundColor: colors.disabled,
    },
    base: {
        padding: "10px",
    },
    containerMobile: {
        position: "relative",
        display: "block",
        verticalAlign: "middle",
    },
}));
const StyledLink = withStyles({
    root: {
        color: colors.primary,
    },
})(Link);

function SignIn(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [errorTxt, setErrorTxt] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [form, setForm] = useState({ email: null, password: null });

    const validator = new Validator(form, props.validator.validatorErorrs, props.validator.rules);

    const _reCaptchaRef = useRef(null);
    const [reCaptchaValue, setReCaptchaValue] = useState(null);
    const [needReCaptcha, setNeedReCaptchaValue] = useState(false);

    useEffect(() => {
        props.setValidatorErrors({});

        props.setValidatorRules({
            email: "required|email",
            password: "required",
        });
        props
            .needReCaptcha()
            .then((data) => {
                setNeedReCaptchaValue(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleChange = (value) => {
        //console.log("Captcha value:", value);
        setReCaptchaValue(value);
        // if value is null recaptcha expired
        if (value === null) setReCaptchaValue(null);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        removeIsziirUserLoggedIn();

        setErrorTxt(null);

        props
            .login(email, password, reCaptchaValue)
            .then((data) => {
                // Sikeres bejelentkezés
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data && error.response.data.error) {
                            setErrorTxt(error.response.data.error);
                            setNeedReCaptchaValue(error.response.data.showCaptcha);
                        } else {
                            setErrorTxt(props.t("auth.failed"));
                        }
                    } else {
                        setErrorTxt(null);
                        validator.setReponseError(error);
                        props.setValidatorErrors(validator.getErrors());
                    }
                }

                if (needReCaptcha) {
                    if (_reCaptchaRef && _reCaptchaRef.current) _reCaptchaRef.current.reset();
                    setReCaptchaValue(null);
                } else {
                    props
                        .needReCaptcha()
                        .then((data) => {
                            setNeedReCaptchaValue(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
    };

    const onChangeUser = (event) => {
        setErrorTxt(null);
        setEmail(event.target.value);
        setForm({ ...form, email: event.target.value });
    };

    const onChangePsw = (event) => {
        setErrorTxt(null);
        setPassword(event.target.value);
        setForm({ ...form, password: event.target.value });
    };
    const handleBlur = (event) => {
        setErrorTxt(null);
        validator.setErrorsClient(event.target.name);
        props.setValidatorErrors(validator.getErrors());
    };

    ///majd áttesszük külön fájlba

    const [openForgot, setOpenForgot] = useState(false);
    const [emailForgot, setEmailForgot] = useState("");
    const [errorTxtForgot, setErrorTxtForgot] = useState(null);
    const [loadingForgot, setLoadingForgot] = useState(false);
    const handleClickOpenForgot = () => {
        setErrorTxtForgot(null);
        setEmailForgot(email);
        setOpenForgot(true);
    };

    const handleCloseForgot = () => {
        setErrorTxtForgot(null);
        setEmailForgot("");
        setOpenForgot(false);
    };
    const handleSubmitForgot = (event) => {
        event.preventDefault();

        if (!errorTxtForgot) {
            setLoadingForgot(true);
            API.post("/users/forgotten-password", { email: emailForgot })
                .then(function (response) {
                    setLoadingForgot(false);
                    setOpenForgot(false);

                    if (response.data && response.data.err) {
                        props.addNotification("error", response.data.err, false, {
                            autoHideDuration: 10000,
                        });
                    } else {
                        props.addNotification("success", i18next.t("login.forgot.success"), false, {
                            autoHideDuration: 10000,
                        });
                    }
                })
                .catch(function (error) {
                    setLoadingForgot(false);
                    props.addNotification("error", i18next.t("login.error"), false, {
                        autoHideDuration: 10000,
                    });
                    console.log(error);
                });
        }
    };
    const onChangeEmailForgot = (event) => {
        setEmailForgot(event.target.value);
    };

    const handleBlurEmailForgot = (event) => {
        setErrorTxtForgot(null);

        if (!emailForgot) {
            setErrorTxtForgot(props.t("validation.required"));
        }
        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailForgot)) {
            setErrorTxtForgot(props.t("validation.email"));
        }
    };

    return (
        <Container component="main" maxWidth="xs" className={matches ? "" : classes.containerMobile}>
            <HelmetTemplate title="Belépés" />
            <CssBaseline />
            <div className={matches ? classes.base : ""}>
                <div className={classes.paper}>
                    <img className={classes.avatar} src={Logo} style={{ height: "20vh", width: "auto" }} alt={`Logo`} />
                    <Typography component="h1" variant="h4" className={classes.title}>
                        {i18next.t("login.mkik")}
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit} noValidate>
                        <TxtField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label={i18next.t("login.email")}
                            name="email"
                            autoComplete="email"
                            type="email"
                            value={email}
                            onChange={onChangeUser}
                            required={validator.isRequired("email")}
                            error={validator.hasError("email") || !!errorTxt}
                            helperText={validator.getErrorText("email")}
                            onBlur={(event) => handleBlur(event)}
                        />
                        <TxtField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label={i18next.t("login.password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={onChangePsw}
                            required={validator.isRequired("password")}
                            error={validator.hasError("password") || !!errorTxt}
                            helperText={validator.getErrorText("password") || errorTxt}
                            onBlur={(event) => handleBlur(event)}
                        />
                        <Grid container>
                            <Grid item xs>
                                <StyledLink href="#" onClick={handleClickOpenForgot}>
                                    {i18next.t("login.forgot")}
                                </StyledLink>
                                <Dialog
                                    fullWidth
                                    open={openForgot}
                                    onClose={handleCloseForgot}
                                    aria-labelledby="form-dialog-title"
                                >
                                    <DialogTitle id="form-dialog-title">{i18next.t("login.forgot")}</DialogTitle>
                                    <form>
                                        <DialogContent>
                                            <DialogContentText>{i18next.t("login.email_input")}</DialogContentText>
                                            <TxtField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label={i18next.t("login.email")}
                                                type="email"
                                                value={emailForgot}
                                                onChange={onChangeEmailForgot}
                                                fullWidth
                                                required={true}
                                                error={!!errorTxtForgot}
                                                helperText={errorTxtForgot}
                                                onBlur={handleBlurEmailForgot}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <CancelButton
                                                text={i18next.t("cancel")}
                                                disabled={loadingForgot}
                                                onClick={handleCloseForgot}
                                            />
                                            <SubmitButton
                                                text={i18next.t("login.new")}
                                                type="submit"
                                                variant="outlined"
                                                disabled={loadingForgot}
                                                onClick={handleSubmitForgot}
                                            />
                                        </DialogActions>
                                    </form>
                                </Dialog>
                            </Grid>
                        </Grid>
                        {needReCaptcha ? (
                            <div className={classes.ReCAPTCHADiv}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    ref={_reCaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    onChange={handleChange}
                                    hl={`hu`}
                                />
                                <p
                                    hidden={!validator.hasError("g-recaptcha-response")}
                                    style={{ color: "#f44336" }}
                                    className="MuiFormHelperText-root MuiFormHelperText-contained"
                                    id="ReCAPTCHA-helper-text"
                                >
                                    {validator.getErrorText("g-recaptcha-response")}
                                </p>
                            </div>
                        ) : null}

                        <div className={classes.wrapper}>
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={props.loading || (!reCaptchaValue && needReCaptcha)}
                                text={i18next.t("logging.login")}
                            />
                            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <SubmitButton
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={props.loading}
                                text={i18next.t("login.isziir")}
                                onClick={(event) => {
                                    removeIsziirUserLoggedIn();
                                    window.location.href = process.env.REACT_APP_ISZIIR_LOGIN_URL;
                                }}
                            />
                            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
            </div>
            <Box mt={4}>
                <Copyright />
            </Box>
            {props.snackbar.type ? (
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={props.snackbar.type !== ""}
                    autoHideDuration={
                        props.snackbar.options && props.snackbar.options.autoHideDuration
                            ? props.snackbar.options.autoHideDuration
                            : 7000
                    }
                    onClose={() => props.removeNotification()}
                >
                    <Alert severity={props.snackbar.type}>{props.snackbar.msg}</Alert>
                </Snackbar>
            ) : (
                ""
            )}
            {getIsziirUserLoggedIn() === "yes" && <IsziirUserInvalidateModalOnLogin />}
        </Container>
    );
}

function mapState(state) {
    const { loading } = state.authentication;
    const validator = state.validator;
    const { snackbar } = state.notifications;
    return { loading, validator, snackbar };
}

const actionCreators = {
    needReCaptcha: loginActions.needReCaptcha,
    login: loginActions.login,
    logout: loginActions.logout,
    setValidatorErrors: validator.setErrors,
    setValidatorRules: validator.setRules,
    addNotification: notificationsActions.addNotification,
    removeNotification: notificationsActions.removeNotification,
};

export default compose(withTranslation(), connect(mapState, actionCreators))(SignIn);
