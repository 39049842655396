import colors from "../../../styles/colors.scss";

export default (theme) => ({
    breadcrumbs: {
        color: colors.primary,
        fontWeight: "bold",
        fontSize: "large",
    },
    mainTitle: {
        marginBottom: theme.spacing(3),
    },
    title: {
        fontWeight: "bold",
    },
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    submit: {
        margin: theme.spacing(3, 3, 3, 0),
    },

    tabs: {
        width: "100%",
        margin: theme.spacing(3, 0),
        boxShadow: 3,
    },
    tabsBg: {
        backgroundColor: colors.primary,
    },
    subject: {
        margin: theme.spacing(3, 0, 3, 0),
    },
    titleText: {
        fontSize: "large",
        marginBottom: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
    heading: {
        color: colors.white,
        fontWeight: "bold",
        fontSize: "large",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    listActionsGrid: {
        justifyContent: "unset",
    },
    nameTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    buttonLink: {
        textDecoration: "none",
        "&:focus, &:hover, &:visited, &:link, &:active": {
            textDecoration: "none",
        },
    },
});
