/**
 * Keresési űrlapokat feldolgozó osztály ami módosítja a szervernek megfelelő formára a paramétereket
 */
class SearchForm {
    /**
     * @param formData A form adatok objektum formában
     * @param mapping Mappelési objektum. Amennyiben a field neve az űrlapon eltér a szűrési névtől, úgy használható. Pl: {chamber: 'chamber_id'}
     */
    constructor(formData, mapping, fieldTypes, isPaginated) {
        this.formData = formData ? Object.assign({}, formData) : {};
        this.mapping = mapping ?? {};
        this.isPaginated = isPaginated ?? false;
        this.fieldTypes = fieldTypes ?? {};
    }

    /**
     * Elküldendő form data generálása
     */
    getSend() {
        let send = {};
        const formData = this.isPaginated ? (this.formData.filter ?? []) : this.formData;

        for (const [field, value] of Object.entries(formData)) {
            const fieldToSend = this.mapping[field] ?? field;

            if (this.fieldTypes[fieldToSend] == "date") {
                const dateFilterName = this.getDateFilter(field, fieldToSend);
                if(this.isPaginated){
                    if(!send[dateFilterName[0]]){
                        send[dateFilterName[0]] = {};
                    }
                    send[dateFilterName[0]][dateFilterName[1]] = this.getDateFilterValue(value, field);
                }else{
                    send[dateFilterName] = this.getDateFilterValue(value, field);
                }
            } else if (this.fieldTypes[fieldToSend] == "datetime") {
                const dateFilterName = this.getDateFilter(field, fieldToSend);
                if (dateFilterName && value) {
                    if(this.isPaginated){
                        if(!send[dateFilterName[0]]){
                            send[dateFilterName[0]] = {};
                        }
                        send[dateFilterName[0]][dateFilterName[1]] = this.getDateTimeFilterValue(value, field);
                    }else{
                        send[dateFilterName] = this.getDateTimeFilterValue(value, field);
                    }
                }
            } else if (Array.isArray(value)) {
                // tömb kezelése
                if (value.length > 0) {
                    if (this.fieldTypes[fieldToSend] == "comma") {
                        send[fieldToSend] = value.map((object) => object.value).join(",");
                    } else {
                        if(this.isPaginated){
                            send[fieldToSend] = {
                                'in' : value.map((object) => object.value ?? object).join(",")
                            };
                        }else{
                            send[fieldToSend + "[in]"] = value.map((object) => object.value ?? object).join(",");
                        }
                    }
                }
            } else {
                if(value === null){
                    continue;
                }
                send[fieldToSend] = value;
            }
        }

        return this.getSendReturn(send);
    }

    getSendReturn (send){
        if(!this.isPaginated){
            return send;
        }else {
            return {
                filter: send,
                perpage: this.formData.perpage ?? 25,
                page: this.formData.page ?? 1,
                sort: this.formData.sort ?? [],
            }
        }
    }

    getDateFilter(fieldName, fieldToSend) {
        if (fieldName.substr(fieldName.length - 5) == "Until") {
            if(this.isPaginated){
                return [fieldToSend, 'lte'];
            }else{
                return fieldToSend + "[lte]";
            }
        } else if (fieldName.substr(fieldName.length - 4) == "From") {
            if(this.isPaginated){
                return [fieldToSend, 'gte'];
            }else{
                return fieldToSend + "[gte]";
            }
        }

        return null;
    }

    getDateFilterValue(value, fieldName) {
        var retVal = value;

        if ((value?._d || "") + "" === "Invalid Date") {
            return null;
        }

        if (retVal) {
            if(!value.set){
                return value;
            }

            if (fieldName.substr(fieldName.length - 5) == "Until") {
                retVal = value.set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss");
            } else if (fieldName.substr(fieldName.length - 4) == "From") {
                retVal = value.set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss");
            }
        }

        return retVal;
    }

    getDateTimeFilterValue(value, fieldName) {
        var retVal = value;
        if ((value?._d || "") + "" === "Invalid Date") {
            return null;
        }

        if (retVal) {
            retVal = value.format("YYYY-MM-DD HH:mm:ss");
        }

        return retVal;
    }
}

export default SearchForm;
