import React from "react";
import { TableCellText } from "../Components/Common/TableCellText";

export function defaultCellRenderWithTooltip(params) {
    //ha nyelvesített, visszaadja azt, ha nem, akkor az értéket

    if (params.context && params.context.lang && params.value[params.context.lang])
        return <TableCellText val={params.context.lang && params.value[params.context.lang]} />;
    return <TableCellText val={params.value} />;
}
