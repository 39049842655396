import { Box, DialogActions, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import i18next from "i18next";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import notificationsActions from "../../../../store/notifications/actions";
import { deleteRow, getVersionsAction, reset } from "../../../../store/pages/actions";
import colors from "../../../../styles/colors.scss";
import DeleteButton from "../../../Common/DeleteButton";
import DeleteCancelButton from "../../../Common/DeleteCancel";
import GreenTooltip from "../../../Common/GreenTooltip";
import OperationButton from "../../../Common/OperationButton";
import Table from "../../../Common/Table";
import DeleteIcon from "../../Icons/DeleteIcon";

function BtnCellRenderer(params) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const versionId = params.data.id;
    const pageId = params.data.page_id;
    return (
        <>
            <OperationButton to={`/pages/${pageId}/version/${versionId}/edit`} type="edit" />

            {/*TODO: ezt az egészet megoldani OperationButton-nel*/}
            <Box component="span" marginX={1} />
            {params.data.status === "future" && params.data.end_date === null && (
                <>
                    <GreenTooltip title={`Törlés`}>
                        <Link to={"#"} onClick={() => handleClickOpen()}>
                            <DeleteIcon />
                        </Link>
                    </GreenTooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{ backgroundColor: colors.darkError, color: colors.white }}
                        >
                            {i18next.t("ob.delete")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Biztosan törölni kívánja? A művelet nem visszavonható.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <DeleteCancelButton
                                text={"Mégsem"}
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <DeleteButton
                                text={"Törlés"}
                                onClick={() => {
                                    handleClose();

                                    params.context["delete"](pageId, versionId)
                                        .then((data) => {
                                            params.context["getVersions"](pageId);
                                            params.context.AddNotification("success", "Sikeres törlés");
                                        })
                                        .catch((error) => {
                                            params.context.AddNotification("error", "Hiba történt a törlés során");
                                        });
                                }}
                            />
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
}

const dateFunctionByType = {
    1: (date) => date.toLocaleDateString(i18next.language),
    2: (date) => date.toLocaleDateString(i18next.language) + " " + date.toLocaleTimeString("HU-hu").slice(0, -3),
};

function DateCellRenderer(params) {
    if (params.value && params.context.type) {
        return dateFunctionByType[params.context.type](new Date(params.value));
    }
    return "-";
}

function StatusCellRenderer(params) {
    switch (params.data.status) {
        case "future":
            return (
                <Box component="span" bgcolor="warning.main" color="white" p={1}>
                    {i18next.t("pages.future")}
                </Box>
            );
        case "active":
            return (
                <Box component="span" bgcolor="primary.main" color="white" p={1}>
                    {i18next.t("pages.active")}
                </Box>
            );
        case "archived":
            return (
                <Box component="span" bgcolor="text.disabled" p={1}>
                    {i18next.t("pages.archive")}
                </Box>
            );
        default:
            return (
                <Box component="span" bgcolor="text.disabled" p={1}>
                    {i18next.t("pages.unknown")}
                </Box>
            );
    }
}

function VersionPagesTable(props) {
    const state = {
        columnDefs: [
            {
                headerName: i18next.t("pages.start"),
                field: "start_date",
                cellRenderer: "dateCellRenderer",
                sort: "desc",
            },
            {
                headerName: i18next.t("pages.end"),
                field: "end_date",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: i18next.t("pages.status"),
                field: "status",
                cellRenderer: "statusCellRenderer",
                sortable: false,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "btnCellRenderer",
                cellStyle: { "text-align": "center" },
                sortable: false,
            },
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
    };

    const [gridApi, setGridApi] = React.useState();
    const onGridReady = (params) => setGridApi(params.api);

    useEffect(() => {
        return () => {
            props.reset();
        };
    }, []);

    return (
        <Table
            columnDefs={state.columnDefs}
            rowData={props.data}
            frameworkComponents={state.frameworkComponents}
            onGridReady={onGridReady}
            gridApi={gridApi}
            context={{
                delete: props.deletePageVersion,
                getVersions: props.getVersions,
                AddNotification: props.addNotification,
                type: props.type,
            }}
            newButtonText={i18next.t("pages.statement.new")}
            to={`/pages/${props.pageId}/version/new`}
            minWidth={200}
        />
    );
}

function mapStateToProps(state) {
    return {
        data: state.pages.versions,
        loading: state.pages.loading,
        userLang: state.authentication.user.language,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deletePageVersion: (pageId, versionId) => dispatch(deleteRow(pageId, versionId)),
        getVersions: (pageId) => dispatch(getVersionsAction(pageId)),
        addNotification: (type, message) => dispatch(notificationsActions.addNotification(type, message)),
        reset: () => dispatch(reset()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionPagesTable);
